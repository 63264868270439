import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Order, OrderResponse } from '../../types';
export interface OrdersSliceState {
  orders: Order[];
  truck_capacity: number;
  allOrdersCount: number;
}
const initialState: OrdersSliceState  = {
    orders: [],
    allOrdersCount: 0,
    truck_capacity: 0,
};

const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
      setOrders(state, action: PayloadAction<any>) {
        state.orders = action.payload;
      },
      setAllOrdersCount(state, action: PayloadAction<number>) {
        state.allOrdersCount = action.payload;
      },

    },
  })
  
  export const { setOrders, setAllOrdersCount } = orderSlice.actions;
  export default orderSlice.reducer;