import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CTable,
    CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow
} from "@coreui/react";

import { ProductItem, Pallet } from "../../../types";
import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";


interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    closeModal: () => void;
    cartData: any;
    productsData: ProductItem[];
    productType: string;
    onClick: (value: any) => void;
    removeFromCart: (value: number) => void;
}
const ProductCartModal = (props: ModalProps) => {
    const {isModalVisible, closeModal, headerText, bodyText, cartData, productsData, productType, onClick, removeFromCart} = props;

    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal}>
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{bodyText}</div>
                <CTable align="middle" className="mb-0" hover responsive>
                    <CTableHead color="light">
                        <CTableRow>
                            <CTableHeaderCell className="text-center">Id</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Article</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Grade point</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Selling price</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                    {cartData?.map((cartItem: any, index: number) => {
                        return (
                            <>
                                <CTableRow v-for="item in tableItems" key={index}>                             
                                    <CTableDataCell className="text-center">
                                        <div>{ cartItem?.set_articles ? cartItem?.set_articles[0] : cartItem}</div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                        <div> { 
                                            cartItem?.set_articles ? productsData.filter((setArticle) => {return setArticle.id === cartItem?.set_articles[0]})[0]?.article 
                                        : 
                                            productsData.filter((product: ProductItem) => {return product.id === cartItem})[0]?.article }</div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                        <div>{Math.round(Number( 
                                                cartItem?.set_articles ? productsData.filter((article) => {return article.id === cartItem?.set_articles[0]})[0]?.final_points
                                            :
                                            productsData.filter((product: ProductItem) => {return product.id === cartItem})[0]?.final_points
                                        ))}</div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                        <div>{Math.round(Number(
                                            cartItem?.set_articles ? productsData.filter((article) => {return article.id === cartItem?.set_articles[0]})[0]?.selling_price
                                            :
                                            productsData.filter((product: ProductItem) => {return product.id === cartItem})[0]?.selling_price
                                            ))}</div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                        <CButton
                                            shape="square"
                                            size="sm"

                                            onClick={() =>{
                                                cartItem?.set_articles ?
                                                    onClick(cartItem?.set_articles[0])
                                                : 
                                                    onClick(cartItem)
                                            }}

                                        >
                                            details
                                        </CButton>
                                        <CIcon
                                            color={'red'}
                                            icon={cilTrash}
                                            customClassName="nav-icon"
                                            onClick={()=>{removeFromCart(cartItem?.set_articles ? cartItem?.set_articles[0] : cartItem)}}
                                            style={{width:25, height: 25, marginLeft: 8}}
                                        /> 
                                    </CTableDataCell>                                   
                                </CTableRow>
                                {  
                                     cartItem?.set_articles && cartItem?.set_articles[1] ?
                                        <CTableRow v-for="item in tableItems" key={index}>
                                            <CTableDataCell className="text-center">
                                                <div>{cartItem?.set_articles[1] }</div>
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                <div> { productsData.filter((setArticle) => {return setArticle.id === cartItem?.set_articles[1]})[0]?.article }</div>
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                <div>{Math.round(Number(productsData.filter((article) => {return article.id === cartItem?.set_articles[1]})[0]?.final_points ))}</div>
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                <div>{Math.round(Number(productsData.filter((article) => {return article.id === cartItem?.set_articles[1]})[0]?.selling_price ))}</div>
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                <CButton
                                                    shape="square"
                                                    size="sm"

                                                    onClick={() => {                                                        
                                                        cartItem?.set_articles ?
                                                            onClick(cartItem?.set_articles[1])
                                                        : 
                                                            onClick(cartItem)                                                     

                                                    }}
                                                >
                                                    detials
                                                </CButton>
                                                <CIcon
                                                    color={'red'}
                                                    icon={cilTrash}
                                                    customClassName="nav-icon"
                                                    onClick={()=>{removeFromCart(cartItem?.set_articles ? cartItem?.set_articles[0] : cartItem)}}
                                                    style={{width:25, height: 25, marginLeft: 8}}
                                                /> 
                                            </CTableDataCell>
                                        </CTableRow> : null
                                }
                            </>
                        )
                    })}
                    </CTableBody>
                </CTable>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default ProductCartModal;
