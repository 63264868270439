import axios from "axios";
import {BACKEND_API_URL} from "../constants";

const api = axios.create({
    baseURL: BACKEND_API_URL, 
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

export const getOrders = async() => {
    return await api.get(
        'api/orders/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const placeOrder = async(form_data: any) => {
    return await api.post(
        'api/orders/',
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const acceptOrder = async(id: number) => {
    return await api.patch(
        `api/orders/${id}/approve/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const deleteOrder = async(id: number) => {
    return await api.delete(
        `api/orders/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const filterOrderByProductID = async (product_id: number) => {
    return await api.get(
        `api/orders/product/?product_id=${product_id}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const getOrdersCount = async() => {
    return await api.get(
        `api/orders/all-orders-count/`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const filterOrderByID = async(orderID: number) => {
    return await api.get(
        `api/orders/${orderID}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};