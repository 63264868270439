import { CForm, CRow, CCol, CFormLabel } from "@coreui/react";

import { ProductBrand,  ProductItem, DropDownItem, Usage, ProductSubCategory } from "../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { ELV_BLUE, FONT_15 } from "../../../constants";
import { t } from "i18next";
import Select, {components} from 'react-select';
const { Group } = components;
import 'react-datepicker/dist/react-datepicker.css';

interface PackageFilterProps {
    editPackage?: boolean;
    selectedTab: 0 | 1 | 2 | 3;
    minValue: number;
    maxValue: number;
    setCurrentBrandList: (value: string[]) => void;
    setCurrentSubGroupList: (value: string[]) => void;
    setCurrentArticleList: (value: string[]) => void; 
    setCurrentUsageList: (value: string[]) => void; 
    setMinValue: (value: number) => void;
    setMaxValue: (value: number) => void;
}

const PackageFiltersComponent = (props: PackageFilterProps) => {
    const {
        selectedTab, minValue, maxValue, setCurrentSubGroupList,
        setCurrentUsageList, setCurrentArticleList, setCurrentBrandList, setMinValue, setMaxValue
    } = props;
    const products: ProductItem[] = useSelector((state: RootState) => state.packageSlice.products);
    const setArticles: ProductItem[] = useSelector((state: RootState) => state.packageSlice.setArticles);
    const productBrands = useSelector((state: RootState) => state.productSlice.productBrands);
    const productSubCategories: ProductSubCategory[] = useSelector((state: RootState) => state.productSlice.productSubCategories);
    const productArticles = useSelector((state: RootState) => state.productSlice.productArticles);
    const productUsage = useSelector((state: RootState) => state.productSlice.productUsage);
 
    const brandOptions: DropDownItem[] = productBrands.filter((brand: ProductBrand) =>{
        return (products.some(item => item.brand == brand.name) || setArticles.some(item => item.brand == brand.name))
    }).map((brand: ProductBrand) => ({ value: brand.name, label: brand.name }));
    const articleOptions: DropDownItem[] = productArticles.filter((article: string) =>{
        return (products.some(item => item.article == article) || setArticles.some(item => item.article == article))
    }).map((article: string) => ({ value: article, label: article }));
    const usageOptions: DropDownItem[] = productUsage.filter((usage: Usage) =>{
        return (products.some(item => item.usage.name == usage.name) || setArticles.some(item => item.usage.name == usage.name))
    }).map((usage: Usage) => ({ value: usage.name, label: usage.name }));
    const subCatOptions: DropDownItem[] = productSubCategories.filter((subCat: ProductSubCategory ) =>{
        return (products.some(item => item.sub_category == subCat.name) || setArticles.some(item => item.sub_category == subCat.name))
    }).map((subCat: ProductSubCategory) => ({ value: subCat.name, label: subCat.name }));
   
    return (
        <CForm>
            {
            selectedTab !== 2 &&
                <CRow>
                    <CCol xs={3}>
                        <MultiRangeSlider
                            minValue={minValue}
                            maxValue={maxValue}
                            min={0}
                            max={100}
                            onInput={(e) => {
                                    setMinValue(e.minValue);
                                    setMaxValue(e.maxValue);
                                }}
                            onChange={(e: ChangeResult) => {
                                // This function changes values after dragging is done.
                                setMinValue(e.minValue);
                                setMaxValue(e.maxValue);
                            }}
                            label={true}
                            ruler={false}
                            style={{ border: "none", boxShadow: "none", padding: "15px 10px", marginTop: 50 }}
                            barLeftColor="white"
                            barInnerColor={ELV_BLUE}
                            barRightColor="white"
                            thumbLeftColor={ELV_BLUE}
                            thumbRightColor={ELV_BLUE}
                        />
                    </CCol>
                    <CCol xs={3} className='padding-top-16'>
                        <CFormLabel><b>{t('adminDashboard.Products.article')}</b></CFormLabel>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            noOptionsMessage={({ inputValue: string }) => 'Article not found.' }
                            options={ articleOptions }
                            onChange={(options: any) => {
                                setCurrentArticleList(
                                    options.map((option: any) => (option.value))
                                )
                            }} 
                            onInputChange = {(option: string)=>{
                                // setArticleSearchText(option)
                            }}
                            styles={{
                                multiValueLabel: (base) => ({
                                    ...base,
                                    backgroundColor: ELV_BLUE,
                                    color: 'white',
                                    fontSize: FONT_15,
                                }),
                            }}
                        />
                    </CCol>
                    
                    <CCol xs={3} className='padding-top-16'>
                        <CFormLabel><b>{t('adminDashboard.Products.usage')}</b></CFormLabel>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={usageOptions}
                            onChange={(options: any) => {
                                setCurrentUsageList(
                                    options.map((option: DropDownItem) => (option.value))
                                )
                            }}
                            styles={{
                                multiValueLabel: (base) => ({
                                    ...base,
                                    backgroundColor: ELV_BLUE,
                                    color: 'white',
                                    fontSize: FONT_15,
                                }),
                            }}
                        />
                    </CCol>
                    <CCol xs={3} className='padding-top-16'>
                        <CFormLabel><b>{t('adminDashboard.Products.brands')}</b></CFormLabel>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={brandOptions}
                            onChange={(options: any) => {
                                setCurrentBrandList(
                                    options.map((option: DropDownItem) => (option.value))
                                )
                            }}
                            styles={{
                                multiValueLabel: (base) => ({
                                    ...base,
                                    backgroundColor: ELV_BLUE,
                                    color: 'white',
                                    fontSize: FONT_15,
                                }),
                            }}
                        />
                    </CCol>
                    <CCol xs={3} className='padding-top-16'>
                        <CFormLabel><b>{t('adminDashboard.Products.category')}</b></CFormLabel>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={subCatOptions}
                            onChange={(options: any) => {
                                setCurrentSubGroupList(
                                    options.map((option: DropDownItem) => (option.value))
                                )
                            }}
                            styles={{
                                multiValueLabel: (base) => ({
                                    ...base,
                                    backgroundColor: ELV_BLUE,
                                    color: 'white',
                                    fontSize: FONT_15,
                                }),
                            }}
                        />
                    </CCol>
                </CRow>
            }
        </CForm>
    )
};

export default PackageFiltersComponent;