import axios from "axios";
import { StaffUser } from "../types";
import {BACKEND_API_URL} from "../constants";

const api = axios.create({
    baseURL: BACKEND_API_URL,   
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
  });
// API Routes for internal staff members.
export const createStaffUser = async(staffUser: StaffUser) => {
    return await api.post(
        'api/users/staff/',
        staffUser
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const updateStaffUser = async(id: number, staffUser: StaffUser) => {
    return await api.patch(
        `api/users/staff/${id}/`,
        staffUser
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const deleteStaffUser = async(id: number) => {
    return await api.delete(
        `api/users/staff/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};


export const getAllStaffUsers = async() => {
    return await api.get(
        'api/users/staff/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getStaffUserById = async(id: number) => {
    return await api.get(
        `api/users/staff/${id}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};