import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  CContainer,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilBell, cilMenu } from '@coreui/icons';
import { setSidebarShow } from '../../../redux/slices/AdminSlice';
import  AdminHeaderDropdown from './HeaderDropdownComponent';
import { RootState } from '../../../redux/store';

const AdminHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state: RootState) => state.adminSlice.sidebarShow)

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(setSidebarShow(!sidebarShow))}
        >
          <CIcon icon={cilMenu} size="lg" className='white'/>
        </CHeaderToggler>
        <CHeaderNav className="d-none d-md-flex me-auto">
        </CHeaderNav>
        {/* <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilBell} size="xl" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        <CHeaderNav className="ms-3">
          <AdminHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

export default AdminHeader
