import { useDispatch } from "react-redux";
import { setSuppliesList, setCompaniesList, setSalesPersonList, setYearsData, setScanningTime } from "../../../redux/slices/AdminSlice";
import { getAllSupplies, getAllCompanies, getAllSalesPerson, getYearlyProfitData, getUserScanningTime } from "../../../services/DashboardService";

export const AnalyticsUtils = () => {
    const dispatch = useDispatch();
    
    const getAllAnalyticsData = () => {
        getUserScanningTime().then((response: any) => {
          dispatch(setScanningTime(JSON.parse(response.data.data)));
        }).catch((e) => {
          console.log('scanning time error: ', e);          
          dispatch(setScanningTime([]));
        });

        getAllSupplies().then((response) => {
            dispatch(setSuppliesList(response.data.all_supplies));
          }).catch((e) => {
            console.log('Get All Supplies Error: ', e);  
            dispatch(setSuppliesList([]));
          });
      
          getAllCompanies().then((response) => { // get the names of all companies.
            dispatch(setCompaniesList(response.data.data));
          }).catch((e) => {
            dispatch(setSalesPersonList([]));
          });
      
          getAllSalesPerson().then((response) => { // get the names of all sales men.
            dispatch(setSalesPersonList(response.data.data));
          }).catch((e) => {
            dispatch(setSalesPersonList([]));
          });
          
          getYearlyProfitData().then((response) => { // getting years from another route. 
            // Calling this route is only to get years.
            let new_years_data = [];
            for (let dataItem of response.data.data.data) {
              new_years_data.push(dataItem[0]);
            }
            dispatch(setYearsData(new_years_data));
        }).catch((e) => {
          dispatch(setYearsData([]));
        });
    }
    return {getAllAnalyticsData}
}