import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface OrdersState {
  totalOrders: number,
  loading: boolean,
  error: string | null,
  selectedTab: number;
}

const initialState: OrdersState = {
  totalOrders: 0,
  loading: false,
  error: null,
  selectedTab: 0,
}

const customerTotalOrdersSlice = createSlice({
  name: 'totalOrders',
  initialState,
  reducers: {
    setSelectedTab(state, action: PayloadAction<number>) {
      state.selectedTab = action.payload;
    },
    setOrdersCountStart(state) {
      state.loading = true;
      state.error = null;
    },

    setOrdersCountSuccess(state, action: PayloadAction<{ totalOrders: number }>) {
      state.totalOrders = action.payload.totalOrders;
      state.loading = false;
    },

    setOrdersCountFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const { setOrdersCountStart, setOrdersCountSuccess, setOrdersCountFailure, setSelectedTab } = customerTotalOrdersSlice.actions;
export default customerTotalOrdersSlice.reducer;
