import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ScanningTimeObject } from '../../types';

export interface AdminSliceState {
    sidebarShow: boolean;
    sidebarUnfoldable: boolean;
    years: string[];
    yearly_profit_data: any;
    yearly_turnover_data: any;
    language: 'de'| 'en';
    salesPersonList: string[];
    companiesList: string[];
    categoriesList: string[];
    supplies: string[];
    yearlyProfit: any;
    monthlyProfit: any;
    months: string[];
    scanningTime: ScanningTimeObject[];
}
const initialState = {
  language: 'en',
  sidebarShow: false, 
  sidebarUnfoldable: false,
  years: [],
  salesPersonList: ["Vitalie Ghiban", "Nikos Celaj", "Administrator", "Siyad Mutaschar"],
  companiesList: [],
  categoriesList: ["1_WM"],
  yearlyProfit: [],
  monthlyProfit: [],
  yearly_turnover_data: {},
  yearly_profit_data: {},
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  supplies: [],
  scanningTime: [],
 } as AdminSliceState

const adminSlice = createSlice({
  name: 'adminSlice',
  initialState,
  reducers: {
    setSidebarShow(state, action: PayloadAction<boolean>) {
      state.sidebarShow = action.payload;
    },
    setScanningTime(state, action: PayloadAction<AdminSliceState["scanningTime"]>) {
      state.scanningTime = action.payload;
    },
    setSidebarUnfoldable(state, action: PayloadAction<boolean>) {
      state.sidebarUnfoldable = action.payload;
    },
    setYearsData(state, action: PayloadAction<AdminSliceState["years"]>) {
      state.years = action.payload;
    },
    setYearlyProfitData(state, action: PayloadAction<AdminSliceState["yearly_profit_data"]>) {
      state.yearly_profit_data = action.payload;
    },
    setYearlyTurnoverData(state, action: PayloadAction<AdminSliceState["yearly_turnover_data"]>) {
      state.yearly_turnover_data = action.payload;
    },
    setSalesPersonList(state, action: PayloadAction<AdminSliceState["salesPersonList"]>) {
      state.salesPersonList = action.payload;
    },
    setCompaniesList(state, action: PayloadAction<AdminSliceState["companiesList"]>) {
      state.companiesList = action.payload;
    },
    setCategoriesList(state, action: PayloadAction<AdminSliceState["categoriesList"]>) {
      state.categoriesList = action.payload;
    },
    setYearlyProfit(state, action: PayloadAction<AdminSliceState["yearlyProfit"]>) {
      state.yearlyProfit = action.payload;
    },
    setMonthlyProfit(state, action: PayloadAction<AdminSliceState["monthlyProfit"]>) {
      state.monthlyProfit = action.payload;
    },
    setSuppliesList(state, action: PayloadAction<AdminSliceState["supplies"]>) {
      state.supplies = action.payload;
    }
  },
})

export const { 
      setSidebarShow, setSidebarUnfoldable, setYearsData,
      setYearlyProfitData, setYearlyTurnoverData, setYearlyProfit,
      setCategoriesList, setSalesPersonList, setMonthlyProfit,
      setSuppliesList, setCompaniesList, setScanningTime,
    } = adminSlice.actions;

export default adminSlice.reducer