import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSmartTable, CToaster } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { ProductItem } from "../../types";
import { downloadImages, getProductsBySupplyId } from "../../services/ProductService";
import { useLocation } from "react-router-dom";
import EmptyImage from '../../assets/images/empty-image.jpg';
import { Utils } from "../components/utils";

const SupplyProducts = () => {
    const state = useLocation();
    const {downloadZipImages, checkPDFStatus, toast, SupplierSupplyProductColumns, returnDateFormat} = Utils();
    
    const [loading, setLoading] = useState<boolean>(true);
    const [productsData, setProductsData] = useState<ProductItem[]>([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [records, setRecords] = useState<number>(0);

    
    const getProducts = () => {
        getProductsBySupplyId(state?.state?.supply_id).then((response) => {
            setProductsData(response?.data);
            setRecords(response?.data?.length)
            setLoading(false);
        }).catch((e) => {
            setProductsData([]);
            setRecords(1);
            setLoading(false);
        })
    }
    const handleOpenNewTab = (product: ProductItem) => {
        // Open the link in a new tab using window.open
        const newTab: any = window.open('/admin/dashboard/product/details', '_blank');
        const serializedProduct = JSON.stringify(product);
        newTab.localStorage.setItem('product', serializedProduct);
    };

    const convertDateTime = (timestamp: string) =>  {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }      
    

    useEffect(() => {
        getProducts();
    },[])
    return (
        <div className='website-settings-main-container'>
            <CToaster push={toast} placement="top-end" />
            <h4><b>Products</b></h4>
            <CSmartTable
                columns={SupplierSupplyProductColumns}
                tableFilter
                tableFilterPlaceholder={'Search'}
                tableFilterLabel=''
                items={productsData}
                itemsPerPage={itemsPerPage}
                loading={loading}
                itemsPerPageSelect
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onItemsPerPageChange={(itemsPerPage) => {
                    setActivePage(1)
                    setItemsPerPage(itemsPerPage)
                }}
                pagination={true}  
                paginationProps={{
                    activePage: activePage,
                    pages: Math.ceil(records / itemsPerPage) || 1,
                }}
                tableHeadProps={{
                    color: 'info',
                }}
                tableProps={{
                    hover: true,
                    responsive: true,
                    style:{marginTop: 8},
                    striped: true,
                }}
                tableBodyProps={{
                    className: 'align-middle'
                }}
                scopedColumns={{
                image_list: (item: ProductItem) => {
                    return (<td className="text-center">
                    {
                    item.image_list ?
                        <img
                            style={{width: 80, height: 125, cursor: 'pointer', marginLeft: 12}}
                            src={`https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${item.id}&filename=${item.image_list[0]}.jpg`}
                            onClick={() => handleOpenNewTab(item)}
                        />
                    : <img style={{width: 80, height: 125, cursor: 'pointer', marginLeft: 12}} src={ EmptyImage} onClick={() => handleOpenNewTab(item)}/>
                    }
                    </td>)
                },
                created_at: (item: ProductItem) => {
                    return (
                    <td className="text-center">
                        {returnDateFormat(item.created_at)}
                    </td>
                    )
                },
                id: (item: ProductItem) => {
                    return (
                    <td className="text-center">
                        {item.id}
                    </td>
                    )
                },
                supply: (item: ProductItem) => {
                    return (
                    <td className="text-center">
                        {item.supply}
                    </td>
                    )
                },
                article: (item: ProductItem) => {
                    return (
                    <td className="text-center">
                        {item.article}
                    </td>
                    )
                },
                sub_category: (item: ProductItem) => {
                    return (
                    <td className="text-center">
                         {item.sub_category}
                    </td>
                    )
                },
                // current_online_price: (item: ProductItem) => {
                //     return (
                //     <td className="text-center">
                //          {item.current_online_price}
                //     </td>
                //     )
                // },
                brand: (item: ProductItem) => {
                    return (
                    <td className="text-center">
                        {item.brand}
                    </td>
                    )
                },
                final_points: (item: ProductItem) => {
                    return (
                    <td className="text-center">
                        {item.final_points}
                    </td>
                    )
                },

                action: (item: ProductItem) => {
                    return (
                    <td>
                        <CDropdown>
                        <CDropdownToggle className="elv-btn" href="#" style={{width: 110}}>Action</CDropdownToggle>
                        <CDropdownMenu style={{position: 'absolute'}}>
                            <CDropdownItem onClick={()=>handleOpenNewTab(item)}>View</CDropdownItem>
                            <CDropdownItem onClick={()=>downloadZipImages([item.id])}>Images</CDropdownItem>
                            <CDropdownItem onClick={()=>checkPDFStatus(item.id, 'productPDF')}>PDF</CDropdownItem>
                        </CDropdownMenu>
                        </CDropdown>
                    </td>
                    )
                },
                }}
        />
    </div>
    )
};
export default SupplyProducts;
