import { CHeader, CNav, CNavItem, CNavLink, CRow, CCol, CFormSwitch, CButton, CSpinner, CLoadingButton} from "@coreui/react-pro";
import { CustomerDetails, ProductItem } from "../../../types";
import ProgressBar from "@ramonak/react-progress-bar";
import { cilTruck, cilReload  } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useEffect, useState } from "react";
import { getTruckCapacity } from "../../../services/PackageService";
import { t } from "i18next";
import "../AdminComponentStyles.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface PackageHeaderProps {
    headerText?: string;
    productsLength: number;
    palletLength?: number;
    selectedTab: 0 | 1 | 2 | 3;
    setArticlesLength: number;
    cartLength: number;
    automaticPairing: boolean;
    truckCapacity: number;
    selectedSetArticles: number[];
    selectedPallets: number[];
    selectedProducts: number[];
    selectedCustomer?: CustomerDetails;
    setSelecteTab: (value: 0 | 1 | 2) => void;
    setAutomaticPairing: (value: boolean) => void;
    getPairedSetArticlesOptions: (e: any, item?: ProductItem, callBackFunction?: () => void) => void;
    setTruckCapacity: (value: number) => void;
    calculatePackagePrice?: () => void;
    truckErrorCallBack?: () => void;
};

const PackageHeaderComponent = (props: PackageHeaderProps) => {
    const [numOfTrucks, setNumOfTrucks] = useState<number>(1);
    const [showHeader, setShowHeader] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const packageTotalPrice = useSelector((state: RootState) => state.warehousePackageSlice.warehousePackageTotalPrice);

    const {
        headerText, truckCapacity, productsLength, setSelecteTab, selectedSetArticles, selectedPallets,
        selectedTab, setArticlesLength, cartLength, automaticPairing, selectedProducts, calculatePackagePrice, palletLength,
        setAutomaticPairing, getPairedSetArticlesOptions, setTruckCapacity,
        truckErrorCallBack} = props;

    useEffect(() => {
        if(selectedSetArticles?.length > 0 ||selectedProducts?.length > 0) {
            calculateTruckCapacity();
        }
    },[selectedSetArticles, selectedProducts, selectedPallets])
    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY || document.documentElement.scrollTop;
          // Define the threshold at which the header should appear (e.g., 100px)
          const threshold = 250;
    
          if (scrollPosition > threshold) {
            setShowHeader(true);
          } else {
            setShowHeader(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); // Run this effect only once on component mount

    const calculateTruckCapacity = () => {
        setLoading(true);
        let products = selectedProducts.map(number => `'${number}'`).join(',');
        let set_articles = selectedSetArticles.map(number => `'${number}'`).join(',');

        let form_data = {
            "lager_numbers": selectedProducts?.length && selectedSetArticles?.length ?  '(' + products + ',' + set_articles + ')' :  selectedProducts?.length ? '(' +  products + ')'  : '(' + set_articles + ')' ,
            fetched_pallet_count: selectedPallets?.length > 0 ? selectedPallets.length : 0,
        }
        getTruckCapacity(form_data).then((response) => {
            const totalCapacityOccupied = Number(response.data.truck_details.occupancy["total_occupied_in_meters"]);
            if(totalCapacityOccupied) {
                const totalTrucks = Math.ceil(totalCapacityOccupied / 13.6);
                const currentTruckCapacity = (totalCapacityOccupied - ((totalTrucks-1) * 13.6));
                setNumOfTrucks(totalTrucks);
                setTruckCapacity(parseFloat(currentTruckCapacity.toFixed(2)));
                // setDisableCreateButton(false);
                setLoading(false);
            }
            else {
                truckErrorCallBack && truckErrorCallBack();
                setNumOfTrucks(0);
                // setDisableCreateButton(false);
                setLoading(false);
            }
           
            
        }).catch((e)=> {
            truckErrorCallBack && truckErrorCallBack();
            setLoading(false);
            
        });
    }
   
    return (
        <>
            <CHeader position="sticky" className={`mb-4 ${showHeader ? 'd-block' : 'd-none'}`}>
                <CNav style={{justifyContent: 'flex-end', alignItems:'flex-end' }}>
                    <CNavItem>
                        {
                            loading ?
                            <CRow>
                                <CCol>
                                    <CSpinner />
                                </CCol>
                            </CRow>
                            : null
                        }
                        <CRow>
                            <CCol>
                                <h6>{numOfTrucks} x {t('adminDashboard.Packages.truck_capacity_text')} ({truckCapacity} m)</h6>
                                <ProgressBar completed={`${truckCapacity}`} maxCompleted={13.5} bgColor="#23B2D9" width="100%" customLabel=" "/>
                            </CCol>
                        </CRow>
                    </CNavItem>
                </CNav>
            </CHeader>
            <CNav style={{justifyContent: 'flex-end', alignItems:'flex-end' }}>
                <CNavItem>
                    {
                        loading ?
                        <CRow>
                            <CCol>
                                <CSpinner />
                            </CCol>
                        </CRow>
                        : null
                    }
                    <CRow>
                        <CCol>
                            <h4>{numOfTrucks} x </h4>
                            <CIcon icon={cilTruck} className="me-2" size="3xl"/>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            {t('adminDashboard.Packages.truck_capacity_text')} ({truckCapacity} m)
                            <ProgressBar completed={`${truckCapacity}`} maxCompleted={13.5} bgColor="#23B2D9" width="100%" customLabel=" "/>
                        </CCol>
                    </CRow>
                </CNavItem>
            </CNav>
            <CHeader>
                <h1><b>{headerText ? headerText : t('adminDashboard.Packages.create_package_text')}</b></h1>
                <CNav variant="tabs" style={{alignItems: "flex-end"}}>
                    <CNavItem className="margin-right-24">
                        <h5>{t('adminDashboard.Packages.grand_total_euro')} {packageTotalPrice}</h5>
                    </CNavItem>
                    <CNavItem className='margin-left-12'>
                        <CNavLink onClick={() => setSelecteTab(0)} active={selectedTab === 0 ? true : false}>
                        {t('adminDashboard.Products.products')} ({productsLength + setArticlesLength})
                        </CNavLink>
                    </CNavItem>
                    <CNavItem className='margin-left-12'>
                        <CNavLink onClick={() => setSelecteTab(1)} active={selectedTab === 1 ? true : false}>{t('adminDashboard.Pallets.pallets')} ({palletLength})</CNavLink>
                    </CNavItem> 
                    <CNavItem className='margin-left-12'>
                        <CNavLink onClick={() => {setSelecteTab(2);calculatePackagePrice && calculatePackagePrice();}} active={selectedTab === 2 ? true : false}>{t('adminDashboard.CommonWords.cart')} ({cartLength})</CNavLink>
                    </CNavItem>
                </CNav>
            </CHeader>

            {/* <CRow>
                <CCol style={{justifyContent: 'flex-end', alignItems: 'center', display: 'flex', marginTop: 16}}>
                    {
                        selectedTab === 0 && selectedSetArticles?.length > 0 ?
                            <CFormSwitch label={t('adminDashboard.Packages.smart_pair_switch')} checked={automaticPairing} onClick={(e) => {setAutomaticPairing(!automaticPairing)}}/>
                        :   null
                    }                
                    {
                        selectedTab === 0 && cartLength > 0 && automaticPairing ?
                            <CButton
                                className='elv-btn'
                                variant="outline"
                                shape="square"
                                style={{ marginLeft: 14 }}
                                disabled={!automaticPairing || smartPairLoading}
                                onClick={(e)=>{
                                    setSmartPairLoading(true);
                                    getPairedSetArticlesOptions(e, undefined, ()=>{setSmartPairLoading(false)});
                                }}
                            >
                            {
                                smartPairLoading ?
                                    <CRow>
                                        <CCol>
                                            <CSpinner />
                                        </CCol>
                                    </CRow>
                                : t('adminDashboard.Packages.pair_all_button')
                            }
                        </CButton>
                        : null
                    }
                </CCol>
            </CRow> */}
        </>
    )
};
export default PackageHeaderComponent;