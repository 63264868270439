import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CForm, CFormLabel, CFormFeedback } from "@coreui/react";
import { DropDownItem, Supply, SupplyType } from "../../../types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { editProductSupplies, getProductSuppliesInformation } from "../../../services/ProductService";
import Select from 'react-select';

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    selectedSupplyItem: Supply | undefined;
    closeModal: () => void;
    onClickConfirmation: () => void;
    confirmButtonText: string;
}

const EditSupplyModal = (props: ModalProps) => {   
    const {isModalVisible, closeModal, headerText, onClickConfirmation, bodyText, confirmButtonText, selectedSupplyItem} = props;
    const supplyTypes: SupplyType[] = useSelector((state: RootState) => state.productSlice.supplyTypes);
    // const [supplyId, setSupplyId] = useState<string>(selectedSupplyItem ? selectedSupplyItem.id : '');
    const [invalid, setInvalid] = useState<boolean>(true);
    const [errorText, setErrorText] = useState<string>("");
    
    const supplyTypeOptions: DropDownItem[]= (supplyTypes.map((type: SupplyType) => ({ value: type.id, label: type.name })));
    const currentsupplyTypeOption: DropDownItem = supplyTypeOptions.filter((option: DropDownItem)=> {return option.label == selectedSupplyItem?.type})[0];
    const [selectedSupplyType, setSelectedSupplyType] = useState<number>(currentsupplyTypeOption.value);

   useEffect(()=> {
        getProductSuppliesInformation(selectedSupplyItem ? selectedSupplyItem.id : '').then((response) => {
            console.log('Response: ', response.data);
        }).catch((e) => {
        })
   }, []);

    const editSupply = () => {
        let formData = {
            type: selectedSupplyType,
        }
        editProductSupplies(selectedSupplyItem ? selectedSupplyItem.id : '', formData).then((response) => {           
            onClickConfirmation();
        }).catch((e) => {
            // console.log(e.response.data.type);
            setErrorText(e?.response?.data?.type)
            setInvalid(false);
        })
    };
    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal}>
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{bodyText}</div>
                <CForm style={{marginBottom: 16, marginTop: 24}}>
                    <CFormFeedback invalid={invalid} style={{color: 'red'}}>{errorText}</CFormFeedback>
                    <CFormLabel><b>Supply type </b></CFormLabel>
                    <Select
                        defaultValue={currentsupplyTypeOption}
                        placeholder={'Select type'}
                        noOptionsMessage={({ inputValue: string }) => 'Supply-type not found.' }
                        options={supplyTypeOptions}
                        onChange={(option: any) => {
                            setSelectedSupplyType(option.value)
                        }}
                    />
                </CForm>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={editSupply}>{confirmButtonText}</CButton>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default EditSupplyModal;