
import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setSetArticles, setSelectedProducts, setSelectedSetArticles, setCart, setPackageProducts, setPackageTotalPrice, setPackageDiscount, setPallets, setSelectedPallets } from '../../../redux/slices/PackageSlice';
import { useNavigate } from 'react-router-dom';
import { ProductItem, PossibleArticlePairs, Cart, PairedCartItem, CreatPackageObject, Pallet } from '../../../types';
import { CCol, CRow, CToaster } from '@coreui/react';
// Import Custom Components.
import PackageSearchComponent from '../../components/create-package-components/PackageSearchComponent';
import { getAutomaticPairs, getSetArticlePairs, createPackage, syncCart } from '../../../services/PackageService';
import Modal from '../../components/modals/PairArticleModal';
import PackageHeaderComponent from '../../components/create-package-components/PackageHeaderComponent';
import { editProductDetails, getProductById } from '../../../services/ProductService';
import { alreadyPairedToast, pairedFoundToast, noPairFoundToast, AddedToCartToast, RemovedFromCartToast, packageCreationError, TruckErrorToast } from '../../components/create-package-components/ToastsComponent';
import PlaceHolder from '../../components/product-components/PlaceHolder';
import OrderSummary from '../../components/create-package-components/Summary';
import ProductCardComponent from '../../components/product-card-component';
import GenericConfirmationModal from '../../components/modals/DeleteItemModal';
import { Utils } from '../../components/utils';
import PalletCardComponent from '../../components/pallet-card-component';
import { editPalletDetails } from '../../../services/PalletService';
import PackageFiltersComponent from '../../components/create-package-components/PackageFiltersComponent';
import { useTranslation } from 'react-i18next';

const CreatePackageScreen = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const {
        getPackagesData, getPendingPackagesData,getAllProductSupplies, 
        getAllProductBrands, getUsageData, getAllProductSubCategories} = Utils();
    const {t} = useTranslation();
    const cart: Cart = useSelector((state: RootState) => state.packageSlice.cart);
    const products: ProductItem[] = useSelector((state: RootState) => state.packageSlice.products);
    const discount =  useSelector((state: RootState) => state.packageSlice.discount);
    const setArticles: ProductItem[] = useSelector((state: RootState) => state.packageSlice.setArticles);
    const pallets: Pallet[] = useSelector((state: RootState) => state.packageSlice.pallets);
    const selectedProducts: number[] = useSelector((state: RootState) => state.packageSlice.selectedProducts);
    const selectedPallets: any = useSelector((state: RootState) => state.packageSlice.selectedPallets);
    const selectedSetArticles: number[] = useSelector((state: RootState) => state.packageSlice.selectedSetArticles);
    const [currentBrandList, setCurrentBrandList] = useState<string[]>([]);  
    const [currentArticleList, setCurrentArticleList] = useState<string[]>([]);
    const [currentSubGroupList, setCurrentSubGroupList] = useState<string[]>([]);
    const [currentUsageList, setCurrentUsageList] = useState<string[]>([]);
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(100);
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [truckCapacity, setTruckCapacity] = useState<number>(0);
    const [selectedTab, setSelecteTab] = useState<0 | 1 | 2 | 3>(0); // 0 stands for the standalone products. 1 stands for set articles.
    const [automaticPairing, setAutomaticPairing] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(true);
    const [toast, addToast] = useState<any>(null);
    const [pairOptions, setPairOptions] = useState<PossibleArticlePairs | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [priceInputId, setPriceInputId] = useState<string>('');
    const [conflictingIds, setConflictingIds] = useState<string[]>([]);
    const [packageConfirmationModal, setPackageConfirmationModal] = useState<boolean>(false);
    const [priceInputValue, setPriceInputValue] = useState<number>(0);
    const [smartPairLoading, setSmartPairLoading] = useState<boolean>(false);
    const [addToCart, setAddToCart] = useState<boolean>(true);
    const [packageStatus, setPackageStatus] = useState<string>('');
    
    const filteredProducts = products?.filter((product: any) => {
        if (product?.final_points >= minValue && product?.final_points <= maxValue) {
            const brandMatch = currentBrandList?.length === 0 || currentBrandList.includes(product?.brand);
            const subGroupMatch = currentSubGroupList?.length === 0 || currentSubGroupList.includes(product?.sub_category);
            const articleMatch = currentArticleList?.length === 0 || currentArticleList.includes(product?.article);
            const usageMatch = currentUsageList?.length === 0 || currentUsageList.includes(product?.usage?.name);
            return brandMatch && articleMatch && usageMatch && subGroupMatch;
        }
        else
        {
            return false
        }
    });
    const filteredSetArticles = setArticles?.filter((product: any) => {
        if (product?.final_points >= minValue && product?.final_points <= maxValue) {
            const brandMatch = currentBrandList.length === 0 || currentBrandList.includes(product.brand);
            const subGroupMatch = currentSubGroupList.length === 0 || currentSubGroupList.includes(product.sub_category);
            const articleMatch = currentArticleList.length === 0 || currentArticleList.includes(product.article);
            const usageMatch = currentUsageList.length === 0 || currentUsageList.includes(product.usage.name);
            return brandMatch && articleMatch && usageMatch && subGroupMatch;
        }
        else
        {
            return false
        }
    });
    
    useEffect(()=> {
        resetToDefaults();
        getAllProductBrands();
        getUsageData();
        getAllProductSubCategories();
    },[]);

    useEffect(()=> {
        if(selectedSetArticles.length == 0) {
            setAutomaticPairing(false);
        }
    },[selectedSetArticles]);

    useEffect(()=> {
        if(conflictingIds?.length > 0) {
            setPackageConfirmationModal(true);
        }
    },[conflictingIds]);
    
    useEffect(() => {
        if(pairOptions && pairOptions?.availableOptions?.length > 0){
            setIsModalVisible(true);
        }
    }, [pairOptions]);

    useEffect(() => {
        if(cart && cart?.paired?.length >= 0){
           // call the synch cart API.
           calculatePackagePrice();
           syncCart(cart?.paired).then((response) => {
                console.log('cart synched: ');
           }).catch((e) => {
                console.log('syncing error: ');
           });
        }
    },[cart?.paired]);

    useEffect(() => {
        if(cart && cart?.standalone?.length >= 0){         
           calculatePackagePrice();
        }
    },[cart?.standalone]);

    useEffect(() => {
        if(cart && cart?.pallets?.length >= 0){         
           calculatePackagePrice();
        }
    },[cart?.pallets]);
    
    useEffect(() => {
        if(cart && cart?.unpaired?.length >= 0){          
           calculatePackagePrice();
        }
    },[cart?.unpaired]);
    
    const toggleSelectAllProducts = () => {
        const updatedSelectedProIds: number[] = [];
        if(selectedTab == 0) {
            filteredProducts.map((item) => {
                updatedSelectedProIds.push(item.id);
            });
        }
        else if(selectedTab == 1) {
            filteredSetArticles.map((item) => {
                updatedSelectedProIds.push(item.id);
            });
        }
        else if(selectedTab == 2) {
            pallets.map((item) => {
                updatedSelectedProIds.push(item.id);
            });
        }

        if(selectAll){ // already selected now we have to deselect.
            if(selectedTab == 0) {
                dispatch(setSelectedProducts(selectedProducts.filter((id) => !updatedSelectedProIds.includes(id))));
                dispatch(setCart({
                    ...cart,
                    standalone: cart.standalone.filter((id) =>!updatedSelectedProIds.includes(id)),
                }));
                addToast(RemovedFromCartToast(()=>{addToast(null)}));
            }
            else if(selectedTab == 1) {
                dispatch(setSelectedSetArticles(selectedSetArticles.filter((id) => !updatedSelectedProIds.includes(id))));
                dispatch(setCart({
                    ...cart,
                    paired: cart.paired.filter((item) => !updatedSelectedProIds.includes(item.set_articles[0]) && !updatedSelectedProIds.includes(item.set_articles[1])),
                    unpaired: cart.unpaired.filter((id) =>!updatedSelectedProIds.includes(id)),
                }));
                addToast(RemovedFromCartToast(()=>{addToast(null)}));
            }
            else if(selectedTab == 2) {
                dispatch(setSelectedPallets(selectedPallets?.filter((id: any) => !updatedSelectedProIds.includes(id))));
                dispatch(setCart({
                    ...cart,
                    pallets: cart.pallets?.filter((id: any) =>!updatedSelectedProIds.includes(id)),
                }));
                addToast(RemovedFromCartToast(()=>{addToast(null)}));
            }
        }
        else { // already deselected now we have to select all 
            if(selectedTab == 0) {
                dispatch(setSelectedProducts(updatedSelectedProIds));
                dispatch(setCart({
                    ...cart,
                    standalone: [...updatedSelectedProIds],
                }));
                addToast(AddedToCartToast(()=>{addToast(null)}));
            }
            else if(selectedTab == 1) {
                dispatch(setSelectedSetArticles(updatedSelectedProIds));
                dispatch(setCart({
                    ...cart,
                    paired: [],
                    unpaired: [...updatedSelectedProIds],
                }));
                addToast(AddedToCartToast(()=>{addToast(null)}));
            }
            else if(selectedTab == 2) {
                dispatch(setSelectedPallets(updatedSelectedProIds));
                dispatch(setCart({
                    ...cart,
                    pallets: [...updatedSelectedProIds],
                }));
                addToast(AddedToCartToast(()=>{addToast(null)}));
            }
            
        }
        setSelectAll(!selectAll);
    }
    
    const getMissingProducts = (missingProducts: number[]) => {
        let missingProductsResponse: ProductItem[] = [];
        missingProducts.map(async (item: number) => {{
            await getProductById(item).then((response) => {
                missingProductsResponse.push(response.data); 
            }).catch((e) => {
                console.log('get products error: ', e);
            });
            if(missingProductsResponse?.length === missingProducts?.length) {
                dispatch(setSetArticles([ ...missingProductsResponse, ...setArticles]));
            }
        }});
    }
    const resetToDefaults = () => {
        dispatch(setSelectedSetArticles([]));
        dispatch(setSelectedProducts([]));
        dispatch(setSelectedPallets([]));
        dispatch(setPackageProducts([]));
        dispatch(setSetArticles([]));
        dispatch(setPackageTotalPrice(0));
        dispatch(setPackageDiscount(0));
        dispatch(setCart({            
            standalone: [],
            unpaired: [],
            paired: [],
            pallets: [],
        }));
        dispatch(setPallets([]));
    }

    const CreatePackage = (setCreatePackageLoading?: (value: boolean) => void, exclude_ids?: boolean) => {
        let productIds: any = [];
        productIds = [...cart.standalone, ...cart.unpaired, ...cart.pallets];
        cart?.paired.map((item)=>{
            productIds =  [...productIds, ...item.set_articles];
        });

        if(exclude_ids) {
            productIds = productIds.filter((id: number) => {return !conflictingIds.includes(String(id))});
           
            if(productIds?.length == 0) {
                dispatch(setSelectedPallets([]));
                dispatch(setSelectedProducts([]));
                dispatch(setSelectedSetArticles([]));
                dispatch(setCart({
                    standalone: [],
                    unpaired: [],
                    pallets: [],
                    paired: [],
                }));
                setConflictingIds([]);
                setPackageConfirmationModal(false);
                return;
            }
        }

        let packageObject: CreatPackageObject =  {
            products: productIds,
            discount: discount / 100,
            reminders_count: 2,
            reminder_timedelta_days: "3 00:00:00",
            package_validity_hours: "3 00:00:00",
            customer: selectedCustomer?.id,
            add_to_cart: addToCart,
        }
        createPackage(packageObject).then((response) => {
            getPackagesData();
            getPendingPackagesData();
            getAllProductSupplies();
            resetToDefaults();
            navigation('/admin/dashboard/home');
        }).catch((e: any) => {            
            setConflictingIds(e?.response?.data?.conflicting_products);
            addToast(packageCreationError(()=>{addToast(null)}));
            setCreatePackageLoading && setCreatePackageLoading(false);
        });
    }

    const updateSelectedProducts = (product: number) => {
        let updatedSelectedProducts: number[] = [...selectedProducts];
        if(!updatedSelectedProducts.includes(product)){ // add product to cart and selected product items list.               
            updatedSelectedProducts.push(product);  
            addProductToCart(product); 
            dispatch(setSelectedProducts(updatedSelectedProducts));
        }
        else { // deselect / remove the product item from the cart. when click.
            updatedSelectedProducts = updatedSelectedProducts.filter((item) => {
                return item !== product;
            });
            dispatch(setSelectedProducts(updatedSelectedProducts));
            removeStandaloneFromCart(product);
            addToast(RemovedFromCartToast(()=>{addToast(null)}));            
        }
    }

    const updateSelectedPallets = (pallet: any) => {
        let updatedSelectedPallets: any = [...selectedPallets];
        if(!updatedSelectedPallets?.includes(pallet)){ // add product to cart and selected product items list.               
            updatedSelectedPallets.push(pallet);
            addPalletsToCart(pallet); 
            dispatch(setSelectedPallets(updatedSelectedPallets));
        }
        else { // deselect / remove the product item from the cart. when click.
            updatedSelectedPallets = updatedSelectedPallets?.filter((item: any) => {
                return item !== pallet;
            });
            dispatch(setSelectedPallets(updatedSelectedPallets));
            removePalletsFromCart(pallet);
            addToast(RemovedFromCartToast(()=>{addToast(null)}));
        }
    }

    const updateSelectedSetArticles = (setArticle: number) => {
        let updatedSelectedSetArticles: number[] = [...selectedSetArticles];
        
        if(!updatedSelectedSetArticles.includes(setArticle)) {
            updatedSelectedSetArticles.push(setArticle);
            addSetArticleToCart(setArticle);
            dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
            addToast(AddedToCartToast(()=>{addToast(null)}));
        }
        else { 
            // we have to remove it from the cart on the backend side to set the item free.
            updatedSelectedSetArticles = updatedSelectedSetArticles.filter((item) => {
                return item !== setArticle;
            });
            dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
            if(cart.unpaired.includes(setArticle)) {
                removeUnpairedFromCart(setArticle)
            }
            else {
                removePairedFromCart(setArticle);
            }
            addToast(RemovedFromCartToast(()=>{addToast(null)}));
        }
    }

    const addProductToCart = (product: number) => {
        let standalone: number[] = [...cart.standalone];
        let updatedStandalone = standalone.filter((cartItem: number) => {
            return (cartItem === product)
        });
        if(updatedStandalone?.length === 0) { // the product is not in the cart to we have to append it.
            standalone.push(product);
            dispatch(setCart({
                ...cart,
                standalone: standalone,
            }));
        } // else do nothing.
    }

    const addPalletsToCart = (pallet: any) => {
        let pallets: any = [...cart.pallets];
        let updatedPallets = pallets.filter((cartItem: any) => {
            return (cartItem === pallet)
        });
        if(updatedPallets?.length === 0) { // the product is not in the cart to we have to append it.
            pallets.push(pallet);
            dispatch(setCart({
                ...cart,
                pallets: pallets,
            }));
        } // else do nothing.
    }
    
    const addSetArticleToCart = (selectedSetArticle: number, suggestedPair?: number) => {
        let paired: PairedCartItem[] = [...cart.paired];
        let unpaired: number[] = [...cart.unpaired];
        
        if(!suggestedPair) {
            // If there is no second argument then it means it has been called just to add an item in the cart.
            let updatedUnpaired = unpaired.filter((cartItem: number) => {
                return (cartItem === selectedSetArticle)
            });
            if(updatedUnpaired?.length === 0) { // the set article is not in the cart to we have to append it.
                unpaired.push(selectedSetArticle);
            }
            dispatch(setCart({
                ...cart,
                unpaired: unpaired,
            }));
        }
        else {
            // If there is a second argument passed to this function it means we have to pair these 2 and, 
            // check if one of them is already paired then give error message to the user.
            let updatedPaired = paired.filter((cartItem: PairedCartItem) => {               
                return (cartItem.set_articles.includes(selectedSetArticle) || cartItem.set_articles.includes(suggestedPair))
            });
            if(updatedPaired?.length === 0) { // Both of the articles are not paired and this is the simplest case.
                paired.push({
                    is_manual: true,
                    set_articles: [
                        selectedSetArticle,
                        suggestedPair
                    ]
                });
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });
            }
            else if(updatedPaired?.length === 2) { // Both of them are in the cart but they are single, not paired.
                updatedPaired.map((item: any) => {
                    paired = paired.filter((cartItem: PairedCartItem) => {
                        return (!cartItem.set_articles.includes(item.set_articles[0]))
                    });
                })
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );

                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });
            }
            else if(updatedPaired?.length === 1 && updatedPaired[0].set_articles.length === 2) { // Optional : we can show error to the user that which one is already paired which one is not.
                // one of them is already paired. show error toast
                addToast(alreadyPairedToast(()=>{addToast(null)}));
            }

            else if(updatedPaired?.length === 1 && updatedPaired[0].set_articles?.length === 1 && updatedPaired[0].set_articles.includes(selectedSetArticle)) {              
                paired = paired.filter((cartItem: PairedCartItem) => {
                    return (!cartItem.set_articles.includes(selectedSetArticle))
                });
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );

                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });  
            }
            else if(updatedPaired.length === 1 &&  updatedPaired[0].set_articles.length === 1 && updatedPaired[0].set_articles.includes(suggestedPair)) {            
                paired = paired.filter((cartItem: PairedCartItem) => {
                    return (!cartItem.set_articles.includes(suggestedPair))
                });
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });                   
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });  
            }
            dispatch(setCart({
                ...cart,
                unpaired: unpaired,
                paired: paired,
            }));
        }
    }

    const removePairedFromCart = (setArticle: number) => {
        // this can also be used in case of un pair the set articles
        // removing any one of them from the cart should remove both.
        let updatedSelectedSetArticles = [...selectedSetArticles];
        let paired: PairedCartItem[] = [...cart.paired];

        let updatedPaired = paired.filter((cartItem: PairedCartItem) => {
            return (cartItem.set_articles.includes(setArticle))
        });

        updatedPaired[0].set_articles.map((cartItem: number) => { // First remove this article also from the selected set article array list.
            updatedSelectedSetArticles = updatedSelectedSetArticles.filter((item: number) => {
                return item !== cartItem;
            });            
        })

        paired = paired.filter((cartItem: PairedCartItem) => { // // then remove this article also from paired array of the cart.
            return (!cartItem.set_articles.includes(setArticle))
        });

        dispatch(setCart({
            ...cart,
            paired: paired,
        }));

        dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
    }

    const removeUnpairedFromCart = (setArticle: number) => {
        // This function can be done using simple splice.      
        let unpaired: number[] = [...cart.unpaired];       

        unpaired = unpaired.filter((cartItem: number) => { // // then remove this article also from the cart.
            return (!(cartItem === setArticle))
        });

        dispatch(setCart({
            ...cart,
            unpaired: unpaired,
        }));        
    }

    const removeStandaloneFromCart = (product: number) => {
        // This function can be done using simple splice.        
        let standalone: number[] = [...cart.standalone];

        standalone = standalone.filter((cartItem: number) => { // // then remove this product also from the cart.
            return (!(cartItem === product))
        });
        dispatch(setCart({
            ...cart,
            standalone: standalone,
        }));        
    }

    const removePalletsFromCart = (pallet: any) => {
        // This function can be done using simple splice.        
        let pallets: any = [...cart.pallets];

        pallets = pallets?.filter((cartItem: any) => { // // then remove this product also from the cart.
            return (!(cartItem === pallet))
        });
        dispatch(setCart({
            ...cart,
            pallets: pallets,
        }));        
    }

    const pairSetArticle = (selectedItem: ProductItem, option: ProductItem) => {
         // This function is only meant to be used for manual pairing
            let updatedSelectedSetArticles: number[] = [...selectedSetArticles];      
            const IsSetArticleSelected = updatedSelectedSetArticles.includes(option.id)
            const IsSetArticleExists = setArticles.some(article => article.id === option.id)
    
            if(!updatedSelectedSetArticles.includes(selectedItem.id)) {
                updatedSelectedSetArticles.push(selectedItem.id); // Currently pressed pair item will be selected.
            }

            if (option?.article && !IsSetArticleSelected && !IsSetArticleExists) {
                // we have to pair
                dispatch(setSetArticles([...setArticles, option]));
                updatedSelectedSetArticles.push(option.id);
            }
    
            // In the below conditions, the pair is on the front end side then we push it in our selected set article array.
            else if (option?.article?.length > 0 && !IsSetArticleSelected && IsSetArticleExists) {            
                updatedSelectedSetArticles.push(option.id); // Currently pressed pair item will be selected.  
            }
            else {
                // No pair found.
            }
            addSetArticleToCart(selectedItem.id, option.id);
            dispatch(setSelectedSetArticles(updatedSelectedSetArticles));            
            addToast(pairedFoundToast(()=>{addToast(null)}));
            addToast(AddedToCartToast(()=>{addToast(null)}));              
    };
    
     const handleAutomaticPairingResponse = (response: {
        paired_temp_sets: PairedCartItem[],
        unpaired_ids: number[],
        already_existing_temp_sets: PairedCartItem[],
    }) => {
        let paired: PairedCartItem[] = [];
        let unpaired: number[] = [];
        let missingProducts: number[] = [];
        let updatedSelectedSetArticles =  [...selectedSetArticles];

        response.paired_temp_sets.map((item: PairedCartItem) => {
            paired.push(item);
            if(!setArticles.some(article => article.id === item.set_articles[0])){ 
                // if the corresponding pair is not loaded on the front end then we have to bring it from backend.
                updatedSelectedSetArticles.push(item.set_articles[0]);
                missingProducts.push(item.set_articles[0]);
            }
            else if(setArticles.some(article => article.id === item.set_articles[0]) && !updatedSelectedSetArticles.includes(item.set_articles[0])) {
                // if the corresponding pair is loaded on the front end but it is not in the selected list.
                updatedSelectedSetArticles.push(item.set_articles[0]);
            }
            
            // Checks both of the paired articles coming from backend if any one of them is not loaded then we have to get it from server.
            if(!setArticles.some(article => article.id === item.set_articles[1])) {
                // if the corresponding pair is not loaded on the front end then we have to bring it from backend.
                updatedSelectedSetArticles.push(item.set_articles[1]);
                missingProducts.push(item.set_articles[1]);
            }
            else if(setArticles.some(article => article.id === item.set_articles[1]) && !updatedSelectedSetArticles.includes(item.set_articles[1])) {
                // if the corresponding pair is loaded on the front end but it is not in the selected list.
                updatedSelectedSetArticles.push(item.set_articles[1]);
            }
        });
        response.unpaired_ids.map((item: number) => {
            unpaired.push(item);
        });
        response.already_existing_temp_sets.map((item: PairedCartItem) => {
            paired.push(item);
        });
        getMissingProducts(missingProducts);

        updatedSelectedSetArticles = updatedSelectedSetArticles.filter((item) => {
            // unpaired articles should be deselected.
            return paired.some(pair => pair.set_articles.includes(item));
        })

        dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
        dispatch(setCart(
            {
                ...cart,
                paired: paired,
                unpaired: [],// unpaired: unpaired,
            }
        ));
    }

    const getPairedSetArticlesOptions = (e: any, item?: ProductItem | undefined, callBackFunction?: () => void) => {
        e.stopPropagation() // prevents the click event of parent element.

        if(item) {
            getSetArticlePairs(item).then((response) => {
                if (response?.data?.possible_pairs?.length === 0) {
                    addToast(noPairFoundToast(()=>{addToast(null)}));
                    setPairOptions({
                        selectedItem: item,
                        availableOptions: [],
                    });
                }
                else {
                    setPairOptions({
                        selectedItem: item,
                        availableOptions: response.data.possible_pairs,
                    });
                }
            }).catch((e) => {
                console.log('exception while getting manual pairs: ', e);
                addToast(noPairFoundToast(()=>{addToast(null)}));
            });
        }
        else if (automaticPairing) {
            getAutomaticPairs(selectedSetArticles).then((response) => {
                addToast(pairedFoundToast(()=>{addToast(null)}));
                callBackFunction &&  callBackFunction();
                handleAutomaticPairingResponse(response.data);
            }).catch((e) => {
                console.log('exception while getting automatic pairs: ', e);
                if(e?.response?.data) {
                    addToast(noPairFoundToast(()=>{addToast(null)}));
                    callBackFunction &&  callBackFunction();
                    handleAutomaticPairingResponse(e.response.data);
                }
            });
        }
    }

    const setSellingPriceState = (e: any, productId: number, selling_price: number) => {
        e.stopPropagation();
        setPriceInputId(`selling-price-input-${productId}`);
        setPriceInputValue(selling_price);
    }
    const changeProductPrice = (e: any, id: any, type: string) => {
        e.stopPropagation();
        let form_data = {
            selling_price: priceInputValue,
        }
        if (type === 'pallet') {
            editPalletDetails(id, form_data).then((response) => {
                const newPallets = pallets?.map((item: Pallet) => {
                    if(item.id === id){
                        return {
                            ...item, // Copy existing fields
                            selling_price: priceInputValue, // Update selling_price
                          };
                    }
                    else {
                        return item;
                    }
                })
                dispatch(setPallets(newPallets));
                setPriceInputId('');
                setPriceInputValue(0);
            })
            .catch((e) => {
            })
        }
        else {
            editProductDetails(id, form_data).then((response) => {
                if(type === 'set-articles') {
                    const newSetArticles = setArticles.map((item: ProductItem) => {
                        if(item.id === id){
                            return {
                                ...item, // Copy existing fields
                                selling_price: priceInputValue, // Update selling_price
                              };
                        }
                        else {
                            return item;
                        }
                    })
                    dispatch(setSetArticles(newSetArticles));
                }
                else if(type === 'products') {
                    const newProducts = products.map((item: ProductItem) => {
                        if(item.id === id){
                            return {
                                ...item, // Copy existing fields
                                selling_price: priceInputValue, // Update selling_price
                              };
                        }
                        else {
                            return item;
                        }
                    })
                    dispatch(setPackageProducts(newProducts));
                }
                setPriceInputId('');
                setPriceInputValue(0);
            }).catch((e) => {
            });
        }
        
    }

    const calculatePackagePrice = () => {
        let packagePrice: number = 0;
        products?.map((productItem: ProductItem) => {
            if(cart?.standalone?.includes(productItem.id)){
                packagePrice = packagePrice + Number(productItem.selling_price);
            }
        });
        pallets?.map((palletItem: Pallet) => {

            if(cart?.pallets?.includes(palletItem.id)){
                packagePrice = packagePrice + Number(palletItem.selling_price);
            }
        });
        setArticles?.map((productItem: ProductItem) => {

            if(cart.unpaired.includes(productItem.id)){
                packagePrice = packagePrice + Number(productItem.selling_price);
            }
            else {
                cart?.paired?.map((pairedItem: PairedCartItem) => {

                    if(pairedItem.set_articles.includes(productItem.id)) {
                        packagePrice = packagePrice + Number(productItem.selling_price);
                    }
                })
            }
        })
        dispatch(setPackageTotalPrice(packagePrice.toFixed(2)));
    }

    return (
        <div>
            <CToaster push={toast} placement="top-end" />
            <GenericConfirmationModal
                  isModalVisible={packageConfirmationModal}
                  closeModal={()=> {setPackageConfirmationModal(false); setConflictingIds([])}}
                  headerText={'Are you sure?'}
                  bodyText={`Some articles were sold while you were creating your order, they will be automaticly removed, see list below: ${conflictingIds?.join(', ')}`}
                  confirmButtonText={'Accept and continue'}
                  onClickConfirmation={() => {
                    CreatePackage(setPackageConfirmationModal, true);
                }}
                hideCancelButton={true}
            />
            <Modal
                isModalVisible={isModalVisible}
                closeModal={()=>setIsModalVisible(false)}
                pairOptions={pairOptions}
                pairSetArticle={pairSetArticle}
                cart={cart}
                alreadyPairedToast={alreadyPairedToast}
                addToast={addToast}
            />

            <div className='website-settings-main-container'>
               
                <PackageHeaderComponent
                    selectedPallets={selectedPallets}
                    productsLength={products?.length}
                    setSelecteTab={setSelecteTab}
                    selectedTab={selectedTab}
                    setArticlesLength={setArticles?.length}
                    palletLength={pallets?.length}
                    cartLength={cart?.paired?.length + cart?.unpaired?.length + cart?.standalone?.length + cart?.pallets?.length}
                    automaticPairing={automaticPairing}
                    setAutomaticPairing={setAutomaticPairing}
                    getPairedSetArticlesOptions={getPairedSetArticlesOptions}
                    CreatePackage={CreatePackage}
                    truckCapacity={truckCapacity}
                    setTruckCapacity={setTruckCapacity}
                    selectedSetArticles={selectedSetArticles}
                    selectedProducts={selectedProducts}
                    selectedCustomer={selectedCustomer}
                    calculatePackagePrice={calculatePackagePrice}
                    truckErrorCallBack = {() => addToast(TruckErrorToast(()=>{addToast(null)}))}
                    smartPairLoading={smartPairLoading}
                    setSmartPairLoading = {setSmartPairLoading}
                    addToCart={addToCart}
                    setAddToCart={setAddToCart}
                    setPackageStatus={setPackageStatus}

                />

                <CRow>
                    <CCol xs={12} md={2}>
                        <PackageSearchComponent
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer ={setSelectedCustomer}
                            selectedTab={selectedTab}
                            setLoading={setLoading}                          
                            setSelectAll = {setSelectAll}
                            setSmartPairLoading={setSmartPairLoading}
                            packageStatus={packageStatus}
                        />
                    </CCol>
                    <CCol xs={12} md={10}>
                    {
                        loading ?
                            <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                <PlaceHolder />
                            </CCol>
                        :   null
                    }
                    {
                        ((selectedTab == 0 && products?.length > 0)  || (selectedTab == 1 && setArticles?.length > 0) || (selectedTab == 2 && pallets?.length > 0)) ?
                            <div
                                className="form-check"
                                onClick={(e) => {
                                    toggleSelectAllProducts();
                                }} 
                                style={{marginTop: 16}}
                            >
                                <input className="form-check-input" type="checkbox" value="" id="selectAll" checked={selectAll} />
                                <label className="form-check-label">
                                    {selectAll ? t('adminDashboard.Packages.unselect_all') : t('adminDashboard.Packages.select_all')}
                                </label>
                            </div>
                        :
                            null
                    }
                    {
                         ((selectedTab === 0 && products.length > 0) || (selectedTab === 1 && setArticles.length > 0)) ?
                            <PackageFiltersComponent 
                                selectedTab = {selectedTab}
                                editPackage={false}
                                setCurrentBrandList={setCurrentBrandList}
                                setCurrentArticleList={setCurrentArticleList}
                                setCurrentSubGroupList={setCurrentSubGroupList}
                                setCurrentUsageList={setCurrentUsageList}
                                setMinValue={setMinValue}
                                setMaxValue={setMaxValue}
                                minValue={minValue}
                                maxValue={maxValue}
                            />
                            : null
                    }
                       
                        <CRow>                            
                            {
                                selectedTab === 0 ?
                                    products?.length === 0 ?
                                        <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                            <h5>{t('adminDashboard.Products.no_product_found')}</h5>
                                        </CCol>
                                    :
                                    filteredProducts.map((item: ProductItem) => {
                                        // rendering only set articles
                                        return (
                                            <CCol xs={12} md={6} lg={4} xl={4} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                             <ProductCardComponent 
                                                selectedProducts={selectedProducts}
                                                item={item}
                                                changeProductPrice={changeProductPrice}
                                                priceInputId={priceInputId}
                                                setSellingPriceState={setSellingPriceState}
                                                priceInputValue={priceInputValue}
                                                setPriceInputValue={setPriceInputValue}
                                                cardOnClick={() =>  {
                                                    if(item.selling_price > 0) {
                                                        updateSelectedProducts(item.id);
                                                    }
                                                    else {
                                                        alert('Please set the selling price first.');
                                                    }
                                                }}
                                                selectedTab={selectedTab}
                                                setPriceInputId={setPriceInputId}
                                            />
                                            </CCol>
                                        )
                                    })
                                :  selectedTab === 1 ?
                                    setArticles?.length === 0 ?
                                        <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                            <h5>{t('adminDashboard.Products.no_setarticle_found')}</h5>
                                        </CCol>
                                    :
                                    filteredSetArticles.map((item: ProductItem) => {
                                        // rendering only set articles
                                        return (
                                            <CCol xs={12} md={6} lg={4} xl={4} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                                <ProductCardComponent 
                                                    selectedProducts={selectedSetArticles}
                                                    item={item}
                                                    changeProductPrice={changeProductPrice}
                                                    priceInputId={priceInputId}
                                                    setSellingPriceState={setSellingPriceState}
                                                    priceInputValue={priceInputValue}
                                                    setPriceInputValue={setPriceInputValue}
                                                    cardOnClick={() => {
                                                        if (item?.article?.includes('-2') || (item?.article?.includes('-1') && item.selling_price > 0)) {
                                                            updateSelectedSetArticles(item.id);
                                                        }
                                                        else {
                                                            alert('Please set the selling price first.');
                                                        }
                                                        
                                                    }}
                                                    automaticPairing={automaticPairing}
                                                    getPairedSetArticlesOptions={getPairedSetArticlesOptions}                                                                                            
                                                    selectedTab={selectedTab}
                                                    setPriceInputId={setPriceInputId}
                                                />
                                            </CCol>
                                        )

                                    })
                                : selectedTab === 2 ?
                                    pallets?.length === 0 ?
                                        <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                            <h5>{t('adminDashboard.Products.no_pallet_found')}</h5>
                                        </CCol>
                                    :
                                    pallets?.map((item: Pallet) => {
                                        // rendering only set articles
                                        return (
                                            <CCol xs={12} md={6} lg={4} xl={4} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                                <PalletCardComponent 
                                                    selectedPallets={selectedPallets}
                                                    item={item}
                                                    changeProductPrice={changeProductPrice}
                                                    priceInputId={priceInputId}
                                                    setSellingPriceState={setSellingPriceState}
                                                    priceInputValue={priceInputValue}
                                                    setPriceInputValue={setPriceInputValue}
                                                    cardOnClick={() =>  updateSelectedPallets(item.id)}
                                                    selectedTab={selectedTab}
                                                    setPriceInputId={setPriceInputId}
                                                />
                                            </CCol>
                                        )
                                    })
                                : selectedTab === 3 ?
                                <>
                                    <OrderSummary
                                        updateSelectedProducts={updateSelectedProducts}
                                        updateSelectedSetArticles={updateSelectedSetArticles}
                                        updateSelectedPallets={updateSelectedPallets}
                                    />                                    
                                </>
                                :
                                <div></div>
                            }
                        </CRow>
                    </CCol>
                </CRow>
            </div>
        </div>
    );
};
export default CreatePackageScreen;
