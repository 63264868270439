import { CButton, CCol, CFormCheck, CFormInput, CFormLabel, CRow } from "@coreui/react"
import Select from 'react-select';
import { DropDownItem, ProductDetailedSetArticle, ProductItem, Usage } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import { editProductData } from "../../services/ProductService";
import { useTranslation } from "react-i18next";

interface EditProductFormProps {
    editProduct: boolean;
    productEditable: boolean;
    product: ProductItem | ProductDetailedSetArticle;
    setEditProduct: (value: boolean) => void;
    setProduct?: (productItem: ProductItem | ProductDetailedSetArticle) => void;
}
const EditProductForm = (props: EditProductFormProps) => {
    const {editProduct, productEditable, product, setEditProduct, setProduct } = props;
    const {t} = useTranslation();
    const [currentArticle, setCurrentArticle] = useState<DropDownItem>({label: product?.article, value: product?.article});
    const [currentUsage, setCurrentUsage] = useState<DropDownItem>({label: product?.usage?.name, value: product?.usage?.id});
    const [finalGrade, setFinalGrade] = useState<DropDownItem>({label: product?.final_grade, value: product?.final_grade});
    const [sellingPrice, setSellingPrice] = useState<number>(product?.selling_price);
    const [isActive, setIsActive] = useState<boolean>(!(product?.is_deleted));
    const [isGlassBroke, setIsGlassBroke] = useState<boolean>(product?.metadata?.broken_glass);
    const [isDoorDamaged, setIsDoorDamaged] = useState<boolean>(product?.metadata?.broken_door);
    const [isDisplayDamaged, setIsDisplayDamaged] = useState<boolean>(product?.metadata?.broken_display);
    const [remarks, setRemarks] = useState<string>(product?.metadata?.remarks);

    const productArticles = useSelector((state: RootState) => state.productSlice.productArticles);
    const productUsage = useSelector((state: RootState) => state.productSlice.productUsage);
    const articleOptions: DropDownItem[] = productArticles.map((article: string) => ({ value: article, label: article }));
    const usageOptions: DropDownItem[] = productUsage.map((usage: Usage) => ({ value: usage.id, label: usage.name }));
    const finalGradeOptions: DropDownItem[] = [{label: 'A', value: 'A'}, {label: 'B', value: 'B'}, {label: 'C', value: 'C'}];

  const [articleSearchText, setArticleSearchText] = useState<string>('');  
  const updateProductData = () => {
    if(editProduct) {
        setEditProduct(!editProduct)
        const formData = {
            article: currentArticle.value,
            usage: currentUsage.value,
            final_grade: finalGrade.value,
            is_deleted: !isActive,
            selling_price: sellingPrice,
            metadata: {
                ...product.metadata,
                remarks: remarks,
                broken_glass: isGlassBroke,
                broken_door: isDoorDamaged,
                broken_display: isDisplayDamaged,
            }
        }
        editProductData(product.id, formData).then((response) => {
            setProduct && setProduct(response.data);
        }).catch((e) => {
        console.log('error: ', e);
        })
    }
    else {
        setEditProduct(!editProduct)
    }
}
    return(
        <>
        {
            !product?.is_sold ?
                <CRow>
                    <CCol xs={12}>
                        <CButton
                            className="elv-btn margin-left-12"
                            size="sm"
                            onClick={updateProductData}
                        >
                            {editProduct? "Done" : "Edit"}
                        </CButton>
                    </CCol>
                </CRow>
            : null
        }
        {
            editProduct ?

          
        <CRow>
            <CCol xs={6} className='padding-top-16'>
                <CFormLabel><b>Articles</b></CFormLabel>
                <Select
                    value={currentArticle}
                    placeholder={t('adminDashboard.CommonWords.min_3_digits')}
                    noOptionsMessage={({ inputValue: string }) => articleSearchText?.length < 3 ? t('adminDashboard.CommonWords.min_3_digits') : 'Article not found.' }
                    options={ articleSearchText.length >= 3 ? articleOptions : []}
                    onChange={(option: any) => {
                        setCurrentArticle(option)
                    }} 
                    onInputChange = {(option: string)=>{
                        setArticleSearchText(option)
                    }}
                />
            </CCol>
            <CCol xs={6} className='padding-top-16'>
                <CFormLabel><b>Usage</b></CFormLabel>
                <Select
                    value={currentUsage}
                    options={usageOptions}
                    onChange={(option: any) => {
                        setCurrentUsage(option)
                    }}
                />
            </CCol>
            <CCol xs={6} className='padding-top-16'>
                <CFormLabel><b>Final grade</b></CFormLabel>
                <Select
                    value={finalGrade}
                    options={finalGradeOptions}
                    onChange={(option: any) => {
                        setFinalGrade(option)
                    }}
                />
            </CCol>
            <CCol xs={6} className='padding-top-16'>
                <CFormLabel><b>Selling price</b></CFormLabel>
                <CFormInput
                    type="number"
                    value={sellingPrice}
                    onChange={(e: any) => {
                        setSellingPrice(Number(e.target.value))
                    }}
                />
            </CCol>
            <CCol xs={6} className='padding-top-16'>
                <CFormLabel><b>Remarks</b></CFormLabel>
                <CFormInput
                    type="text"
                    value={remarks}
                    onChange={(e: any) => {
                        setRemarks(e.target.value);
                    }}
                />
            </CCol>
            <CCol xs={12} className='padding-top-16'>
                <CFormCheck 
                    type="checkbox"
                    checked={isActive}
                    label={'Is Active'}
                    onClick={() => setIsActive(!isActive)}
                />
            </CCol>
            <CCol xs={12} className='padding-top-8'>
                <CFormCheck 
                    type="checkbox"
                    checked={isGlassBroke}
                    label={'Glass Broke'}
                    onClick={() => setIsGlassBroke(!isGlassBroke)}
                />
            </CCol>
            <CCol xs={12} className='padding-top-8'>
                <CFormCheck 
                    type="checkbox"
                    checked={isDoorDamaged}
                    label={'Door Damaged'}
                    onClick={() => setIsDoorDamaged(!isDoorDamaged)}
                />
            </CCol>
            <CCol xs={12} className='padding-top-8'>
                <CFormCheck 
                    type="checkbox"
                    checked={isDisplayDamaged}
                    label={'Display Damaged'}
                    onClick={() => setIsDisplayDamaged(!isDisplayDamaged)}
                />
            </CCol>
        </CRow>
          : null
        }
        </>
    )
}
export default EditProductForm;