import axios from "axios";
import { AuthUser, Customer, StaffUser, CustomerPatchData } from "../types";
import {BACKEND_API_URL} from "../constants";

const api = axios.create({
    baseURL: BACKEND_API_URL,   
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
  });
export const getCustomers = async() => {
    return await api.get(
        'api/customers/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const addCustomer = async(customerObject: Customer) => {
   
    return await api.post(
        'api/customers/',
        customerObject
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const updateCustomer = async(id: number, customerObject: Customer) => {
    return await api.patch(
        `api/customers/${id}/`,
        customerObject
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const deleteCustomer = async(id: number) => {
    return await api.delete(
        `api/customers/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getAllCountries = async() => {
    return await api.get(
        'api/users/country/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getAllGroups = async() => {
    return await api.get(
        'api/groups/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getCustomerData = async() => {
    return await api.get(
        `api/customer/users/`,
    )
    .then((response) =>{
        return response;
    })
    .catch(function(error) {
        return error;
    });
};

export const updateCustomerData = async(CustomerPatchDataObject: CustomerPatchData) => {
    return await api.patch(
        `api/customer/users/`,
        CustomerPatchDataObject
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
