import { CCol, CFormInput, CFormLabel, CHeader, CRow, CWidgetStatsF } from "@coreui/react";
import { t } from "i18next";
import { useState } from "react";
import { Utils } from "../utils";
import { cilClock } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";

interface SupplyManagementHeaderProps {
    headerText?: string;   
};

const CatalogManagementHeader = (props: SupplyManagementHeaderProps) => {
    const { headerText } = props;
    const {getCatalogData, getCatalogsByModelsData} = Utils();
    const {t} = useTranslation();
    const [articleId, setArticleId] = useState<string>('');
    const [model, setModel] = useState<string>('');
    const InCompleteCatalog = useSelector((state: RootState) => state.catalogSlice.inCompleteCatalog);

    return (
        <>
            <CHeader className="margin-bottom-24">
                <h1><b>{headerText ? headerText : t('adminDashboard.CatalogManagement.sidebar_text')}</b></h1>
                <div style={{position: 'absolute', right: 0, bottom: 0}}>
                    {
                    InCompleteCatalog ?
                            <CWidgetStatsF
                                color="danger"
                                icon={<CIcon icon={cilClock} height={24} />}
                                title={t('adminDashboard.CatalogManagement.show_articles')}
                                value={t('adminDashboard.CatalogManagement.complete_articles')}
                                onClick={() => getCatalogsByModelsData('NOT IN OTTO API')}
                                className="pointer"
                            />
                        : 
                            null
                    }
                </div>
            </CHeader>
            <CRow>
                <CCol xs={3}>
                    <CFormLabel><b>{t('adminDashboard.Products.article')}</b></CFormLabel>
                    <CFormInput
                        type={"text"}
                        placeholder='Article 123ABC, etc'
                        value={articleId}
                        onChange={(e) => {
                            setArticleId(e.target.value);
                            if(e?.target?.value?.length > 4) {
                                getCatalogData(e.target.value);
                            }
                        }}
                        style={{marginBottom: 44}}
                    /> 
                </CCol>
                <CCol xs={3}>
                    <CFormLabel><b>{t('adminDashboard.CommonWords.model')}</b></CFormLabel>
                    <CFormInput
                        type={"text"}
                        placeholder='Model 123456, etc'
                        value={model}
                        onChange={(e) => {
                            setModel(e.target.value);
                            if(e?.target?.value?.length > 4) {
                                getCatalogsByModelsData(e.target.value);
                            }
                        }}
                        style={{marginBottom: 44}}
                    />
                </CCol>
            </CRow>
        </>
    )
};

export default CatalogManagementHeader;
