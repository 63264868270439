import { postCRMData, putCRMData } from "../../../services/ProductService";

export const CrmUtils = () => {
    const registerCRMUser = (customer: any, errorCallBack?: (value: string) => void) => {
      let sales_accounts_form_data = {
        name: customer.company,
        address: customer.address,
        city: customer.city,
        zipcode: customer.zipcode,
        country: customer.country?.label,
        custom_field: {
          "cf_ustid_nr": customer.vat_id,
          'cf_jtl_customer_number': customer?.jtlID ? customer?.jtlID : undefined,
        }
      }
      postCRMData('sales_accounts', sales_accounts_form_data).then((response) => {
        console.log('Company account created.', response?.data?.sales_account);
        let contact_form_data = {
          first_name: customer.firstName, 
          last_name: customer.lastName, 
          mobile_number: customer.phoneNumber,
          email: customer.email,
          sales_accounts: [{"id" : response?.data?.sales_account?.id , "is_primary" : true}]
        }
        postCRMData('contacts', contact_form_data).then((response) => {
          console.log('contact created.', response?.data?.contact);
          // Following is the hard coded id of Sales Marketing list.
          let list_form_data = {"ids":[response?.data?.contact?.id]}
          putCRMData('lists/31000108282/add_contacts', list_form_data).then((response) => {
            console.log('contact added: ', response?.data);
          }).catch((e) => {
            errorCallBack && errorCallBack(e?.response?.data?.errors?.message?.join());
            console.log('contact adding to list error: ', e?.response?.data?.errors?.message);
          })
        }).catch((e) => {
          errorCallBack && errorCallBack(e?.response?.data?.errors?.message?.join());
          console.log('contacts creation error: ', e?.response?.data?.errors?.message);
        })
      }).catch((e) => {
        errorCallBack && errorCallBack(e?.response?.data?.errors?.message?.join());
        console.log('sales_account creation error: ', e?.response?.data?.errors?.message);
      })
    }
    return { registerCRMUser}
}