import axios from "axios";
import { CreatPackageObject, Customer, Package, ProductItem } from "../types";
import {BACKEND_API_URL} from "../constants";

const api = axios.create({
    baseURL: BACKEND_API_URL,   
    withCredentials: true,
    headers: {       
        'Content-Type': 'application/json',
    }
  });

  const TruckApi = axios.create({
    baseURL: 'https://flask.api.elvinci.ai/palletization/web/',
    headers: {       
        'Content-Type': 'application/json',
    }
  });

  export const createPackage = async(PackageObject: CreatPackageObject) => {
    return await api.post(
        'api/packages/',
        PackageObject,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const modifyPackage = async(id: number, formData = {}) => {
    return await api.patch(
        `api/packages/${id}/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const getPackages = async(page?: number) => {
    if (page){
        return await api.get(
            `api/packages/?page=${page}`,
        )
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            throw error;
        });
    }
    else {
        return await api.get(
            `api/packages/`,
        )
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            throw error;
        }); 
    }
};

export const getPendingPackages = async() => {
    return await api.get(
        `api/packages/pending/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    }); 
    
};
export const getPackage = async(id: number) => {
    return await api.get(
        `api/packages/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const getPackageSnapshots = async(id: number) => {
    return await api.get(
        `api/packages/${id}/snapshots/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const revertPackageSnapshot = async(packageId: number, snapshotId: number) => {
    return await api.post(
        `api/packages/${packageId}/revert/${snapshotId}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};


export const deletePackage = async(id: number) => {
    return await api.delete(
        `api/packages/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

// Pairing requests
export const getSetArticlePairs = async(item: ProductItem) => {
    return await api.get(
        `api/set-articles/find-pairs/?product_id=${item.id}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const syncCart = async(formdata: any) => {
    return await api.post(
        `api/set-articles/find-pairs/sync/`,
        formdata,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getAutomaticPairs = async( product_ids: number []) => {
    return await api.post(
        `api/set-articles/find-pairs/automatic/`,
        {
            product_ids: product_ids
        }
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const linkSetArticles = async( id: number, product_ids: number []) => {
    // used only for warehouse module where we have to directly link or unlink product.
    return await api.post(
        `api/packages/link-setarticle/?package=${id}`,
        {
            product_ids: product_ids
        }
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

// Calculate Truck capacity
export const getTruckCapacity = async(form_data: any) => {
    return await TruckApi.post(
        'fetch_pallets',
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

// Cart requests
export const editCartItem = async(id: number, formData = {}) => {
    return await api.patch(
        `api/cart/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const updateCart = async(id: number, formData = {}) => {
    return await api.patch(
        `api/cart/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const deleteCart = async() => {
    return await api.delete(
        `api/cart/`,       
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getCart = async() => {
    return await api.get(
        `api/cart/`,       
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const filterPackageByProductID = async (product_id: number) => {
    return await api.get(
        `api/packages/product/?product_id=${product_id}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const getPackagesCount = async() => {
    return await api.get(
        `api/packages/all-packages-count/`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const addProductsToPackage = async(id: number, formData = {}) => {
    return await api.post(
        `api/packages/add-products-to-package/?package=${id}`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const removeProductsFromPackage = async(id: number, formData = {}) => {
    return await api.post(
        `api/packages/remove-products-from-package/?package=${id}`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

