import PropTypes from 'prop-types';

interface DividerProps {
    color?: string;
    thickness?: number;
    margin?: number;
  }
const Divider = ({ color, thickness, margin }: DividerProps) => {
  const dividerStyle = {
    borderTop: `${thickness}px solid ${color}`,
    margin: `${margin}px 0`,
  };

  return <div style={dividerStyle}></div>;
};

Divider.propTypes = {
  color: PropTypes.string,
  thickness: PropTypes.number,
  margin: PropTypes.number,
};

Divider.defaultProps = {
  color: '#000', // Default color black
  thickness: 1, // Default thickness 1px
  margin: 16, // Default margin 16px
};

export default Divider;