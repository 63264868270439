import { CRow, CCol, CFormLabel, CButton, CFormInput} from "@coreui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { DropDownItem, ProductBrand, ProductCategory, ProductSubCategory, Supply, SupplyType, Products, ProductItem } from "../../../types";
import '../AdminComponentStyles.css';
import { useEffect, useState } from "react";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { ELV_BLUE, FONT_15 } from "../../../constants";
import Select from 'react-select';

import { getProductById, getTypeProductSupplies } from "../../../services/ProductService";
import { getPalletById } from "../../../services/PalletService";
import { useTranslation } from "react-i18next";

interface FilterProps {
    setBrandist: (value: string[]) => void;   
    setCurrentSubGroupList: (value: string[]) => void;
    setArticleList: (value: string[]) => void;
    setCurrentGradeMin: (value: number) => void;
    setCurrentGradeMax: (value: number) => void;
    setCurrentStatus: (value: string) => void;
    setSupplyList: (value: string[]) => void;
    setCurrentSupplyTypeList: (value: string[]) => void;
    setSubGroupList: (value: number[]) => void;
    clearFilters: () => void;
    applyFilters: () => void;
    setTempProducts: (value: ProductItem[]) => void;
    setNoReultFound: (value: boolean) => void;
    setLastScannedText: (value: boolean) => void;
    getLatestProduct: () => void;
    tempProducts: ProductItem[];
    currentGradeMin: number;
    currentGradeMax: number;
    currentSubGroupList: string[];    
    currentSupplyTypeList: string[];
    noReultFound: boolean;
}
const ProductHorizontalFilterComponent = (props: FilterProps) => {
    const {
        setBrandist, setArticleList, setCurrentGradeMin, setCurrentGradeMax, tempProducts,
        currentSubGroupList, setSupplyList,  setCurrentSubGroupList, getLatestProduct, setTempProducts,
        currentGradeMin, currentGradeMax, setCurrentStatus, setNoReultFound, setLastScannedText,
        noReultFound, setSubGroupList
    } = props;
    const {t} = useTranslation();
    // const [supplyLoading, setSupplyLoading] = useState<boolean>(false);
    const productBrands = useSelector((state: RootState) => state.productSlice.productBrands);
    const productSupplies = useSelector((state: RootState) => state.productSlice.productSupplies);
    const productSubCategories = useSelector((state: RootState) => state.productSlice.productSubCategories);
    const productArticles = useSelector((state: RootState) => state.productSlice.productArticles);
    const subCategoryOptions: DropDownItem[] = productSubCategories.map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }));
    
    const subCategoryFilteredOptions: DropDownItem[] = productSubCategories.filter((subCat: ProductSubCategory) => {
        return tempProducts.some((item: ProductItem) => item?.sub_category == subCat.name)
    }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }));

    const brandOptions: DropDownItem[] = productBrands.filter((brand: ProductBrand) => {
        return tempProducts.some((item: ProductItem) => item?.brand == brand.name)
    }).map((brand: ProductBrand) => ({ value: brand.id, label: brand.name }));

    const productStatusOptions = [{ value: 'all', label: "All" }, { value: 'sold', label: "Sold" }, { value: 'not_sold', label: "Not sold" }];
    const articleOptions = productArticles.map((article: string) => ({ value: article, label: article }));
    const [supplyOptions, setSupplyOptions] = useState<any>(productSupplies.map((supply: Supply) => ({ value: supply.id, label: `${supply.id} (${supply.total_products})` })));
    const [articleSearchText, setArticleSearchText] = useState<string>('');
    const [supplySearchText, setSupplySearchText] = useState<string>('');

    const filterProductById = (id: number) => {
        getProductById(id).then((response) => {
            setLastScannedText(false);
            setNoReultFound(false);
            setTempProducts([{
                ...response.data, 
                image_list: response?.data?.metadata?.image_list
            }]);
        }).catch((e) => {
            getPalletById(id).then((response) => {
                setLastScannedText(false);
                setNoReultFound(false);
                setTempProducts([{
                    ...response.data, 
                }]);
            }).catch((e) => {
                setNoReultFound(true);
            });
        })
      }

      useEffect(() => {
        setSupplyOptions(productSupplies.map((supply: Supply) => ({ value: supply.id, label: `${supply.id} (${supply.total_products})` })))
      },[productSupplies])
    return (
        <>
        <CRow className="col-sm-12">
            <CCol className="margin-top-24" xs={12} md={2}>
                <CFormLabel><b>{t('adminDashboard.Products.product_id_search')}</b></CFormLabel>
                <CFormInput
                    placeholder={`${t('adminDashboard.CommonWords.search')}`}
                    type='number'
                    onChange={(e: any) => {
                        if(e?.target?.value.length == 0) {
                            setNoReultFound(false);
                            getLatestProduct();
                        }
                        else {
                            filterProductById(e?.target?.value);
                        }
                    }}
                />
            </CCol>
                
            <CCol className="margin-top-24" xs={12} md={2}>
                <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply_id')}</b></CFormLabel>
                <Select
                    placeholder={`${t('adminDashboard.CommonWords.select')}`}
                    isMulti
                    noOptionsMessage={({ inputValue: string }) => supplySearchText.length >= 3 ? 'Supply not found.' : '' }
                    options={supplyOptions}
                    onChange={(options: any) => {
                        setSupplyList(
                            options.map((option: any) => (option.value))
                        )
                    }} 
                    onInputChange = {(value: string)=>{
                        setSupplySearchText(value)
                    }}
                    // isLoading={supplyLoading}
                />
            </CCol>

            <CCol className="margin-top-24" xs={12} md={2} >
                <CFormLabel><b>{t('adminDashboard.Products.article')}</b></CFormLabel>
                <Select
                    isMulti
                    placeholder={t('adminDashboard.CommonWords.min_3_digits')}
                    noOptionsMessage={({ inputValue: string }) => articleSearchText.length >=3 ? 'Article not found.' : t('adminDashboard.CommonWords.min_3_digits')}
                    options={ articleSearchText.length >= 3 ? articleOptions : []}
                    onChange={(options: any) => {
                        setArticleList(
                            options.map((option: any) => (option.value))
                        )
                    }} 
                    onInputChange = {(option: string)=>{
                        setArticleSearchText(option)
                    }}
                />
            </CCol>
            
            <CCol xs={12} md={2} className='margin-top-24'>
                <CFormLabel><b>{t('adminDashboard.Products.category')}</b></CFormLabel>
                {/* This used to be a sub cat, but it should be renamed according to the current portal, as asked by Boris.*/}
                <Select
                    placeholder={t('adminDashboard.CommonWords.select')}
                    closeMenuOnSelect={false}
                    isMulti
                    options={subCategoryOptions} 
                    onChange={(options: any) => {
                        setSubGroupList(
                            options.map((option: any) => (option.value))
                        )
                    }}
                    styles={{
                        multiValueLabel: (base) => ({
                            ...base,
                            backgroundColor: ELV_BLUE,
                            color: 'white',
                            fontSize: FONT_15,
                        }),
                    }}
                />
            </CCol>
        </CRow>
        
        {
            tempProducts?.length > 1 && noReultFound == false ?
            <>
                <h4 className="margin-top-44"><b>Filters</b></h4>
                <CRow className="margin-top-16">
                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.Products.brands')}</b></CFormLabel>
                        <Select
                            placeholder={`${t('adminDashboard.CommonWords.select')}`}
                            isMulti
                            options={brandOptions}
                            onChange={(options: any) => {
                                setBrandist(
                                    options.map((option: any) => (option.label))
                                )
                            }}
                        />
                    </CCol>

                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.Products.category')}</b></CFormLabel>
                        <Select
                            placeholder={`${t('adminDashboard.CommonWords.select')}`}
                            isMulti
                            options={subCategoryFilteredOptions} 
                            onChange={(options: any) => {
                                setCurrentSubGroupList(
                                    options.map((option: any) => (option.label))
                                )
                            }}
                        />
                    </CCol>
                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.CommonWords.status')}</b></CFormLabel>
                        <Select
                            defaultValue={productStatusOptions[0]}
                            options={productStatusOptions} 
                            onChange={(option: any) => {
                                setCurrentStatus(option.value);
                            }}
                        />
                    </CCol>
                    <CCol xs={12} md={3} >
                        <CFormLabel><b>{t('adminDashboard.Products.grade_point')}</b></CFormLabel>
                        <MultiRangeSlider
                            minValue={currentGradeMin}
                            maxValue={currentGradeMax}
                            min={0}
                            max={100}
                            onInput={(e) => {
                                setCurrentGradeMin(e.minValue);
                                setCurrentGradeMax(e.maxValue);
                            }}
                            onChange={(e: ChangeResult) => {
                                // This function changes values after dragging is done.
                                setCurrentGradeMin(e.minValue);
                                setCurrentGradeMax(e.maxValue);
                            }}
                            label={true}
                            ruler={false}
                            style={{ border: "none", boxShadow: "none", padding: "15px 10px", marginTop: 25 }}
                            barLeftColor="white"
                            barInnerColor={ELV_BLUE}
                            barRightColor="white"
                            thumbLeftColor={ELV_BLUE}
                            thumbRightColor={ELV_BLUE}
                        />
                    </CCol>
                </CRow>
            </>
            : null
        }
      </>
    );
}
export default ProductHorizontalFilterComponent;