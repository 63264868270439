import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      authentication: {
          register: {
              info: 'Please fill out the form below and we will contact you shortly.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
          my_dashboard: 'My Dashboard',
        },
        Analytics: {
          sidebar_text: 'Analytics',
          analytics: 'Analytics',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Profit and Turnover',
        },
        ScanningTime: {
          sidebar_text: 'Scanning Time',
        },
        SalesPersonComparison: {
          sidebar_text: 'Sales comparison',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Sales cat-analysis',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Sales brand-analysis',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Sales grade-analysis',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Company sales',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Company category sales',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Company brand sales',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Company grade sales',
        },
        SupplyAnalysis: {
          sidebar_text: 'Supply analysis',
          searchbar_text: 'Search',
        },
        StockAnalysis: {
          sidebar_text: 'Stock analysis',
          searchbar_text: 'Select warehouse',
        },
        MainDashboard: {
          sidebar_text: 'Main dashboard',
        },
        Navigation: {
          sidebar_text: 'DASHBOARD ITEMS',
        },
        Users: {
          sidebar_text: 'Users',
          customers_data: 'Customers Data ',
          edit_info: 'Edit info',
          delete_customer: 'Delete',
          actions: 'Actions',
          active: 'Active',
          add_new_staff_user: 'Add new staff user',
          add_supply_type: 'Add supply type',
          add_user: 'Add user',
          admin: 'Admin',
          user: 'user',
          enter_first_name: 'Enter First name',
          enter_last_name: 'Enter Last name',
          enter_user_password: 'Enter user password',
          not_active: 'Not active',
          pending_customer_requests: 'Pending Customer Requests',
          unable_to_log_in: 'Unable to log in with provided credentials.',
        },
        Team: {
          sidebar_text: 'Team'
        },
        Customers: {
          sidebar_text: 'Customers',
          main_text: 'Customers Data',
          my_packages: 'My Packages',
          my_orders: 'My orders',
          my_orders_details: 'Order Details',
          add_customer: 'Add Customer',
          edit_info: 'Edit info',
          delete_customer: 'Delete',
          customer: 'Customer',
          customer_email: 'Customer Email',
          customers: 'Customers',
          phone_number: 'Phone #',
          accepted_terms: 'Thank you very much for agreeing to our General Terms and Conditions. You can now proceed',
          rejected_terms: 'You can not proceed further and being logged out of the system',
        },
        Packages: {
          sidebar_text: 'Packages',
          package: 'Package',
          create_package_text: 'Create Package',
          warehouse_text: 'Warehouse',
          customer_text: 'Customers',
          truck_capacity_text: 'Truck Capacity',
          selling_price_text: 'Selling price',
          brands_text: 'Brands',
          fetch_products_button_text: 'Apply',
          fetch_from_rest: 'fetch from rest',
          supply_id_please: 'Please provide supply id.',
          pair_all_button: 'Pair all',
          pair_button: 'Pair',
          smart_pair_switch: 'Smart pairing',
          edit_info: 'Edit info',
          are_you_sure: 'Are you sure?',
          article: 'Article',
          date_from: 'Date from',
          date_to: 'Date to',
          extra: 'Extra',
          extra_items: 'Extra items',
          grade_points_range: 'Grade points range',
          grand_total_euro: 'Grand total €',
          pending_packages: 'Pending packages',
          status: 'Status',
          place_order: 'place order',
          select_all: 'select all',
          unselect_all: 'un-select all',
          total_buying_offer: 'Total buying offer:',
          total_buying_price: 'Total buying price:',
          total_items: 'Total items',
          total_packages: 'Total packages',
          select_pallet_supply: 'Please select pallet supply',
          please_select_supplier: 'Please select supplier',
          validity_hours: 'Validity days',
          confirm_package: 'Confirm package',
          active_packages: 'Active packages',
          you_have_selected: 'You have selected',
          items: 'Items',
          message: 'Message',
          type_your_messsage_here: 'Type your message here...',
          revert: "Revert",
          revert_to_prev_snapshots: 'Revert to previous packet',
          which_snapshot: 'Which snapshot would you like to revert to?',
          package_summary: 'Package Summary',
          already_ordered_edit_package_placed_order_message: 'Package is already ordered, JUST EDIT action can be perfomed for modifications',
          fetch_products_button_edit_package_placed_order_message: 'Package is already ordered, more products can not be added',
        },
        PendingPackages: {
          sidebar_text: 'Pending packages',
          place_order_confirmation: 'Are you sure you want to place this order?',
        },
        OrderRequests: {
          sidebar_text: 'Orders',
          total_orders: 'Total Orders',
          customer_carts: 'Pending Customer carts',
          add_to_cart: 'Add to cart',
          remove_from_cart: 'Remove from cart',
          create_cart: 'Create Cart',
        },
        Warehouse: {
          sidebar_text: 'Warehouse',
          create_warehouse_order: 'Warehouse package #',
          allow_warehouse_scan: 'Are you sure you want to allow warehouse to scan this supply',
          warehouse: 'Warehouse',
          warehouse_package: 'Warehouse Package',
          warehouse_manager: 'WarehouseManager',
          change_customer_already_ordered_message: 'Package is already ordered, you can not change the customer',
        },
        Products: {
          sidebar_text: 'Products',
          product_details: 'Product details',
          latest_product_scanned: 'Latest product scanned',
          online_price: 'Online price',
          price_euro: 'Price €',
          product_id: 'Product id',
          product_id_search: 'Product Id Search',
          products: 'Products',
          products_quantity: 'Products Quantity',
          products_selected: 'Products selected',
          selling_price: 'Selling price',
          items_selected_percentage: 'Items selected %',
          min_3_digits: 'Min 3 digits',
          minimum_3_digits: 'Minimum 3 digits',
          no_of_products_scanned: 'No of Products Scanned',
          no_product_within_applied_filter: 'No product within the applied filter',
          not_scanned_items: 'Not scanned items',
          number_of_products_scanned: 'Number of products Scanned',
          number_of_products: 'Number of products',
          description: 'Description',
          grade_point: 'Grade point',
          final_grade: 'Final grade',
          category: 'Category',
          remarks: 'Remarks',
          usage: 'Usage',
          add_to_cart: 'Add to Cart',
          remove_from_cart: 'Remove from Cart',
          brand: 'Brand',
          brands: 'Brands',
          buying_percentage: 'Buying %',
          buying_online_price: 'Buying online price',
          buying_price: 'Buying price',
          retail_price: 'Retail price',
          article: 'Article',
          seen: 'SEEN',
          paired: 'PAIRED',
          no_product_found: 'No product found.',
          no_setarticle_found: 'No set-article found.',
          no_pallet_found: 'No Pallet found.',
          supplier_grade: 'Supplier grade',
          broken_glass: 'Broken glass',
          broken_door: 'Broken door',
          price_percent: 'Price percent',
          scale: 'Scale',
        },
        Pallets: {
          pallet_details: 'Pallet details',
          products_inside: 'Products inside',
          pallet_product_detail: "Enter pallet product details",
          mandatory_fields_text: "fields with * are mandatory.",
          add_pallet_product_button: '+ Add pallet product',
          pallet: 'Pallet',
          pallets: 'Pallets',
          total_selling_price: 'Total selling price',
          total_online_price: 'Total online price',
        },
        SupplyManagement: {
          sidebar_text: 'Supply management',
          supply: 'Supply',
          supply_id: 'Supply ID',
          create_new_supply: 'Create new supply',
          create_new_supply_type: 'Create new supply type',
          new_supply_id: "New supply id",
          create_supplier_text: 'Create new supplier',
          create_supplier_name_Text: 'New supplier name',
          create_supplier_button: 'Create supplier',
          add_supply: 'Add Supply',
          all_supplies: 'All supplies',
          all_supply_types: 'All supply types',
          all_suppliers: 'All suppliers',
          manually_uploaded_prices: 'Manually uploaded prices',
          supplier: 'Supplier',
          supplier_name: 'Supplier name',
          supply_inventory: 'Supply inventory',
          supply_management: 'Supply management',
          supply_not_found: 'Supply not found',
          supply_type: 'Supply Type',
          supply_type_a: 'Supply type A (0 - 1)',
          supply_type_b: 'Supply type B (0 - 1)',
          supply_type_buying: 'Supply type Buying (0 - 1)',
          supply_type_c: 'Supply type C (0 - 1)',
          supply_type_name: 'Supply type name',
          supply_type_not_found: 'Supply-type not found',
          total_suppliers: 'Total suppliers',
          total_supplies: 'Total supplies',
          total_supply_types: 'Total supply types',
          edit_supplier_information: 'Edit supplier information',
          edit_supply: 'Edit supply',
          edit_supply_type: 'Edit supply type.',
          exclude_supplies: 'Exclude Supplies',
          a_grade_percentage: 'A Grade %',
          b_grade_percentage: 'B Grade %',
          c_grade_percentage: 'C Grade %',
          allow_supply_to_sales: 'Are you sure you want to allow this supply to sales',
          new_supply: 'New supply',
          old_supply: 'Old supply',
          edit_supplier_details: 'Please edit the following details of the supplier.',
          edit_supply_type_details: 'Please edit the following details of the supply type.',
          provide_supply_id: 'Please provide supply id.',
        },
        SupplyInventory: {
          sidebar_text: 'Supply inventory',
          extra_text: 'Extra products',
          scanned_text: 'Scanned products',
          unscanned_text: 'Not scanned yet',
          mark_scanned_completely: 'Are you sure you want to mark as "scanned completely"',
          restrict_supply_to_sales: 'Are you sure you want to restrict this supply to sales',
          confirm_delete_item: 'Are you sure, you want to delete this item?',
          assign_type_to_new_supply: 'Assign type to a new supply',
          scanned: 'Scanned',
          unscanned: 'Unscanned',
          extra: 'Extra',
          not_visible_until_completed: 'They will not be visible until completed.',
          scanned_items: 'Scanned items',
          scanning_in_progress: 'Scanning in progress',
        },

        CatalogManagement: {
          sidebar_text: 'Catalog management',
          catalog: 'Catalog',
          type: 'Type',
          width_primary_cm: 'Width primary (cm)',
          width_secondary_cm: 'Width secondary (cm)',
          height_primary_cm: 'Height primary (cm)',
          height_secondary_cm: 'Height secondary (cm)',
          depth_primary_cm: 'Depth primary (cm)',
          depth_secondary_cm: 'Depth secondary (cm)',
          catalog_management: 'Catalog management',
          depth_primary: 'Depth primary',
          depth_secondary: 'Depth secondary',
          height_primary: 'Height primary',
          height_secondary: 'Height secondary',
          weight_kg: 'Weight(Kg)',
          width_primary: 'Width primary',
          width_secondary: 'Width secondary',
          loading_meters: 'Loading Meters',
          complete_articles: 'Complete some incomplete articles',
          show_articles: 'SHOW ARTICLES',
        },
        RightsManagement: {
          sidebar_text: 'Rights management',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Pending customer requests',
        },
        WebsiteSettings: {
          sidebar_text: 'Website settings',
          select_language: 'Select Language',
          upload: 'upload',
          upload_catalog: 'Upload Catalog',
          upload_pallet_products: 'Upload pallet products',
          upload_product: 'Upload product',
          settings: 'Settings',
          required_columns: 'Required columns',
          change_password: 'Change password',
          new_password: 'New password',
          confirm_new_password: 'Confirm new password',
          review_price_text: 'Have you reviewed the file in detail?',
          review_buying_price_text: 'If file contains some product having buying price = 0',
          review_selling_price_text: 'If file contains some product having selling price is less than 60',
          review_buyingonline_price_text: 'If file contains some product having buying online price already in Portal different value.',
        },
        OrdersWords:{
          available_items: 'Available items',
          available_pallets: 'Available Pallets',
          available_products: 'Available Products',
          created_by: 'Created by',
          order: 'Order',
          order_id: 'Order Id',
          ordered: 'Ordered',
          package_id: 'package id',
          sales_assistant: 'Sales-assistant',
          search_by_name: 'Search by name',
          search_company: 'Search company',
          select_sales_person: 'Select sales person',
          select_supplier: 'Select supplier',
          set_articles: 'Set Articles',
          sub_category: 'Sub-category',
          sub_total_euro: 'Sub Total €',
          sub_cat: 'Sub Cat',
          order_id_search: 'Search By Order ID',
        },
        AnalysisWords:{
          analysis_text: 'Analysis',
          category: 'Category',
          category_analysis: 'Category Analysis',
          change_supply_visibility: 'Change Supply Visibility',
          companies_category_analysis: 'Companies Category Analysis',
          companies_grades_analysis: 'Companies Grades Analysis',
          compare_sales_person: 'Compare Sales person',
          turnover_monthly: 'Turnover Monthly',
          turnover_yearly: 'Turnover Yearly',
          profit_monthly: 'Profit Monthly',
          profit_yearly: 'Profit Yearly',
        },
        CommonWords: {
          min_3_digits: 'Min 3 digits',
          no_items_found: 'No items found ',
          accept: 'Accept',
          download_pdf: 'Download PDF',
          download_images: 'Download Images',
          note: 'Note',
          jtl_id: 'JTL ID',
          joined_date: 'Joined Date',
          false: 'false',
          true: 'true',
          pending: 'pending',
          order_requested: 'Order Requested',
          first_name: 'First Name',
          last_name: 'Last Name',
          logout: "logout",
          more: 'more',
          account: "Account",
          search: 'Search',
          close: 'Close',
          pending_approval: 'Pending',
          order_placed: 'Order Placed',
          view_details: 'View Details',
          download_excel: 'Download Excel',
          download_template: 'Download Template',
          place_order: 'Place order',
          Edit: 'Edit',
          revert: 'Revert',
          action: 'Action',
          status: 'Status',
          quantity: 'Quantity',
          apply: 'Apply',
          done: 'Done',
          edit: 'Edit',
          assign: 'Assign',
          default: 'Default',
          delete: 'Delete',
          cancel: 'Cancel',
          date: 'Date',
          company: 'Company',
          compare: 'Compare',
          email: 'Email',
          image: 'Image',
          images: 'Images',
          model: 'Model',
          month: 'Month',
          group: 'Group',
          backoffice: 'BackOffice',
          brand_analysis: 'Brand Analysis',
          brand: 'Brand',
          brands: 'Brands',
          buying_percentage: 'Buying %',
          buying_online_price: 'Buying online price',
          buying_price: 'Buying price',
          cart: 'Cart',
          confirm: 'confirm',
          create: 'Create',
          discount_percentage: 'Discount %',
          items_per_page: 'Items per page:',
          navigation: 'Navigation',
          select: 'Select',
          view: 'View',
          role: 'Role',
          sales: 'Sales',
          yes: 'Yes',
          item_created_successfully: 'Created successfully.',
          item_updated_successfully: 'Updated successfully.',
          permissions: 'Permissions.',
          assign_default_permissions: 'Assign default permissions.',
        },
      },
    }
  },
  de: {
    translation: {
        authentication: {
            register: {
                info: 'Bitte füllen Sie das untenstehende Formular aus und wir werden uns in Kürze mit Ihnen in Verbindung setzen.'
            },
            login: {},
        },
        adminDashboard: {
          Dashboard: {
            sidebar_text: 'Dashboard',
            my_dashboard: 'Mein Dashboard',
          },
          Analytics: {
            sidebar_text: 'Analytics',
            analytics: 'Analyse',
          },
          ProfitAndTurnover: {
            sidebar_text: 'Gewinn und Umsatz',
          },
          ScanningTime: {
            sidebar_text: 'Durchschnittliche Scanzeit (Sek.)',
          },
          SalesPersonComparison: {
            sidebar_text: 'Umsatzvergleich',
          },
          SalesPersonCategoriesAnalysis: {
            sidebar_text: 'Kategorie Verkaufsanalyse',
          },
          SalesPersonBrandAnalysis: {
            sidebar_text: 'Markenanalyse',
          },
          SalesPersonGradeAnalysis: {
            sidebar_text: 'Grade Verkaufsanalyse',
          },
          SalesCompaniesComparison: {
            sidebar_text: 'Unternehmensverkäufe',
          },
          SalesCompaniesCategories: {
            sidebar_text: 'Unternehmen Kategorie Verkaufsanalyse',
          },
          SalesCompaniesBrands: {
            sidebar_text: 'Unternehmen Marken Verkaufsanalyse',
          },
          SalesCompaniesGrades: {
            sidebar_text: 'Unternehmen Grade Verkaufsanalyse',
          },
          SupplyAnalysis: {
            sidebar_text: 'Angebotsanalyse',
            searchbar_text: 'Suche',
          },
          StockAnalysis: {
            sidebar_text: 'Bestandsanalyse',
            searchbar_text: 'Lager auswählen',
          },
          MainDashboard: {
            sidebar_text: 'Haupt-Dashboard',
          },
          Navigation: {
            sidebar_text: 'Navigation',
          },
          Users: {
            sidebar_text: 'Benutzer',
            customers_data: 'Kundendaten',
            edit_info: 'Info bearbeiten',
            delete_customer: 'Löschen',
            actions: 'Aktionen',
            active: 'Aktiv',
            add_new_staff_user: 'Neuen Mitarbeiter hinzufügen',
            add_supply_type: 'Lieferart hinzufügen',
            add_user: 'Benutzer hinzufügen',
            admin: 'Admin',
            user: 'Benutzer',
            enter_first_name: 'Vornamen eingeben',
            enter_last_name: 'Nachnamen eingeben',
            enter_user_password: 'Benutzerpasswort eingeben',
            not_active: 'Nicht aktiv',
            pending_customer_requests: 'Ausstehende Kundenanfragen',
            unable_to_log_in: 'Anmeldung mit den angegebenen Zugangsdaten nicht möglich.',
          },
          Team: {
            sidebar_text: 'Team'
          },
          Customers: {
            sidebar_text: 'Kunden',
            main_text: 'Kundendaten',
            my_packages: 'Meine Pakete',
            my_orders: 'Meine Aufträge',
            my_orders_details: 'Auftragsdetails',
            add_customer: 'Kunden hinzufügen',
            edit_info: 'Info bearbeiten',
            delete_customer: 'Löschen',
            customer: 'Kunde',
            customer_email: 'Kunden-E-Mail',
            customers: 'Kunden',
            phone_number: 'Telefonnummer',
            accepted_terms: 'Sie können jetzt fortfahren',
            rejected_terms: 'Sie können nicht mehr weitermachen und werden aus dem System ausgeloggt',
          },
          Packages: {
            sidebar_text: 'Pakete',
            package: 'Paket',
            my_packages: 'Meine Pakete',
            active_packages: 'Aktive Pakete',
            create_package_text: 'Angebot erstellen',
            warehouse_text: 'Lager',
            customer_text: 'Kunden',
            truck_capacity_text: 'Lkw-Kapazität',
            selling_price_text: 'Verkaufspreis',
            brands_text: 'Marken',
            fetch_products_button_text: 'Anwenden',
            fetch_from_rest: 'Aus Rest abrufen',
            supply_id_please: 'Bitte geben Sie die Plombennummer an.',
            pair_all_button: 'Alle paaren',
            pair_button: 'Paaren',
            smart_pair_switch: 'Intelligentes Paaren',
            edit_info: 'Info bearbeiten',
            are_you_sure: 'Sind Sie sicher?',
            article: 'Artikel',
            date_from: 'Datum von',
            date_to: 'Datum bis',
            extra: 'Extra',
            extra_items: 'Zusätzliche Artikel',
            grade_points_range: 'Punktebereich',
            grand_total_euro: 'Gesamtsumme €',
            pending_packages: 'Ausstehende Pakete',
            status: 'Status',
            place_order: 'Bestellung aufgeben',
            select_all: 'Alle auswählen',
            unselect_all: 'Alle abwählen',
            total_buying_offer: 'Gesamteinkaufangebot:',
            total_buying_price: 'Gesamteinkaufpreis:',
            total_items: 'Gesamtzahl Artikel',
            total_packages: 'Gesamtzahl Pakete',
            select_pallet_supply: 'Bitte Paletten-Plombe auswählen',
            please_select_supplier: 'Bitte Lieferanten auswählen',
            validity_hours: 'Gültigkeitsdauer Tage',
            confirm_package: 'Paket Bestätigen',
            you_have_selected: 'Sie haben ausgewählt',
            items: 'Artikel',
            message: 'Nachricht',
            type_your_messsage_here: 'Geben Sie hier Ihre Nachricht ein...',
            revert: "Zurückkehren",
            revert_to_prev_snapshots: 'Zum vorherigen Paket zurückkehren',
            which_snapshot: 'Zu welchem Paket möchten Sie zurückkehren?',
            package_summary: 'Paket Zusammenfassung',
            already_ordered_edit_package_placed_order_message: 'Paket ist bereits bestellt, JUST EDIT Aktion kann für Änderungen durchgeführt werden',
            fetch_products_button_edit_package_placed_order_message: 'Paket ist bereits bestellt, weitere Produkte können nicht hinzugefügt werden',
          },
          PendingPackages: {
            sidebar_text: 'Ausstehende Pakete',
            place_order_confirmation: 'Sind Sie sicher, dass Sie diese Bestellung aufgeben möchten?',
          },
          OrderRequests: {
            sidebar_text: 'Bestellanfrage',
            my_orders: 'Meine Aufträge',
            total_orders: 'Aufträge insgesamt',
            customer_carts: 'Ausstehende Kunden Warenkorb',
            add_to_cart: 'In den Warenkorb legen',
            remove_from_cart: 'Aus dem Warenkorb entfernen',
            create_cart: 'Warenkorb erstellen',
          },
          Warehouse: {
            sidebar_text: 'Lager',
            create_warehouse_order: 'Lagerpaket #',
            allow_warehouse_scan: 'Sind Sie sicher, dass Sie dem Lager erlauben möchten, diese Lieferung zu scannen?',
            warehouse: 'Lager',
            warehouse_package: 'Lagerpaket',
            warehouse_manager: 'Lagerverwalter',
            change_customer_already_ordered_message: 'Das Paket ist bereits bestellt, Sie können den Kunden nicht ändern',
          },
          Products: {
            sidebar_text: 'Produkte',
            product_details: 'Einzelheiten zum Produkt',
            latest_product_scanned: 'Letzte gescannte Produkte',
            my_packages: 'Meine Pakete',
            active_packages: 'Aktive Pakete',
            online_price: 'Online-Preis',
            price_euro: 'Preis €',
            product_id: 'Lager-Nr',
            product_id_search: 'Lager-Nr. Suche',
            products: 'Produkte',
            products_quantity: 'Produktmenge',
            products_selected: 'Ausgewählte Produkte',
            selling_price: 'Verkaufspreis',
            items_selected_percentage: 'Ausgewählte Artikel %',
            min_3_digits: 'Min. 3 Ziffern',
            minimum_3_digits: 'Mindestens 3 Ziffern',
            no_of_products_scanned: 'Anzahl gescannter Produkte',
            no_product_within_applied_filter: 'Kein Produkt innerhalb des angewendeten Filters',
            not_scanned_items: 'Nicht gescannte Artikel',
            number_of_products_scanned: 'Anzahl gescannter Produkte',
            number_of_products: 'Anzahl der Produkte',
            description: 'Bezeichnung',
            grade_point: 'Notenpunkte',
            final_grade: 'Klasse',
            category: 'Kategorie',
            remarks: 'Bemerkungen',
            usage: 'Zustand',
            add_to_cart: 'In den Warenkorb legen',
            remove_from_cart: 'Aus dem Warenkorb entfernen',
            article: 'Artikel',
            brand: 'Marke',
            brands: 'Marken',
            buying_percentage: 'Einkauf %',
            buying_online_price: 'Online-Einkaufpreis',
            buying_price: 'Einkaufpreis',
            retail_price: 'Einzel_Verkauf',
            seen: 'Gesehen',
            paired: 'Gepaart',
            no_product_found: 'Kein Produkt gefunden.',
            no_setarticle_found: 'Kein Set-Artikel gefunden.',
            no_pallet_found: 'Keine Palette gefunden.',
            supplier_grade: 'Lieferantenqualität',
            broken_glass: 'Glasbruch',
            broken_door: 'Türbruch',
            price_percent: 'Preis prozen',
            scale: 'Skala',
          },
          Pallets: {
            pallet_details: 'Details zur Palette',
            products_inside: 'Produkte im Inneren',
            pallet_product_detail: "Geben Sie die Produktdetails der Palette ein",
            mandatory_fields_text: "Felder mit * sind Pflichtfelder.",
            add_pallet_product_button: '+ Neu Palettenprodukt hinzufügen',
            pallet: 'Palette',
            pallets: 'Paletten',
            total_selling_price: 'Gesamtverkaufspreis',
            total_online_price: 'Online-Gesamtpreis',
          },
          SupplyManagement: {
            sidebar_text: 'Plombe Verwaltung',
            supply: 'Plombe',
            supply_id: 'Plombe ID',
            create_new_supply: 'Neue Plombe erstellen',
            create_new_supply_type: 'Neue Plombe Typ erstellen',
            new_supply_id: "Neue Plomben-ID",
            create_supplier_text: 'Neuer Anbieter',
            create_supplier_name_Text: 'Anbietername',
            create_supplier_button: 'Erstellen',
            add_supply: 'Plombe hinzufügen',
            all_supplies: 'Alle Plomben',
            all_supply_types: 'Alle Plombentypen',
            all_suppliers: 'Alle Anbieter',
            manually_uploaded_prices: 'Manuell hochgeladene Preise',
            supplier: 'Lieferant',
            supplier_name: 'Lieferantenname',
            supply_inventory: 'Plombe Lagerbestand',
            supply_management: 'Plombe Verwaltung',
            supply_not_found: 'Lieferant nicht gefunden',
            supply_type: 'Lieferungstyp',
            supply_type_a: 'Lieferungstyp A (0 - 1)',
            supply_type_b: 'Lieferungstyp B (0 - 1)',
            supply_type_buying: 'Lieferungstyp Einkauf (0 - 1)',
            supply_type_c: 'Lieferungstyp C (0 - 1)',
            supply_type_name: 'Lieferungstypname',
            supply_type_not_found: 'Lieferungstyp nicht gefunden',
            total_suppliers: 'Gesamtzahl Lieferanten',
            total_supplies: 'Gesamtzahl Lieferungen',
            total_supply_types: 'Gesamtzahl Lieferarten',
            edit_supplier_information: 'Lieferanteninformationen bearbeiten',
            edit_supply: 'Lieferung bearbeiten',
            edit_supply_type: 'Lieferungsart bearbeiten.',
            exclude_supplies: 'Lieferung ausschließen',
            a_grade_percentage: 'A-Grade %',
            b_grade_percentage: 'B-Grade %',
            c_grade_percentage: 'C-Grade %',
            allow_supply_to_sales: 'Sind Sie sicher, dass Sie diese Lieferung XYZ für den Verkauf zulassen möchten?',
            new_supply: 'Neue Lieferung',
            old_supply: 'Alte Lieferung',
            edit_supplier_details: 'Bitte bearbeiten Sie die folgenden Daten von Lieferanten.',
            edit_supply_type_details: 'Bitte bearbeiten Sie die folgenden Daten zum Lieferungstyp.',
            provide_supply_id: 'Bitte geben Sie die Plombennummer an.',
          },
          SupplyInventory: {
            sidebar_text: 'Plombe Lagerbestand',
            extra_text: 'Zusätzliche Produkte',
            scanned_text: 'Gesannte Produkte',
            unscanned_text: 'Nicht gescannt',
            mark_scanned_completely: 'Sind Sie sicher, dass Sie XYZ als „vollständig gescannt“ markieren möchten?',
            restrict_supply_to_sales: 'Sind Sie sicher, dass Sie diese Lieferung XYZ für den Verkauf beschränken möchten?',
            confirm_delete_item: 'Sind Sie sicher, dass Sie diesen Artikel löschen möchten?',
            assign_type_to_new_supply: 'Typ einem neuen Lieferung zuweisen',
            scanned: 'Gescannt',
            unscanned: 'Nicht gescannt',
            extra: 'Extra',
            not_visible_until_completed: 'Das wird erst nach Abschluss angezeigt.',
            scanned_items: 'Gescannte Artikel',
            scanning_in_progress: 'Scannen läuft',
          },

          CatalogManagement: {
            sidebar_text: 'Katalogverwaltung',
            catalog: 'Katalog',
            type: 'Typ',
            width_primary_cm: 'Primäre Breite (cm)',
            width_secondary_cm: 'Sekundäre Breite (cm)',
            height_primary_cm: 'Primäre Höhe (cm)',
            height_secondary_cm: 'Sekundäre Höhe (cm)',
            depth_primary_cm: 'Primäre Tiefe (cm)',
            depth_secondary_cm: 'Sekundäre Tiefe (cm)',
            catalog_management: 'Katalogverwaltung',
            depth_primary: 'Primäre Tiefe',
            depth_secondary: 'Sekundäre Tiefe',
            height_primary: 'Primäre Höhe',
            height_secondary: 'Sekundäre Höhe',
            weight_kg: 'Gewicht (kg)',
            width_primary: 'Primäre Breite',
            width_secondary: 'Sekundäre Breite',
            loading_meters: 'Lademeter',
            complete_articles: 'Vervollständigung einiger unvollständiger Artikel',
            show_articles: 'Artikel zeigen',
          },
          RightsManagement: {
            sidebar_text: 'Rechte Verwaltung',
          },
          PendingCustomerRequests: {
            sidebar_text: 'Ausstehende Kundenanfragen',
          },
          WebsiteSettings: {
            sidebar_text: 'Website-Einstellungen',
            select_language: 'Sprache auswählen',
            upload: 'Hochladen',
            upload_catalog: 'Katalog hochladen',
            upload_pallet_products: 'Palettenprodukte hochladen',
            upload_product: 'Produkt hochladen',
            settings: 'Einstellungen',
            required_columns: 'Erforderliche Spalten',
            change_password: 'Passwort ändern',
            new_password: 'neues Kennwort',
            confirm_new_password: 'neues Passwort bestätigen',

            review_price_text: 'Haben Sie die Akte eingehend geprüft?',
            review_buying_price_text: 'Wenn die Datei ein Produkt mit einem Einkaufspreis = 0 enthält',
            review_selling_price_text: 'Wenn die Datei ein Produkt mit einem Verkaufspreis von weniger als 60',
            review_buyingonline_price_text: 'Wenn die Datei ein Produkt enthält, dessen Online-Kaufpreis bereits in Portal einen anderen Wert hat.',
          },
          OrdersWords:{
            available_items: 'Verfügbare Artikel',
            available_pallets: 'Verfügbare Paletten',
            available_products: 'Verfügbare Produkte',
            created_by: 'Erstellt von',
            order: 'Bestellung',
            order_id: 'Bestell-ID',
            ordered: 'Bestellt',
            package_id: 'Paket-ID',
            sales_assistant: 'Verkaufsassistent',
            search_by_name: 'Suche nach Name',
            search_company: 'Firma suchen',
            select_sales_person: 'Verkäufer auswählen',
            select_supplier: 'Lieferant auswählen',
            set_articles: 'Set Artikel',
            sub_category: 'Unterkategorie',
            sub_total_euro: 'Untersumme €',
            sub_cat: 'Unterkategorie',
            order_id_search: 'Suche nach Bestell-ID',
          },
          AnalysisWords:{
            analysis_text: 'Analyse',
            category: 'Kategorie',
            category_analysis: 'Kategorieanalyse',
            change_supply_visibility: 'Sichtbarkeit des Lieferung ändern',
            companies_category_analysis: 'Unternehmenskategorieanalyse',
            companies_grades_analysis: 'Unternehmensklassenanalyse',
            compare_sales_person: 'Vergleichen Verkäufer',
            turnover_monthly: 'Monatlicher Umsatz',
            turnover_yearly: 'Jährlicher Umsatz',
            profit_monthly: 'Monatlicher Gewinn',
            profit_yearly: 'Jährlicher Gewinn',
          },
          CommonWords: {
            min_3_digits: 'Mindestens 3 Zeichen',
            no_items_found: 'Keine Einträge gefunden',
            accept: 'Akzeptieren',
            download_pdf: 'PDF herunteladen',
            download_images: 'Bilder herunterladen',
            note: 'Anmerkung',
            jtl_id: 'Kunden-Nr.',
            joined_date: 'Datum der Erstellung',
            false: 'falsch',
            true: 'richtig',
            pending: 'Ausstehend',
            order_requested: 'Bestellung angefragt',
            first_name: 'Vorname',
            last_name: 'Nachname',
            logout: "Ausloggen",
            more: 'mehr',
            account: "Konto",
            search: 'Suche',
            close: 'Schließen',
            pending_approval: 'Anhängig',
            order_placed: 'Bestellung aufgegeben',
            view_details: 'Details anzeigen',
            action: 'Aktion',
            status: 'Status',
            quantity: 'Menge',
            apply: 'Anwenden',
            done: 'Fertig',
            edit: 'Bearbeiten',
            assign: 'Zuweisen',
            default: 'Standard',
            delete: 'Löschen',
            cancel: 'Abbrechen',
            date: 'Datum',
            company: 'Firma',
            compare: 'Vergleichen',
            email: 'E-Mail',
            image: 'Bild',
            images: 'Bilder',
            model: 'Modell',
            month: 'Monat',
            group: 'Gruppe',
            backoffice: 'BackOffice',
            brand_analysis: 'Markenanalyse',
            brand: 'Marke',
            brands: 'Marken',
            buying_percentage: 'Einkauf %',
            buying_online_price: 'Online-Einkaufpreis',
            buying_price: 'Einkaufpreis',
            cart: 'Warenkorb',
            confirm: 'Bestätigen',
            create: 'Erstellen',
            discount_percentage: 'Rabatt %',
            items_per_page: 'Elemente pro Seite:',
            navigation: 'Navigation',
            select: 'Auswählen',
            view: 'Ansehen',
            download_excel: 'Excel herunterladen',
            download_template: 'Vorlage herunterladen',
            place_order: 'Bestellung aufgeben',
            Edit: 'Bearbeiten',
            revert: 'zurückkehren',
            role: 'Rolle',
            sales: 'Verkauf',
            yes: 'Ja',
            item_created_successfully: 'Erfolgreich erstellt.',
            item_updated_successfully: 'Erfolgreich aktualisiert.',
            permissions: 'Berechtigungen.',
            assign_default_permissions: 'Standardberechtigungen zuweisen.',
          },
      },
    }
  },
  ru: {
    translation: {
      authentication: {
          register: {
              info: 'Пожалуйста, заполните форму ниже, и мы свяжемся с вами в ближайшее время.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Главная панель управления',
          my_dashboard: 'Моя приборная панель',
        },
        Analytics: {
          sidebar_text: 'Аналитика',
          analytics: 'Аналитика',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Ежемесячная прибыль и оборот',
        },
        ScanningTime: {
          sidebar_text: 'Среднее время сканирования (сек)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Сравнить продавцов',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Анализ категорий',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Анализ бренда',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Анализ классов продавцов',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Анализ компаний',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Анализ категорий бизнеса',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Анализ брендов компаний',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Анализ классов компаний',
        },
        SupplyAnalysis: {
          sidebar_text: 'Анализ предложений',
          searchbar_text: 'Поиск',
        },
        StockAnalysis: {
          sidebar_text: 'Анализ инвентаря',
          searchbar_text: 'Выберите склад',
        },
        MainDashboard: {
          sidebar_text: 'Главная панель управления',
        },
        Navigation: {
          sidebar_text: 'Навигация',
        },
        Users: {
          sidebar_text: 'Пользователи',
          customers_data: 'Данные клиента',
          edit_info: 'Изменить информацию',
          delete_customer: 'Удалить',
          actions: 'Действия',
          active: 'Активный',
          add_new_staff_user: 'Добавить нового сотрудника',
          add_supply_type: 'Добавить тип поставки',
          add_user: 'Добавить пользователя',
          admin: 'Администратор',
          user: 'Пользователь',
          enter_first_name: 'Введите имя',
          enter_last_name: 'Введите фамилию',
          enter_user_password: 'Введите пароль пользователя',
          not_active: 'Не активен',
          pending_customer_requests: 'Ожидающие запросы клиентов',
          unable_to_log_in: 'Вход невозможен с предоставленными данными доступа.',
        },
        Team: {
          sidebar_text: 'Команда'
        },
        Customers: {
          sidebar_text: 'Клиенты',
          main_text: 'Данные клиента',
          my_packages: 'Мои пакеты',
          my_orders: 'Мои заказы',
          my_orders_details: 'Детали заказа',
          add_customer: 'Добавить клиентов',
          edit_info: 'Изменить информацию',
          delete_customer: 'Удалить',
          customer: 'Клиент',
          customer_email: 'Электронная почта клиента',
          customers: 'Клиенты',
          phone_number: 'Номер телефона',
          accepted_terms: 'Теперь вы можете продолжить',
          rejected_terms: 'Вы не можете продолжить работу и выходите из системы',
        },
        Packages: {
          active_packages: 'Активные пакеты',
          package: 'Пакет',
          sidebar_text: 'Пакеты',
          create_package_text: 'Создать пакет',
          warehouse_text: 'Склад',
          customer_text: 'Клиент',
          truck_capacity_text: 'Объём грузовика (0 м)',
          selling_price_text: 'Цена продажи',
          brands_text: 'Бренды',
          fetch_products_button_text: 'Применить',
          fetch_from_rest: 'Показать остатки',
          supply_id_please: 'Пожалуйста, укажите номер пломбы.',
          pair_all_button: 'Соединить все',
          pair_button: 'Соединить',
          smart_pair_switch: 'Умное соединение',
          edit_info: 'Изменить информацию',
          are_you_sure: 'Вы уверены?',
          article: 'Артикул',
          date_from: 'Дата с',
          date_to: 'Дата до',
          extra: 'Дополнительно',
          extra_items: 'Дополнительные элементы',
          grade_points_range: 'Диапазон баллов',
          grand_total_euro: 'Всего сумма €',
          pending_packages: 'Ожидающие пакеты',
          status: 'Статус',
          place_order: 'Сделать заказ',
          unselect_all: 'Отменить выбор всего',
          total_buying_offer: 'Общее предложение закупки:',
          total_buying_price: 'Общая стоимость закупки:',
          total_items: 'Всего товаров',
          total_packages: 'Общее количество пакетов',
          select_pallet_supply: 'Пожалуйста, выберите номер поставки палетт',
          please_select_supplier: 'Пожалуйста, выберите поставщика',
          validity_hours: 'Дни действия',
          confirm_package: 'Подтвердите пакет',
          you_have_selected: 'Вы выбрали',
          items: 'товары',
          message: 'Сообщение',
          type_your_messsage_here: 'Напечатайте свое сообщение здесь...',
        },
        PendingPackages: {
          sidebar_text: 'Ожидающие пакеты',
        },
        OrderRequests: {
          sidebar_text: 'Запросы на заказ',
          my_orders: 'Мои заказы',
          total_orders: 'Всего заказов',
        },
        Warehouse: {
          sidebar_text: 'Склад',
          create_warehouse_order: 'Создать заказ для склада',
          allow_warehouse_scan: 'Вы уверены, что хотите разрешить складу сканировать эту доставку?',
          warehouse: 'Склад',
          warehouse_package: 'Пакет на складе',
          warehouse_manager: 'Менеджер склада',
        },
        Products: {
          sidebar_text: 'Товары',
          online_price: 'Онлайн цена',
          price_euro: 'Цена €',
          product_id: 'Id товара',
          product_id_search: 'Поиск по ID товара',
          products: 'Товары',
          products_quantity: 'Количество товаров',
          products_selected: 'Выбранные товары',
          selling_price: 'Цена продажи',
          items_selected_percentage: 'Выбранные товары %',
          min_3_digits: 'Мин. 3 цифры',
          minimum_3_digits: 'Минимум 3 цифры',
          no_of_products_scanned: 'Количество отсканированных продуктов',
          no_product_within_applied_filter: 'В примененном фильтре нет товаров',
          not_scanned_items: 'Несканированные товары',
          number_of_products_scanned: 'Количество отсканированных продуктов',
          number_of_products: 'Количество продуктов',
          description: 'Описание',
          grade_point: 'Балл за оценку',
          final_grade: 'Класс',
          category: 'Категория',
          remarks: 'Примечания',
          usage: 'Использование',
          add_to_cart: 'Добавить в корзину',
          remove_from_cart: 'Выньте из корзины',
          brand: 'Бренд',
          brands: 'Бренды',
          buying_percentage: 'Покупка %',
          buying_online_price: 'Покупка онлайн цена',
          buying_price: 'Цена покупки',
          article: 'Статья',
          seen: 'Озера',
          paired: 'Парные',
          no_product_found: 'Товар не найден',
          no_setarticle_found: 'Не найдено ни одного установленного артикула',
          no_pallet_found: 'Поддон не найден',
          supplier_grade: 'Качество поставщика',
          broken_glass: 'Разбитое стекло',
          broken_door: 'Поломка двери',
        },
        Pallets: {
          products_inside: 'Продукты внутри',
          pallet_details: 'Детали поддона',
          pallet_product_detail: "Введите детали палетты",
          mandatory_fields_text: "Поля с * обязательны для заполнения.",
          add_pallet_product_button: '+ Добавить продукт на палетту',
          pallet: 'Палетта',
          pallets: 'Палетты',
          total_selling_price: 'Итого Продажная цена',
          total_online_price: 'Общая цена онлайн',
        },
        SupplyManagement: {
          sidebar_text: 'Управление пломбами',
          supply: 'Поставка',
          supply_id: 'Id поставки',
          create_new_supply: 'Создать новую поставку',
          new_supply_id: "Новый Id поставки",
          create_supplier_text: 'Создать поставщика',
          create_supplier_name_Text: 'Имя поставщика',
          create_supplier_button: 'Создать',
          add_supply: 'Добавить поставку',
          all_supplies: 'Все поставки',
          all_supply_types: 'Все типы поставок',
          all_suppliers: 'Все поставщики',
          manually_uploaded_prices: 'Ручная загрузка цен',
          supplier: 'Поставщик',
          supplier_name: 'Имя поставщика',
          supply_inventory: 'Инвентаризация поставок',
          supply_management: 'Управление поставками',
          supply_not_found: 'Поставщик не найден',
          supply_type: 'Тип поставки',
          supply_type_a: 'Тип поставки А (0 - 1)',
          supply_type_b: 'Тип поставки Б (0 - 1)',
          supply_type_buying: 'Тип доставки Закупка (0 - 1)',
          supply_type_c: 'Тип доставки C (0 - 1)',
          supply_type_name: 'Название типа поставки',
          supply_type_not_found: 'Тип поставки не найден',
          total_suppliers: 'Общее количество поставщиков',
          total_supplies: 'Общее количество поставок',
          total_supply_types: 'Общее количество типов доставки',
          edit_supplier_information: 'Редактировать информацию о поставщике',
          edit_supply: 'Редактировать поставку',
          edit_supply_type: 'Изменить тип поставки.',
          exclude_supplies: 'Исключить поставку',
          a_grade_percentage: 'Категория А %',
          b_grade_percentage: 'Категория Б %',
          c_grade_percentage: 'Категория C %',
          allow_supply_to_sales: 'Вы уверены, что хотите разрешить эту поставку XYZ для продаж?',
          new_supply: 'Новая поставка',
          old_supply: 'Старая доставка',
          edit_supplier_details: 'Пожалуйста, отредактируйте следующие данные поставщика.',
          edit_supply_type_details: 'Пожалуйста, отредактируйте следующие данные о типе доставки.',
          provide_supply_id: 'Пожалуйста, укажите номер пломбы.',
        },
        SupplyInventory: {
          sidebar_text: 'Инвентаризация поставок',
          extra_text: 'Дополнительные товары',
          scanned_text: 'Отсканированные товары',
          unscanned_text: 'Неотсканированные товары',
          mark_scanned_completely: 'Вы уверены, что хотите отметить XYZ как «отсканировано полностью»?',
          restrict_supply_to_sales: 'Вы уверены, что хотите ограничить эту поставку XYZ для продаж?',
          confirm_delete_item: 'Вы уверены, что хотите удалить этот элемент?',
          assign_type_to_new_supply: 'Назначить тип для новой поставки',
          scanned: 'Отсканировано',
          unscanned: 'Неотсканированный',
          extra: 'Дополнительно',
          not_visible_until_completed: 'Это будет отображаться только после завершения.',
          scanned_items: 'Отсканированные товары',
          scanning_in_progress: 'Сканирование выполняется',
        },
        CatalogManagement: {
          sidebar_text: 'Управление каталогом',
          catalog: 'Каталог',
          width_primary_cm: 'Основная ширина (см)',
          width_secondary_cm: 'Вторичная ширина (см)',
          height_primary_cm: 'Основная высота (см)',
          height_secondary_cm: 'Вторичная высота (см)',
          depth_primary_cm: 'Первичная глубина (см)',
          depth_secondary_cm: 'Вторичная глубина (см)',
          catalog_management: 'Управление каталогом',
          depth_primary: 'Первичная глубина',
          depth_secondary: 'Вторичная глубина',
          height_primary: 'Основная высота',
          height_secondary: 'Вторичная высота',
          weight_kg: 'Вес (кг)',
          width_primary: 'Основная ширина',
          width_secondary: 'Вторичная ширина',
          loading_meters: 'Объём погрузки',
        },
        RightsManagement: {
          sidebar_text: 'Управление правами',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Ожидающие запросы клиентов',
        },
        WebsiteSettings: {
          settings: 'Настройки',
          sidebar_text: 'Настройки веб-сайта',
          select_language: 'Выбрать язык',
          upload: 'Загрузить',
          upload_catalog: 'Загрузить каталог',
          upload_pallet_products: 'Загрузить товары на палеттах',
          upload_product: 'Загрузить товар',
          change_password: 'Изменить пароль',
          new_password: 'Новый пароль',
          confirm_new_password: 'Подтвердите новый пароль',
        },
        OrdersWords:{
          available_items: 'Доступные товары',
          available_pallets: 'Доступные палетты',
          available_products: 'Доступные продукты',
          created_by: 'Создано',
          order: 'Заказ',
          order_id: 'Id заказа',
          ordered: 'Заказано',
          package_id: 'Id пакета',
          sales_assistant: 'Продавец-консультант',
          search_by_name: 'Поиск по имени',
          search_company: 'Поиск компании',
          select_sales_person: 'Выбрать продавца',
          select_supplier: 'Выбрать поставщика',
          set_articles: 'Сэты',
          sub_category: 'Подкатегория',
          sub_total_euro: 'Общая сумма €',
          sub_cat: 'Подкатегория',
          order_id_search: 'Поиск по идентификатору заказа',
        },
        AnalysisWords:{
          analysis_text: 'Аналитика',
          category: 'Категория',
          category_analysis: 'Анализ категорий',
          change_supply_visibility: 'Изменить видимость поставок',
          companies_category_analysis: 'Анализ категорий компаний',
          companies_grades_analysis: 'Анализ класса компаний',
          compare_sales_person: 'Сравнить продавцов',
          turnover_monthly: 'Ежемесячный оборот',
          turnover_yearly: 'Годовой оборот',
          profit_monthly: 'Ежемесячная прибыль',
          profit_yearly: 'Годовая прибыль',
        },
        CommonWords: {
          logout: "выход из системы",
          more: 'подробнее',
          account: "Счет",
          search: 'Поиск',
          close: 'Закрыть',
          pending_approval: 'В ожидании',
          order_placed: 'Размещенный заказ',
          view_details: 'Посмотреть детали',
          action: 'Действие',
          status: 'Статус',
          quantity: 'Количество',
          apply: 'Применить',
          done: 'Готово',
          edit: 'Редактировать',
          assign: 'Назначить',
          default: 'По умолчанию',
          delete: 'Удалить',
          cancel: 'Отмена',
          date: 'Дата',
          company: 'Компания',
          compare: 'Сравнить',
          email: 'Электронная почта',
          image: 'Картинка',
          images: 'Картинки',
          model: 'Модель',
          month: 'Месяц',
          group: 'Группа',
          backoffice: 'Бэк-офис',
          brand_analysis: 'Анализ бренда',
          brands: 'Бренды',
          buying_percentage: 'Закупка %',
          buying_online_price: 'Цена онлайн-закупки',
          buying_price: 'Цена закупки',
          cart: 'Корзина',
          confirm: 'Подтвердить',
          create: 'Создать',
          discount_percentage: 'Скидка %',
          items_per_page: 'Товаров на страницу:',
          navigation: 'Навигация',
          select: 'Выбрать',
          view: 'Вид',
          role: 'Роль',
          sales: 'Продажи',
          yes: 'Да',
        },
      },
    }
  },
  fr: {
    translation: {
      authentication: {
          register: {
              info: 'Veuillez remplir le formulaire ci-dessous et nous vous contacterons sous peu.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Tableau de bord principal',
        },
        Analytics: {
          sidebar_text: 'Analytics',
          analytics: 'Analytics',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Bénéfice et chiffre d\'affaires',
        },
        ScanningTime: {
          sidebar_text: 'Temps moyen de numérisation (sec)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Comparer un vendeur',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Analyse de catégorie',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Analyse de marque',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Analyse de qualité des vendeurs',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Comparer les entreprises',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Analyse de catégorie des entreprises',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Analyse des marques des entreprises',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Analyse de qualité des entreprises',
        },
        SupplyAnalysis: {
          sidebar_text: 'Analyse des produits',
          searchbar_text: 'Recherche',
        },
        StockAnalysis: {
          sidebar_text: 'Analyse des stocks',
          searchbar_text: 'Sélectionner le magasin',
        },
        MainDashboard: {
          sidebar_text: 'Tableau de bord principal',
        },
        Navigation: {
          sidebar_text: 'Navigation',
        },
        Users: {
          sidebar_text: 'Utilisateurs',
          customers_data: 'Données client',
          edit_info: 'Modifier les informations',
          delete_customer: 'Supprimer',
          actions: 'Actions',
          active: 'Actif',
          add_new_staff_user: 'Ajouter un nouvel utilisateur du personnel',
          add_supply_type: 'Ajouter un type d\'approvisionnement',
          add_user: 'Ajouter un utilisateur',
          admin: 'Admin',
          user: 'Utilisateur',
          enter_first_name: 'Entrez le prénom',
          enter_last_name: 'Entrez le nom de famille',
          enter_user_password: 'Entrez le mot de passe de l\'utilisateur',
          not_active: 'Non actif',
          pending_customer_requests: 'Demandes client en attente',
          unable_to_log_in: 'Impossible de se connecter avec les informations d\'identification fournies.',
        },
        Team: {
          sidebar_text: 'Équipe'
        },
        Customers: {
          sidebar_text: 'Clients',
          main_text: 'Données client',
          my_packages: 'Mes colis',
          my_orders: 'Mes commandes',
          my_orders_details: 'Détails de la commande',
          add_customer: 'Ajouter des clients',
          edit_info: 'Modifier les informations',
          delete_customer: 'Supprimer',
          customer: 'Client',
          customer_email: 'E-mail du client',
          customers: 'Clients',
          phone_number: 'Numéro de téléphone',
        },
        Packages: {
          sidebar_text: 'Colis',
          create_package_text: 'Créer un colis',
          warehouse_text: 'Entrepôt',
          customer_text: 'Client',
          truck_capacity_text: 'Capacité du camion (0 m)',
          selling_price_text: 'Prix de vente',
          brands_text: 'Marques',
          fetch_products_button_text: 'Appliquer',
          fetch_from_rest: 'Récupérer à partir du reste',
          supply_id_please: 'Veuillez fournir l\'identifiant de l\'offre.',
          pair_all_button: 'Associer tout',
          pair_button: 'Associer',
          smart_pair_switch: 'Association intelligente',
          edit_info: 'Modifier les informations',
          are_you_sure: 'Êtes-vous sûr?',
          article: 'Article',
          date_from: 'Date de',
          date_to: 'Date à',
          extra: 'Supplémentaire',
          extra_items: 'Articles supplémentaires',
          grade_points_range: 'Plage de points de note',
          grand_total_euro: 'Total général €',
          pending_packages: 'Colis en attente',
          status: 'Statut',
          place_order: 'Passer commande',
          unselect_all: 'Désélectionner tout',
          total_buying_offer: 'Offre d\'achat totale:',
          total_buying_price: 'Prix d\'achat total:',
          total_items: 'Nombre total d\'articles',
          total_packages: 'Nombre total de colis',
          select_pallet_supply: 'Veuillez sélectionner l\'approvisionnement en palettes',
          please_select_supplier: 'Veuillez sélectionner le fournisseur',
        },
        AutomatedPackages: {
          sidebar_text: 'Colis automatisés'
        },
        PendingPackages: {
          sidebar_text: 'Colis en attente',
        },
        OrderRequests: {
          sidebar_text: 'Demandes de commande',
        },
        Warehouse: {
          sidebar_text: 'Entrepôt',
          create_warehouse_order: 'Créer une commande d\'entrepôt',
          allow_warehouse_scan: 'Êtes-vous sûr de vouloir autoriser l\'entrepôt à scanner cet approvisionnement?',
          warehouse: 'Entrepôt',
          warehouse_package: 'Colis d\'entrepôt',
          warehouse_manager: 'Gestionnaire d\'entrepôt',
        },
        Products: {
          sidebar_text: 'Produits',
          online_price: 'Prix en ligne',
          price_euro: 'Prix €',
          product_id: 'Identifiant produit',
          product_id_search: 'Recherche d\'ID produit',
          products: 'Produits',
          products_quantity: 'Quantité de produits',
          products_selected: 'Produits sélectionnés',
          selling_price: 'Prix de vente',
          items_selected_percentage: 'Articles sélectionnés %',
          min_3_digits: 'Min 3 chiffres',
          minimum_3_digits: 'Minimum 3 chiffres',
          no_of_products_scanned: 'Nombre de produits scannés',
          no_product_within_applied_filter: 'Aucun produit dans le filtre appliqué',
          not_scanned_items: 'Articles non scannés',
          number_of_products_scanned: 'Nombre de produits scannés',
          no_product_found: 'Aucun produit trouvé',
          no_setarticle_found: `Aucun article n'a été trouvé`,
          no_pallet_found: 'Aucune palette trouvée',
          supplier_grade: 'Grade du fournisseur',
          broken_glass: 'Verre cassé',
        },
        Pallets: {
          pallet_product_detail: "Entrez les détails du produit de la palette",
          mandatory_fields_text: "Les champs avec * sont obligatoires.",
          add_pallet_product_button: '+ Ajouter un produit de palette',
          pallet: 'Palette',
          pallets: 'Palettes',
        },
        SupplyManagement: {
          sidebar_text: 'Gestion des approvisionnements',
          supply: 'Fourniture',
          supply_id: 'ID de fourniture',
          create_new_supply: 'Créer un nouvel approvisionnement',
          new_supply_id: "Nouvel identifiant d'offre",
          create_supplier_text: 'Créer un fournisseur',
          create_supplier_name_Text: 'Nom du fournisseur',
          create_supplier_button: 'Créer',
          add_supply: 'Ajouter un approvisionnement',
          all_supplies: 'Toutes les fournitures',
          all_supply_types: 'Tous les types de fournitures',
          all_suppliers: 'Tous les fournisseurs',
          manually_uploaded_prices: 'Prix téléchargés manuellement',
          supplier: 'Fournisseur',
          supplier_name: 'Nom du fournisseur',
          supply_inventory: 'Inventaire de fourniture',
          supply_management: 'Gestion de la fourniture',
          supply_not_found: 'Fourniture non trouvée',
          supply_type: 'Type de fourniture',
          supply_type_a: 'Type de fourniture A (0 - 1)',
          supply_type_b: 'Type de fourniture B (0 - 1)',
          supply_type_buying: 'Type de fourniture Achat (0 - 1)',
          supply_type_c: 'Type de fourniture C (0 - 1)',
          supply_type_name: 'Nom du type de fourniture',
          supply_type_not_found: 'Type de fourniture non trouvé',
          total_suppliers: 'Nombre total de fournisseurs',
          total_supplies: 'Nombre total de fournitures',
          total_supply_types: 'Nombre total de types de fournitures',
          edit_supplier_information: 'Modifier les informations du fournisseur',
          edit_supply: 'Modifier l\'approvisionnement',
          edit_supply_type: 'Modifier le type d\'approvisionnement.',
          exclude_supplies: 'Exclure les fournitures',
          a_grade_percentage: 'A Grade %',
          b_grade_percentage: '% de qualité B',
          c_grade_percentage: '% de qualité C',
          allow_supply_to_sales: 'Êtes-vous sûr de vouloir autoriser cet approvisionnement XYZ aux ventes?',
          new_supply: 'Nouvelle fourniture',
          old_supply: 'Ancienne fourniture',
          edit_supplier_details: 'Veuillez modifier les détails suivants du fournisseur.',
          edit_supply_type_details: 'Veuillez modifier les détails suivants du type d\'approvisionnement.',
          provide_supply_id: 'Veuillez fournir l\'identifiant de l\'offre.',
        },
        SupplyInventory: {
          sidebar_text: 'Inventaire de fourniture',
          extra_text: 'Produits supplémentaires',
          scanned_text: 'Produits scannés',
          unscanned_text: 'Produits non scannés',
          mark_scanned_completely: 'Êtes-vous sûr de vouloir marquer XYZ comme "entièrement scanné"?',
          restrict_supply_to_sales: 'Êtes-vous sûr de vouloir restreindre cet approvisionnement XYZ aux ventes?',
          confirm_delete_item: 'Êtes-vous sûr de vouloir supprimer cet article?',
          assign_type_to_new_supply: 'Affecter un type à un nouvel approvisionnement',
          scanned: 'Scanné',
          unscanned: 'Non scanné',
          extra: 'Supplémentaire',
          not_visible_until_completed: 'Ils ne sera pas visible tant qu\'elle n\'aura pas été terminée.',
          scanned_items: 'Articles scannés',
          scanning_in_progress: 'Scan en cours',
        },
        CatalogManagement: {
          sidebar_text: 'Gestion du catalogue',
          catalog: 'Catalogue',
          width_primary_cm: 'Largeur principale (cm)',
          width_secondary_cm: 'Largeur secondaire (cm)',
          height_primary_cm: 'Hauteur primaire (cm)',
          height_secondary_cm: 'Hauteur secondaire (cm)',
          depth_primary_cm: 'Profondeur principale (cm)',
          depth_secondary_cm: 'Profondeur secondaire (cm)',
          catalog_management: 'Gestion du catalogue',
          depth_primary: 'Profondeur principale',
          depth_secondary: 'Profondeur secondaire',
          height_primary: 'Hauteur primaire',
          height_secondary: 'Hauteur secondaire',
          weight_kg: 'Poids (kg)',
          width_primary: 'Largeur principale',
          width_secondary: 'Largeur secondaire',
          loading_meters: 'Compteurs de chargement',
        },
        RightsManagement: {
          sidebar_text: 'Gestion des droits',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Demandes client en attente',
        },
        WebsiteSettings: {
          sidebar_text: 'Paramètres du site Web',
          select_language: 'Sélectionner la langue',
          upload: 'Télécharger',
          upload_catalog: 'Télécharger le catalogue',
          upload_pallet_products: 'Télécharger les produits de palette',
          upload_product: 'Télécharger le produit',
        },
        OrdersWords:{
          available_items: 'Articles disponibles',
          available_pallets: 'Palettes disponibles',
          available_products: 'Produits disponibles',
          created_by: 'Créé par',
          order: 'Commande',
          order_id: 'Identifiant de commande',
          ordered: 'Commandé',
          package_id: 'Identifiant de colis',
          sales_assistant: 'Assistant commercial',
          search_by_name: 'Recherche par nom',
          search_company: 'Recherche d\'entreprise',
          select_sales_person: 'Sélectionner le vendeur',
          select_supplier: 'Sélectionner le fournisseur',
          set_articles: 'Définir les articles',
          sub_category: 'Sous-catégorie',
          sub_total_euro: 'Sous-total €',
          sub_cat: 'Sous-catégorie',
          order_id_search: 'Поиск по идентификатору заказа',
        },
        AnalysisWords:{
          analysis_text: 'Analyse',
          category: 'Catégorie',
          category_analysis: 'Analyse de catégorie',
          change_supply_visibility: 'Modifier la visibilité de l\'approvisionnement',
          companies_category_analysis: 'Analyse de catégorie des entreprises',
          companies_grades_analysis: 'Analyse de qualité des entreprises',
          compare_sales_person: 'Comparer un vendeur',
          turnover_monthly: 'Chiffre d\'affaires mensuel',
          turnover_yearly: 'Chiffre d\'affaires annuel',
          profit_monthly: 'Bénéfice mensuel',
          profit_yearly: 'Bénéfice annuel',
        },
        CommonWords: {
          apply: 'Appliquer',
          done: 'Terminé',
          edit: 'Modifier',
          assign: 'Affecter',
          default: 'Par défaut',
          delete: 'Supprimer',
          cancel: 'Annuler',
          date: 'Date',
          company: 'Entreprise',
          compare: 'Comparer',
          email: 'Courriel',
          image: 'Image',
          images: 'Images',
          model: 'Modèle',
          month: 'Mois',
          group: 'Groupe',
          backoffice: 'BackOffice',
          brand_analysis: 'Analyse de marque',
          brands: 'Marques',
          buying_percentage: '% d\'achat',
          buying_online_price: 'Prix d\'achat en ligne',
          buying_price: 'Prix d\'achat',
          cart: 'Panier',
          confirm: 'Confirmer',
          create: 'Créer',
          discount_percentage: '% de remise',
          items_per_page: 'Articles par page:',
          navigation: 'Navigation',
          select: 'Sélectionner',
          view: 'Afficher',
          role: 'Rôle',
          sales: 'Ventes',
          yes: 'Oui',
        },
      },
    }
  },
  ro: {
    translation: {
      authentication: {
        register: {
            info: 'Vă rugăm să completați formularul de mai jos și vă vom contacta în curând.'
        },
        login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
          my_dashboard: 'Tabloul meu de bord',
        },
        Analytics: {
          sidebar_text: 'Analiza',
          analytics: 'Analiza',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Profit și venituri',
        },
        ScanningTime: {
          sidebar_text: 'Timp mediu de scanare (sec)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Comparați vânzătorul',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Analiza categorii',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Analiza mărcii',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Analiza notelor vânzătorilor',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Comparație companii',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Analiza categoriilor de companii',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Analiza mărcilor companiilor',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Analiza notelor companiilor',
        },
        SupplyAnalysis: {
          sidebar_text: 'Analiza ofertei',
          searchbar_text: 'Căutare',
        },
        StockAnalysis: {
          sidebar_text: 'Analiza stocurilor',
          searchbar_text: 'selectați stocul',
        },
        MainDashboard: {
          sidebar_text: 'Tabloul de bord principal',
        },
        Navigation: {
          sidebar_text: 'Navigare',
        },
        Users: {
          sidebar_text: 'Utilizatori',
          customers_data: 'Datele clienților',
          edit_info: 'Editați informații',
          delete_customer: 'Şterge',
          actions: 'Acțiuni',
          active: 'Activ',
          add_new_staff_user: 'Adăugați un nou utilizator de personal',
          add_supply_type: 'Adăugați tipul de aprovizionare/livrare',
          add_user: 'Adăugați utilizator',
          admin: 'Admin',
          user: 'Utilizator',
          enter_first_name: 'Introduceți prenumele',
          enter_last_name: 'Introduceți numele de familie',
          enter_user_password: 'Introduceți parola de utilizator',
          not_active: 'Nu este activ',
          pending_customer_requests: 'Solicitări în așteptare ale clienților',
          unable_to_log_in: 'Autentificare cu datele de acces furnizate nu este posibilă.',
        },
        Team: {
          sidebar_text: 'Echipa'
        },
        Customers: {
          sidebar_text: 'Clienții',
          main_text: 'Datele clienților',
          my_packages: 'Pachetele mele',
          my_orders: 'Comenzile mele',
          my_orders_details: 'Detalii comandă',
          add_customer: 'Adaugă clienți',
          edit_info: 'Editați informații',
          delete_customer: 'Şterge',
          customer: 'Client',
          customer_email: 'E-mailul clientului',
          customers: 'Clienții',
          phone_number: '# de telefon',
          accepted_terms: 'Acum puteți continua',
          rejected_terms: 'Nu puteți merge mai departe și fiind deconectat din sistem',
        },
        Packages: {
          sidebar_text: 'Pachete',
          package: 'Pachet',
          create_package_text: 'Creați pachet',
          warehouse_text: 'Depozit/Stoc',
          customer_text: 'Client',
          truck_capacity_text: 'Capacitate camion (0 m)',
          selling_price_text: 'Preț de vânzare',
          brands_text: 'Mărci',
          fetch_products_button_text: 'Aplicați',
          fetch_from_rest: 'retrage din stocul rămas',
          supply_id_please: 'Vă rugăm să introduceți id-ul de furnizare.',
          pair_all_button: 'Asociați tot',
          pair_button: 'Asociați',
          smart_pair_switch: 'Asociere inteligentă',
          edit_info: 'Editați informații',
          are_you_sure: 'Sunteți sigur?',
          article: 'Articol',
          date_from: 'Data de la',
          date_to: 'Data päna la',
          extra: 'Suplimentar',
          extra_items: 'Articole suplimentare',
          grade_points_range: 'Gama de puncte',
          grand_total_euro: 'Total €',
          pending_packages: 'Pachete în așteptare',
          status: 'Stare',
          place_order: 'Plasați comanda',
          unselect_all: 'Deselectați toate',
          total_buying_offer: 'Total oferta:',
          total_buying_price: 'Preț total:',
          total_items: 'Total articole',
          total_packages: 'Total pachete',
          select_pallet_supply: 'Vă rugăm să selectați aprovizionarea cu palete',
          please_select_supplier: 'Vă rugăm să selectați furnizorul',

          validity_hours: 'Zile de valabilitate',
          confirm_package: 'Confirmați pachetul',
          active_packages: 'Pachete active',
          you_have_selected: 'Ați selectat',
          items: 'Articole',
          message: 'Mesaj',
          type_your_messsage_here: 'Scrieți mesajul dvs. aici...',
        },
        PendingPackages: {
          sidebar_text: 'Pachete în așteptare',
        },
        OrderRequests: {
          sidebar_text: 'Solicitări de comandă',
          total_orders: 'Total comenzi',
        },
        Warehouse: {
          sidebar_text: 'Depozit/Stoc',
          create_warehouse_order: 'Creați comanda de depozit',
          allow_warehouse_scan: 'Sigur doriți să permiteți depozitului să scaneze această aprovizionare?',
          warehouse: 'Depozit/Stoc',
          warehouse_package: 'Pachet depozit/Stoc',
          warehouse_manager: 'Gestionar de depozit',
        },
        Products: {
          sidebar_text: 'Produse',
          online_price: 'Preț online',
          price_euro: 'Preț €',
          product_id: 'ID produs',
          product_id_search: 'Căutare ID produs',
          products: 'Produse',
          products_quantity: 'Cantitate produse',
          products_selected: 'Produse selectate',
          selling_price: 'Preț de vânzare',
          items_selected_percentage: 'Articole selectate %',
          min_3_digits: 'Min 3 cifre',
          minimum_3_digits: 'Minim 3 cifre',
          no_of_products_scanned: 'Numărul de produse scanate',
          no_product_within_applied_filter: 'Niciun produs în filtrul aplicat',
          not_scanned_items: 'Elemente nescanate',
          number_of_products_scanned: 'Numărul de produse scanate',

          product_details: 'Detalii despre produs',
          number_of_products: 'Număr de produse',
          description: 'Descriere',
          grade_point: 'Punctaj',
          final_grade: 'Grad',
          category: 'Categorie',
          remarks: 'Observații',
          usage: 'Utilizare',
          add_to_cart: 'Adăugați la coș',
          remove_from_cart: 'Îndepărtați din coș',
          brand: 'Marca',
          brands: 'Mărci',
          buying_percentage: 'Cumpărare %',
          buying_online_price: 'Prețul de cumpărare online',
          buying_price: 'Prețul de cumpărare',
          article: 'Articol',
          seen: 'VĂZUT',
          paired: 'PAIRED',
          no_product_found: 'Nu s-a găsit niciun produs',
          no_setarticle_found: 'Niciun set-articol găsit',
          no_pallet_found: 'Nu s-a găsit niciun palet',
          supplier_grade: 'Nivelul furnizorului',
          broken_glass: 'Sticlă spartă',
        },
        Pallets: {
          pallet_product_detail: "Introduceți detaliile produsului paletei",
          mandatory_fields_text: "Câmpurile marcate cu * sunt obligatorii.",
          add_pallet_product_button: '+ Adăugați produs paletă',
          pallet: 'Paletă',
          pallets: 'Palete',
          pallet_details: 'Detalii palet',
          products_inside: 'Produse din interior',
          total_selling_price: 'Prețul total de vânzare',
          total_online_price: 'Prețul total online',
        },
        SupplyManagement: {
          sidebar_text: 'Managementul aprovizionării',
          supply: 'Livra',
          supply_id: 'ID aprovizionare',
          create_new_supply: 'Creați o nouă aprovizionare',
          new_supply_id: "Id-ul de aprovizionare nou",
          create_supplier_text: 'Creați furnizor',
          create_supplier_name_Text: 'Numele furnizorului',
          create_supplier_button: 'Creați',
          add_supply: 'Adăugați aprovizionare',
          all_supplies: 'Toate proviziile',
          all_supply_types: 'Toate tipurile de aprovizionare',
          all_suppliers: 'Toți furnizorii',
          manually_uploaded_prices: 'Prețuri încărcate manual',
          supplier: 'Furnizor',
          supplier_name: 'Numele furnizorului',
          supply_inventory: 'Inventar de aprovizionare',
          supply_management: 'Managementul aprovizionării',
          supply_not_found: 'Aprovizionarea nu a fost găsită',
          supply_type: 'Tip de aprovizionare',
          supply_type_a: 'Tip de livrare A (0 - 1)',
          supply_type_b: 'Tip de livrare B (0 - 1)',
          supply_type_buying: 'Tip de aprovizionare Cumpărare (0 - 1)',
          supply_type_c: 'Tip de livrare C (0 - 1)',
          supply_type_name: 'Numele tipului de aprovizionare/livrare',
          supply_type_not_found: 'Tipul de aprovizionare/livrare nu a fost găsit',
          total_suppliers: 'Total furnizori',
          total_supplies: 'Total provizii',
          total_supply_types: 'Tipuri totale de aprovizionare',
          edit_supplier_information: 'Editați informațiile despre furnizor',
          edit_supply: 'Editați oferta',
          edit_supply_type: 'Editați tipul de aprovizionare.',
          exclude_supplies: 'Excluderea livrării',
          a_grade_percentage: 'A categorie %',
          b_grade_percentage: '% categoria B',
          c_grade_percentage: '% categoria C',
          allow_supply_to_sales: 'Sigur doriți să permiteți această aprovizionare XYZ pentru vânzări?',
          new_supply: 'Aprovizionare nouă',
          old_supply: 'Aprovizionare veche',
          edit_supplier_details: 'Vă rugăm să editați următoarele detalii ale furnizorului.',
          edit_supply_type_details: 'Vă rugăm să editați următoarele detalii ale tipului de furnizare.',
          provide_supply_id: 'Vă rugăm să introduceți id-ul de furnizare.',
        },
        SupplyInventory: {
          sidebar_text: 'Inventar de aprovizionare',
          extra_text: 'Produse suplimentare',
          scanned_text: 'Produse scanate',
          unscanned_text: 'Produse nescanate',
          mark_scanned_completely: 'Sigur doriți să marcați XYZ ca „scanat complet”?',
          restrict_supply_to_sales: 'Sigur doriți să restricționați această aprovizionare XYZ la vânzări?',
          confirm_delete_item: 'Sunteți sigur că doriți să ștergeți acest articol?',
          assign_type_to_new_supply: 'Atribuiți tip unei noi surse',
          scanned: 'Scanat',
          unscanned: 'Nescanat',
          extra: 'Suplimentar',
          not_visible_until_completed: 'Aceastea nu vor fi vizibile până la finalizare.',
          scanned_items: 'Elemente scanate',
          scanning_in_progress: 'Scanare în curs',
        },

        CatalogManagement: {
          sidebar_text: 'Gestionarea catalogului',
          catalog: 'Catalog',
          width_primary_cm: 'Lățimea primară (cm)',
          width_secondary_cm: 'Lățimea secundară (cm)',
          height_primary_cm: 'Înălțime primară (cm)',
          height_secondary_cm: 'Înălțime secundară (cm)',
          depth_primary_cm: 'Adâncime primară (cm)',
          depth_secondary_cm: 'Adâncimea secundară (cm)',
          catalog_management: 'Gestionarea catalogului',
          depth_primary: 'Adâncime primară',
          depth_secondary: 'Adâncimea secundară',
          height_primary: 'Înălțime primară',
          height_secondary: 'Înălțime secundară',
          weight_kg: 'Greutate (Kg)',
          width_primary: 'Lățimea primară',
          width_secondary: 'Lățimea secundară',
          loading_meters: 'volumul de încărcare',
        },
        RightsManagement: {
          sidebar_text: 'Managementul drepturilor',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Solicitări în așteptare ale clienților',
        },
        WebsiteSettings: {
          sidebar_text: 'Setările site-ului web',
          select_language: 'Selectați Limbă',
          upload: 'încărcați',
          upload_catalog: 'Încărcați catalogul',
          upload_pallet_products: 'Încărcați produse pe palete',
          upload_product: 'Încărcați produsul',

          settings: 'Setări',
          required_columns: 'Coloane obligatorii',
          change_password: 'Modificarea parolei',
          new_password: 'Parolă nouă',
          confirm_new_password: 'Confirmați noua parolă',
        },
        OrdersWords:{
          available_items: 'Articole disponibile',
          available_pallets: 'Palete disponibile',
          available_products: 'Produse disponibile',
          created_by: 'Creat de',
          order: 'Comanda',
          order_id: 'Id. comandă',
          ordered: 'Ordonat',
          package_id: 'ID pachet',
          sales_assistant: 'Asistent de vânzări',
          search_by_name: 'Căutați după nume',
          search_company: 'Cauta firma',
          select_sales_person: 'Selectați persoana de vânzări',
          select_supplier: 'Selectați furnizorul',
          set_articles: 'Setați articole',
          sub_category: 'Sub Cat',
          sub_total_euro: 'Subtotal €',
          sub_cat: 'Sub Cat',
          order_id_search: 'Căutare după ID-ul comenzii',
        },
        AnalysisWords:{
          analysis_text: 'Analiza',
          category: 'Categorie',
          category_analysis: 'Analiza categorii',
          change_supply_visibility: 'Schimbați vizibilitatea aprovizionării',
          companies_category_analysis: 'Analiza categoriilor de companii',
          companies_grades_analysis: 'Analiza notelor companiilor',
          compare_sales_person: 'Comparați vânzătorul',
          turnover_monthly: 'Venit lunar',
          turnover_yearly: 'Venit anual',
          profit_monthly: 'Profit lunar',
          profit_yearly: 'Profit anual',
        },
        CommonWords: {
          apply: 'Aplicați',
          done: 'Gata',
          edit: 'Edita',
          assign: 'Atribui',
          default: 'Implicit',
          delete: 'Şterge',
          cancel: 'Anula',
          date: 'Data',
          company: 'Companie',
          compare: 'Comparaţie',
          email: 'E-mail',
          image: 'Imagine',
          images: 'Imagini',
          model: 'Model',
          month: 'Lună',
          group: 'Grup',
          backoffice: 'BackOffice',
          brand_analysis: 'Analiza mărcii',
          brands: 'Mărci',
          buying_percentage: 'Achiziție %',
          buying_online_price: 'Pret de cumparare online',
          buying_price: 'Pret de cumparare',
          cart: 'coș',
          confirm: 'confirma',
          create: 'Crea',
          discount_percentage: '% reducere',
          items_per_page: 'Articole pe pagină:',
          navigation: 'Navigare',
          select: 'Selecta',
          view: 'Vizualizare',
          role: 'Rol',
          sales: 'Vânzări',
          yes: 'Da',
          logout: "deconectare",
          more: 'mai mult',
          account: "Cont",
          search: 'Căutare',
          close: 'Închidere',
          pending_approval: 'În așteptare',
          order_placed: 'Comanda plasată',
          view_details: 'Vezi detalii',
          action: 'Acțiune',
          status: 'Statut',
          quantity: 'Cantitate',
          brand: 'Marca',
        },
      },
    }
  },
  gr: {
    translation: {
      authentication: {
          register: {
              info: 'Παρακαλώ συμπληρώστε την παρακάτω φόρμα και θα επικοινωνήσουμε μαζί σας σύντομα.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Κύριο ταμπλό',
          my_dashboard: 'Το ταμπλό μου',
        },
        Analytics: {
          sidebar_text: 'Ανάλυση',
          analytics: 'Ανάλυση',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Κέρδος και κύκλος εργασιών',
        },
        ScanningTime: {
          sidebar_text: 'Μέσος χρόνος σάρωσης (δευτ.)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Συγκρίνετε Πωλητές',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Ανάλυση κατηγορίας',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Ανάλυση επωνυμίας',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Ανάλυση Βαθμών Πωλητών',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Σύγκριση εταιρειών',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Ανάλυση Κατηγορίας Εταιρειών',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Ανάλυση επωνυμιών εταιρειών',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Ανάλυση βαθμών εταιρειών',
        },
        SupplyAnalysis: {
          sidebar_text: 'Ανάλυση προσφοράς',
          searchbar_text: 'Ερευνα',
        },
        StockAnalysis: {
          sidebar_text: 'Ανάλυση αποθέματος',
          searchbar_text: 'Επιλέξτε απόθεμα',
        },
        MainDashboard: {
          sidebar_text: 'Κύριο ταμπλό',
        },
        Navigation: {
          sidebar_text: 'Πλοήγηση',
        },
        Users: {
          sidebar_text: 'Χρήστες',
          customers_data: 'Δεδομένα πελατών',
          edit_info: 'Επεξεργασία πληροφοριών',
          delete_customer: 'Διαγράφω',
          actions: 'Δράσεις',
          active: 'Ενεργός',
          add_new_staff_user: 'Προσθήκη νέου χρήστη προσωπικού',
          add_supply_type: 'Προσθήκη τύπου προμήθειας',
          add_user: 'Προσθήκη χρήστη',
          admin: 'Διαχειριστής',
          user: 'Χρήστης',
          enter_first_name: 'Εισαγάγετε Όνομα',
          enter_last_name: 'Εισαγάγετε Επώνυμο',
          enter_user_password: 'Εισαγάγετε τον κωδικό πρόσβασης χρήστη',
          not_active: 'Μη ενεργό',
          pending_customer_requests: 'Εκκρεμείς αιτήσεις πελατών',
          unable_to_log_in: 'Δεν είναι δυνατή η σύνδεση με τα παρεχόμενα διαπιστευτήρια.',
        },
        Team: {
          sidebar_text: 'Ομάδα'
        },
        Customers: {
          sidebar_text: 'Πελάτες',
          main_text: 'Δεδομένα πελατών',
          my_packages: 'Τα πακέτα μου',
          my_orders: 'Οι παραγγελίες μου',
          my_orders_details: 'Λεπτομέρειες παραγγελίας',
          add_customer: 'Προσθήκη πελατών',
          edit_info: 'Επεξεργασία πληροφοριών',
          delete_customer: 'Διαγράφω',
          customer: 'Πελάτης',
          customer_email: 'Email πελάτη',
          customers: 'Πελάτες',
          phone_number: 'Τηλέφωνο #',
          accepted_terms: 'Μπορείτε τώρα να προχωρήσετε',
          rejected_terms: 'Δεν μπορείτε να προχωρήσετε περαιτέρω και να αποσυνδεθείτε από το σύστημα',
        },
        Packages: {
          sidebar_text: 'Πακέτα',
          create_package_text: 'Δημιουργία πακέτου',
          warehouse_text: 'Αποθήκη',
          customer_text: 'Πελάτης',
          truck_capacity_text: 'Χωρητικότητα φορτηγού (0 m)',
          selling_price_text: 'Τιμή πώλησης',
          brands_text: 'Μάρκες',
          fetch_products_button_text: 'Εφαρμόζω',
          fetch_from_rest: 'Φέρτε από την ανάπαυση',
          supply_id_please: 'Παρακαλώ εισάγετε τον αναγνωριστικό αριθμό προμήθειας.',
          pair_all_button: 'Συνδέστε όλα',
          pair_button: 'Συνδέστε',
          smart_pair_switch: 'Έξυπνη σύνδεση',
          edit_info: 'Επεξεργασία πληροφοριών',
          are_you_sure: 'Είσαι σίγουρος;',
          article: 'Αρθρο',
          date_from: 'Ημερομηνία από',
          date_to: 'Ημερομηνία έως',
          extra: 'Επιπλέον',
          extra_items: 'Επιπλέον είδη',
          grade_points_range: 'Εύρος βαθμών',
          grand_total_euro: 'Συνολικό σύνολο €',
          pending_packages: 'Πακέτα σε εκκρεμότητα',
          status: 'Κατάσταση',
          place_order: 'Τοποθετήστε την παραγγελία',
          unselect_all: 'Καταργήστε την επιλογή όλων',
          total_buying_offer: 'Συνολική προσφορά αγοράς:',
          total_buying_price: 'Συνολική τιμή αγοράς:',
          total_items: 'Σύνολο στοιχείων',
          total_packages: 'Σύνολο πακέτων',
          select_pallet_supply: 'Παρακαλώ επιλέξτε την προμήθεια παλέτας',
          please_select_supplier: 'Παρακαλώ επιλέξτε προμηθευτή',
          package: 'Πακέτο',
          validity_hours: 'Ημέρες ισχύος',
          confirm_package: 'Επιβεβαίωση πακέτου',
          active_packages: 'Ενεργά πακέτα',
          you_have_selected: 'Έχετε επιλέξει',
          items: 'Στοιχεία',
          message: 'Μήνυμα',
          type_your_messsage_here: 'Πληκτρολογήστε το μήνυμά σας εδώ...',
        },
        PendingPackages: {
          sidebar_text: 'Πακέτα σε εκκρεμότητα',
        },
        OrderRequests: {
          sidebar_text: 'Αιτήσεις παραγγελίας',
          total_orders: 'Σύνολο παραγγελιών',
        },
        Warehouse: {
          sidebar_text: 'Αποθήκη',
          create_warehouse_order: 'Δημιουργία παραγγελίας αποθήκης',
          allow_warehouse_scan: 'Είστε βέβαιοι ότι θέλετε να επιτρέψετε στην αποθήκη να σαρώσει αυτό το απόθεμα;',
          warehouse: 'Αποθήκη',
          warehouse_package: 'Πακέτο αποθήκης',
          warehouse_manager: 'Υπεύθυνος Αποθήκης',
        },
        Products: {
          sidebar_text: 'Προϊόντα',
          online_price: 'Διαδικτυακή τιμή',
          price_euro: 'Τιμή €',
          product_id: 'Αναγνωριστικό προϊόντος',
          product_id_search: 'Αναζήτηση αναγνωριστικού προϊόντος',
          products: 'Προϊόντα',
          products_quantity: 'Ποσότητα προϊόντων',
          products_selected: 'Επιλεγμένα προϊόντα',
          selling_price: 'Τιμή πωλήσεως',
          items_selected_percentage: 'Επιλεγμένα στοιχεία %',
          min_3_digits: 'Ελάχιστο 3 ψηφία',
          minimum_3_digits: 'Τουλάχιστον 3 ψηφία',
          no_of_products_scanned: 'Αριθμός προϊόντων που σαρώθηκαν',
          no_product_within_applied_filter: 'Δεν υπάρχει προϊόν εντός του εφαρμοζόμενου φίλτρου',
          not_scanned_items: 'Μη σαρωμένα στοιχεία',
          number_of_products_scanned: 'Αριθμός προϊόντων που σαρώθηκαν',
          product_details: 'Λεπτομέρειες προϊόντος',
          number_of_products: 'Αριθμός προϊόντων',
          description: 'Περιγραφή',
          grade_point: 'Βαθμός βαθμού',
          final_grade: 'Βαθμός',
          category: 'Κατηγορία',
          remarks: 'Παρατηρήσεις',
          usage: 'Χρήση',
          add_to_cart: 'Προσθήκη στο καλάθι',
          remove_from_cart: 'Αφαίρεση από το καλάθι',
          brand: 'Μάρκα',
          brands: 'Μάρκες',
          buying_percentage: 'Αγοράζοντας %',
          buying_online_price: 'Αγοράζοντας online τιμή',
          buying_price: 'Τιμή αγοράς',
          article: 'Άρθρο',
          seen: 'δει',
          paired: 'ζεύγος',
          no_product_found: 'Δεν βρέθηκε προϊόν',
          no_setarticle_found: 'Δεν βρέθηκε set-article',
          no_pallet_found: 'Βαθμός προμηθευτή',
          supplier_grade: 'Nivelul furnizorului',
          broken_glass: 'Σπασμένο γυαλί',
        },
        Pallets: {
          pallet_product_detail: "Εισαγάγετε τα στοιχεία του προϊόντος παλέτας",
          mandatory_fields_text: "Τα πεδία με * είναι υποχρεωτικά.",
          add_pallet_product_button: '+ Προσθήκη προϊόντος παλέτας',
          pallet: 'Παλέτα',
          pallets: 'Παλέτες',
          pallet_details: 'Λεπτομέρειες παλέτας',
          products_inside: 'Προϊόντα στο εσωτερικό',
          total_selling_price: 'Συνολική τιμή πώλησης',
          total_online_price: 'Συνολική online τιμή',
        },
        SupplyManagement: {
          sidebar_text: 'Διαχείριση προμηθειών',
          supply: 'Προμήθεια',
          supply_id: 'Αναγνωριστικό προμήθειας',
          create_new_supply: 'Δημιουργία νέας προμήθειας',
          new_supply_id: "Νέο αναγνωριστικό προμήθειας",
          create_supplier_text: 'Δημιουργία προμηθευτή',
          create_supplier_name_Text: 'Όνομα προμηθευτή',
          create_supplier_button: 'Δημιουργώ',
          add_supply: 'Προσθήκη προμήθειας',
          all_supplies: 'Όλες οι προμήθειες',
          all_supply_types: 'Όλοι οι τύποι προμηθειών',
          all_suppliers: 'Όλοι οι προμηθευτές',
          manually_uploaded_prices: 'Τιμές που ανέβηκαν χειροκίνητα',
          supplier: 'Προμηθευτής',
          supplier_name: 'Όνομα προμηθευτή',
          supply_inventory: 'Απογραφή προμήθειας',
          supply_management: 'Διαχείριση προμηθειών',
          supply_not_found: 'Η προμήθεια δεν βρέθηκε',
          supply_type: 'Τύπος προμήθειας',
          supply_type_a: 'Προμήθεια τύπου Α (0 - 1)',
          supply_type_b: 'Προμήθεια τύπου Β (0 - 1)',
          supply_type_buying: 'Τύπος προμήθειας Αγορά (0 - 1)',
          supply_type_c: 'Προμήθεια τύπου C (0 - 1)',
          supply_type_name: 'Όνομα τύπου προμήθειας',
          supply_type_not_found: 'Δεν βρέθηκε ο τύπος προμήθειας',
          total_suppliers: 'Σύνολο προμηθευτών',
          total_supplies: 'Συνολικές προμήθειες',
          total_supply_types: 'Συνολικοί τύποι προμήθειας',
          edit_supplier_information: 'Επεξεργασία πληροφοριών προμηθευτή',
          edit_supply: 'Επεξεργασία προμήθειας',
          edit_supply_type: 'Επεξεργασία τύπου προμήθειας',
          exclude_supplies: 'Εξαίρεση προμηθειών',
          a_grade_percentage: 'Ένας βαθμός %',
          b_grade_percentage: 'Β Βαθμός %',
          c_grade_percentage: 'Γ Βαθμός %',
          allow_supply_to_sales: 'Είστε βέβαιοι ότι θέλετε να επιτρέψετε αυτή την προμήθεια XYZ σε πωλήσεις;',
          new_supply: 'Νέα προμήθεια',
          old_supply: 'Παλιά προμήθεια',
          edit_supplier_details: 'Παρακαλούμε επεξεργαστείτε τα ακόλουθα στοιχεία του προμηθευτή.',
          edit_supply_type_details: 'Επεξεργαστείτε τις παρακάτω λεπτομέρειες του τύπου προμήθειας.',
          provide_supply_id: 'Καταχωρίστε το αναγνωριστικό προμήθειας.',
        },
        SupplyInventory: {
          sidebar_text: 'Απογραφή προμήθειας',
          extra_text: 'Επιπλέον προϊόντα',
          scanned_text: 'Σαρωμένα προϊόντα',
          unscanned_text: 'Μη σαρωμένα προϊόντα',
          mark_scanned_completely: 'Είστε βέβαιοι ότι θέλετε να επισημάνετε το XYZ ως "σαρωμένο πλήρως";',
          restrict_supply_to_sales: 'Είστε βέβαιοι ότι θέλετε να περιορίσετε αυτήν την προμήθεια XYZ σε πωλήσεις;',
          confirm_delete_item: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το στοιχείο;',
          assign_type_to_new_supply: 'Αντιστοιχίστε τον τύπο σε μια νέα παροχή',
          scanned: 'Σαρωμένο',
          unscanned: 'Μη σαρωμένο',
          extra: 'Επιπλέον',
          not_visible_until_completed: 'Δεν θα είναι ορατά μέχρι να ολοκληρωθούν.',
          scanned_items: 'Σαρωμένα στοιχεία',
          scanning_in_progress: 'Σάρωση σε εξέλιξη',
        },
        CatalogManagement: {
          sidebar_text: 'Διαχείριση καταλόγου',
          catalog: 'Κατάλογος',
          width_primary_cm: 'Πλάτος πρωτεύον (cm)',
          width_secondary_cm: 'Πλάτος δευτερεύον (cm)',
          height_primary_cm: 'Ύψος πρωτεύον (cm)',
          height_secondary_cm: 'Ύψος δευτερεύον (cm)',
          depth_primary_cm: 'Βάθος πρωτεύον (cm)',
          depth_secondary_cm: 'Βάθος δευτερεύον (cm)',
          catalog_management: 'Διαχείριση καταλόγου',
          depth_primary: 'Βάθος πρωτεύον',
          depth_secondary: 'Βάθος δευτερεύον',
          height_primary: 'Ύψος πρωτεύον',
          height_secondary: 'Ύψος δευτερεύον',
          weight_kg: 'Βάρος (Kg)',
          width_primary: 'Πλάτος πρωτεύον',
          width_secondary: 'Πλάτος δευτερεύον',
          loading_meters: 'Μετρητές φόρτωσης',
        },
        RightsManagement: {
          sidebar_text: 'Διαχείριση δικαιωμάτων',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Εκκρεμείς αιτήσεις πελατών',
        },
        WebsiteSettings: {
          sidebar_text: 'Ρυθμίσεις ιστότοπου',
          select_language: 'Επιλέξτε Γλώσσα',
          upload: 'Μεταφόρτωση',
          upload_catalog: 'Ανεβάστε τον κατάλογο',
          upload_pallet_products: 'Ανεβάστε προϊόντα παλέτας',
          upload_product: 'Ανεβάστε προϊόν',
          settings: 'Ρυθμίσεις',
          required_columns: 'Απαιτούμενες στήλες',
          change_password: 'Αλλαγή κωδικού πρόσβασης',
          new_password: 'Νέος κωδικός πρόσβασης',
          confirm_new_password: 'Επιβεβαίωση νέου κωδικού πρόσβασης',
        },
        OrdersWords:{
          available_items: 'Διαθέσιμα είδη',
          available_pallets: 'Διαθέσιμες παλέτες',
          available_products: 'Διαθέσιμα Προϊόντα',
          created_by: 'Δημιουργήθηκε από',
          order: 'Παραγγελία',
          order_id: 'Αναγνωριστικό παραγγελίας',
          ordered: 'Παρήγγειλε',
          package_id: 'Αναγνωριστικό πακέτου',
          sales_assistant: 'Βοηθός πωλήσεων',
          search_by_name: 'Αναζήτηση με βάση το όνομα',
          search_company: 'Αναζήτηση εταιρείας',
          select_sales_person: 'Επιλέξτε πωλητή',
          select_supplier: 'Επιλέξτε προμηθευτή',
          set_articles: 'Ορίστε άρθρα',
          sub_category: 'Υποκατηγορία',
          sub_total_euro: 'Υποσύνολο €',
          sub_cat: 'Υποκατηγορία',
          order_id_search: 'Αναζήτηση με αναγνωριστικό παραγγελίας',
        },
        AnalysisWords:{
          analysis_text: 'Ανάλυση',
          category: 'Κατηγορία',
          category_analysis: 'Ανάλυση κατηγορίας',
          change_supply_visibility: 'Αλλαγή ορατότητας προμήθειας',
          companies_category_analysis: 'Ανάλυση κατηγοριών εταιρειών',
          companies_grades_analysis: 'Ανάλυση Βαθμών Εταιρειών',
          compare_sales_person: 'Συγκρίνετε Πωλητές',
          turnover_monthly: 'Μηνιαία κέρδη',
          turnover_yearly: 'Ετήσια κέρδη',
          profit_monthly: 'Μηνιαίο Κέρδος',
          profit_yearly: 'Ετήσιο Κέρδος',
        },
        CommonWords: {
          logout: "αποσύνδεση",
          more: 'περισσότερα',
          account: "Λογαριασμός",
          search: 'Αναζήτηση',
          close: 'Κλείσιμο',
          pending_approval: 'Εκκρεμεί',
          order_placed: 'Παραγγελία που έγινε',
          view_details: 'Προβολή λεπτομερειών',
          action: 'Δράση',
          status: 'Κατάσταση',
          quantity: 'Ποσότητα',
          apply: 'Εφαρμόζω',
          done: 'Ολοκληρώθηκε',
          edit: 'Εκδίδω',
          assign: 'Αναθέτω',
          default: 'Προκαθορισμένο',
          delete: 'Διαγράφω',
          cancel: 'Ματαίωση',
          date: 'Ημερομηνία',
          company: 'Εταιρεία',
          compare: 'Συγκρίνω',
          email: 'E-mail',
          image: 'Εικών',
          images: 'εικόνες',
          model: 'Μοντέλο',
          month: 'Μήνας',
          group: 'Ομάδα',
          backoffice: 'BackOffice',
          brand_analysis: 'Ανάλυση επωνυμίας',
          brands: 'Μάρκες',
          buying_percentage: 'Αγορά %',
          buying_online_price: 'Αγορά online τιμή',
          buying_price: 'Τιμή αγοράς',
          cart: 'Καροτσάκι',
          confirm: 'επιβεβαιώνω',
          create: 'Δημιουργώ',
          discount_percentage: 'Έκπτωση %',
          items_per_page: 'Στοιχεία ανά σελίδα:',
          navigation: 'Πλοήγηση',
          select: 'Επιλέγω',
          view: 'Θέα',
          role: 'Ρόλος',
          sales: 'Πωλήσεις',
          yes: 'Ναί',        
          brand: 'Μάρκα',
        },
      },
    }
  },
  es: {
    translation: {
      authentication: {
          register: {
              info: 'Por favor complete el formulario a continuación y nos pondremos en contacto con usted en breve.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
        },
        Analytics: {
          sidebar_text: 'Analítica',
          analytics: 'Analítica',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Ganancia y volumen de negocios',
        },
        ScanningTime: {
          sidebar_text: 'Tiempo promedio de escaneo (seg)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Comparar vendedor',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Análisis de categorías',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Análisis de marca',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Análisis de calificaciones',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Comparación de empresas',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Análisis de categorías de empresas',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Análisis de marcas de empresas',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Análisis de calificaciones de empresas',
        },
        SupplyAnalysis: {
          sidebar_text: 'Análisis de oferta',
          searchbar_text: 'Buscar',
        },
        StockAnalysis: {
          sidebar_text: 'Análisis de inventario',
          searchbar_text: 'Seleccionar almacén',
        },
        MainDashboard: {
          sidebar_text: 'Panel principal',
        },
        Navigation: {
          sidebar_text: 'Navegación',
        },
        Users: {
          sidebar_text: 'Usuarios',
          customers_data: 'Datos del cliente',
          edit_info: 'Editar información',
          delete_customer: 'Borrar',
          actions: 'Comportamiento',
          active: 'Activo',
          add_new_staff_user: 'Agregar nuevo usuario del personal',
          add_supply_type: 'Agregar tipo de suministro',
          add_user: 'Agregar usuario',
          admin: 'Administración',
          user: 'Usuario',
          enter_first_name: 'Introduzca el nombre',
          enter_last_name: 'Introduce el apellido',
          enter_user_password: 'Introduzca la contraseña de usuario',
          not_active: 'No activo',
          pending_customer_requests: 'Solicitudes de clientes pendientes',
          unable_to_log_in: 'No se puede iniciar sesión con las credenciales proporcionadas.',
        },
        Team: {
          sidebar_text: 'Equipo'
        },
        Customers: {
          sidebar_text: 'Clientes',
          main_text: 'Datos del cliente',
          my_packages: 'Mis paquetes',
          my_orders: 'Mis pedidos',
          my_orders_details: 'Detalles del pedido',
          add_customer: 'Agregar clientes',
          edit_info: 'Editar información',
          delete_customer: 'Borrar',
          customer: 'Cliente',
          customer_email: 'Correo electrónico del cliente',
          customers: 'Clientes',
          phone_number: 'Teléfono #',
        },
        Packages: {
          sidebar_text: 'Paquetes',
          create_package_text: 'Crear paquete',
          warehouse_text: 'Depósito',
          customer_text: 'Cliente',
          truck_capacity_text: 'Capacidad del camión (0 m)',
          selling_price_text: 'Precio de venta',
          brands_text: 'Marcas',
          fetch_products_button_text: 'Aplicar',
          fetch_from_rest: 'Recuperar del reposo',
          supply_id_please: 'Por favor proporcione la identificación del suministro.',
          pair_all_button: 'Emparejar todo',
          pair_button: 'Emparejar',
          smart_pair_switch: 'Emparejamiento inteligente',
          edit_info: 'Editar información',
          are_you_sure: '¿Estás seguro?',
          article: 'Artículo',
          date_from: 'Fecha de',
          date_to: 'Fecha hasta',
          extra: 'Extra',
          extra_items: 'Artículos adicionales',
          grade_points_range: 'Rango de calificaciones',
          grand_total_euro: 'Gran total €',
          pending_packages: 'Paquetes pendientes',
          status: 'Estado',
          place_order: 'Realizar pedido',
          unselect_all: 'Deseleccionar todo',
          total_buying_offer: 'Oferta de compra total:',
          total_buying_price: 'Precio total de compra:',
          total_items: 'Artículos totales',
          total_packages: 'Paquetes totales',
          select_pallet_supply: 'Por favor seleccione el suministro de paletas',
          please_select_supplier: 'Por favor seleccione el proveedor',
        },
        AutomatedPackages: {
          sidebar_text: 'Paquetes automatizados'
        },
        PendingPackages: {
          sidebar_text: 'Paquetes pendientes',
        },
        OrderRequests: {
          sidebar_text: 'Solicitudes de pedido',
        },
        Warehouse: {
          sidebar_text: 'Depósito',
          create_warehouse_order: 'Crear orden de almacén',
          allow_warehouse_scan: '¿Está seguro de que desea permitir que el almacén escanee este suministro?',
          warehouse: 'Depósito',
          warehouse_package: 'Paquete de almacén',
          warehouse_manager: 'Gerente de almacén',
        },
        Products: {
          sidebar_text: 'Productos',
          online_price: 'Precio en línea',
          price_euro: 'Precio €',
          product_id: 'ID de producto',
          product_id_search: 'Búsqueda de ID de producto',
          products: 'Productos',
          products_quantity: 'Cantidad de productos',
          products_selected: 'Productos seleccionados',
          selling_price: 'Precio de venta',
          items_selected_percentage: 'Artículos seleccionados %',
          min_3_digits: 'Mínimo 3 dígitos',
          minimum_3_digits: 'Mínimo 3 dígitos',
          no_of_products_scanned: 'No de productos escaneados',
          no_product_within_applied_filter: 'No hay producto dentro del filtro aplicado',
          not_scanned_items: 'Artículos no escaneados',
          number_of_products_scanned: 'Número de productos escaneados',
        },
        Pallets: {
          pallet_product_detail: "Ingrese los detalles del producto de la paleta",
          mandatory_fields_text: "Los campos con * son obligatorios.",
          add_pallet_product_button: '+ Agregar producto de paleta',
          pallet: 'Paleta',
          pallets: 'Palets',
        },
        SupplyManagement: {
          sidebar_text: 'Gestión de suministros',
          supply: 'Suministro',
          supply_id: 'ID de suministro',
          create_new_supply: 'Crear nuevo suministro',
          new_supply_id: "Nueva identificación de suministro",
          create_supplier_text: 'Crear proveedor',
          create_supplier_name_Text: 'Nombre del proveedor',
          create_supplier_button: 'Crear',
          add_supply: 'Agregar suministro',
          all_supplies: 'Todos los suministros',
          all_supply_types: 'Todos los tipos de suministro',
          all_suppliers: 'Todos los proveedores',
          manually_uploaded_prices: 'Precios cargados manualmente',
          supplier: 'Proveedor',
          supplier_name: 'Nombre del proveedor',
          supply_inventory: 'Inventario de suministros',
          supply_management: 'Gestión de suministros',
          supply_not_found: 'Suministro no encontrado',
          supply_type: 'Tipo de suministro',
          supply_type_a: 'Tipo de suministro A (0 - 1)',
          supply_type_b: 'Tipo de suministro B (0 - 1)',
          supply_type_buying: 'Tipo de suministro Compra (0 - 1)',
          supply_type_c: 'Tipo de suministro C (0 - 1)',
          supply_type_name: 'Nombre del tipo de suministro',
          supply_type_not_found: 'Tipo de suministro no encontrado',
          total_suppliers: 'Proveedores totales',
          total_supplies: 'Suministros totales',
          total_supply_types: 'Tipos de suministro totales',
          edit_supplier_information: 'Editar información del proveedor',
          edit_supply: 'Editar suministro',
          edit_supply_type: 'Editar tipo de suministro.',
          exclude_supplies: 'Excluir suministros',
          a_grade_percentage: 'Un grado %',
          b_grade_percentage: 'Grado B %',
          c_grade_percentage: '% de calificación C',
          allow_supply_to_sales: '¿Está seguro de que desea permitir este suministro XYZ a ventas?',
          new_supply: 'Nuevo suministro',
          old_supply: 'Suministro antiguo',
          edit_supplier_details: 'Edite los siguientes detalles del proveedor.',
          edit_supply_type_details: 'Edite los siguientes detalles del tipo de suministro.',
          provide_supply_id: 'Proporcione la identificación del suministro.',
        },
        SupplyInventory: {
          sidebar_text: 'Inventario de suministros',
          extra_text: 'Productos adicionales',
          scanned_text: 'Productos escaneados',
          unscanned_text: 'Productos no escaneados',
          mark_scanned_completely: '¿Está seguro de que desea marcar XYZ como "escaneado por completo"?',
          restrict_supply_to_sales: '¿Está seguro de que desea restringir este suministro XYZ a las ventas?',
          confirm_delete_item: '¿Está seguro de que desea eliminar este elemento?',
          assign_type_to_new_supply: 'Asignar tipo a un nuevo suministro',
          scanned: 'Escaneado',
          unscanned: 'Sin escanear',
          extra: 'Extra',
          not_visible_until_completed: 'No serán visibles hasta que estén completos.',
          scanned_items: 'Artículos escaneados',
          scanning_in_progress: 'Escaneo en progreso',
        },
        CatalogManagement: {
          sidebar_text: 'Gestión de catálogos',
          catalog: 'Catálogo',
          width_primary_cm: 'Ancho primario (cm)',
          width_secondary_cm: 'Ancho secundario (cm)',
          height_primary_cm: 'Altura primaria (cm)',
          height_secondary_cm: 'Altura secundaria (cm)',
          depth_primary_cm: 'Profundidad primaria (cm)',
          depth_secondary_cm: 'Profundidad secundaria (cm)',
          catalog_management: 'Gestión de catálogos',
          depth_primary: 'Profundidad primaria',
          depth_secondary: 'Profundidad secundaria',
          height_primary: 'Altura primaria',
          height_secondary: 'Altura secundaria',
          weight_kg: 'Peso (kilogramos)',
          width_primary: 'Ancho primario',
          width_secondary: 'Ancho secundario',
          loading_meters: 'Cargando Metros',
        },
        RightsManagement: {
          sidebar_text: 'Gestión de derechos',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Solicitudes de clientes pendientes',
        },
        WebsiteSettings: {
          sidebar_text: 'Configuración del sitio web',
          select_language: 'Seleccionar idioma',
          upload: 'Subir',
          upload_catalog: 'Subir catálogo',
          upload_pallet_products: 'Subir productos de palet',
          upload_product: 'Subir producto',
        },
        OrdersWords:{
          available_items: 'Artículos disponibles',
          available_pallets: 'Palets disponibles',
          available_products: 'Productos disponibles',
          created_by: 'Creado por',
          order: 'Orden',
          order_id: 'ID de pedido',
          ordered: 'Ordenado',
          package_id: 'Identificación del paquete',
          sales_assistant: 'Asistente de ventas',
          search_by_name: 'Buscar por nombre',
          search_company: 'Buscar empresa',
          select_sales_person: 'Seleccionar vendedor',
          select_supplier: 'Seleccionar proveedor',
          set_articles: 'Establecer artículos',
          sub_category: 'Subcategoría',
          sub_total_euro: 'Subtotal €',
          sub_cat: 'Subcategoría',
        },
        AnalysisWords:{
          analysis_text: 'Análisis',
          category: 'Categoría',
          category_analysis: 'Análisis de categorías',
          change_supply_visibility: 'Cambiar la visibilidad del suministro',
          companies_category_analysis: 'Análisis de categorías de empresas',
          companies_grades_analysis: 'Análisis de calificaciones de empresas',
          compare_sales_person: 'Comparar vendedor',
          turnover_monthly: 'Volumen de negocios mensual',
          turnover_yearly: 'Volumen de negocios anual',
          profit_monthly: 'Ganancia Mensual',
          profit_yearly: 'Beneficio Anual',
        },
        CommonWords: {
          apply: 'Aplicar',
          done: 'Hecho',
          edit: 'Editar',
          assign: 'Asignar',
          default: 'Por defecto',
          delete: 'Borrar',
          cancel: 'Cancelar',
          date: 'Fecha',
          company: 'Compañía',
          compare: 'Comparar',
          email: 'Correo electrónico',
          image: 'imagen',
          images: 'Imágenes',
          model: 'Modelo',
          month: 'Mes',
          group: 'Grupo',
          backoffice: 'Oficina Trasera',
          brand_analysis: 'Análisis de marca',
          brands: 'Marcas',
          buying_percentage: 'Comprar %',
          buying_online_price: 'Comprar precio en línea',
          buying_price: 'Precio de compra',
          cart: 'Carro',
          confirm: 'Confirmar',
          create: 'Crear',
          discount_percentage: 'Descuento %',
          items_per_page: 'Artículos por página:',
          navigation: 'Navegación',
          select: 'Seleccionar',
          view: 'Vista',
          role: 'Role',
          sales: 'Ventas',
          yes: 'Sí',
        },
      },
    }
  },
  it: {
    translation: {
      authentication: {
          register: {
              info: 'Si prega di compilare il modulo sottostante e vi contatteremo a breve.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
        },
        Analytics: {
          sidebar_text: 'Analitica',
          analytics: 'Analitica',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Profitto e fatturato',
        },
        ScanningTime: {
          sidebar_text: 'Tempo medio di scansione (sec)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Confronta il venditore',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Analisi delle categorie',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Analisi del marchio',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Analisi dei voti dei venditori',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Confronto aziende',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Analisi delle categorie aziendali',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Analisi dei marchi aziendali',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Analisi dei voti delle aziende',
        },
        SupplyAnalysis: {
          sidebar_text: 'Analisi dell\'offerta',
          searchbar_text: 'Ricerca',
        },
        StockAnalysis: {
          sidebar_text: 'Analisi dell\'inventario',
          searchbar_text: 'Seleziona magazzino',
        },
        MainDashboard: {
          sidebar_text: 'Cruscotto principale',
        },
        Navigation: {
          sidebar_text: 'Navigazione',
        },
        Users: {
          sidebar_text: 'Utenti',
          customers_data: 'Dati del cliente',
          edit_info: 'Modifica informazioni',
          delete_customer: 'Eliminare',
          actions: 'Azioni',
          active: 'Attivo',
          add_new_staff_user: 'Aggiungi nuovo utente dello staff',
          add_supply_type: 'Aggiungi tipo di fornitura',
          add_user: 'Aggiungi utente',
          admin: 'Amministrazione',
          user: 'Utente',
          enter_first_name: 'Inserisci il nome',
          enter_last_name: 'Inserisci il cognome',
          enter_user_password: 'Inserisci la password dell\'utente',
          not_active: 'Non attivo',
          pending_customer_requests: 'Richieste dei clienti in sospeso',
          unable_to_log_in: 'Impossibile accedere con le credenziali fornite.',
        },
        Team: {
          sidebar_text: 'Squadra'
        },
        Customers: {
          sidebar_text: 'Clienti',
          main_text: 'Dati del cliente',
          my_packages: 'I miei pacchetti',
          my_orders: 'I miei ordini',
          my_orders_details: 'Dettagli dell\'ordine',
          add_customer: 'Aggiungi clienti',
          edit_info: 'Modifica informazioni',
          delete_customer: 'Eliminare',
          customer: 'Cliente',
          customer_email: 'E-mail del cliente',
          customers: 'Clienti',
          phone_number: 'Telefono #',
        },
        Packages: {
          sidebar_text: 'Pacchetti',
          create_package_text: 'Crea pacchetto',
          warehouse_text: 'Magazzino',
          customer_text: 'Cliente',
          truck_capacity_text: 'Capacità del camion (0 m)',
          selling_price_text: 'Prezzo di vendita',
          brands_text: 'Marche',
          fetch_products_button_text: 'Applicare',
          fetch_from_rest: 'Riprendi dal riposo',
          supply_id_please: 'Si prega di fornire l\'ID della fornitura.',
          pair_all_button: 'Associa tutto',
          pair_button: 'Associa',
          smart_pair_switch: 'Associazione intelligente',
          edit_info: 'Modifica informazioni',
          are_you_sure: 'Sei sicuro?',
          article: 'Articolo',
          date_from: 'Data da',
          date_to: 'Data a',
          extra: 'Extra',
          extra_items: 'Articoli aggiuntivi',
          grade_points_range: 'Intervallo di voti',
          grand_total_euro: 'Totale complessivo €',
          pending_packages: 'Pacchetti in sospeso',
          status: 'Stato',
          place_order: 'Effettuare l\'ordine',
          unselect_all: 'Deseleziona tutto',
          total_buying_offer: 'Offerta d\'acquisto totale:',
          total_buying_price: 'Prezzo totale di acquisto:',
          total_items: 'Articoli totali',
          total_packages: 'Pacchetti totali',
          select_pallet_supply: 'Seleziona la fornitura di pallet',
          please_select_supplier: 'Seleziona il fornitore',
        },
        AutomatedPackages: {
          sidebar_text: 'Pacchetti automatizzati'
        },
        PendingPackages: {
          sidebar_text: 'Pacchetti in sospeso',
        },
        OrderRequests: {
          sidebar_text: 'Richieste di ordini',
        },
        Warehouse: {
          sidebar_text: 'Magazzino',
          create_warehouse_order: 'Crea ordine di magazzino',
          allow_warehouse_scan: 'Sei sicuro di voler consentire al magazzino di scansionare questa fornitura?',
          warehouse: 'Magazzino',
          warehouse_package: 'Pacchetto magazzino',
          warehouse_manager: 'Responsabile del magazzino',
        },
        Products: {
          sidebar_text: 'Prodotti',
          online_price: 'Prezzo in linea',
          price_euro: 'Prezzo €',
          product_id: 'Codice prodotto',
          product_id_search: 'Ricerca ID prodotto',
          products: 'Prodotti',
          products_quantity: 'Quantità di prodotti',
          products_selected: 'Prodotti selezionati',
          selling_price: 'Prezzo di vendita',
          items_selected_percentage: 'Articoli selezionati %',
          min_3_digits: 'Minimo 3 cifre',
          minimum_3_digits: 'Minimo 3 cifre',
          no_of_products_scanned: 'N. di prodotti scansionati',
          no_product_within_applied_filter: 'Nessun prodotto nel filtro applicato',
          not_scanned_items: 'Elementi non scansionati',
          number_of_products_scanned: 'Numero di prodotti scansionati',
          no_product_found: 'Nessun prodotto trovato',
          no_setarticle_found: 'Nessun articolo di set trovato',
          no_pallet_found: 'Nessun pallet trovato',
          supplier_grade: 'Grado del fornitore',
          broken_glass: 'Vetro rotto',
        },
        Pallets: {
          pallet_product_detail: "Inserisci i dettagli del prodotto pallet",
          mandatory_fields_text: "I campi contrassegnati con * sono obbligatori.",
          add_pallet_product_button: '+ Aggiungi prodotto pallet',
          pallet: 'Pallet',
          pallets: 'Pallet',
        },
        SupplyManagement: {
          sidebar_text: 'Gestione dell\'offerta',
          supply: 'Fornitura',
          supply_id: 'ID fornitura',
          create_new_supply: 'Crea nuova fornitura',
          new_supply_id: "Nuovo ID fornitura",
          create_supplier_text: 'Crea fornitore',
          create_supplier_name_Text: 'Nome del fornitore',
          create_supplier_button: 'Creare',
          add_supply: 'Aggiungi fornitura',
          all_supplies: 'Tutte le forniture',
          all_supply_types: 'Tutti i tipi di fornitura',
          all_suppliers: 'Tutti i fornitori',
          manually_uploaded_prices: 'Prezzi caricati manualmente',
          supplier: 'Fornitore',
          supplier_name: 'Nome del fornitore',
          supply_inventory: 'Inventario della fornitura',
          supply_management: 'Gestione dell\'offerta',
          supply_not_found: 'Fornitura non trovata',
          supply_type: 'Tipo di fornitura',
          supply_type_a: 'Tipo di alimentazione A (0 - 1)',
          supply_type_b: 'Tipo di alimentazione B (0 - 1)',
          supply_type_buying: 'Tipo fornitura Acquisto (0 - 1)',
          supply_type_c: 'Tipo di alimentazione C (0 - 1)',
          supply_type_name: 'Nome del tipo di fornitura',
          supply_type_not_found: 'Tipo di fornitura non trovato',
          total_suppliers: 'Fornitori totali',
          total_supplies: 'Forniture totali',
          total_supply_types: 'Tipologie di fornitura totali',
          edit_supplier_information: 'Modifica le informazioni del fornitore',
          edit_supply: 'Modifica fornitura',
          edit_supply_type: 'Modifica tipo di fornitura.',
          exclude_supplies: 'Escludere le forniture',
          a_grade_percentage: 'Grado A%',
          b_grade_percentage: 'Grado B%',
          c_grade_percentage: 'Grado C%',
          allow_supply_to_sales: 'Sei sicuro di voler consentire questa fornitura XYZ alle vendite?',
          new_supply: 'Nuova fornitura',
          old_supply: 'Vecchia fornitura',
          edit_supplier_details: 'Modifica i seguenti dettagli del fornitore.',
          edit_supply_type_details: 'Modifica i seguenti dettagli del tipo di fornitura.',
          provide_supply_id: 'Si prega di fornire l\'ID della fornitura.',
        },
        SupplyInventory: {
          sidebar_text: 'Inventario della fornitura',
          extra_text: 'Prodotti extra',
          scanned_text: 'Prodotti scansionati',
          unscanned_text: 'Prodotti non scansionati',
          mark_scanned_completely: 'Sei sicuro di voler contrassegnare XYZ come "scansionato completamente"?',
          restrict_supply_to_sales: 'Sei sicuro di voler limitare questa fornitura XYZ alle vendite?',
          confirm_delete_item: 'Sei sicuro di voler eliminare questo elemento?',
          assign_type_to_new_supply: 'Assegnare il tipo a una nuova fornitura',
          scanned: 'Scansionato',
          unscanned: 'Non scansionato',
          extra: 'Extra',
          not_visible_until_completed: 'Non saranno visibili finché non saranno completati.',
          scanned_items: 'Elementi scansionati',
          scanning_in_progress: 'Scansione in corso',
        },
        CatalogManagement: {
          sidebar_text: 'Gestione del catalogo',
          catalog: 'Catalogo',
          width_primary_cm: 'Larghezza primaria (cm)',
          width_secondary_cm: 'Larghezza secondaria (cm)',
          height_primary_cm: 'Altezza primaria (cm)',
          height_secondary_cm: 'Altezza secondaria (cm)',
          depth_primary_cm: 'Profondità primaria (cm)',
          depth_secondary_cm: 'Profondità secondaria (cm)',
          catalog_management: 'Gestione del catalogo',
          depth_primary: 'Profondità primaria',
          depth_secondary: 'Profondità secondaria',
          height_primary: 'Altezza primaria',
          height_secondary: 'Secondario in altezza',
          weight_kg: 'Peso(Kg)',
          width_primary: 'Larghezza primaria',
          width_secondary: 'Larghezza secondaria',
          loading_meters: 'Caricamento metri',
        },
        RightsManagement: {
          sidebar_text: 'Gestione dei diritti',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Richieste dei clienti in sospeso',
        },
        WebsiteSettings: {
          sidebar_text: 'Impostazioni del sito web',
          select_language: 'Seleziona Lingua',
          upload: 'Caricamento',
          upload_catalog: 'Carica catalogo',
          upload_pallet_products: 'Carica prodotti su pallet',
          upload_product: 'Carica prodotto',
        },
        OrdersWords:{
          available_items: 'Articoli disponibili',
          available_pallets: 'Pallet disponibili',
          available_products: 'Prodotti disponibili',
          created_by: 'Creato da',
          order: 'Ordine',
          order_id: 'ID dell\'ordine',
          ordered: 'Ordinato',
          package_id: 'ID del pacchetto',
          sales_assistant: 'Assistente alle vendite',
          search_by_name: 'Cerca per nome',
          search_company: 'Cerca azienda',
          select_sales_person: 'Seleziona venditore',
          select_supplier: 'Seleziona fornitore',
          set_articles: 'Imposta articoli',
          sub_category: 'Sottocategoria',
          sub_total_euro: 'Sub Totale €',
          sub_cat: 'Sottocategoria',
        },
        AnalysisWords:{
          analysis_text: 'Analisi',
          category: 'Categoria',
          category_analysis: 'Analisi delle categorie',
          change_supply_visibility: 'Modificare la visibilità dell\'offerta',
          companies_category_analysis: 'Analisi delle categorie aziendali',
          companies_grades_analysis: 'Analisi dei voti delle aziende',
          compare_sales_person: 'Confronta il venditore',
          turnover_monthly: 'Fatturato mensile',
          turnover_yearly: 'Fatturato annuale',
          profit_monthly: 'Profitto mensile',
          profit_yearly: 'Profitto annuale',
        },
        CommonWords: {
          apply: 'Applicare',
          done: 'Fatto',
          edit: 'Modificare',
          assign: 'Assegnare',
          default: 'Predefinito',
          delete: 'Eliminare',
          cancel: 'Cancellare',
          date: 'Data',
          company: 'Azienda',
          compare: 'Confrontare',
          email: 'E-mail',
          image: 'immagine',
          images: 'Immagini',
          model: 'Modello',
          month: 'Mese',
          group: 'Gruppo',
          backoffice: 'Back office',
          brand_analysis: 'Analisi del marchio',
          brands: 'Marche',
          buying_percentage: '% d\'acquisto',
          buying_online_price: 'Acquista prezzi on-line',
          buying_price: 'Prezzo d\'acquisto',
          cart: 'Carrello',
          confirm: 'Confermare',
          create: 'Creare',
          discount_percentage: 'Sconto %',
          items_per_page: 'Articoli per pagina:',
          navigation: 'Navigazione',
          select: 'Selezionare',
          view: 'Visualizzazione',
          role: 'Ruolo',
          sales: 'Saldi',
          yes: 'SÌ',
        },
      },
    }
  },
  por: {
    translation: {
      authentication: {
          register: {
              info: 'Por favor, preencha o formulário abaixo e entraremos em contato em breve.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Painel principal',
        },
        Analytics: {
          sidebar_text: 'Análise',
          analytics: 'Análise',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Lucro e faturamento',
        },
        ScanningTime: {
          sidebar_text: 'Tempo médio de digitalização (seg)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Comparar vendedor',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Análise de categoria',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Análise de marca',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Análise de notas de vendedores',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Comparação de empresas',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Análise de categoria de empresas',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Análise de marcas de empresas',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Análise de notas de empresas',
        },
        SupplyAnalysis: {
          sidebar_text: 'Análise de oferta',
          searchbar_text: 'Procurar',
        },
        StockAnalysis: {
          sidebar_text: 'Análise de stock',
          searchbar_text: 'Selecione o armazém',
        },
        MainDashboard: {
          sidebar_text: 'Painel principal',
        },
        Navigation: {
          sidebar_text: 'Navegação',
        },
        Users: {
          sidebar_text: 'Utilizadores',
          customers_data: 'Dados do cliente',
          edit_info: 'Editar informações',
          delete_customer: 'Excluir',
          actions: 'Ações',
          active: 'Ativo',
          add_new_staff_user: 'Adicionar novo utilizador da equipa',
          add_supply_type: 'Adicionar tipo de fornecimento',
          add_user: 'Adicionar utilizador',
          admin: 'Administrador',
          user: 'Utilizador',
          enter_first_name: 'Introduza o primeiro nome',
          enter_last_name: 'Introduza o sobrenome',
          enter_user_password: 'Introduza a senha do utilizador',
          not_active: 'Não ativo',
          pending_customer_requests: 'Solicitações de clientes pendentes',
          unable_to_log_in: 'Não é possível fazer login com as credenciais fornecidas.',
        },
        Team: {
          sidebar_text: 'Equipe'
        },
        Customers: {
          sidebar_text: 'Clientes',
          main_text: 'Dados do cliente',
          my_packages: 'Meus pacotes',
          my_orders: 'Meus pedidos',
          my_orders_details: 'Detalhes do pedido',
          add_customer: 'Adicionar clientes',
          edit_info: 'Editar informações',
          delete_customer: 'Excluir',
          customer: 'Cliente',
          customer_email: 'E-mail do cliente',
          customers: 'Clientes',
          phone_number: 'Telefone nº',
        },
        Packages: {
          sidebar_text: 'Pacotes',
          create_package_text: 'Criar pacote',
          warehouse_text: 'Armazém',
          customer_text: 'Cliente',
          truck_capacity_text: 'Capacidade do camião (0 m)',
          selling_price_text: 'Preço de venda',
          brands_text: 'Marcas',
          fetch_products_button_text: 'Aplicar',
          fetch_from_rest: 'Buscar do resto',
          supply_id_please: 'Por favor, forneça o ID do fornecimento.',
          pair_all_button: 'Emparelhar todos',
          pair_button: 'Emparelhar',
          smart_pair_switch: 'Emparelhamento inteligente',
          edit_info: 'Editar informações',
          are_you_sure: 'Tem a certeza?',
          article: 'Artigo',
          date_from: 'Data de',
          date_to: 'Data para',
          extra: 'Extra',
          extra_items: 'Itens extras',
          grade_points_range: 'Faixa de notas',
          grand_total_euro: 'Total geral €',
          pending_packages: 'Pacotes pendentes',
          status: 'Status',
          place_order: 'Fazer pedido',
          unselect_all: 'Desmarcar tudo',
          total_buying_offer: 'Oferta total de compra:',
          total_buying_price: 'Preço total de compra:',
          total_items: 'Total de artigos',
          total_packages: 'Total de pacotes',
          select_pallet_supply: 'Selecione o fornecimento de paletes',
          please_select_supplier: 'Por favor, selecione o fornecedor',
        },
        AutomatedPackages: {
          sidebar_text: 'Pacotes automatizados'
        },
        PendingPackages: {
          sidebar_text: 'Pacotes pendentes',
        },
        OrderRequests: {
          sidebar_text: 'Solicitações de pedidos',
        },
        Warehouse: {
          sidebar_text: 'Armazém',
          create_warehouse_order: 'Criar ordem de armazém',
          allow_warehouse_scan: 'Tem a certeza de que pretende permitir que o armazém digitalize este fornecimento?',
          warehouse: 'Armazém',
          warehouse_package: 'Pacote de armazém',
          warehouse_manager: 'Gestor de Armazém',
        },
        Products: {
          sidebar_text: 'Produtos',
          online_price: 'Preço on-line',
          price_euro: 'Preço €',
          product_id: 'ID do produto',
          product_id_search: 'Pesquisa de ID do produto',
          products: 'Produtos',
          products_quantity: 'Quantidade de produtos',
          products_selected: 'Produtos selecionados',
          selling_price: 'Preço de venda',
          items_selected_percentage: 'Itens selecionados %',
          min_3_digits: 'Mínimo de 3 dígitos',
          minimum_3_digits: 'Mínimo de 3 dígitos',
          no_of_products_scanned: 'Nº de produtos digitalizados',
          no_product_within_applied_filter: 'Nenhum produto dentro do filtro aplicado',
          not_scanned_items: 'Itens não digitalizados',
          number_of_products_scanned: 'Número de produtos digitalizados',
          no_product_found: 'Nenhum produto encontrado',
          no_setarticle_found: 'Nenhum artigo de conjunto encontrado',
          no_pallet_found: 'Nenhuma palete encontrada',
          supplier_grade: 'Grau de fornecedor',
          broken_glass: 'Vidro partido',
        },
        Pallets: {
          pallet_product_detail: "Insira os detalhes do produto da palete",
          mandatory_fields_text: "Os campos com * são obrigatórios.",
          add_pallet_product_button: '+ Adicionar produto à palete',
          pallet: 'Palete',
          pallets: 'Paletes',
        },
        SupplyManagement: {
          sidebar_text: 'Gestão de aprovisionamentos',
          supply: 'Fornecimento',
          supply_id: 'ID do fornecimento',
          create_new_supply: 'Criar novo fornecimento',
          new_supply_id: "Novo ID de fornecimento",
          create_supplier_text: 'Criar fornecedor',
          create_supplier_name_Text: 'Nome do fornecedor',
          create_supplier_button: 'Criar',
          add_supply: 'Adicionar fornecimento',
          all_supplies: 'Todos os fornecimentos',
          all_supply_types: 'Todos os tipos de fornecimento',
          all_suppliers: 'Todos os fornecedores',
          manually_uploaded_prices: 'Preços carregados manualmente',
          supplier: 'Fornecedor',
          supplier_name: 'Nome do fornecedor',
          supply_inventory: 'Inventário de fornecimentos',
          supply_management: 'Gestão de aprovisionamentos',
          supply_not_found: 'Fornecimento não encontrado',
          supply_type: 'Tipo de fornecimento',
          supply_type_a: 'Fornecimento tipo A (0 - 1)',
          supply_type_b: 'Fornecimento tipo B (0 - 1)',
          supply_type_buying: 'Tipo de fornecimento Compra (0 - 1)',
          supply_type_c: 'Fornecimento tipo C (0 - 1)',
          supply_type_name: 'Nome do tipo de fornecimento',
          supply_type_not_found: 'Tipo de fornecimento não encontrado',
          total_suppliers: 'Total de fornecedores',
          total_supplies: 'Suprimentos totais',
          total_supply_types: 'Tipos totais de fornecimento',
          edit_supplier_information: 'Editar informações do fornecedor',
          edit_supply: 'Editar fornecimento',
          edit_supply_type: 'Edite o tipo de fornecimento.',
          exclude_supplies: 'Excluir fornecimentos',
          a_grade_percentage: 'Uma nota%',
          b_grade_percentage: 'Nota B%',
          c_grade_percentage: '% de nota C',
          allow_supply_to_sales: 'Tem a certeza de que pretende permitir este fornecimento XYZ para vendas?',
          new_supply: 'Novo fornecimento',
          old_supply: 'Fornecimento antigo',
          edit_supplier_details: 'Edite os seguintes dados do fornecedor.',
          edit_supply_type_details: 'Edite os seguintes detalhes do tipo de fornecimento.',
          provide_supply_id: 'Forneça o ID do fornecimento.',
        },
        SupplyInventory: {
          sidebar_text: 'Inventário de fornecimentos',
          extra_text: 'Produtos extras',
          scanned_text: 'Produtos digitalizados',
          unscanned_text: 'Produtos não digitalizados',
          mark_scanned_completely: 'Tem a certeza de que pretende marcar XYZ como "digitalizado completamente"?',
          restrict_supply_to_sales: 'Tem a certeza de que pretende restringir este fornecimento XYZ às vendas?',
          confirm_delete_item: 'Tem a certeza de que pretende eliminar este item?',
          assign_type_to_new_supply: 'Atribuir tipo a um novo fornecimento',
          scanned: 'Digitalizado',
          unscanned: 'Não verificado',
          extra: 'Extra',
          not_visible_until_completed: 'Não ficarão visíveis até serem concluídos.',
          scanned_items: 'Itens digitalizados',
          scanning_in_progress: 'Verificação em curso',
        },
        CatalogManagement: {
          sidebar_text: 'Gestão de catálogo',
          catalog: 'Catálogo',
          width_primary_cm: 'Largura primária (cm)',
          width_secondary_cm: 'Largura secundária (cm)',
          height_primary_cm: 'Altura primária (cm)',
          height_secondary_cm: 'Altura secundária (cm)',
          depth_primary_cm: 'Profundidade primária (cm)',
          depth_secondary_cm: 'Profundidade secundária (cm)',
          catalog_management: 'Gestão de catálogo',
          depth_primary: 'Profundidade primária',
          depth_secondary: 'Profundidade secundária',
          height_primary: 'Altura primária',
          height_secondary: 'Altura secundária',
          weight_kg: 'Peso (Kg)',
          width_primary: 'Largura primária',
          width_secondary: 'Largura secundária',
          loading_meters: 'Carregando Medidores',
        },
        RightsManagement: {
          sidebar_text: 'Gestão de direitos',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Solicitações de clientes pendentes',
        },
        WebsiteSettings: {
          sidebar_text: 'Configurações do site',
          select_language: 'Selecione Idioma',
          upload: 'Carregar',
          upload_catalog: 'Carregar Catálogo',
          upload_pallet_products: 'Carregar produtos em paletes',
          upload_product: 'Carregar produto',
        },
        OrdersWords:{
          available_items: 'Itens disponíveis',
          available_pallets: 'Paletes disponíveis',
          available_products: 'Produtos Disponíveis',
          created_by: 'Criado por',
          order: 'Ordem',
          order_id: 'ID do pedido',
          ordered: 'Encomendado',
          package_id: 'ID do pacote',
          sales_assistant: 'Assistente de vendas',
          search_by_name: 'Pesquisar por nome',
          search_company: 'Pesquisar empresa',
          select_sales_person: 'Selecione o vendedor',
          select_supplier: 'Selecione o fornecedor',
          set_articles: 'Definir artigos',
          sub_category: 'Subcategoria',
          sub_total_euro: 'Subtotal €',
          sub_cat: 'Subcategoria',
        },
        AnalysisWords:{
          analysis_text: 'Análise',
          category: 'Categoria',
          category_analysis: 'Análise de categoria',
          change_supply_visibility: 'Alterar a visibilidade do fornecimento',
          companies_category_analysis: 'Análise de categoria de empresa',
          companies_grades_analysis: 'Análise de notas de empresas',
          compare_sales_person: 'Compare o vendedor',
          turnover_monthly: 'Faturamento Mensal',
          turnover_yearly: 'Volume de negócios anual',
          profit_monthly: 'Lucro Mensal',
          profit_yearly: 'Lucro Anual',
        },
        CommonWords: {
          apply: 'Aplicar',
          done: 'Feito',
          edit: 'Editar',
          assign: 'Atribuir',
          default: 'Padrão',
          delete: 'Excluir',
          cancel: 'Cancelar',
          date: 'Data',
          company: 'Empresa',
          compare: 'Comparar',
          email: 'E-mail',
          image: 'Imagem',
          images: 'Imagens',
          model: 'Modelo',
          month: 'Mês',
          group: 'Grupo',
          backoffice: 'Backoffice',
          brand_analysis: 'Análise de marca',
          brands: 'Marcas',
          buying_percentage: 'Comprando %',
          buying_online_price: 'Comprar preço on-line',
          buying_price: 'Preço de compra',
          cart: 'Carrinho',
          confirm: 'Confirmar',
          create: 'Criar',
          discount_percentage: '% de desconto',
          items_per_page: 'Artigos por página:',
          navigation: 'Navegação',
          select: 'Selecione',
          view: 'Visualizar',
          role: 'Papel',
          sales: 'Vendas',
          yes: 'Sim',
        },
      },
    }
  },
  pol: {
    translation: {
      authentication: {
          register: {
              info: 'Proszę wypełnić poniższy formularz, a my wkrótce się z Tobą skontaktujemy.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Główny panel',
        },
        Analytics: {
          sidebar_text: 'Analityka',
          analytics: 'Analityka',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Zysk i obrót',
        },
        ScanningTime: {
          sidebar_text: 'Średni czas skanowania (s)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Porównaj Sprzedawca',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Analiza kategorii',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Analiza marki',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Analiza ocen sprzedawców',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Porównanie firm',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Analiza kategorii firm',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Analiza marek firm',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Analiza ocen firm',
        },
        SupplyAnalysis: {
          sidebar_text: 'Analiza oferty',
          searchbar_text: 'Szukaj',
        },
        StockAnalysis: {
          sidebar_text: 'Analiza zapasów',
          searchbar_text: 'Wybierz magazyn',
        },
        MainDashboard: {
          sidebar_text: 'Główny panel',
        },
        Navigation: {
          sidebar_text: 'Nawigacja',
        },
        Users: {
          sidebar_text: 'Użytkownicy',
          customers_data: 'Dane klienta',
          edit_info: 'Edytuj informacje',
          delete_customer: 'Usuwać',
          actions: 'Działania',
          active: 'Aktywny',
          add_new_staff_user: 'Dodaj nowego użytkownika personelu',
          add_supply_type: 'Dodaj typ dostawy',
          add_user: 'Dodaj użytkownika',
          admin: 'Administrator',
          user: 'Użytkownik',
          enter_first_name: 'Wpisz imię',
          enter_last_name: 'Wprowadź nazwisko',
          enter_user_password: 'Wprowadź hasło użytkownika',
          not_active: 'Nieaktywny',
          pending_customer_requests: 'Oczekujące żądania klientów',
          unable_to_log_in: 'Nie można zalogować się przy użyciu podanych danych uwierzytelniających.',
        },
        Team: {
          sidebar_text: 'Zespół'
        },
        Customers: {
          sidebar_text: 'Klienci',
          main_text: 'Dane klienta',
          my_packages: 'Moje pakiety',
          my_orders: 'Moje zamówienia',
          my_orders_details: 'Szczegóły zamówienia',
          add_customer: 'Dodaj klientów',
          edit_info: 'Edytuj informacje',
          delete_customer: 'Usuwać',
          customer: 'Klient',
          customer_email: 'E-mail klienta',
          customers: 'Klienci',
          phone_number: 'Numer telefonu',
        },
        Packages: {
          sidebar_text: 'Pakiety',
          create_package_text: 'Utwórz pakiet',
          warehouse_text: 'Magazyn',
          customer_text: 'Klient',
          truck_capacity_text: 'Pojemność ciężarówki (0 m)',
          selling_price_text: 'Cena sprzedaży',
          brands_text: 'Marki',
          fetch_products_button_text: 'Stosować',
          fetch_from_rest: 'Przynieś z odpoczynku',
          supply_id_please: 'Proszę podać identyfikator dostawy.',
          pair_all_button: 'Przypisz wszystko',
          pair_button: 'Przypisz',
          smart_pair_switch: 'Inteligentne przypisywanie',
          edit_info: 'Edytuj informacje',
          are_you_sure: 'Czy jesteś pewien?',
          article: 'Artykuł',
          date_from: 'Data od',
          date_to: 'Data do',
          extra: 'Dodatkowy',
          extra_items: 'Dodatkowe przedmioty',
          grade_points_range: 'Zakres punktów ocen',
          grand_total_euro: 'Łączna suma €',
          pending_packages: 'Oczekujące pakiety',
          status: 'Status',
          place_order: 'Złożyć zamówienie',
          unselect_all: 'Odznacz wszystko',
          total_buying_offer: 'Całkowita oferta zakupu:',
          total_buying_price: 'Całkowita cena zakupu:',
          total_items: 'Łączna liczba elementów',
          total_packages: 'Razem pakiety',
          select_pallet_supply: 'Proszę wybrać dostawę paletową',
          please_select_supplier: 'Proszę wybrać dostawcę',
        },
        AutomatedPackages: {
          sidebar_text: 'Zautomatyzowane pakiety'
        },
        PendingPackages: {
          sidebar_text: 'Oczekujące pakiety',
        },
        OrderRequests: {
          sidebar_text: 'Zamówienia',
        },
        Warehouse: {
          sidebar_text: 'Magazyn',
          create_warehouse_order: 'Utwórz zamówienie magazynowe',
          allow_warehouse_scan: 'Czy na pewno chcesz zezwolić magazynowi na skanowanie tej dostawy?',
          warehouse: 'Magazyn',
          warehouse_package: 'Pakiet magazynowy',
          warehouse_manager: 'Kierownik Magazynu',
        },
        Products: {
          sidebar_text: 'Produkty',
          online_price: 'Cena w Internecie',
          price_euro: 'Cena €',
          product_id: 'Identyfikator produktu',
          product_id_search: 'Wyszukiwanie identyfikatora produktu',
          products: 'Produkty',
          products_quantity: 'Ilość produktów',
          products_selected: 'Wybrane produkty',
          selling_price: 'Cena sprzedaży',
          items_selected_percentage: 'Wybrane elementy %',
          min_3_digits: 'Min. 3 cyfry',
          minimum_3_digits: 'Minimum 3 cyfry',
          no_of_products_scanned: 'Liczba zeskanowanych produktów',
          no_product_within_applied_filter: 'Brak produktu w zastosowanym filtrze',
          not_scanned_items: 'Niezeskanowane elementy',
          number_of_products_scanned: 'Liczba zeskanowanych produktów',
          no_product_found: 'Nie znaleziono produktu',
          no_setarticle_found: 'Nie znaleziono zestawu-artykułu',
          no_pallet_found: 'Nie znaleziono palety',
          supplier_grade: 'Klasa dostawcy',
          broken_glass: 'Stłuczone szkło',
        },
        Pallets: {
          pallet_product_detail: "Wprowadź szczegóły produktu paletowego",
          mandatory_fields_text: "Pola oznaczone * są obowiązkowe.",
          add_pallet_product_button: '+ Dodaj produkt paletowy',
          pallet: 'Paleta',
          pallets: 'Palety',
        },
        SupplyManagement: {
          sidebar_text: 'Zarządzanie dostawami',
          supply: 'Dostarczać',
          supply_id: 'Identyfikator dostawy',
          create_new_supply: 'Utwórz nową dostawę',
          new_supply_id: "Nowy identyfikator dostawy",
          create_supplier_text: 'Utwórz dostawcę',
          create_supplier_name_Text: 'Nazwa dostawcy',
          create_supplier_button: 'Tworzyć',
          add_supply: 'Dodaj dostawę',
          all_supplies: 'Wszystkie dostawy',
          all_supply_types: 'Wszystkie typy dostaw',
          all_suppliers: 'Wszyscy dostawcy',
          manually_uploaded_prices: 'Ręcznie przesłane ceny',
          supplier: 'Dostawca',
          supplier_name: 'Nazwa dostawcy',
          supply_inventory: 'Zapasy zaopatrzenia',
          supply_management: 'Zarządzanie dostawami',
          supply_not_found: 'Nie znaleziono podaży',
          supply_type: 'Rodzaj dostawy',
          supply_type_a: 'Typ zasilania A (0 - 1)',
          supply_type_b: 'Typ zasilania B (0 - 1)',
          supply_type_buying: 'Rodzaj zaopatrzenia Kupno (0 - 1)',
          supply_type_c: 'Typ zasilania C (0 - 1)',
          supply_type_name: 'Nazwa typu dostawy',
          supply_type_not_found: 'Nie znaleziono typu dostawy',
          total_suppliers: 'Łączni dostawcy',
          total_supplies: 'Całkowite dostawy',
          total_supply_types: 'Łączne rodzaje dostaw',
          edit_supplier_information: 'Edytuj informacje o dostawcy',
          edit_supply: 'Edytuj podaż',
          edit_supply_type: 'Edytuj typ dostawy.',
          exclude_supplies: 'Wyklucz materiały eksploatacyjne',
          a_grade_percentage: 'Stopień A%',
          b_grade_percentage: 'Stopień B%',
          c_grade_percentage: 'Stopień C%',
          allow_supply_to_sales: 'Czy na pewno chcesz dopuścić tę dostawę XYZ do sprzedaży?',
          new_supply: 'Nowa dostawa',
          old_supply: 'Stara dostawa',
          edit_supplier_details: 'Proszę edytować następujące dane dostawcy.',
          edit_supply_type_details: 'Zmień następujące szczegóły dotyczące rodzaju dostawy.',
          provide_supply_id: 'Proszę podać identyfikator dostawy.',
        },
        SupplyInventory: {
          sidebar_text: 'Zapasy zaopatrzenia',
          extra_text: 'Dodatkowe produkty',
          scanned_text: 'Zeskanowane produkty',
          unscanned_text: 'Niezeskanowane produkty',
          mark_scanned_completely: 'Czy na pewno chcesz oznaczyć XYZ jako „całkowicie przeskanowany”?',
          restrict_supply_to_sales: 'Czy na pewno chcesz ograniczyć tę dostawę XYZ do sprzedaży?',
          confirm_delete_item: 'Czy na pewno chcesz usunąć ten element?',
          assign_type_to_new_supply: 'Przypisz typ do nowej dostawy',
          scanned: 'Zeskanowano',
          unscanned: 'Niezeskanowane',
          extra: 'Dodatkowy',
          not_visible_until_completed: 'Nie będą widoczne, dopóki nie zostaną ukończone.',
          scanned_items: 'Zeskanowane elementy',
          scanning_in_progress: 'Skanowanie w toku',
        },

        CatalogManagement: {
          sidebar_text: 'Zarządzanie katalogiem',
          catalog: 'Katalog',
          width_primary_cm: 'Szerokość podstawowa (cm)',
          width_secondary_cm: 'Szerokość drugorzędna (cm)',
          height_primary_cm: 'Wysokość podstawowa (cm)',
          height_secondary_cm: 'Wysokość drugorzędna (cm)',
          depth_primary_cm: 'Głębokość pierwotna (cm)',
          depth_secondary_cm: 'Głębokość wtórna (cm)',
          catalog_management: 'Zarządzanie katalogiem',
          depth_primary: 'Głębokość pierwotna',
          depth_secondary: 'Głębokość wtórna',
          height_primary: 'Wysokość podstawowa',
          height_secondary: 'Wysokość drugorzędna',
          weight_kg: 'Waga (kg)',
          width_primary: 'Szerokość podstawowa',
          width_secondary: 'Szerokość drugorzędna',
          loading_meters: 'Ładowanie liczników',
        },
        RightsManagement: {
          sidebar_text: 'Zarządzanie prawami',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Oczekujące żądania klientów',
        },
        WebsiteSettings: {
          sidebar_text: 'Ustawienia strony',
          select_language: 'Wybierz Język',
          upload: 'Wgrywać',
          upload_catalog: 'Prześlij katalog',
          upload_pallet_products: 'Prześlij produkty paletowe',
          upload_product: 'Prześlij produkt',
        },
        OrdersWords:{
          available_items: 'Dostępne pozycje',
          available_pallets: 'Dostępne palety',
          available_products: 'Dostępne produkty',
          created_by: 'Stworzony przez',
          order: 'Zamówienie',
          order_id: 'Identyfikator zamówienia',
          ordered: 'Zamówione',
          package_id: 'Identyfikator pakietu',
          sales_assistant: 'Asystent sprzedaży',
          search_by_name: 'Szukaj według nazwy',
          search_company: 'Wyszukaj firmę',
          select_sales_person: 'Wybierz sprzedawcę',
          select_supplier: 'Wybierz dostawcę',
          set_articles: 'Ustaw artykuły',
          sub_category: 'Podkategoria',
          sub_total_euro: 'Suma częściowa €',
          sub_cat: 'Podkat',
        },
        AnalysisWords:{
          analysis_text: 'Analiza',
          category: 'Kategoria',
          category_analysis: 'Analiza kategorii',
          change_supply_visibility: 'Zmień widoczność dostaw',
          companies_category_analysis: 'Analiza kategorii firm',
          companies_grades_analysis: 'Analiza ocen firm',
          compare_sales_person: 'Porównaj sprzedawcę',
          turnover_monthly: 'Obrót miesięczny',
          turnover_yearly: 'Obrót roczny',
          profit_monthly: 'Zysk miesięczny',
          profit_yearly: 'Zysk roczny',
        },
        CommonWords: {
          apply: 'Stosować',
          done: 'Zrobione',
          edit: 'Redagować',
          assign: 'Przydzielać',
          default: 'Domyślny',
          delete: 'Usuwać',
          cancel: 'Anulować',
          date: 'Data',
          company: 'Firma',
          compare: 'Porównywać',
          email: 'E-mail',
          image: 'Obraz',
          images: 'Obrazy',
          model: 'Model',
          month: 'Miesiąc',
          group: 'Grupa',
          backoffice: 'Powrót do biura',
          brand_analysis: 'Analiza marki',
          brands: 'Marki',
          buying_percentage: 'Kupowanie %',
          buying_online_price: 'Cena zakupu w internecie',
          buying_price: 'Cena zakupu',
          cart: 'Wózek',
          confirm: 'Potwierdzać',
          create: 'Tworzyć',
          discount_percentage: 'Rabat %',
          items_per_page: 'Elementy na stronę:',
          navigation: 'Nawigacja',
          select: 'Wybierać',
          view: 'Pogląd',
          role: 'Rola',
          sales: 'Obroty',
          yes: 'Tak',
        },
      },
    }
  },
  bul: {
    translation: {
      authentication: {
          register: {
              info: 'Моля, попълнете формуляра по-долу и ние ще се свържем с вас скоро.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
        },
        Analytics: {
          sidebar_text: 'Анализ',
          analytics: 'Анализ',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Печалба и оборот',
        },
        ScanningTime: {
          sidebar_text: 'Средно време за сканиране (сек)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Сравнете лице по продажбите',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Анализ на категории',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Анализ на марката',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Анализ на оценките на търговците',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Сравнение на компаниите',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Анализ на категории компании',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Анализ на марките на компаниите',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Анализ на оценките на компаниите',
        },
        SupplyAnalysis: {
          sidebar_text: 'Анализ на офертата',
          searchbar_text: 'Търсене',
        },
        StockAnalysis: {
          sidebar_text: 'Анализ на запасите',
          searchbar_text: 'Изберете склад',
        },
        MainDashboard: {
          sidebar_text: 'Главно табло за управление',
        },
        Navigation: {
          sidebar_text: 'Навигация',
        },
        Users: {
          sidebar_text: 'Потребители',
          customers_data: 'Клиентски данни',
          edit_info: 'Редактиране на информация',
          delete_customer: 'Изтриване',
          actions: 'Действия',
          active: 'Активен',
          add_new_staff_user: 'Добавете нов потребител на персонала',
          add_supply_type: 'Добавете тип доставка',
          add_user: 'Добавете потребител',
          admin: 'Админ',
          user: 'Потребител',
          enter_first_name: 'Въведете собствено име',
          enter_last_name: 'Въведете фамилия',
          enter_user_password: 'Въведете потребителска парола',
          not_active: 'Не е активно',
          pending_customer_requests: 'Очакващи клиентски заявки',
          unable_to_log_in: 'Не може да се влезе с предоставените идентификационни данни.',
        },
        Team: {
          sidebar_text: 'Екип'
        },
        Customers: {
          sidebar_text: 'Клиенти',
          main_text: 'Клиентски данни',
          my_packages: 'Моите пакети',
          my_orders: 'Моите поръчки',
          my_orders_details: 'Детайли на поръчките',
          add_customer: 'Добавете клиенти',
          edit_info: 'Редактиране на информация',
          delete_customer: 'Изтриване',
          customer: 'Клиент',
          customer_email: 'Имейл на клиента',
          customers: 'Клиенти',
          phone_number: 'телефон #',
        },
        Packages: {
          sidebar_text: 'Пакети',
          create_package_text: 'Създаване на пакет',
          warehouse_text: 'Склад',
          customer_text: 'Клиент',
          truck_capacity_text: 'Капацитет на камиона (0 m)',
          selling_price_text: 'Продажна цена',
          brands_text: 'Марки',
          fetch_products_button_text: 'Кандидатствайте',
          fetch_from_rest: 'Извличане от почивка',
          supply_id_please: 'Моля, предоставете ID на доставката.',
          pair_all_button: 'Сдвоете всичко',
          pair_button: 'Сдвоете',
          smart_pair_switch: 'Интелигентно сдвояване',
          edit_info: 'Редактиране на информация',
          are_you_sure: 'сигурен ли си?',
          article: 'статия',
          date_from: 'Дата от',
          date_to: 'Дата до',
          extra: 'Екстра',
          extra_items: 'Допълнителни елементи',
          grade_points_range: 'Диапазон на оценките',
          grand_total_euro: 'Общо €',
          pending_packages: 'Чакащи пакети',
          status: 'Статус',
          place_order: 'направете поръчка',
          unselect_all: 'премахване на избора на всички',
          total_buying_offer: 'Общо предложение за покупка:',
          total_buying_price: 'Обща покупна цена:',
          total_items: 'Общо елементи',
          total_packages: 'Общо пакети',
          select_pallet_supply: 'Моля, изберете доставка на палети',
          please_select_supplier: 'Моля изберете доставчик',
        },
        AutomatedPackages: {
          sidebar_text: 'Автоматизирани пакети'
        },
        PendingPackages: {
          sidebar_text: 'Чакащи пакети',
        },
        OrderRequests: {
          sidebar_text: 'Заявки за поръчки',
        },
        Warehouse: {
          sidebar_text: 'Склад',
          create_warehouse_order: 'Създаване на складова поръчка',
          allow_warehouse_scan: 'Сигурни ли сте, че искате да разрешите на склада да сканира тази доставка?',
          warehouse: 'Склад',
          warehouse_package: 'Складов пакет',
          warehouse_manager: 'WarehouseManager',
        },
        Products: {
          sidebar_text: 'Продукти',
          online_price: 'Онлайн цена',
          price_euro: 'Цена €',
          product_id: 'ID на продукта',
          product_id_search: 'Търсене на идентификатор на продукт',
          products: 'Продукти',
          products_quantity: 'Продукти Количество',
          products_selected: 'Избрани продукти',
          selling_price: 'Продажна цена',
          items_selected_percentage: 'Избрани елементи %',
          min_3_digits: 'Минимум 3 цифри',
          minimum_3_digits: 'Минимум 3 цифри',
          no_of_products_scanned: 'Брой сканирани продукти',
          no_product_within_applied_filter: 'Няма продукт в приложения филтър',
          not_scanned_items: 'Не сканирани елементи',
          number_of_products_scanned: 'Брой сканирани продукти',
          no_product_found: 'Не е намерен продукт',
          no_setarticle_found: 'Не е намерен комплект-статия',
          no_pallet_found: 'Не е намерен палет',
          supplier_grade: 'Клас на доставчика',
          broken_glass: 'Счупено стъкло',
        },
        Pallets: {
          pallet_product_detail: "Въведете подробности за продукта на палета",
          mandatory_fields_text: "Полетата със * са задължителни.",
          add_pallet_product_button: '+ Добавете палетен продукт',
          pallet: 'Палет',
          pallets: 'Палети',
        },
        SupplyManagement: {
          sidebar_text: 'Управление на доставките',
          supply: 'Снабдяване',
          supply_id: 'ID на доставката',
          create_new_supply: 'Създайте нова доставка',
          new_supply_id: "Нов идентификатор на доставка",
          create_supplier_text: 'Създаване на нов доставчик',
          create_supplier_name_Text: 'Име на доставчика',
          create_supplier_button: 'Създаване',
          add_supply: 'Добавяне на доставка',
          all_supplies: 'Всички доставки',
          all_supply_types: 'Всички видове доставки',
          all_suppliers: 'Всички доставчици',
          manually_uploaded_prices: 'Ръчно качени цени',
          supplier: 'Доставчик',
          supplier_name: 'Име на доставчика',
          supply_inventory: 'Снабдяване с инвентар',
          supply_management: 'Управление на доставките',
          supply_not_found: 'Доставката не е намерена',
          supply_type: 'Тип доставка',
          supply_type_a: 'Тип захранване A (0 - 1)',
          supply_type_b: 'Тип захранване B (0 - 1)',
          supply_type_buying: 'Тип доставка Купуване (0 - 1)',
          supply_type_c: 'Тип захранване C (0 - 1)',
          supply_type_name: 'Име на типа доставка',
          supply_type_not_found: 'Типът консуматив не е намерен',
          total_suppliers: 'Общо доставчици',
          total_supplies: 'Общо доставки',
          total_supply_types: 'Общо видове доставки',
          edit_supplier_information: 'Редактирайте информацията за доставчика',
          edit_supply: 'Редактиране на доставките',
          edit_supply_type: 'Редактиране на типа доставка.',
          exclude_supplies: 'Изключете консумативи',
          a_grade_percentage: 'Оценка A%',
          b_grade_percentage: 'Клас B%',
          c_grade_percentage: 'Клас C%',
          allow_supply_to_sales: 'Сигурни ли сте, че искате да разрешите тази доставка XYZ за продажби?',
          new_supply: 'Нова доставка',
          old_supply: 'Стари доставки',
          edit_supplier_details: 'Моля, редактирайте следните данни за доставчика.',
          edit_supply_type_details: 'Моля, редактирайте следните подробности за типа доставка.',
          provide_supply_id: 'Моля, предоставете ID на доставката.',
        },
        SupplyInventory: {
          sidebar_text: 'Снабдяване с инвентар',
          extra_text: 'Допълнителни продукти',
          scanned_text: 'Сканирани продукти',
          unscanned_text: 'Несканирани продукти',
          mark_scanned_completely: 'Сигурни ли сте, че искате да маркирате XYZ като „напълно сканирано“?',
          restrict_supply_to_sales: 'Сигурни ли сте, че искате да ограничите тази доставка XYZ до продажби?',
          confirm_delete_item: 'Сигурни ли сте, че искате да изтриете този елемент?',
          assign_type_to_new_supply: 'Задайте тип на нова доставка',
          scanned: 'Сканирани',
          unscanned: 'Несканиран',
          extra: 'Екстра',
          not_visible_until_completed: 'Те няма да се виждат, докато не бъдат завършени.',
          scanned_items: 'Сканирани елементи',
          scanning_in_progress: 'В ход е сканиране',
        },
        CatalogManagement: {
          sidebar_text: 'Управление на каталог',
          catalog: 'Каталог',
          width_primary_cm: 'Основна ширина (см)',
          width_secondary_cm: 'Ширина вторична (см)',
          height_primary_cm: 'Основна височина (см)',
          height_secondary_cm: 'Височина вторична (см)',
          depth_primary_cm: 'Дълбочина първична (см)',
          depth_secondary_cm: 'Дълбочина вторична (см)',
          catalog_management: 'Управление на каталог',
          depth_primary: 'Дълбочина първична',
          depth_secondary: 'Дълбочина вторична',
          height_primary: 'Височина основна',
          height_secondary: 'Височина вторична',
          weight_kg: 'Тегло (кг)',
          width_primary: 'Основна ширина',
          width_secondary: 'Ширина вторична',
          loading_meters: 'Зареждане на метри',
        },
        RightsManagement: {
          sidebar_text: 'Управление на правата',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Очакващи клиентски заявки',
        },
        WebsiteSettings: {
          sidebar_text: 'Настройки на уебсайта',
          select_language: 'Изберете език',
          upload: 'качване',
          upload_catalog: 'Качване на каталог',
          upload_pallet_products: 'Качете палетни продукти',
          upload_product: 'Качване на продукт',
        },
        OrdersWords:{
          available_items: 'Налични артикули',
          available_pallets: 'Налични палети',
          available_products: 'Налични продукти',
          created_by: 'Създаден от',
          order: 'ред',
          order_id: 'ID на поръчката',
          ordered: 'Поръчан',
          package_id: 'идентификатор на пакета',
          sales_assistant: 'Продавач-асистент',
          search_by_name: 'Търсене по име',
          search_company: 'Търсене на фирма',
          select_sales_person: 'Изберете продавач',
          select_supplier: 'Изберете доставчик',
          set_articles: 'Задайте статии',
          sub_category: 'Подкатегория',
          sub_total_euro: 'Междинна сума €',
          sub_cat: 'Под кат',
        },
        AnalysisWords:{
          analysis_text: 'Анализ',
          category: 'Категория',
          category_analysis: 'Анализ на категории',
          change_supply_visibility: 'Промяна на видимостта на доставките',
          companies_category_analysis: 'Анализ на категории компании',
          companies_grades_analysis: 'Анализ на оценките на компаниите',
          compare_sales_person: 'Сравнете лице по продажбите',
          turnover_monthly: 'Месечен оборот',
          turnover_yearly: 'Годишен оборот',
          profit_monthly: 'Месечна печалба',
          profit_yearly: 'Годишна печалба',
        },
        CommonWords: {
          apply: 'Кандидатствайте',
          done: 'Готово',
          edit: 'Редактиране',
          assign: 'Присвояване',
          default: 'По подразбиране',
          delete: 'Изтриване',
          cancel: 'Отказ',
          date: 'Дата',
          company: 'Компания',
          compare: 'Сравнете',
          email: 'Имейл',
          image: 'Изображение',
          images: 'Изображения',
          model: 'Модел',
          month: 'месец',
          group: 'Група',
          backoffice: 'BackOffice',
          brand_analysis: 'Анализ на марката',
          brands: 'Марки',
          buying_percentage: 'Купуване %',
          buying_online_price: 'Закупуване онлайн цена',
          buying_price: 'Изкупна цена',
          cart: 'Количка',
          confirm: 'потвърди',
          create: 'Създавайте',
          discount_percentage: '% отстъпка',
          items_per_page: 'Елементи на страница:',
          navigation: 'Навигация',
          select: 'Изберете',
          view: 'Преглед',
          role: 'Роля',
          sales: 'Продажби',
          yes: 'да',
        },
      },
    }
  },
  cz: {
    translation: {
      authentication: {
          register: {
              info: 'Vyplňte prosím níže uvedený formulář a brzy vás budeme kontaktovat.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
        },
        Analytics: {
          sidebar_text: 'Analytics',
          analytics: 'Analytics',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Zisk a obrat',
        },
        ScanningTime: {
          sidebar_text: 'Průměrná doba skenování (s)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Porovnat Prodejce',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Analýza kategorií',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Analýza značky',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Analýza hodnocení prodejců',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Porovnání společností',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Analýza kategorií společností',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Analýza značek společností',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Analýza hodnocení společností',
        },
        SupplyAnalysis: {
          sidebar_text: 'Analýza nabídky',
          searchbar_text: 'Vyhledávání',
        },
        StockAnalysis: {
          sidebar_text: 'Analýza zásob',
          searchbar_text: 'Vyberte sklad',
        },
        MainDashboard: {
          sidebar_text: 'Hlavní palubní deska',
        },
        Navigation: {
          sidebar_text: 'Navigace',
        },
        Users: {
          sidebar_text: 'Uživatelé',
          customers_data: 'Zákaznická data',
          edit_info: 'Upravit informace',
          delete_customer: 'Vymazat',
          actions: 'Akce',
          active: 'Aktivní',
          add_new_staff_user: 'Přidat nového zaměstnance',
          add_supply_type: 'Přidejte typ dodávky',
          add_user: 'Přidat uživatele',
          admin: 'Admin',
          user: 'Uživatel',
          enter_first_name: 'Zadejte křestní jméno',
          enter_last_name: 'Zadejte příjmení',
          enter_user_password: 'Zadejte uživatelské heslo',
          not_active: 'Není aktivní',
          pending_customer_requests: 'Nevyřízené požadavky zákazníků',
          unable_to_log_in: 'Nelze se přihlásit pomocí zadaných přihlašovacích údajů.',
        },
        Team: {
          sidebar_text: 'Tým'
        },
        Customers: {
          sidebar_text: 'Zákazníci',
          main_text: 'Zákaznická data',
          my_packages: 'Moje balíčky',
          my_orders: 'Moje objednávky',
          my_orders_details: 'Detaily objednávky',
          add_customer: 'Přidejte zákazníky',
          edit_info: 'Upravit informace',
          delete_customer: 'Vymazat',
          customer: 'Zákazník',
          customer_email: 'E-mail zákazníka',
          customers: 'Zákazníci',
          phone_number: 'číslo telefonu',
        },
        Packages: {
          sidebar_text: 'Balíčky',
          create_package_text: 'Vytvořit balíček',
          warehouse_text: 'Sklad',
          customer_text: 'Zákazník',
          truck_capacity_text: 'Kapacita nákladního auta (0 m)',
          selling_price_text: 'Prodejní cena',
          brands_text: 'Značky',
          fetch_products_button_text: 'Použít',
          fetch_from_rest: 'Aport z klidu',
          supply_id_please: 'Uveďte prosím ID dodávky.',
          pair_all_button: 'Spárovat vše',
          pair_button: 'Pár',
          smart_pair_switch: 'Inteligentní párování',
          edit_info: 'Upravit informace',
          are_you_sure: 'jsi si jistý?',
          article: 'Článek',
          date_from: 'Datum od',
          date_to: 'Datum do',
          extra: 'Další',
          extra_items: 'Položky navíc',
          grade_points_range: 'Rozsah klasifikačních bodů',
          grand_total_euro: 'Celková částka €',
          pending_packages: 'Nevyřízené balíčky',
          status: 'Postavení',
          place_order: 'zadat objednávku',
          unselect_all: 'zrušte výběr všech',
          total_buying_offer: 'Celková nabídka nákupu:',
          total_buying_price: 'Celková kupní cena:',
          total_items: 'Celkem položek',
          total_packages: 'Celkový počet balíčků',
          select_pallet_supply: 'Vyberte prosím dodávku palet',
          please_select_supplier: 'Vyberte dodavatele',
        },
        AutomatedPackages: {
          sidebar_text: 'Automatizované balíčky'
        },
        PendingPackages: {
          sidebar_text: 'Nevyřízené balíčky',
        },
        OrderRequests: {
          sidebar_text: 'Objednávky',
        },
        Warehouse: {
          sidebar_text: 'Sklad',
          create_warehouse_order: 'Vytvořit skladovou objednávku',
          allow_warehouse_scan: 'Opravdu chcete skladu povolit skenování této zásoby?',
          warehouse: 'Sklad',
          warehouse_package: 'Skladový balíček',
          warehouse_manager: 'Správce skladu',
        },
        Products: {
          sidebar_text: 'Produkty',
          online_price: 'Online cena',
          price_euro: 'Cena €',
          product_id: 'ID produktu',
          product_id_search: 'Hledání ID produktu',
          products: 'Produkty',
          products_quantity: 'Množství produktů',
          products_selected: 'Vybrané produkty',
          selling_price: 'Prodejní cena',
          items_selected_percentage: 'Vybrané položky %',
          min_3_digits: 'Minimálně 3 číslice',
          minimum_3_digits: 'Minimálně 3 číslice',
          no_of_products_scanned: 'Počet naskenovaných produktů',
          no_product_within_applied_filter: 'V použitém filtru není žádný produkt',
          not_scanned_items: 'Nenaskenované položky',
          number_of_products_scanned: 'Počet naskenovaných produktů',
          no_product_found: 'Nebyl nalezen žádný produkt',
          no_setarticle_found: 'Nebyl nalezen žádný set-článek',
          no_pallet_found: 'Nebyla nalezena žádná paleta',
          supplier_grade: 'Třída dodavatele',
          broken_glass: 'Rozbité sklo',
        },
        Pallets: {
          pallet_product_detail: "Zadejte podrobnosti o paletovém produktu",
          mandatory_fields_text: "Pole označená * jsou povinná.",
          add_pallet_product_button: '+ Přidat paletový produkt',
          pallet: 'Paleta',
          pallets: 'Palety',
        },
        SupplyManagement: {
          sidebar_text: 'Řízení zásobování',
          supply: 'Zásobování',
          supply_id: 'ID dodávky',
          create_new_supply: 'Vytvořit novou dodávku',
          new_supply_id: "Nové ID dodávky",
          create_supplier_text: 'Vytvořit nového dodavatele',
          create_supplier_name_Text: 'Jméno dodavatele',
          create_supplier_button: 'Vytvořit',
          add_supply: 'Přidat dodávku',
          all_supplies: 'Všechny dodávky',
          all_supply_types: 'Všechny typy dodávek',
          all_suppliers: 'Všichni dodavatelé',
          manually_uploaded_prices: 'Manuálně nahrané ceny',
          supplier: 'Dodavatel',
          supplier_name: 'Jméno dodavatele',
          supply_inventory: 'Zásobování zásob',
          supply_management: 'Řízení zásobování',
          supply_not_found: 'Zásoba nenalezena',
          supply_type: 'Typ dodávky',
          supply_type_a: 'Typ dodávky A (0 - 1)',
          supply_type_b: 'Typ dodávky B (0 - 1)',
          supply_type_buying: 'Typ dodávky Nákup (0 - 1)',
          supply_type_c: 'Typ dodávky C (0 - 1)',
          supply_type_name: 'Název typu dodávky',
          supply_type_not_found: 'Typ dodávky nenalezen',
          total_suppliers: 'Celkem dodavatelů',
          total_supplies: 'Celkové zásoby',
          total_supply_types: 'Celkové typy dodávek',
          edit_supplier_information: 'Upravit informace o dodavateli',
          edit_supply: 'Upravit nabídku',
          edit_supply_type: 'Upravit typ dodávky.',
          exclude_supplies: 'Vyloučit zásoby',
          a_grade_percentage: 'A stupeň %',
          b_grade_percentage: 'B stupeň %',
          c_grade_percentage: 'C stupeň %',
          allow_supply_to_sales: 'Jste si jisti, že chcete povolit prodej této dodávky XYZ?',
          new_supply: 'Nová zásoba',
          old_supply: 'Stará zásoba',
          edit_supplier_details: 'Upravte prosím následující údaje dodavatele.',
          edit_supply_type_details: 'Upravte prosím následující podrobnosti o typu spotřebního materiálu.',
          provide_supply_id: 'Uveďte prosím ID dodávky.',
        },
        SupplyInventory: {
          sidebar_text: 'Zásobování zásob',
          extra_text: 'Produkty navíc',
          scanned_text: 'Naskenované produkty',
          unscanned_text: 'Nenaskenované produkty',
          mark_scanned_completely: 'Jste si jisti, že chcete označit XYZ jako „úplně naskenováno“?',
          restrict_supply_to_sales: 'Jste si jisti, že chcete omezit tuto dodávku XYZ na prodej?',
          confirm_delete_item: 'Opravdu chcete tuto položku smazat?',
          assign_type_to_new_supply: 'Přiřaďte typ nové dodávce',
          scanned: 'Naskenováno',
          unscanned: 'Nenaskenováno',
          extra: 'Další',
          not_visible_until_completed: 'Budou viditelné až po dokončení.',
          scanned_items: 'Naskenované položky',
          scanning_in_progress: 'Probíhá skenování',
        },
        CatalogManagement: {
          sidebar_text: 'Správa katalogu',
          catalog: 'Katalog',
          width_primary_cm: 'Primární šířka (cm)',
          width_secondary_cm: 'Sekundární šířka (cm)',
          height_primary_cm: 'Primární výška (cm)',
          height_secondary_cm: 'Sekundární výška (cm)',
          depth_primary_cm: 'Primární hloubka (cm)',
          depth_secondary_cm: 'Hloubka sekundární (cm)',
          catalog_management: 'Správa katalogu',
          depth_primary: 'Primární hloubka',
          depth_secondary: 'Hloubka sekundární',
          height_primary: 'Primární výška',
          height_secondary: 'Výška sekundární',
          weight_kg: 'Hmotnost (kg)',
          width_primary: 'Primární šířka',
          width_secondary: 'Sekundární šířka',
          loading_meters: 'Načítání měřidel',
        },
        RightsManagement: {
          sidebar_text: 'Správa práv',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Nevyřízené požadavky zákazníků',
        },
        WebsiteSettings: {
          sidebar_text: 'Nastavení webu',
          select_language: 'Vyberte jazyk',
          upload: 'Nahrát',
          upload_catalog: 'Nahrát katalog',
          upload_pallet_products: 'Nahrajte paletové produkty',
          upload_product: 'Nahrajte produkt',
        },
        OrdersWords:{
          available_items: 'Dostupné položky',
          available_pallets: 'Dostupné palety',
          available_products: 'Dostupné produkty',
          created_by: 'Vytvořil',
          order: 'Objednávka',
          order_id: 'ID objednávky',
          ordered: 'Objednáno',
          package_id: 'ID balíčku',
          sales_assistant: 'Prodejní asistent',
          search_by_name: 'Hledejte podle jména',
          search_company: 'Hledat společnost',
          select_sales_person: 'Vyberte prodejce',
          select_supplier: 'Vyberte dodavatele',
          set_articles: 'Nastavit články',
          sub_category: 'Podkategorie',
          sub_total_euro: 'Mezisoučet €',
          sub_cat: 'Sub Cat',
        },
        AnalysisWords:{
          analysis_text: 'Analýza',
          category: 'Kategorie',
          category_analysis: 'Analýza kategorií',
          change_supply_visibility: 'Změnit viditelnost dodávky',
          companies_category_analysis: 'Analýza kategorií společností',
          companies_grades_analysis: 'Analýza hodnocení společností',
          compare_sales_person: 'Porovnat prodejce',
          turnover_monthly: 'Obrat měsíčně',
          turnover_yearly: 'Obrat ročně',
          profit_monthly: 'Měsíční zisk',
          profit_yearly: 'Zisk ročně',
        },
        CommonWords: {
          apply: 'Použít',
          done: 'Hotovo',
          edit: 'Upravit',
          assign: 'Přiřadit',
          default: 'Výchozí',
          delete: 'Vymazat',
          cancel: 'Zrušit',
          date: 'Datum',
          company: 'Společnost',
          compare: 'Porovnejte',
          email: 'E-mail',
          image: 'Obraz',
          images: 'Obrázky',
          model: 'Model',
          month: 'Měsíc',
          group: 'Skupina',
          backoffice: 'BackOffice',
          brand_analysis: 'Analýza značky',
          brands: 'Značky',
          buying_percentage: 'Nákup %',
          buying_online_price: 'Cena nákupu online',
          buying_price: 'Kupní cena',
          cart: 'Vozík',
          confirm: 'Potvrdit',
          create: 'Vytvořit',
          discount_percentage: 'sleva %',
          items_per_page: 'Položky na stránku:',
          navigation: 'Navigace',
          select: 'Vybrat',
          view: 'Pohled',
          role: 'Role',
          sales: 'Prodej',
          yes: 'Ano',
        },
      },
    }
  },
  alb: {
    translation: {
      authentication: {
          register: {
              info: 'Ju lutemi plotësoni formularin më poshtë dhe ne do të kontaktojmë së shpejti.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Paneli kryesor',
        },
        Analytics: {
          sidebar_text: 'Analiza',
          analytics: 'Analiza',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Fitimi dhe qarkullimi',
        },
        ScanningTime: {
          sidebar_text: 'Koha mesatare e skanimit (sek)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Krahasoni personin e shitjes',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Analiza e kategorisë',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Analiza e markës',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Analiza e notave të shitësve',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Krahasimi i kompanive',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Analiza e kategorisë së kompanive',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Analiza e markave të kompanive',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Analiza e notave të kompanive',
        },
        SupplyAnalysis: {
          sidebar_text: 'Analiza e ofertës',
          searchbar_text: 'Kërko',
        },
        StockAnalysis: {
          sidebar_text: 'Analiza e inventarit',
          searchbar_text: 'Zgjidhni depo',
        },
        MainDashboard: {
          sidebar_text: 'Paneli kryesor',
        },
        Navigation: {
          sidebar_text: 'Navigimi',
        },
        Users: {
          sidebar_text: 'Përdoruesit',
          customers_data: 'Të dhënat e klientit',
          edit_info: 'Redakto informacionin',
          delete_customer: 'Fshije',
          actions: 'Veprimet',
          active: 'Aktiv',
          add_new_staff_user: 'Shto përdorues të ri të stafit',
          add_supply_type: 'Shto llojin e furnizimit',
          add_user: 'Shto përdorues',
          admin: 'Admin',
          user: 'Përdorues',
          enter_first_name: 'Shkruani emrin',
          enter_last_name: 'Shkruani mbiemrin',
          enter_user_password: 'Fut fjalëkalimin e përdoruesit',
          not_active: 'Jo aktiv',
          pending_customer_requests: 'Kërkesat e klientëve në pritje',
          unable_to_log_in: 'Nuk mund të identifikohesh me kredencialet e dhëna.',
        },
        Team: {
          sidebar_text: 'Ekipi'
        },
        Customers: {
          sidebar_text: 'Konsumatorët',
          main_text: 'Të dhënat e klientit',
          my_packages: 'Paketat e mia',
          my_orders: 'Porositë e mia',
          my_orders_details: 'Detajet e porosive',
          add_customer: 'Shtoni klientët',
          edit_info: 'Redakto informacionin',
          delete_customer: 'Fshije',
          customer: 'Klienti',
          customer_email: 'Email-i i klientit',
          customers: 'Konsumatorët',
          phone_number: 'telefoni #',
        },
        Packages: {
          sidebar_text: 'Paketat',
          create_package_text: 'Krijo paketën',
          warehouse_text: 'Magazina',
          customer_text: 'Klienti',
          truck_capacity_text: 'Kapaciteti i kamionit (0 m)',
          selling_price_text: 'Çmimi i shitjes',
          brands_text: 'Markat',
          fetch_products_button_text: 'Aplikoni',
          fetch_from_rest: 'Merr nga pushimi',
          supply_id_please: 'Ju lutemi jepni ID-në e furnizimit.',
          pair_all_button: 'Çifto të gjitha',
          pair_button: 'Çifto',
          smart_pair_switch: 'Çiftimi inteligjent',
          edit_info: 'Redakto informacionin',
          are_you_sure: 'je i sigurt?',
          article: 'Neni',
          date_from: 'Data nga',
          date_to: 'Data në',
          extra: 'Shtesë',
          extra_items: 'Artikuj shtesë',
          grade_points_range: 'Gama e pikëve të notave',
          grand_total_euro: 'Totali i përgjithshëm €',
          pending_packages: 'Paketat në pritje',
          status: 'Statusi',
          place_order: 'vendos porosinë',
          unselect_all: 'çzgjidh të gjitha',
          total_buying_offer: 'Oferta totale për blerje:',
          total_buying_price: 'Çmimi total i blerjes:',
          total_items: 'Totali i artikujve',
          total_packages: 'Totali i paketave',
          select_pallet_supply: 'Ju lutemi zgjidhni furnizimin me paletë',
          please_select_supplier: 'Ju lutemi zgjidhni furnizuesin',
        },
        AutomatedPackages: {
          sidebar_text: 'Pako të automatizuara'
        },
        PendingPackages: {
          sidebar_text: 'Paketat në pritje',
        },
        OrderRequests: {
          sidebar_text: 'Kërkesat për porosi',
        },
        Warehouse: {
          sidebar_text: 'Magazina',
          create_warehouse_order: 'Krijoni porosinë e magazinës',
          allow_warehouse_scan: 'Jeni i sigurt që dëshironi të lejoni që magazina të skanojë këtë furnizim?',
          warehouse: 'Magazina',
          warehouse_package: 'Paketa Magazine',
          warehouse_manager: 'Menaxher Magazine',
        },
        Products: {
          sidebar_text: 'Produktet',
          online_price: 'Çmimi online',
          price_euro: 'Çmimi €',
          product_id: 'ID-ja e produktit',
          product_id_search: 'Kërkimi i ID-së së produktit',
          products: 'Produktet',
          products_quantity: 'Sasia e produkteve',
          products_selected: 'Produktet e zgjedhura',
          selling_price: 'Çmimi i shitjes',
          items_selected_percentage: 'Artikujt e zgjedhur %',
          min_3_digits: 'Minim 3 shifra',
          minimum_3_digits: 'Minimumi 3 shifra',
          no_of_products_scanned: 'Numri i produkteve të skanuara',
          no_product_within_applied_filter: 'Asnjë produkt brenda filtrit të aplikuar',
          not_scanned_items: 'Artikuj jo të skanuar',
          number_of_products_scanned: 'Numri i produkteve të skanuara',
        },
        Pallets: {
          pallet_product_detail: "Futni detajet e produktit të paletës",
          mandatory_fields_text: "Fushat me * janë të detyrueshme.",
          add_pallet_product_button: '+ Shto produktin e paletës',
          pallet: 'Paletë',
          pallets: 'Paleta',
        },
        SupplyManagement: {
          sidebar_text: 'Menaxhimi i furnizimit',
          supply: 'Furnizimi',
          supply_id: 'ID e furnizimit',
          create_new_supply: 'Krijo furnizim të ri',
          new_supply_id: "ID e re e furnizimit",
          create_supplier_text: 'Krijo furnizues të ri',
          create_supplier_name_Text: 'Emri i furnizuesit',
          create_supplier_button: 'Krijo',
          add_supply: 'Shto furnizimin',
          all_supplies: 'Të gjitha furnizimet',
          all_supply_types: 'Të gjitha llojet e furnizimit',
          all_suppliers: 'Të gjithë furnizuesit',
          manually_uploaded_prices: 'Çmimet e ngarkuara manualisht',
          supplier: 'Furnizuesi',
          supplier_name: 'Emri i furnizuesit',
          supply_inventory: 'Inventari i furnizimit',
          supply_management: 'Menaxhimi i furnizimit',
          supply_not_found: 'Furnizimi nuk u gjet',
          supply_type: 'Lloji i furnizimit',
          supply_type_a: 'Lloji i furnizimit A (0 - 1)',
          supply_type_b: 'Lloji i furnizimit B (0 - 1)',
          supply_type_buying: 'Lloji i furnizimit Blerja (0 - 1)',
          supply_type_c: 'Lloji i furnizimit C (0 - 1)',
          supply_type_name: 'Emri i llojit të furnizimit',
          supply_type_not_found: 'Lloji i furnizimit nuk u gjet',
          total_suppliers: 'Furnizuesit total',
          total_supplies: 'Furnizimet totale',
          total_supply_types: 'Llojet totale të furnizimit',
          edit_supplier_information: 'Redaktoni informacionin e furnizuesit',
          edit_supply: 'Redakto furnizimin',
          edit_supply_type: 'Ndrysho llojin e furnizimit.',
          exclude_supplies: 'Përjashtoni furnizimet',
          a_grade_percentage: 'Një notë %',
          b_grade_percentage: 'Klasa B %',
          c_grade_percentage: 'Nota C %',
          allow_supply_to_sales: 'Jeni të sigurt që dëshironi ta lejoni këtë furnizim XYZ në shitje?',
          new_supply: 'Furnizimi i ri',
          old_supply: 'Furnizimi i vjetër',
          edit_supplier_details: 'Ju lutemi modifikoni të dhënat e mëposhtme të furnizuesit.',
          edit_supply_type_details: 'Ju lutemi modifikoni detajet e mëposhtme të llojit të furnizimit.',
          provide_supply_id: 'Ju lutemi jepni ID-në e furnizimit.',
        },
        SupplyInventory: {
          sidebar_text: 'Inventari i furnizimit',
          extra_text: 'Produkte shtesë',
          scanned_text: 'Produkte të skanuara',
          unscanned_text: 'Produkte jo të skanuara',
          mark_scanned_completely: 'Jeni i sigurt që dëshironi të shënoni XYZ si "të skanuar plotësisht"?',
          restrict_supply_to_sales: 'Jeni të sigurt që dëshironi ta kufizoni këtë furnizim XYZ në shitje?',
          confirm_delete_item: 'Jeni i sigurt, doni ta fshini këtë artikull?',
          assign_type_to_new_supply: 'Cakto llojin për një furnizim të ri',
          scanned: 'Skanuar',
          unscanned: 'E pa skanuar',
          extra: 'Shtesë',
          not_visible_until_completed: 'Ato nuk do të jenë të dukshme derisa të përfundojnë.',
          scanned_items: 'Artikujt e skanuar',
          scanning_in_progress: 'Skanimi në vazhdim e sipër',
        },

        CatalogManagement: {
          sidebar_text: 'Menaxhimi i katalogut',
          catalog: 'Katalogu',
          width_primary_cm: 'Gjerësia kryesore (cm)',
          width_secondary_cm: 'Gjerësia dytësore (cm)',
          height_primary_cm: 'Lartësia kryesore (cm)',
          height_secondary_cm: 'Lartësia dytësore (cm)',
          depth_primary_cm: 'Thellësia parësore (cm)',
          depth_secondary_cm: 'Thellësia dytësore (cm)',
          catalog_management: 'Menaxhimi i katalogut',
          depth_primary: 'Thellësia parësore',
          depth_secondary: 'Thellësia dytësore',
          height_primary: 'Lartësia kryesore',
          height_secondary: 'Lartësia dytësore',
          weight_kg: 'Pesha (Kg)',
          width_primary: 'Gjerësia kryesore',
          width_secondary: 'Gjerësia dytësore',
          loading_meters: 'Matësit e ngarkimit',
        },
        RightsManagement: {
          sidebar_text: 'Menaxhimi i të drejtave',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Kërkesat e klientëve në pritje',
        },
        WebsiteSettings: {
          sidebar_text: 'Cilësimet e faqes',
          select_language: 'Zgjidhni gjuhën',
          upload: 'Ngarkoni',
          upload_catalog: 'Ngarko Katalogun',
          upload_pallet_products: 'Ngarko produktet e paletës',
          upload_product: 'Ngarkoni produktin',
        },
        OrdersWords:{
          available_items: 'Artikujt e disponueshëm',
          available_pallets: 'Paletat e disponueshme',
          available_products: 'Produktet e disponueshme',
          created_by: 'Krijuar nga',
          order: 'Rendit',
          order_id: 'Id i porosisë',
          ordered: 'porositur',
          package_id: 'ID e paketës',
          sales_assistant: 'Asistent shitës',
          search_by_name: 'Kërko sipas emrit',
          search_company: 'Kërko kompani',
          select_sales_person: 'Zgjidhni personin e shitjes',
          select_supplier: 'Zgjidhni furnizuesin',
          set_articles: 'Set Artikuj',
          sub_category: 'Nënkategoria',
          sub_total_euro: 'Nën Total €',
          sub_cat: 'Nën Cat',
        },
        AnalysisWords:{
          analysis_text: 'Analiza',
          category: 'Kategoria',
          category_analysis: 'Analiza e kategorisë',
          change_supply_visibility: 'Ndrysho dukshmërinë e furnizimit',
          companies_category_analysis: 'Analiza e kategorisë së kompanive',
          companies_grades_analysis: 'Analiza e notave të kompanive',
          compare_sales_person: 'Krahasoni personin e shitjes',
          turnover_monthly: 'Qarkullimi Mujor',
          turnover_yearly: 'Qarkullimi vjetor',
          profit_monthly: 'Fitimi Mujor',
          profit_yearly: 'Fitimi vjetor',
        },
        CommonWords: {
          apply: 'Aplikoni',
          done: 'U krye',
          edit: 'Redakto',
          assign: 'Cakto',
          default: 'E paracaktuar',
          delete: 'Fshije',
          cancel: 'Anulo',
          date: 'Data',
          company: 'Kompania',
          compare: 'Krahasoni',
          email: 'Email',
          image: 'Imazhi',
          images: 'Imazhe',
          model: 'Model',
          month: 'muaj',
          group: 'Grupi',
          backoffice: 'BackOffice',
          brand_analysis: 'Analiza e markës',
          brands: 'Markat',
          buying_percentage: 'Blerja %',
          buying_online_price: 'Blerja e çmimit në internet',
          buying_price: 'Çmimi i blerjes',
          cart: 'Karrocë',
          confirm: 'konfirmoj',
          create: 'Krijo',
          discount_percentage: 'zbritje %',
          items_per_page: 'Artikujt për faqe:',
          navigation: 'Navigimi',
          select: 'Zgjidhni',
          view: 'Shiko',
          role: 'Roli',
          sales: 'Shitjet',
          yes: 'po',
        },
      },
    }
  },
  arb: {
    translation: {
      authentication: {
          register: {
              info: 'يرجى ملء النموذج أدناه وسنتواصل معك قريبًا.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'لوحة التحكم',
        },
        Analytics: {
          sidebar_text: 'التحليلات',
          analytics: 'التحليلات',
        },
        ProfitAndTurnover: {
          sidebar_text: 'الربح والمبيعات',
        },
        ScanningTime: {
          sidebar_text: 'متوسط ​​وقت المسح (ثانية)',
        },
        SalesPersonComparison: {
          sidebar_text: 'مقارنة مندوب المبيعات',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'تحليل الفئة',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'تحليل العلامة التجارية',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'تحليل درجات مندوب المبيعات',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'مقارنة الشركات',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'تحليل فئة الشركات',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'تحليل علامات الشركات التجارية',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'تحليل درجات الشركات',
        },
        SupplyAnalysis: {
          sidebar_text: 'تحليل العرض',
          searchbar_text: 'بحث',
        },
        StockAnalysis: {
          sidebar_text: 'تحليل المخزون',
          searchbar_text: 'اختر المستودع',
        },
        MainDashboard: {
          sidebar_text: 'لوحة المعلومات الرئيسية',
        },
        Navigation: {
          sidebar_text: 'التنقل',
        },
        Users: {
          sidebar_text: 'المستخدمون',
          customers_data: 'بيانات العميل',
          edit_info: 'تحرير المعلومات',
          delete_customer: 'حذف',
          actions: 'الإجراءات',
          active: 'نشط',
          add_new_staff_user: 'إضافة مستخدم جديد للموظفين',
          add_supply_type: 'إضافة نوع التوريد',
          add_user: 'إضافة مستخدم',
          admin: 'Admin',
          user: 'المستخدم',
          enter_first_name: 'أدخل الاسم الأول',
          enter_last_name: 'أدخل الاسم الأخير',
          enter_user_password: 'أدخل كلمة مرور المستخدم',
          not_active: 'غير نشط',
          pending_customer_requests: 'طلبات العملاء المعلقة',
          unable_to_log_in: 'غير قادر على تسجيل الدخول باستخدام بيانات الاعتماد المقدمة.',
        },
        Team: {
          sidebar_text: 'الفريق'
        },
        Customers: {
          sidebar_text: 'العملاء',
          main_text: 'بيانات العميل',
          my_packages: 'طرودي',
          my_orders: 'طلباتي',
          my_orders_details: 'تفاصيل الطلب',
          add_customer: 'إضافة العملاء',
          edit_info: 'تحرير المعلومات',
          delete_customer: 'حذف',
          customer: 'العميل',
          customer_email: 'بريد العميل الإلكتروني',
          customers: 'العملاء',
          phone_number: 'رقم الهاتف',
        },
        Packages: {
          sidebar_text: 'الحزم',
          create_package_text: 'إنشاء حزمة',
          warehouse_text: 'المستودع',
          customer_text: 'العميل',
          truck_capacity_text: 'سعة الشاحنة (0 م)',
          selling_price_text: 'سعر البيع',
          brands_text: 'العلامات التجارية',
          fetch_products_button_text: 'تطبيق',
          fetch_from_rest: 'الجلب من الباقي',
          supply_id_please: 'يرجى تقديم معرف الإمداد.',
          pair_all_button: 'اقتران الكل',
          pair_button: 'اقتران',
          smart_pair_switch: 'اقتران ذكي',
          edit_info: 'تحرير المعلومات',
          are_you_sure: 'هل أنت متأكد؟',
          article: 'المادة',
          date_from: 'التاريخ من',
          date_to: 'التاريخ إلى',
          extra: 'إضافي',
          extra_items: 'عناصر إضافية',
          grade_points_range: 'نطاق نقاط الدرجة',
          grand_total_euro: 'الإجمالي الكلي €',
          pending_packages: 'الحزم المعلقة',
          status: 'الحالة',
          place_order: 'إرسال الطلب',
          unselect_all: 'إلغاء تحديد الكل',
          total_buying_offer: 'إجمالي عرض الشراء:',
          total_buying_price: 'إجمالي سعر الشراء:',
          total_items: 'إجمالي العناصر',
          total_packages: 'إجمالي الطرود',
          select_pallet_supply: 'الرجاء تحديد مورد المنصات',
          please_select_supplier: 'الرجاء تحديد المورد',
        },
        AutomatedPackages: {
          sidebar_text: 'حزم مؤتمتة'
        },
        PendingPackages: {
          sidebar_text: 'الحزم المعلقة',
        },
        OrderRequests: {
          sidebar_text: 'طلبات الطلبات',
        },
        Warehouse: {
          sidebar_text: 'المستودع',
          create_warehouse_order: 'إنشاء طلب مستودع',
          allow_warehouse_scan: 'هل أنت متأكد من أنك تريد السماح للمستودع بمسح هذا التوريد؟',
          warehouse: 'المستودع',
          warehouse_package: 'حزمة المستودع',
          warehouse_manager: 'مدير المستودع',
        },
        Products: {
          sidebar_text: 'المنتجات',
          online_price: 'السعر عبر الإنترنت',
          price_euro: 'السعر €',
          product_id: 'معرف المنتج',
          product_id_search: 'معرف المنتج البحث',
          products: 'المنتجات',
          products_quantity: 'كمية المنتجات',
          products_selected: 'المنتجات المحددة',
          selling_price: 'سعر البيع',
          items_selected_percentage: 'العناصر المحددة %',
          min_3_digits: '3 أرقام على الأقل',
          minimum_3_digits: '3 أرقام على الأقل',
          no_of_products_scanned: 'عدد المنتجات الممسوحة ضوئيًا',
          no_product_within_applied_filter: 'لا يوجد منتج ضمن الفلتر المطبق',
          not_scanned_items: 'عناصر غير ممسوحة ضوئيًا',
          number_of_products_scanned: 'عدد المنتجات الممسوحة ضوئيًا',
          no_product_found: 'لم يتم العثور على أي منتج',
          no_setarticle_found: 'لم يتم العثور على أي مجموعة مقالات',
          no_pallet_found: 'لم يتم العثور على منصة نقالة',
          supplier_grade: 'درجة المورد',
          broken_glass: 'زجاج مكسور',
        },
        Pallets: {
          pallet_product_detail: "أدخل تفاصيل منتج المنصة النقالة",
          mandatory_fields_text: "الحقول ذات * إلزامية.",
          add_pallet_product_button: '+ أضف منتجًا بالمنصة النقالة',
          pallet: 'منصة نقالة',
          pallets: 'المنصات النقالة',
        },
        SupplyManagement: {
          sidebar_text: 'إدارة التوريد',
          supply: 'التوريد',
          supply_id: 'معرف التوريد',
          create_new_supply: 'إنشاء توريد جديد',
          new_supply_id: "معرف التوريد الجديد",
          create_supplier_text: 'إنشاء مورد جديد',
          create_supplier_name_Text: 'اسم المورد',
          create_supplier_button: 'إنشاء',
          add_supply: 'إضافة توريد',
          all_supplies: 'جميع الإمدادات',
          all_supply_types: 'جميع أنواع التوريد',
          all_suppliers: 'جميع الموردين',
          manually_uploaded_prices: 'الأسعار التي تم تحميلها يدويًا',
          supplier: 'المورد',
          supplier_name: 'اسم المورد',
          supply_inventory: 'مخزون التوريد',
          supply_management: 'إدارة التوريد',
          supply_not_found: 'لم يتم العثور على التوريد',
          supply_type: 'نوع التوريد',
          supply_type_a: 'نوع التوريد أ (0 - 1)',
          supply_type_b: 'نوع التوريد ب (0 - 1)',
          supply_type_buying: 'نوع التوريد شراء (0 - 1)',
          supply_type_c: 'نوع التوريد ج (0 - 1)',
          supply_type_name: 'اسم نوع التوريد',
          supply_type_not_found: 'نوع التوريد لم يتم العثور عليها',
          total_suppliers: 'إجمالي الموردين',
          total_supplies: 'إجمالي الإمدادات',
          total_supply_types: 'إجمالي أنواع الإمدادات',
          edit_supplier_information: 'تحرير معلومات المورد',
          edit_supply: 'تحرير التوريد',
          edit_supply_type: 'تحرير نوع التوريد.',
          exclude_supplies: 'استبعاد اللوازم',
          a_grade_percentage: 'الدرجة A %',
          b_grade_percentage: 'الدرجة B %',
          c_grade_percentage: 'الدرجة C %',
          allow_supply_to_sales: 'هل أنت متأكد من أنك تريد السماح لهذا التوريد XYZ بالمبيعات؟',
          new_supply: 'إمدادات جديدة',
          old_supply: 'الإمدادات القديمة',
          edit_supplier_details: 'يرجى تعديل التفاصيل التالية للمورد.',
          edit_supply_type_details: 'يرجى تعديل التفاصيل التالية لنوع الإمداد.',
          provide_supply_id: 'يرجى تقديم معرف الإمداد.',
        },
        SupplyInventory: {
          sidebar_text: 'مخزون التوريد',
          extra_text: 'منتجات إضافية',
          scanned_text: 'منتجات ممسوحة ضوئيًا',
          unscanned_text: 'منتجات غير ممسوحة ضوئيًا',
          mark_scanned_completely: 'هل أنت متأكد من أنك تريد وضع علامة على XYZ على أنه "تم مسحه ضوئيًا بالكامل"؟',
          restrict_supply_to_sales: 'هل أنت متأكد من أنك تريد تقييد هذا التوريد XYZ بالمبيعات؟',
          confirm_delete_item: 'هل أنت متأكد، تريد حذف هذا العنصر؟',
          assign_type_to_new_supply: 'تعيين نوع لإمدادات جديدة',
          scanned: 'تم المسح الضوئي',
          unscanned: 'غير ممسوحة',
          extra: 'إضافي',
          not_visible_until_completed: 'لن تكون مرئية حتى اكتمالها.',
          scanned_items: 'العناصر الممسوحة ضوئيًا',
          scanning_in_progress: 'المسح الضوئي قيد التقدم',
        },
        CatalogManagement: {
          sidebar_text: 'إدارة الكتالوج',
          catalog: 'الكتالوج',
          width_primary_cm: 'العرض الأساسي (سم)',
          width_secondary_cm: 'العرض الثانوي (سم)',
          height_primary_cm: 'الارتفاع الأساسي (سم)',
          height_secondary_cm: 'الارتفاع الثانوي (سم)',
          depth_primary_cm: 'العمق الأساسي (سم)',
          depth_secondary_cm: 'العمق الثانوي (سم)',
          catalog_management: 'إدارة الكتالوج',
          depth_primary: 'العمق الأساسي',
          depth_secondary: 'العمق الثانوي',
          height_primary: 'الارتفاع الأساسي',
          height_secondary: 'الارتفاع الثانوي',
          weight_kg: 'الوزن (كجم)',
          width_primary: 'العرض الأساسي',
          width_secondary: 'العرض الثانوي',
          loading_meters: 'عدادات التحميل',
        },
        RightsManagement: {
          sidebar_text: 'إدارة الحقوق',
        },
        PendingCustomerRequests: {
          sidebar_text: 'طلبات العملاء المعلقة',
        },
        WebsiteSettings: {
          sidebar_text: 'إعدادات الموقع',
          select_language: 'تحديد اللغة',
          upload: 'تحميل',
          upload_catalog: 'تحميل الكتالوج',
          upload_pallet_products: 'تحميل منتجات المنصات',
          upload_product: 'تحميل المنتج',
        },
        OrdersWords:{
          available_items: 'العناصر المتوفرة',
          available_pallets: 'المنصات المتوفرة',
          available_products: 'المنتجات المتوفرة',
          created_by: 'تم إنشاؤه بواسطة',
          order: 'الطلب',
          order_id: 'معرف الطلب',
          ordered: 'تم الطلب',
          package_id: 'معرف الحزمة',
          sales_assistant: 'مساعد المبيعات',
          search_by_name: 'البحث حسب الاسم',
          search_company: 'البحث عن الشركة',
          select_sales_person: 'تحديد مندوب المبيعات',
          select_supplier: 'تحديد المورد',
          set_articles: 'تعيين العناصر',
          sub_category: 'الفئة الفرعية',
          sub_total_euro: 'المجموع الفرعي €',
          sub_cat: 'الفئة الفرعية',
        },
        AnalysisWords:{
          analysis_text: 'تحليل',
          category: 'الفئة',
          category_analysis: 'تحليل الفئة',
          change_supply_visibility: 'تغيير رؤية الإمداد',
          companies_category_analysis: 'تحليل فئة الشركات',
          companies_grades_analysis: 'تحليل درجات الشركات',
          compare_sales_person: 'مقارنة مندوب المبيعات',
          turnover_monthly: 'المبيعات الشهرية',
          turnover_yearly: 'المبيعات السنوية',
          profit_monthly: 'الربح الشهري',
          profit_yearly: 'الربح السنوي',
        },
        CommonWords: {
          apply: 'تطبيق',
          done: 'تم',
          edit: 'تحرير',
          assign: 'تعيين',
          default: 'افتراضي',
          delete: 'حذف',
          cancel: 'إلغاء',
          date: 'التاريخ',
          company: 'الشركة',
          compare: 'مقارنة',
          email: 'البريد الإلكتروني',
          image: 'الصورة',
          images: 'الصور',
          model: 'الطراز',
          month: 'الشهر',
          group: 'المجموعة',
          backoffice: 'المكتب الخلفي',
          brand_analysis: 'تحليل العلامة التجارية',
          brands: 'العلامات التجارية',
          buying_percentage: 'الشراء %',
          buying_online_price: 'سعر الشراء عبر الإنترنت',
          buying_price: 'سعر الشراء',
          cart: 'عربة التسوق',
          confirm: 'تأكيد',
          create: 'إنشاء',
          discount_percentage: 'نسبة الخصم',
          items_per_page: 'العناصر لكل صفحة:',
          navigation: 'التنقل',
          select: 'تحديد',
          view: 'عرض',
          role: 'الدور',
          sales: 'المبيعات',
          yes: 'نعم',
        },
      },
    }
  },
  cro: {
    translation: {
      authentication: {
          register: {
              info: 'Molimo ispunite donji obrazac i uskoro ćemo vas kontaktirati.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
        },
        Analytics: {
          sidebar_text: 'Analitika',
          analytics: 'Analitika',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Dobit i promet',
        },
        ScanningTime: {
          sidebar_text: 'Prosječno vrijeme skeniranja (sek)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Usporedi prodavača',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Analiza kategorije',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Analiza marke',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Analiza ocjena prodavača',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Usporedba tvrtki',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Analiza kategorija tvrtki',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Analiza marki tvrtki',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Analiza ocjena tvrtki',
        },
        SupplyAnalysis: {
          sidebar_text: 'Analiza ponude',
          searchbar_text: 'Pretraživanje',
        },
        StockAnalysis: {
          sidebar_text: 'Analiza zaliha',
          searchbar_text: 'Odaberite skladište',
        },
        MainDashboard: {
          sidebar_text: 'Glavna nadzorna ploča',
        },
        Navigation: {
          sidebar_text: 'Navigacija',
        },
        Users: {
          sidebar_text: 'Korisnici',
          customers_data: 'Podaci o kupcima',
          edit_info: 'Uredi podatke',
          delete_customer: 'Izbrisati',
          actions: 'Radnje',
          active: 'Aktivan',
          add_new_staff_user: 'Dodaj novog korisnika osoblja',
          add_supply_type: 'Dodajte vrstu opskrbe',
          add_user: 'Dodaj korisnika',
          admin: 'Administrator',
          user: 'Korisnik',
          enter_first_name: 'Unesite ime',
          enter_last_name: 'Unesite prezime',
          enter_user_password: 'Unesite korisničku lozinku',
          not_active: 'Nije aktivno',
          pending_customer_requests: 'Zahtjevi korisnika na čekanju',
          unable_to_log_in: 'Nije moguće prijaviti se s navedenim vjerodajnicama.',
        },
        Team: {
          sidebar_text: 'Tim'
        },
        Customers: {
          sidebar_text: 'Kupci',
          main_text: 'Podaci o kupcima',
          my_packages: 'Moji paketi',
          my_orders: 'Moje narudžbe',
          my_orders_details: 'Detalji narudžbe',
          add_customer: 'Dodajte klijente',
          edit_info: 'Uredi podatke',
          delete_customer: 'Izbrisati',
          customer: 'Kupac',
          customer_email: 'E-pošta kupca',
          customers: 'Kupci',
          phone_number: 'Broj telefona',
        },
        Packages: {
          sidebar_text: 'Paketi',
          create_package_text: 'Stvoriti paket',
          warehouse_text: 'Skladište',
          customer_text: 'Kupac',
          truck_capacity_text: 'Kapacitet kamiona (0 m)',
          selling_price_text: 'Prodajna cijena',
          brands_text: 'Marke',
          fetch_products_button_text: 'primijeniti',
          fetch_from_rest: 'Dohvati iz mirovanja',
          supply_id_please: 'Navedite ID nabave.',
          pair_all_button: 'Spoji sve',
          pair_button: 'Spoji',
          smart_pair_switch: 'Pametno spajanje',
          edit_info: 'Uredi podatke',
          are_you_sure: 'Jeste li sigurni?',
          article: 'Članak',
          date_from: 'Datum iz',
          date_to: 'Datum do',
          extra: 'Ekstra',
          extra_items: 'Dodatne stavke',
          grade_points_range: 'Raspon ocjena',
          grand_total_euro: 'Ukupno €',
          pending_packages: 'Paketi na čekanju',
          status: 'Status',
          place_order: 'izvršiti narudžbu',
          unselect_all: 'poništi odabir svih',
          total_buying_offer: 'Ukupna ponuda za kupnju:',
          total_buying_price: 'Ukupna otkupna cijena:',
          total_items: 'Ukupno stavki',
          total_packages: 'Ukupni paketi',
          select_pallet_supply: 'Odaberite ponudu paleta',
          please_select_supplier: 'Odaberite dobavljača',
        },
        AutomatedPackages: {
          sidebar_text: 'Automatizirani paketi'
        },
        PendingPackages: {
          sidebar_text: 'Paketi na čekanju',
        },
        OrderRequests: {
          sidebar_text: 'Zahtjevi za narudžbe',
        },
        Warehouse: {
          sidebar_text: 'Skladište',
          create_warehouse_order: 'Kreirajte skladišni nalog',
          allow_warehouse_scan: 'Jeste li sigurni da želite dopustiti skladištu da skenira ovu zalihu?',
          warehouse: 'Skladište',
          warehouse_package: 'Skladišni paket',
          warehouse_manager: 'WarehouseManager',
        },
        Products: {
          sidebar_text: 'Proizvodi',
          online_price: 'Online cijena',
          price_euro: 'Cijena €',
          product_id: 'ID proizvoda',
          product_id_search: 'Pretraživanje ID proizvoda',
          products: 'Proizvodi',
          products_quantity: 'Količina proizvoda',
          products_selected: 'Odabrani proizvodi',
          selling_price: 'Prodajna cijena',
          items_selected_percentage: 'Odabrane stavke %',
          min_3_digits: 'Najmanje 3 znamenke',
          minimum_3_digits: 'Minimalno 3 znamenke',
          no_of_products_scanned: 'Broj skeniranih proizvoda',
          no_product_within_applied_filter: 'Nema proizvoda unutar primijenjenog filtra',
          not_scanned_items: 'Neskenirane stavke',
          number_of_products_scanned: 'Broj skeniranih proizvoda',
        },
        Pallets: {
          pallet_product_detail: "Unesite detalje o proizvodu na paleti",
          mandatory_fields_text: "Polja označena * su obavezna.",
          add_pallet_product_button: '+ Dodaj proizvod na paletu',
          pallet: 'Paleta',
          pallets: 'Palete',
        },
        SupplyManagement: {
          sidebar_text: 'Upravljanje opskrbom',
          supply: 'Opskrbiti',
          supply_id: 'ID nabave',
          create_new_supply: 'Stvorite novu opskrbu',
          new_supply_id: "ID nove opskrbe",
          create_supplier_text: 'Stvorite novog dobavljača',
          create_supplier_name_Text: 'Naziv dobavljača',
          create_supplier_button: 'Stvoriti',
          add_supply: 'Dodajte opskrbu',
          all_supplies: 'Sve opskrbe',
          all_supply_types: 'Sve vrste opskrbe',
          all_suppliers: 'Svi dobavljači',
          manually_uploaded_prices: 'Ručno prenesene cijene',
          supplier: 'Dobavljač',
          supplier_name: 'Naziv dobavljača',
          supply_inventory: 'Opskrba zalihama',
          supply_management: 'Upravljanje opskrbom',
          supply_not_found: 'Zaliha nije pronađena',
          supply_type: 'Vrsta opskrbe',
          supply_type_a: 'Vrsta napajanja A (0 - 1)',
          supply_type_b: 'Vrsta opskrbe B (0 - 1)',
          supply_type_buying: 'Vrsta ponude Kupnja (0 - 1)',
          supply_type_c: 'Vrsta napajanja C (0 - 1)',
          supply_type_name: 'Naziv vrste nabave',
          supply_type_not_found: 'Vrsta nabave nije pronađena',
          total_suppliers: 'Ukupni dobavljači',
          total_supplies: 'Ukupne zalihe',
          total_supply_types: 'Ukupne vrste opskrbe',
          edit_supplier_information: 'Uredite informacije o dobavljaču',
          edit_supply: 'Uredi ponudu',
          edit_supply_type: 'Uredi vrstu opskrbe.',
          exclude_supplies: 'Isključi zalihe',
          a_grade_percentage: 'A ocjena %',
          b_grade_percentage: 'B stupanj %',
          c_grade_percentage: 'C stupanj %',
          allow_supply_to_sales: 'Jeste li sigurni da ovu ponudu XYZ želite dopustiti prodaji?',
          new_supply: 'Nova opskrba',
          old_supply: 'Stara opskrba',
          edit_supplier_details: 'Uredite sljedeće podatke o dobavljaču.',
          edit_supply_type_details: 'Uredite sljedeće pojedinosti o vrsti opskrbe.',
          provide_supply_id: 'Navedite ID nabave.',
        },
        SupplyInventory: {
          sidebar_text: 'Opskrba zalihama',
          extra_text: 'Dodatni proizvodi',
          scanned_text: 'Skenirani proizvodi',
          unscanned_text: 'Neskenirani proizvodi',
          mark_scanned_completely: 'Jeste li sigurni da želite označiti XYZ kao "potpuno skenirano"?',
          restrict_supply_to_sales: 'Jeste li sigurni da ovu ponudu XYZ želite ograničiti na prodaju?',
          confirm_delete_item: 'Jeste li sigurni da želite izbrisati ovu stavku?',
          assign_type_to_new_supply: 'Dodijelite vrstu novoj opskrbi',
          scanned: 'Skenirano',
          unscanned: 'Neskenirano',
          extra: 'Ekstra',
          not_visible_until_completed: 'Neće biti vidljivi dok se ne dovrše.',
          scanned_items: 'Skenirane stavke',
          scanning_in_progress: 'Skeniranje u tijeku',
        },

        CatalogManagement: {
          sidebar_text: 'Upravljanje katalogom',
          catalog: 'Katalog',
          width_primary_cm: 'Širina primarna (cm)',
          width_secondary_cm: 'Širina sekundarna (cm)',
          height_primary_cm: 'Visina primarna (cm)',
          height_secondary_cm: 'Visina sekundarna (cm)',
          depth_primary_cm: 'Dubina primarna (cm)',
          depth_secondary_cm: 'Dubina sekundarna (cm)',
          catalog_management: 'Upravljanje katalogom',
          depth_primary: 'Dubina primarna',
          depth_secondary: 'Dubina sekundarna',
          height_primary: 'Visina primarna',
          height_secondary: 'Visina sekundarna',
          weight_kg: 'Težina (kg)',
          width_primary: 'Širina primarna',
          width_secondary: 'Širina sekundarna',
          loading_meters: 'Učitavanje mjerača',
        },
        RightsManagement: {
          sidebar_text: 'Upravljanje pravima',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Zahtjevi korisnika na čekanju',
        },
        WebsiteSettings: {
          sidebar_text: 'Postavke web stranice',
          select_language: 'Odaberite jezik',
          upload: 'učitati',
          upload_catalog: 'Prenesi katalog',
          upload_pallet_products: 'Učitajte proizvode na palete',
          upload_product: 'Prenesi proizvod',
        },
        OrdersWords:{
          available_items: 'Dostupni artikli',
          available_pallets: 'Dostupne palete',
          available_products: 'Dostupni proizvodi',
          created_by: 'Stvorio',
          order: 'Redoslijed',
          order_id: 'ID narudžbe',
          ordered: 'Naručeno',
          package_id: 'ID paketa',
          sales_assistant: 'Prodajni asistent',
          search_by_name: 'Traži po imenu',
          search_company: 'Traži tvrtku',
          select_sales_person: 'Odaberite osobu za prodaju',
          select_supplier: 'Odaberite dobavljača',
          set_articles: 'Postavite članke',
          sub_category: 'Potkategorija',
          sub_total_euro: 'Podukupni iznos €',
          sub_cat: 'Podkat',
        },
        AnalysisWords:{
          analysis_text: 'Analiza',
          category: 'Kategorija',
          category_analysis: 'Analiza kategorije',
          change_supply_visibility: 'Promjena vidljivosti ponude',
          companies_category_analysis: 'Analiza kategorija tvrtki',
          companies_grades_analysis: 'Analiza ocjena tvrtki',
          compare_sales_person: 'Usporedi prodavača',
          turnover_monthly: 'Promet mjesečno',
          turnover_yearly: 'Godišnji promet',
          profit_monthly: 'Dobit mjesečno',
          profit_yearly: 'Dobit godišnje',
        },
        CommonWords: {
          apply: 'primijeniti',
          done: 'Gotovo',
          edit: 'Uredi',
          assign: 'Dodijeliti',
          default: 'Zadano',
          delete: 'Izbrisati',
          cancel: 'Otkazati',
          date: 'Datum',
          company: 'Tvrtka',
          compare: 'Usporedi',
          email: 'E-mail',
          image: 'Slika',
          images: 'Slike',
          model: 'Model',
          month: 'Mjesec',
          group: 'Skupina',
          backoffice: 'BackOffice',
          brand_analysis: 'Analiza marke',
          brands: 'Marke',
          buying_percentage: 'Kupovina %',
          buying_online_price: 'Kupnja online cijena',
          buying_price: 'Otkupna cijena',
          cart: 'Košarica',
          confirm: 'potvrditi',
          create: 'Stvoriti',
          discount_percentage: 'Popust %',
          items_per_page: 'Stavki po stranici:',
          navigation: 'Navigacija',
          select: 'Odaberite',
          view: 'Pogled',
          role: 'Uloga',
          sales: 'Prodajni',
          yes: 'Da',
        },
      },
    }
  },
  ung: {
    translation: {
      authentication: {
          register: {
              info: 'Kérjük, töltse ki az alábbi űrlapot, és hamarosan felvesszük Önnel a kapcsolatot.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Fő műszerfal',
        },
        Analytics: {
          sidebar_text: 'Analitika',
          analytics: 'Analitika',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Profit és forgalom',
        },
        ScanningTime: {
          sidebar_text: 'Átlagos szkennelési idő (mp)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Hasonlítsa össze az értékesítőt',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Kategóriaelemzés',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Márkaelemzés',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Értékelések elemzése',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Vállalatok összehasonlítása',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Vállalati kategóriaelemzés',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Vállalati márkaelemzés',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Vállalati osztályzatok elemzése',
        },
        SupplyAnalysis: {
          sidebar_text: 'Ajánlatelemzés',
          searchbar_text: 'Keresés',
        },
        StockAnalysis: {
          sidebar_text: 'Készletelemzés',
          searchbar_text: 'Válasszon raktárt',
        },
        MainDashboard: {
          sidebar_text: 'Fő műszerfal',
        },
        Navigation: {
          sidebar_text: 'Navigáció',
        },
        Users: {
          sidebar_text: 'Felhasználók',
          customers_data: 'Ügyféladatok',
          edit_info: 'Szerkessze az adatokat',
          delete_customer: 'Töröl',
          actions: 'Akciók',
          active: 'Aktív',
          add_new_staff_user: 'Új személyzeti felhasználó hozzáadása',
          add_supply_type: 'Adjon hozzá kelléktípust',
          add_user: 'Felhasználó hozzáadása',
          admin: 'Adminisztrátor',
          user: 'Felhasználó',
          enter_first_name: 'Írja be a keresztnevet',
          enter_last_name: 'Írja be a vezetéknevet',
          enter_user_password: 'Adja meg a felhasználói jelszót',
          not_active: 'Nem aktív',
          pending_customer_requests: 'Függőben lévő Ügyfélkérések',
          unable_to_log_in: 'Nem sikerült bejelentkezni a megadott hitelesítő adatokkal.',
        },
        Team: {
          sidebar_text: 'Csapat'
        },
        Customers: {
          sidebar_text: 'Ügyfelek',
          main_text: 'Ügyféladatok',
          my_packages: 'Saját csomagok',
          my_orders: 'Saját rendelések',
          my_orders_details: 'Rendelési adatok',
          add_customer: 'Adjon hozzá ügyfeleket',
          edit_info: 'Szerkessze az adatokat',
          delete_customer: 'Töröl',
          customer: 'Vevő',
          customer_email: 'Ügyfél e-mail',
          customers: 'Ügyfelek',
          phone_number: 'Telefonszám',
        },
        Packages: {
          sidebar_text: 'Csomagok',
          create_package_text: 'Csomag létrehozása',
          warehouse_text: 'Raktár',
          customer_text: 'Ügyfél',
          truck_capacity_text: 'Teherautó kapacitása (0 m)',
          selling_price_text: 'Eladási ár',
          brands_text: 'Márkák',
          fetch_products_button_text: 'Alkalmazni',
          fetch_from_rest: 'Hozd ki a pihenésből',
          supply_id_please: 'Kérjük, adja meg a szállítási azonosítót.',
          pair_all_button: 'Párosítsa mindet',
          pair_button: 'Párosítás',
          smart_pair_switch: 'Intelligens párosítás',
          edit_info: 'Szerkessze az adatokat',
          are_you_sure: 'Biztos benne?',
          article: 'Cikk',
          date_from: 'Dátum innen',
          date_to: 'Dátum ig',
          extra: 'Külön',
          extra_items: 'Extra elemek',
          grade_points_range: 'Osztálypontok tartománya',
          grand_total_euro: 'Végösszeg €',
          pending_packages: 'Függőben lévő csomagok',
          status: 'Állapot',
          place_order: 'rendelést leadni',
          unselect_all: 'törölje az összes kijelölését',
          total_buying_offer: 'Teljes vételi ajánlat:',
          total_buying_price: 'Teljes vételár:',
          total_items: 'Összes tétel',
          total_packages: 'Összes csomag',
          select_pallet_supply: 'Kérjük, válasszon raklapkészletet',
          please_select_supplier: 'Kérjük, válassza ki a szállítót',
        },
        AutomatedPackages: {
          sidebar_text: 'Automatizált csomagok'
        },
        PendingPackages: {
          sidebar_text: 'Függőben lévő csomagok',
        },
        OrderRequests: {
          sidebar_text: 'Rendelési kérelmek',
        },
        Warehouse: {
          sidebar_text: 'Raktár',
          create_warehouse_order: 'Raktári rendelés létrehozása',
          allow_warehouse_scan: 'Biztos benne, hogy engedélyezi a raktár számára a kellék ellenőrzését?',
          warehouse: 'Raktár',
          warehouse_package: 'Raktári csomag',
          warehouse_manager: 'Raktárvezető',
        },
        Products: {
          sidebar_text: 'Termékek',
          online_price: 'Online ár',
          price_euro: 'Ár €',
          product_id: 'Termékazonosító',
          product_id_search: 'Termékazonosító keresés',
          products: 'Termékek',
          products_quantity: 'Termékek mennyisége',
          products_selected: 'Kiválasztott termékek',
          selling_price: 'Eladási ár',
          items_selected_percentage: 'Kijelölt tételek%',
          min_3_digits: 'Minimum 3 számjegy',
          minimum_3_digits: 'Minimum 3 számjegy',
          no_of_products_scanned: 'Szkennelt termékek száma',
          no_product_within_applied_filter: 'Nincs termék az alkalmazott szűrőn belül',
          not_scanned_items: 'Nem szkennelt elemek',
          number_of_products_scanned: 'Beolvasott termékek száma',
        },
        Pallets: {
          pallet_product_detail: "Adja meg a raklaptermék részleteit",
          mandatory_fields_text: "A * mezők kitöltése kötelező.",
          add_pallet_product_button: '+ Raklaptermék hozzáadása',
          pallet: 'Raklap',
          pallets: 'Raklapok',
        },
        SupplyManagement: {
          sidebar_text: 'Ellátás menedzsment',
          supply: 'Kínálat',
          supply_id: 'Ellátási azonosító',
          create_new_supply: 'Új ellátás létrehozása',
          new_supply_id: "Új ellátási azonosító",
          create_supplier_text: 'Hozzon létre új szállítót',
          create_supplier_name_Text: 'Új szállító neve',
          create_supplier_button: 'Teremt',
          add_supply: 'Ellátás hozzáadása',
          all_supplies: 'Minden ellátás',
          all_supply_types: 'Minden ellátási típus',
          all_suppliers: 'Minden szállító',
          manually_uploaded_prices: 'Manuálisan feltöltött árak',
          supplier: 'Szállító',
          supplier_name: 'Szállító neve',
          supply_inventory: 'Ellátási készlet',
          supply_management: 'Ellátás menedzsment',
          supply_not_found: 'A kínálat nem található',
          supply_type: 'Ellátás típusa',
          supply_type_a: 'A ellátás típusa ( 0 - 1 )',
          supply_type_b: 'B ellátási típus ( 0 - 1 )',
          supply_type_buying: 'Ellátás típusa Vásárlás ( 0 - 1 )',
          supply_type_c: 'C ellátás típusa ( 0 - 1 )',
          supply_type_name: 'Ellátástípus neve',
          supply_type_not_found: 'A kínálat típusa nem található',
          total_suppliers: 'Összes beszállító',
          total_supplies: 'Összes készlet',
          total_supply_types: 'Összes ellátási típusok',
          edit_supplier_information: 'Szerkessze a szállítói adatokat',
          edit_supply: 'Szerkessze a kínálatot',
          edit_supply_type: 'Szerkessze az ellátás típusát.',
          exclude_supplies: 'Kellékek kizárása',
          a_grade_percentage: 'A fokozat %',
          b_grade_percentage: 'B fokozat %',
          c_grade_percentage: 'C fokozat %',
          allow_supply_to_sales: 'Biztosan engedélyezi az XYZ kelléket az értékesítés számára?',
          new_supply: 'Új kínálat',
          old_supply: 'Régi ellátás',
          edit_supplier_details: 'Kérjük, módosítsa a szállító alábbi adatait.',
          edit_supply_type_details: 'Kérjük, módosítsa a kelléktípus alábbi adatait.',
          provide_supply_id: 'Kérjük, adja meg a szállítási azonosítót.',
        },
        SupplyInventory: {
          sidebar_text: 'Ellátási készlet',
          extra_text: 'Extra termékek',
          scanned_text: 'Beolvasott termékek',
          unscanned_text: 'Nem beolvasott termékek',
          mark_scanned_completely: 'Biztosan meg szeretnéd jelölni az XYZ-t "teljesen beolvasottként"?',
          restrict_supply_to_sales: 'Biztos benne, hogy ezt az XYZ készletet az értékesítésre szeretné korlátozni?',
          confirm_delete_item: 'Biztos benne, hogy törölni szeretné ezt az elemet?',
          assign_type_to_new_supply: 'Típus hozzárendelése egy új kellékhez',
          scanned: 'Szkennelt',
          unscanned: 'Nem szkennelve',
          extra: 'Külön',
          not_visible_until_completed: 'Befejezéséig nem lesznek láthatók.',
          scanned_items: 'Szkennelt elemek',
          scanning_in_progress: 'Beolvasás folyamatban',
        },

        CatalogManagement: {
          sidebar_text: 'Katalóguskezelés',
          catalog: 'Katalógus',
          width_primary_cm: 'Szélesség elsődleges (cm)',
          width_secondary_cm: 'Szélesség másodlagos (cm)',
          height_primary_cm: 'Magasság elsődleges (cm)',
          height_secondary_cm: 'Másodlagos magasság (cm)',
          depth_primary_cm: 'Mélységi elsődleges (cm)',
          depth_secondary_cm: 'Mélység másodlagos (cm)',
          catalog_management: 'Katalóguskezelés',
          depth_primary: 'Mélységi elsődleges',
          depth_secondary: 'Mélység másodlagos',
          height_primary: 'Magasság elsődleges',
          height_secondary: 'Másodlagos magasság',
          weight_kg: 'Súly (kg)',
          width_primary: 'Szélesség elsődleges',
          width_secondary: 'Szélesség másodlagos',
          loading_meters: 'Mérők betöltése',
        },
        RightsManagement: {
          sidebar_text: 'Jogkezelés',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Függőben lévő Ügyfélkérések',
        },
        WebsiteSettings: {
          sidebar_text: 'Webhelybeállítások',
          select_language: 'Válassza a Nyelv lehetőséget',
          upload: 'feltölteni',
          upload_catalog: 'Katalógus feltöltése',
          upload_pallet_products: 'Raklapos termékek feltöltése',
          upload_product: 'Termék feltöltése',
        },
        OrdersWords:{
          available_items: 'Elérhető tételek',
          available_pallets: 'Elérhető raklapok',
          available_products: 'Elérhető termékek',
          created_by: 'Készítette',
          order: 'Rendelés',
          order_id: 'Rendelési azonosító',
          ordered: 'Rendelve',
          package_id: 'csomag azonosító',
          sales_assistant: 'Eladó-asszisztens',
          search_by_name: 'Keresés név szerint',
          search_company: 'Cég keresése',
          select_sales_person: 'Válassza ki az értékesítőt',
          select_supplier: 'Válassza ki a szállítót',
          set_articles: 'Állítsa be a cikkeket',
          sub_category: 'Alkategória',
          sub_total_euro: 'Részösszeg €',
          sub_cat: 'Alkat',
        },
        AnalysisWords:{
          analysis_text: 'Elemzés',
          category: 'Kategória',
          category_analysis: 'Kategóriaelemzés',
          change_supply_visibility: 'Az ellátás láthatóságának módosítása',
          companies_category_analysis: 'Vállalati kategóriaelemzés',
          companies_grades_analysis: 'Vállalati osztályzatok elemzése',
          compare_sales_person: 'Hasonlítsa össze az értékesítőt',
          turnover_monthly: 'Havi forgalom',
          turnover_yearly: 'Forgalom Éves',
          profit_monthly: 'Profit havi',
          profit_yearly: 'Profit évente',
        },
        CommonWords: {
          apply: 'Alkalmazni',
          done: 'Kész',
          edit: 'Szerkesztés',
          assign: 'Hozzárendelés',
          default: 'Alapértelmezett',
          delete: 'Töröl',
          cancel: 'Mégsem',
          date: 'Dátum',
          company: 'Vállalat',
          compare: 'Hasonlítsa össze',
          email: 'Email',
          image: 'Kép',
          images: 'Képek',
          model: 'Modell',
          month: 'Hónap',
          group: 'Csoport',
          backoffice: 'BackOffice',
          brand_analysis: 'Márkaelemzés',
          brands: 'Márkák',
          buying_percentage: 'Vásárlás %',
          buying_online_price: 'Online vásárlási ár',
          buying_price: 'Vételi ár',
          cart: 'Kosár',
          confirm: 'erősítse meg',
          create: 'Teremt',
          discount_percentage: 'Kedvezmény %',
          items_per_page: 'Elemek oldalanként:',
          navigation: 'Navigáció',
          select: 'Válassza ki',
          view: 'Kilátás',
          role: 'Szerep',
          sales: 'Értékesítés',
          yes: 'Igen',
        },
      },
    }
  },
  ukr: {
    translation: {
      authentication: {
          register: {
              info: "Будь ласка, заповніть форму нижче, і ми зв'яжемося з вами найближчим часом."
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
        },
        Analytics: {
          sidebar_text: 'Аналітика',
          analytics: 'Аналітика',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Прибуток і оборот',
        },
        ScanningTime: {
          sidebar_text: 'Середній час сканування (сек)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Порівняйте продавця',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Аналіз категорій',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Аналіз бренду',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Аналіз оцінок',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Порівняння компаній',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Аналіз категорій компаній',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Аналіз брендів компаній',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Аналіз оцінок компаній',
        },
        SupplyAnalysis: {
          sidebar_text: 'Пропонуйте аналіз',
          searchbar_text: 'Пошук',
        },
        StockAnalysis: {
          sidebar_text: 'Аналіз запасів',
          searchbar_text: 'Виберіть склад',
        },
        MainDashboard: {
          sidebar_text: 'Головна інформаційна панель',
        },
        Navigation: {
          sidebar_text: 'Навігація',
        },
        Users: {
          sidebar_text: 'Користувачі',
          customers_data: 'Дані клієнта',
          edit_info: 'Редагувати інформацію',
          delete_customer: 'Видалити',
          actions: 'Дії',
          active: 'Активний',
          add_new_staff_user: 'Додати нового штатного користувача',
          add_supply_type: 'Додайте тип постачання',
          add_user: 'Додати користувача',
          admin: 'Адміністратор',
          user: 'Користувач',
          enter_first_name: 'Введіть ім\'я',
          enter_last_name: 'Введіть прізвище',
          enter_user_password: 'Введіть пароль користувача',
          not_active: 'Неактивний',
          pending_customer_requests: 'Очікування запитів клієнтів',
          unable_to_log_in: 'Не вдається ввійти за допомогою наданих облікових даних.',
        },
        Team: {
          sidebar_text: 'Команда'
        },
        Customers: {
          sidebar_text: 'Клієнти',
          main_text: 'Дані клієнта',
          my_packages: 'Мої пакунки',
          my_orders: 'Мої замовлення',
          my_orders_details: 'Деталі замовлення',
          add_customer: 'Додайте клієнтів',
          edit_info: 'Редагувати інформацію',
          delete_customer: 'Видалити',
          customer: 'Замовник',
          customer_email: 'Електронна адреса клієнта',
          customers: 'Клієнти',
          phone_number: 'номер телефону',
        },
        Packages: {
          sidebar_text: 'Пакунки',
          create_package_text: 'Створити пакунок',
          warehouse_text: 'Склад',
          customer_text: 'Клієнти',
          truck_capacity_text: 'Вантажопідйомність (0 м)',
          selling_price_text: 'Ціна продажу',
          brands_text: 'Бренди',
          fetch_products_button_text: 'Застосувати',
          fetch_from_rest: 'Вивести з відпочинку',
          supply_id_please: 'Укажіть ідентифікатор постачання.',
          pair_all_button: 'З\'єднати всі',
          pair_button: 'З\'єднати',
          smart_pair_switch: 'Розумне з\'єднання',
          edit_info: 'Редагувати інформацію',
          are_you_sure: 'Ви впевнені?',
          article: 'артiкул',
          date_from: 'Дата з',
          date_to: 'Дата до',
          extra: 'Екстра',
          extra_items: 'Додаткові предмети',
          grade_points_range: 'Діапазон оцінок',
          grand_total_euro: 'Загальна сума €',
          pending_packages: 'Пакунки в очікуванні',
          status: 'Статус',
          place_order: 'розмістити замовлення',
          unselect_all: 'скасувати вибір усіх',
          total_buying_offer: 'Загальна пропозиція купівлі:',
          total_buying_price: 'Загальна вартість покупки:',
          total_items: 'Всього елементів',
          total_packages: 'Всього пакетів',
          select_pallet_supply: 'Виберіть поставку піддонів',
          please_select_supplier: 'Виберіть постачальника',
        },
        AutomatedPackages: {
          sidebar_text: 'Автоматизовані пакунки'
        },
        PendingPackages: {
          sidebar_text: 'Пакунки в очікуванні',
        },
        OrderRequests: {
          sidebar_text: 'Запити на замовлення',
        },
        Warehouse: {
          sidebar_text: 'Склад',
          create_warehouse_order: 'Створити складське замовлення',
          allow_warehouse_scan: 'Ви впевнені, що хочете дозволити складу сканувати цю поставку?',
          warehouse: 'Склад',
          warehouse_package: 'Складський пакет',
          warehouse_manager: 'WarehouseManager',
        },
        Products: {
          sidebar_text: 'Продукти',
          online_price: 'Онлайн ціна',
          price_euro: 'Ціна €',
          product_id: 'ID продукту',
          product_id_search: 'Пошук по ідентифікатору товару',
          products: 'Продукти',
          products_quantity: 'Кількість продуктів',
          products_selected: 'Вибрані продукти',
          selling_price: 'Ціна продажу',
          items_selected_percentage: 'Вибрано %',
          min_3_digits: 'Мінімум 3 цифри',
          minimum_3_digits: 'Мінімум 3 цифри',
          no_of_products_scanned: 'Кількість сканованих продуктів',
          no_product_within_applied_filter: 'Немає продуктів у застосованому фільтрі',
          not_scanned_items: 'Нескановані елементи',
          number_of_products_scanned: 'Кількість сканованих продуктів',
        },
        Pallets: {
          pallet_product_detail: "Введіть деталі продукту палети",
          mandatory_fields_text: "Поля з * є обов'язковими.",
          add_pallet_product_button: '+ Додати продукт палети',
          pallet: 'Піддон',
          pallets: 'Піддони',
        },
        SupplyManagement: {
          sidebar_text: 'Управління постачанням',
          supply: 'Постачання',
          supply_id: 'ID постачання',
          create_new_supply: 'Створити нову поставку',
          new_supply_id: "Новий ідентифікатор постачання",
          create_supplier_text: 'Створити нового постачальника',
          create_supplier_name_Text: 'Назва нового постачальника',
          create_supplier_button: 'Створити',
          add_supply: 'Додати постачання',
          all_supplies: 'Всі постачання',
          all_supply_types: 'Всі види постачання',
          all_suppliers: 'Всі постачальники',
          manually_uploaded_prices: 'Ціни завантажені вручну',
          supplier: 'Постачальник',
          supplier_name: 'Назва постачальника',
          supply_inventory: 'Поставка інвентарю',
          supply_management: 'Управління постачанням',
          supply_not_found: 'Постачання не знайдено',
          supply_type: 'Тип постачання',
          supply_type_a: 'Тип живлення A ( 0 - 1 )',
          supply_type_b: 'Тип живлення B ( 0 - 1 )',
          supply_type_buying: 'Тип постачання Купівля ( 0 - 1 )',
          supply_type_c: 'Тип живлення C ( 0 - 1 )',
          supply_type_name: 'Назва типу постачання',
          supply_type_not_found: 'Тип постачання не знайдено',
          total_suppliers: 'Всього постачальників',
          total_supplies: 'Загальні запаси',
          total_supply_types: 'Всього видів постачання',
          edit_supplier_information: 'Редагувати інформацію про постачальника',
          edit_supply: 'Редагувати поставку',
          edit_supply_type: 'Редагувати тип постачання.',
          exclude_supplies: 'Виключити витратні матеріали',
          a_grade_percentage: 'Оцінка A %',
          b_grade_percentage: 'Оцінка B %',
          c_grade_percentage: 'Оцінка C %',
          allow_supply_to_sales: 'Ви впевнені, що хочете дозволити цю поставку XYZ для продажу?',
          new_supply: 'Нова поставка',
          old_supply: 'Стара поставка',
          edit_supplier_details: 'Відредагуйте наступні відомості про постачальника.',
          edit_supply_type_details: 'Відредагуйте наведені нижче відомості про тип постачання.',
          provide_supply_id: 'Укажіть ідентифікатор постачання.',
        },
        SupplyInventory: {
          sidebar_text: 'Поставка інвентарю',
          extra_text: 'Додаткові продукти',
          scanned_text: 'Відскановані продукти',
          unscanned_text: 'Нескановані продукти',
          mark_scanned_completely: 'Ви впевнені, що хочете позначити XYZ як "повністю відскановане"?',
          restrict_supply_to_sales: 'Ви впевнені, що хочете обмежити цю поставку XYZ продажами?',
          confirm_delete_item: 'Ви впевнені, що хочете видалити цей елемент?',
          assign_type_to_new_supply: 'Призначте тип новому ресурсу',
          scanned: 'Відскановані',
          unscanned: 'Нескановане',
          extra: 'Екстра',
          not_visible_until_completed: 'Їх не буде видно, доки не буде завершено.',
          scanned_items: 'Відскановані елементи',
          scanning_in_progress: 'Триває сканування',
        },

        CatalogManagement: {
          sidebar_text: 'Ведення каталогу',
          catalog: 'Каталог',
          width_primary_cm: 'Ширина первинна (см)',
          width_secondary_cm: 'Ширина вторинна (см)',
          height_primary_cm: 'Висота первинна (см)',
          height_secondary_cm: 'Висота вторинна (см)',
          depth_primary_cm: 'Глибина первинна (см)',
          depth_secondary_cm: 'Глибина вторинна (см)',
          catalog_management: 'Ведення каталогу',
          depth_primary: 'Глибина первинна',
          depth_secondary: 'Глибина вторинна',
          height_primary: 'Висота первинна',
          height_secondary: 'Висота вторинна',
          weight_kg: 'Вага (кг)',
          width_primary: 'Ширина первинна',
          width_secondary: 'Ширина вторинна',
          loading_meters: 'Завантаження лічильників',
        },
        RightsManagement: {
          sidebar_text: 'Управління правами',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Очікування запитів клієнтів',
        },
        WebsiteSettings: {
          sidebar_text: 'Налаштування вебсайту',
          select_language: 'Виберіть мову',
          upload: 'завантажити',
          upload_catalog: 'Завантажити каталог',
          upload_pallet_products: 'Завантажте палетну продукцію',
          upload_product: 'Завантажте продукт',
        },
        OrdersWords:{
          available_items: 'Доступні елементи',
          available_pallets: 'В наявності піддони',
          available_products: 'Доступні продукти',
          created_by: 'Створено',
          order: 'порядок',
          order_id: 'ID замовлення',
          ordered: 'Замовив',
          package_id: 'ідентифікатор пакета',
          sales_assistant: 'Продавець-консультант',
          search_by_name: 'Пошук за назвою',
          search_company: 'Пошук компанії',
          select_sales_person: 'Виберіть продавця',
          select_supplier: 'Виберіть постачальника',
          set_articles: 'Набір статей',
          sub_category: 'Підкатегорія',
          sub_total_euro: 'Проміжна сума €',
          sub_cat: 'Підкатегорія',
        },
        AnalysisWords:{
          analysis_text: 'Аналіз',
          category: 'Категорія',
          category_analysis: 'Аналіз категорій',
          change_supply_visibility: 'Зміна видимості постачання',
          companies_category_analysis: 'Аналіз категорій компаній',
          companies_grades_analysis: 'Аналіз оцінок компаній',
          compare_sales_person: 'Порівняйте продавця',
          turnover_monthly: 'Оборот щомісяця',
          turnover_yearly: 'Оборот Річний',
          profit_monthly: 'Прибуток за місяць',
          profit_yearly: 'Прибуток за рік',
        },
        CommonWords: {
          apply: 'Застосувати',
          done: 'Готово',
          edit: 'Редагувати',
          assign: 'Призначити',
          default: 'За замовчуванням',
          delete: 'Видалити',
          cancel: 'Скасувати',
          date: 'Дата',
          company: 'Компанія',
          compare: 'Порівняйте',
          email: 'Електронна пошта',
          image: 'Зображення',
          images: 'Зображення',
          model: 'Модель',
          month: 'місяць',
          group: 'Група',
          backoffice: 'BackOffice',
          brand_analysis: 'Аналіз бренду',
          brands: 'Бренди',
          buying_percentage: 'Купівля %',
          buying_online_price: 'Ціна покупки онлайн',
          buying_price: 'Купівельна ціна',
          cart: 'кошик',
          confirm: 'підтвердити',
          create: 'Створити',
          discount_percentage: 'Знижка %',
          items_per_page: 'Елементів на сторінці:',
          navigation: 'Навігація',
          select: 'Виберіть',
          view: 'Переглянути',
          role: 'Роль',
          sales: 'Продажі',
          yes: 'так',
        },
      },
    }
  },
  tur: {
    translation: {
      authentication: {
          register: {
              info: 'Lütfen aşağıdaki formu doldurun, en kısa sürede sizinle iletişime geçeceğiz.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Dashboard',
        },
        Analytics: {
          sidebar_text: 'Analitik',
          analytics: 'Analitik',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Kâr ve Ciro',
        },
        ScanningTime: {
          sidebar_text: 'Ortalama Tarama Süresi (sn)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Satış elemanını karşılaştırın',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Kategori Analizi',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Marka Analizi',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Not Analizi',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Şirket Karşılaştırması',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Şirket Kategori Analizi',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Şirket Marka Analizi',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Şirket Not Analizi',
        },
        SupplyAnalysis: {
          sidebar_text: 'Teklif analizi',
          searchbar_text: 'Aramak',
        },
        StockAnalysis: {
          sidebar_text: 'Envanter Analizi',
          searchbar_text: 'Depo seçin',
        },
        MainDashboard: {
          sidebar_text: 'Ana Kontrol Paneli',
        },
        Navigation: {
          sidebar_text: 'Navigasyon',
        },
        Users: {
          sidebar_text: 'Kullanıcılar',
          customers_data: 'Müşteri Verileri',
          edit_info: 'Bilgileri düzenle',
          delete_customer: 'Silmek',
          actions: 'Eylemler',
          active: 'Aktif',
          add_new_staff_user: 'Yeni personel kullanıcısı ekle',
          add_supply_type: 'Tedarik türü ekle',
          add_user: 'Kullanıcı ekle',
          admin: 'Yönetici',
          user: 'Kullanıcı',
          enter_first_name: 'Adını girin',
          enter_last_name: 'Soyadı girin',
          enter_user_password: 'Kullanıcı şifresini girin',
          not_active: 'Aktif değil',
          pending_customer_requests: 'Bekleyen Müşteri İstekleri',
          unable_to_log_in: 'Sağlanan kimlik bilgileriyle oturum açılamıyor.',
        },
        Team: {
          sidebar_text: 'Takım'
        },
        Customers: {
          sidebar_text: 'Müşteriler',
          main_text: 'Müşteri Verileri',
          my_packages: 'Paketlerim',
          my_orders: 'Siparişlerim',
          my_orders_details: 'Sipariş Detayları',
          add_customer: 'Müşteri ekle',
          edit_info: 'Bilgileri düzenle',
          delete_customer: 'Silmek',
          customer: 'Müşteri',
          customer_email: 'Müşteri E-postası',
          customers: 'Müşteriler',
          phone_number: 'Telefon #',
        },
        Packages: {
          sidebar_text: 'Paketler',
          create_package_text: 'Paket Oluştur',
          warehouse_text: 'Depo',
          customer_text: 'Müşteriler',
          truck_capacity_text: 'Kamyon kapasitesi (0 m)',
          selling_price_text: 'Satış fiyatı',
          brands_text: 'Markalar',
          fetch_products_button_text: 'Uygula',
          fetch_from_rest: 'Dinlenmeden getir',
          supply_id_please: 'Lütfen tedarik kimliğini belirtin.',
          pair_all_button: 'Tümünü Eşleştir',
          pair_button: 'Eşleştir',
          smart_pair_switch: 'Akıllı Eşleştirme',
          edit_info: 'Bilgileri düzenle',
          are_you_sure: 'Emin misin?',
          article: 'Madde',
          date_from: 'Başlangıç tarihi',
          date_to: 'Tarih',
          extra: 'Ekstra',
          extra_items: 'Ekstra öğeler',
          grade_points_range: 'Not puan aralığı',
          grand_total_euro: 'Genel toplam €',
          pending_packages: 'Bekleyen paketler',
          status: 'Durum',
          place_order: 'sipariş ver',
          unselect_all: 'tümünün seçimini kaldır',
          total_buying_offer: 'Toplam satın alma teklifi:',
          total_buying_price: 'Toplam alış fiyatı:',
          total_items: 'Toplam öğe',
          total_packages: 'Toplam paketler',
          select_pallet_supply: 'Lütfen palet tedarikini seçin',
          please_select_supplier: 'Lütfen tedarikçiyi seçin',
        },
        AutomatedPackages: {
          sidebar_text: 'Otomatik Paketler'
        },
        PendingPackages: {
          sidebar_text: 'Bekleyen paketler',
        },
        OrderRequests: {
          sidebar_text: 'Sipariş İstekleri',
        },
        Warehouse: {
          sidebar_text: 'Depo',
          create_warehouse_order: 'Depo siparişi oluştur',
          allow_warehouse_scan: 'Deponun bu tedariki taramasına izin vermek istediğinizden emin misiniz?',
          warehouse: 'Depo',
          warehouse_package: 'Depo Paketi',
          warehouse_manager: 'Depo Müdürü',
        },
        Products: {
          sidebar_text: 'Ürünler',
          online_price: 'Çevrimiçi fiyat',
          price_euro: 'Fiyat €',
          product_id: 'Ürün kimliği',
          product_id_search: 'Ürün Kimliği Arama',
          products: 'Ürünler',
          products_quantity: 'Ürün Adeti',
          products_selected: 'Seçilen ürünler',
          selling_price: 'Satış fiyatı',
          items_selected_percentage: 'Seçilen öğeler%',
          min_3_digits: 'En az 3 hane',
          minimum_3_digits: 'Minimum 3 hane',
          no_of_products_scanned: 'Taranan Ürün Sayısı',
          no_product_within_applied_filter: 'Uygulanan filtrede ürün yok',
          not_scanned_items: 'Taranmamış öğeler',
          number_of_products_scanned: 'Taranan ürün sayısı',
        },
        Pallets: {
          pallet_product_detail: "Palet ürün bilgilerini girin",
          mandatory_fields_text: "* ile işaretlenmiş alanlar zorunludur.",
          add_pallet_product_button: '+ Palet ürünü ekle',
          pallet: 'Palet',
          pallets: 'Paletler',
        },
        SupplyManagement: {
          sidebar_text: 'Tedarik yönetimi',
          supply: 'Tedarik',
          supply_id: 'Tedarik Kimliği',
          create_new_supply: 'Yeni tedarik oluştur',
          new_supply_id: "Yeni tedarik kimliği",
          create_supplier_text: 'Yeni tedarikçi oluştur',
          create_supplier_name_Text: 'Yeni tedarikçi adı',
          create_supplier_button: 'Oluştur',
          add_supply: 'Tedarik ekle',
          all_supplies: 'Tüm tedarikler',
          all_supply_types: 'Tüm tedarik türleri',
          all_suppliers: 'Tüm tedarikçiler',
          manually_uploaded_prices: 'Manuel olarak yüklenen fiyatlar',
          supplier: 'Tedarikçi',
          supplier_name: 'Tedarikçi adı',
          supply_inventory: 'Tedarik envanteri',
          supply_management: 'Tedarik yönetimi',
          supply_not_found: 'Tedarik bulunamadı',
          supply_type: 'Tedarik Tipi',
          supply_type_a: 'Besleme türü A ( 0 - 1 )',
          supply_type_b: 'Besleme türü B ( 0 - 1 )',
          supply_type_buying: 'Tedarik türü Satın Alma ( 0 - 1 )',
          supply_type_c: 'Besleme tipi C (0 - 1)',
          supply_type_name: 'Tedarik türü adı',
          supply_type_not_found: 'Tedarik türü bulunamadı',
          total_suppliers: 'Toplam tedarikçiler',
          total_supplies: 'Toplam sarf malzemeleri',
          total_supply_types: 'Toplam tedarik türleri',
          edit_supplier_information: 'Tedarikçi bilgilerini düzenleyin',
          edit_supply: 'Kaynağı düzenle',
          edit_supply_type: 'Tedarik türünü düzenleyin.',
          exclude_supplies: 'Sarf Malzemelerini Hariç Tut',
          a_grade_percentage: 'A Sınıfı %',
          b_grade_percentage: 'B Sınıfı %',
          c_grade_percentage: 'C Sınıfı %',
          allow_supply_to_sales: 'Bu XYZ tedarikinin satışa izin vermek istediğinizden emin misiniz?',
          new_supply: 'Yeni tedarik',
          old_supply: 'Eski tedarik',
          edit_supplier_details: 'Lütfen tedarikçinin aşağıdaki ayrıntılarını düzenleyin.',
          edit_supply_type_details: 'Lütfen tedarik türüne ilişkin aşağıdaki ayrıntıları düzenleyin.',
          provide_supply_id: 'Lütfen tedarik kimliğini belirtin.',
        },
        SupplyInventory: {
          sidebar_text: 'Tedarik envanteri',
          extra_text: 'Ekstra öğeler',
          scanned_text: 'Taranan öğeler',
          unscanned_text: 'Taranmamış öğeler',
          mark_scanned_completely: 'XYZ\'yi "tamamen tarandı" olarak işaretlemek istediğinizden emin misiniz?',
          restrict_supply_to_sales: 'Bu XYZ tedarikini satışlarla sınırlamak istediğinizden emin misiniz?',
          confirm_delete_item: 'Bu öğeyi silmek istediğinizden emin misiniz?',
          assign_type_to_new_supply: 'Yeni bir tedarike tür atama',
          scanned: 'Tarandı',
          unscanned: 'Taranmamış',
          extra: 'Ekstra',
          not_visible_until_completed: 'Tamamlanana kadar görünmezler.',
          scanned_items: 'Taranan öğeler',
          scanning_in_progress: 'Tarama devam ediyor',
        },

        CatalogManagement: {
          sidebar_text: 'Katalog yönetimi',
          catalog: 'Katalog',
          width_primary_cm: 'Genişlik birincil (cm)',
          width_secondary_cm: 'Genişlik ikincil (cm)',
          height_primary_cm: 'Yükseklik birincil (cm)',
          height_secondary_cm: 'Yükseklik ikincil (cm)',
          depth_primary_cm: 'Derinlik birincil (cm)',
          depth_secondary_cm: 'Derinlik ikincil (cm)',
          catalog_management: 'Katalog yönetimi',
          depth_primary: 'Derinlik birincil',
          depth_secondary: 'İkincil derinlik',
          height_primary: 'Yükseklik birincil',
          height_secondary: 'Yükseklik ikincil',
          weight_kg: 'Ağırlık(Kg)',
          width_primary: 'Birincil genişlik',
          width_secondary: 'İkincil genişlik',
          loading_meters: 'Yükleme Sayaçları',
        },
        RightsManagement: {
          sidebar_text: 'Hak yönetimi',
        },
        PendingCustomerRequests: {
          sidebar_text: 'Bekleyen Müşteri İstekleri',
        },
        WebsiteSettings: {
          sidebar_text: 'Web sitesi ayarları',
          select_language: 'Dil Seçiniz',
          upload: 'yüklemek',
          upload_catalog: 'Kataloğu Yükle',
          upload_pallet_products: 'Palet ürünlerini yükleyin',
          upload_product: 'Ürünü yükle',
        },
        OrdersWords:{
          available_items: 'Mevcut öğeler',
          available_pallets: 'Mevcut Paletler',
          available_products: 'Mevcut Ürünler',
          created_by: 'Tarafından oluşturuldu',
          order: 'Emir',
          order_id: 'Sipariş Kimliği',
          ordered: 'sipariş edildi',
          package_id: 'paket kimliği',
          sales_assistant: 'Satış asistanı',
          search_by_name: 'İsme göre ara',
          search_company: 'Şirket ara',
          select_sales_person: 'Satış elemanını seçin',
          select_supplier: 'Tedarikçi seçin',
          set_articles: 'Makaleleri Ayarla',
          sub_category: 'Alt Kategori',
          sub_total_euro: 'Ara Toplam €',
          sub_cat: 'Alt Kedi',
        },
        AnalysisWords:{
          analysis_text: 'Analiz',
          category: 'Kategori',
          category_analysis: 'Kategori Analizi',
          change_supply_visibility: 'Tedarik Görünürlüğünü Değiştir',
          companies_category_analysis: 'Firma Kategori Analizi',
          companies_grades_analysis: 'Şirket Not Analizi',
          compare_sales_person: 'Satış elemanını karşılaştırın',
          turnover_monthly: 'Aylık Ciro',
          turnover_yearly: 'Yıllık Ciro',
          profit_monthly: 'Aylık Kâr',
          profit_yearly: 'Yıllık Kâr',
        },
        CommonWords: {
          apply: 'Uygula',
          done: 'Tamamlamak',
          edit: 'Düzenlemek',
          assign: 'Atamak',
          default: 'Varsayılan',
          delete: 'Silmek',
          cancel: 'İptal etmek',
          date: 'Tarih',
          company: 'Şirket',
          compare: 'Karşılaştırmak',
          email: 'E-posta',
          image: 'Resim',
          images: 'Görseller',
          model: 'Modeli',
          month: 'Ay',
          group: 'Grup',
          backoffice: 'Arka Ofis',
          brand_analysis: 'Marka Analizi',
          brands: 'Markalar',
          buying_percentage: 'Satın Alma Yüzdesi',
          buying_online_price: 'Çevrimiçi fiyat satın alma',
          buying_price: 'Alış fiyatı',
          cart: 'Sepet',
          confirm: 'onaylamak',
          create: 'Yaratmak',
          discount_percentage: 'İndirim %',
          items_per_page: 'Sayfa başına öğe sayısı:',
          navigation: 'Navigasyon',
          select: 'Seçme',
          view: 'Görüş',
          role: 'Rol',
          sales: 'Satış',
          yes: 'Evet',
        },
      },
    }
  },
  ned: {
    translation: {
      authentication: {
          register: {
              info: 'Vul alstublieft het onderstaande formulier in en we zullen spoedig contact met u opnemen.'
          },
          login: {},
      },
      adminDashboard: {
        Dashboard: {
          sidebar_text: 'Hoofddashboard',
        },
        Analytics: {
          sidebar_text: 'Analyse',
          analytics: 'Analyse',
        },
        ProfitAndTurnover: {
          sidebar_text: 'Winst en Omzet',
        },
        ScanningTime: {
          sidebar_text: 'Gemiddelde scantijd (sec)',
        },
        SalesPersonComparison: {
          sidebar_text: 'Vergelijk Verkoper',
        },
        SalesPersonCategoriesAnalysis: {
          sidebar_text: 'Categorieanalyse',
        },
        SalesPersonBrandAnalysis: {
          sidebar_text: 'Merkanalyse',
        },
        SalesPersonGradeAnalysis: {
          sidebar_text: 'Analyse van cijfers',
        },
        SalesCompaniesComparison: {
          sidebar_text: 'Vergelijk bedrijven',
        },
        SalesCompaniesCategories: {
          sidebar_text: 'Categorieanalyse van bedrijven',
        },
        SalesCompaniesBrands: {
          sidebar_text: 'Merkanalyse van bedrijven',
        },
        SalesCompaniesGrades: {
          sidebar_text: 'Analyse van bedrijfscijfers',
        },
        SupplyAnalysis: {
          sidebar_text: 'Aanbieding analyseren',
          searchbar_text: 'Zoekopdracht',
        },
        StockAnalysis: {
          sidebar_text: 'Voorraadanalyse',
          searchbar_text: 'Kies magazijn',
        },
        MainDashboard: {
          sidebar_text: 'Hoofddashboard',
        },
        Navigation: {
          sidebar_text: 'Navigatie',
        },
        Users: {
          sidebar_text: 'Gebruikers',
          customers_data: 'Klantgegevens',
          edit_info: 'Gegevens bewerken',
          delete_customer: 'Verwijderen',
          actions: 'Acties',
          active: 'Actief',
          add_new_staff_user: 'Nieuwe personeelsgebruiker toevoegen',
          add_supply_type: 'Aanvoertype toevoegen',
          add_user: 'Gebruiker toevoegen',
          admin: 'Beheerder',
          user: 'Gebruiker',
          enter_first_name: 'Voer Voornaam in',
          enter_last_name: 'Voer achternaam in',
          enter_user_password: 'Voer het gebruikerswachtwoord in',
          not_active: 'Niet actief',
          pending_customer_requests: 'In behandeling zijnde klantverzoeken',
          unable_to_log_in: 'Kan niet inloggen met de opgegeven inloggegevens.',
        },
        Team: {
          sidebar_text: 'Team'
        },
        Customers: {
          sidebar_text: 'Klanten',
          main_text: 'Klantgegevens',
          my_packages: 'Mijn pakketten',
          my_orders: 'Mijn bestellingen',
          my_orders_details: 'Details van mijn bestellingen',
          add_customer: 'Voeg klanten toe',
          edit_info: 'Gegevens bewerken',
          delete_customer: 'Verwijderen',
          customer: 'Klant',
          customer_email: 'E-mailadres van klant',
          customers: 'Klanten',
          phone_number: 'Telefoon #',
        },
        Packages: {
          sidebar_text: 'Pakketten',
          create_package_text: 'Pakket aanmaken',
          warehouse_text: 'Magazijn',
          customer_text: 'Klanten',
          truck_capacity_text: 'Vrachtwagencapaciteit (0 m)',
          selling_price_text: 'Verkoopprijs',
          brands_text: 'Merken',
          fetch_products_button_text: 'Toepassen',
          fetch_from_rest: 'Haal uit rust',
          supply_id_please: 'Geef de leverings-ID op.',
          pair_all_button: 'Alles koppelen',
          pair_button: 'Koppelen',
          smart_pair_switch: 'Slim Koppelen',
          edit_info: 'Gegevens bewerken',
          are_you_sure: 'Weet je het zeker?',
          article: 'Artikel',
          date_from: 'Datum van',
          date_to: 'Datum tot',
          extra: 'Extra',
          extra_items: 'Extra artikelen',
          grade_points_range: 'Bereik punten',
          grand_total_euro: 'Totaal totaal €',
          pending_packages: 'Pakketten in behandeling',
          status: 'Status',
          place_order: 'bestelling plaatsen',
          unselect_all: 'deselecteer alles',
          total_buying_offer: 'Totaal koopaanbod:',
          total_buying_price: 'Totale aankoopprijs:',
          total_items: 'Totaal aantal artikelen',
          total_packages: 'Totaalpakketten',
          select_pallet_supply: 'Selecteer palletaanbod',
          please_select_supplier: 'Selecteer leverancier',
        },
        AutomatedPackages: {
          sidebar_text: 'Geautomatiseerde pakketten'
        },
        PendingPackages: {
          sidebar_text: 'Pakketten in behandeling',
        },
        OrderRequests: {
          sidebar_text: 'Bestelverzoeken',
        },
        Warehouse: {
          sidebar_text: 'Magazijn',
          create_warehouse_order: 'Magazijnorder aanmaken',
          allow_warehouse_scan: 'Weet u zeker dat u het magazijn deze voorraad wilt laten scannen?',
          warehouse: 'Magazijn',
          warehouse_package: 'Magazijnpakket',
          warehouse_manager: 'Magazijnmanager',
        },
        Products: {
          sidebar_text: 'Producten',
          online_price: 'Online prijs',
          price_euro: 'Prijs €',
          product_id: 'Product-ID',
          product_id_search: 'Product-ID zoeken',
          products: 'Producten',
          products_quantity: 'Producten Hoeveelheid',
          products_selected: 'Producten geselecteerd',
          selling_price: 'Verkoopprijs',
          items_selected_percentage: 'Geselecteerde artikelen%',
          min_3_digits: 'Minimaal 3 cijfers',
          minimum_3_digits: 'Minimaal 3 cijfers',
          no_of_products_scanned: 'Aantal gescande producten',
          no_product_within_applied_filter: 'Geen product binnen het toegepaste filter',
          not_scanned_items: 'Niet gescande items',
          number_of_products_scanned: 'Aantal producten gescand',
        },
        Pallets: {
          pallet_product_detail: "Vul de palletproductgegevens in",
          mandatory_fields_text: "* Velden gemarkeerd met zijn verplicht.",
          add_pallet_product_button: '+ Palletproduct toevoegen',
          pallet: 'Pallet',
          pallets: 'Pallets',
        },
        SupplyManagement: {
          sidebar_text: 'Beheer van het aanbod',
          supply: 'Levering',
          supply_id: 'Leverings-ID',
          create_new_supply: 'Nieuw aanbod creëren',
          new_supply_id: "Nieuwe aanbod-ID",
          create_supplier_text: 'Nieuwe leverancier aanmaken',
          create_supplier_name_Text: 'Naam nieuwe leverancier',
          create_supplier_button: 'Creëren',
          add_supply: 'Voorraad toevoegen',
          all_supplies: 'Alle voorraden',
          all_supply_types: 'Alle soorten aanbod',
          all_suppliers: 'Alle leveranciers',
          manually_uploaded_prices: 'Handmatig geüploade prijzen',
          supplier: 'Leverancier',
          supplier_name: 'Naam leverancier',
          supply_inventory: 'Voorraad voorraad',
          supply_management: 'Beheer van het aanbod',
          supply_not_found: 'Aanbod niet gevonden',
          supply_type: 'Soort levering',
          supply_type_a: 'Soort voeding A ( 0 - 1 )',
          supply_type_b: 'Leveringstype B ( 0 - 1 )',
          supply_type_buying: 'Soort aanbod Inkoop ( 0 - 1 )',
          supply_type_c: 'Soort voeding C ( 0 - 1 )',
          supply_type_name: 'Naam van het leveringstype',
          supply_type_not_found: 'Aanbodtype niet gevonden',
          total_suppliers: 'Totaal leveranciers',
          total_supplies: 'Totale voorraden',
          total_supply_types: 'Totaal aanbodtypes',
          edit_supplier_information: 'Leveranciersgegevens bewerken',
          edit_supply: 'Aanbod bewerken',
          edit_supply_type: 'Aanbodtype bewerken.',
          exclude_supplies: 'Exclusief benodigdheden',
          a_grade_percentage: 'A-klasse %',
          b_grade_percentage: 'B-klasse %',
          c_grade_percentage: 'C-klasse %',
          allow_supply_to_sales: 'Weet u zeker dat u deze levering XYZ wilt toestaan aan de verkoop?',
          new_supply: 'Nieuw aanbod',
          old_supply: 'Oude voorraad',
          edit_supplier_details: 'Bewerk de volgende gegevens van de leverancier.',
          edit_supply_type_details: 'Bewerk de volgende details van het aanbodtype.',
          provide_supply_id: 'Geef de leverings-ID op.',
        },
        SupplyInventory: {
          sidebar_text: 'Voorraad voorraad',
          extra_text: 'Extra artikelen',
          scanned_text: 'Gescande artikelen',
          unscanned_text: 'Niet gescande items',
          mark_scanned_completely: 'Weet u zeker dat u XYZ wilt markeren als \'volledig gescand\'',
          restrict_supply_to_sales: 'Weet u zeker dat u dit aanbod XYZ wilt beperken tot verkoop?',
          confirm_delete_item: 'Weet u het zeker en wilt u dit item verwijderen?',
          assign_type_to_new_supply: 'Type toewijzen aan een nieuw aanbod',
          scanned: 'Gescand',
          unscanned: 'Niet gescand',
          extra: 'Extra',
          not_visible_until_completed: 'Ze zullen pas zichtbaar zijn als ze voltooid zijn.',
          scanned_items: 'Gescande artikelen',
          scanning_in_progress: 'Bezig met scannen',
        },

        CatalogManagement: {
          sidebar_text: 'Catalogusbeheer',
          catalog: 'Catalogus',
          width_primary_cm: 'Breedte primair (cm)',
          width_secondary_cm: 'Breedte secundair (cm)',
          height_primary_cm: 'Hoogte primair (cm)',
          height_secondary_cm: 'Hoogte secundair (cm)',
          depth_primary_cm: 'Diepte primair (cm)',
          depth_secondary_cm: 'Diepte secundair (cm)',
          catalog_management: 'Catalogusbeheer',
          depth_primary: 'Diepte primair',
          depth_secondary: 'Diepte secundair',
          height_primary: 'Hoogte primair',
          height_secondary: 'Hoogte secundair',
          weight_kg: 'Gewicht (kg)',
          width_primary: 'Breedte primair',
          width_secondary: 'Breedte secundair',
          loading_meters: 'Meters laden',
        },
        RightsManagement: {
          sidebar_text: 'Rechtenbeheer',
        },
        PendingCustomerRequests: {
          sidebar_text: 'In behandeling zijnde klantverzoeken',
        },
        WebsiteSettings: {
          sidebar_text: 'Website-instellingen',
          select_language: 'Selecteer Taal',
          upload: 'uploaden',
          upload_catalog: 'Catalogus uploaden',
          upload_pallet_products: 'Palletproducten uploaden',
          upload_product: 'Product uploaden',
        },
        OrdersWords:{
          available_items: 'Beschikbare artikelen',
          available_pallets: 'Beschikbare pallets',
          available_products: 'Beschikbare producten',
          created_by: 'Gemaakt door',
          order: 'Volgorde',
          order_id: 'Bestel-ID',
          ordered: 'Besteld',
          package_id: 'pakket-id',
          sales_assistant: 'Verkoopassistent',
          search_by_name: 'Zoek op naam',
          search_company: 'Zoek bedrijf',
          select_sales_person: 'Selecteer verkoper',
          select_supplier: 'Leverancier selecteren',
          set_articles: 'Artikelen instellen',
          sub_category: 'Subcategorie',
          sub_total_euro: 'Subtotaal €',
          sub_cat: 'Sub-cat',
        },
        AnalysisWords:{
          analysis_text: 'Analyse',
          category: 'Categorie',
          category_analysis: 'Categorieanalyse',
          change_supply_visibility: 'Verander de zichtbaarheid van het aanbod',
          companies_category_analysis: 'Categorieanalyse van bedrijven',
          companies_grades_analysis: 'Analyse van bedrijfscijfers',
          compare_sales_person: 'Vergelijk Verkoper',
          turnover_monthly: 'Omzet maandelijks',
          turnover_yearly: 'Omzet Jaarlijks',
          profit_monthly: 'Winst maandelijks',
          profit_yearly: 'Winst jaarlijks',
        },
        CommonWords: {
          apply: 'Toepassen',
          done: 'Klaar',
          edit: 'Bewerking',
          assign: 'Toewijzen',
          default: 'Standaard',
          delete: 'Verwijderen',
          cancel: 'Annuleren',
          date: 'Datum',
          company: 'Bedrijf',
          compare: 'Vergelijken',
          email: 'E-mail',
          image: 'Afbeelding',
          images: 'Afbeeldingen',
          model: 'Model',
          month: 'Maand',
          group: 'Groep',
          backoffice: 'BackOffice',
          brand_analysis: 'Merkanalyse',
          brands: 'Merken',
          buying_percentage: '% kopen',
          buying_online_price: 'Online prijs kopen',
          buying_price: 'Koopprijs',
          cart: 'Winkelwagen',
          confirm: 'bevestigen',
          create: 'Creëren',
          discount_percentage: 'Korting %',
          items_per_page: 'Artikelen per pagina:',
          navigation: 'Navigatie',
          select: 'Selecteer',
          view: 'Weergave',
          role: 'Rol',
          sales: 'Verkoop',
          yes: 'Ja',
        },
      },
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;