import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSmartTable, CToaster } from '@coreui/react-pro';
import { filterOrderByProductID, filterOrderByID } from '../../../services/OrderService';
import '../../AdminStyles.css';
import { Order } from '../../../types';
import { ORDER_STATUS } from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { downloadExcelFile } from '../../components/GenerateExcelFile';
import { CBadge, CCol, CFormFeedback, CFormInput, CRow } from '@coreui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Utils } from '../../components/utils';
import { useTranslation } from 'react-i18next';

const OrdersScreen = () => {
  const state = useLocation();
  const navigation = useNavigate();
  const {t} = useTranslation();
  const {getOrdersData, downloadZipImages, checkPDFStatus, toast, OrdersColumns, returnDateFormat} = Utils();
  const orders: Order[] = useSelector((state: RootState) => state.orderSlice.orders);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [records, setRecords] = useState<number>(0);
  const [productErrorText, setProductErrorText] = useState<string>('');
  const [orderErrorText, setOrderErrorText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<any>();
  const [tempOrders, setTempOrders] = useState<Order[]>([]);
  
  const [searchTextFilterByID, setSearchTextFilterByID] = useState<any>();
  const [tempOrderFilterByID, setTempOrderFilterByID] = useState<Order[]>([]);

  const filterOrders = (product_id: number) => {
    filterOrderByProductID(product_id).then((response) => {
      setTempOrders([{...response.data}]);
      setProductErrorText('');
      setOrderErrorText('');
    }).catch((e) => {
      if(e?.response?.data?.error) {
        setProductErrorText(e?.response?.data?.error);
      }
      setTempOrders([]);
    });
  };

  // Search Order By ID
  const filterByOrderId = (orderID: number) => {
    filterOrderByID(orderID).then((response) => {
      setTempOrderFilterByID([{...response.data}]);
      setOrderErrorText('');
      setProductErrorText('');
    }).catch((e) => {
      console.log(e?.response?.status)
      if(e?.response?.status == '404') {
        setOrderErrorText(e?.response?.data?.detail);
      }
      setTempOrderFilterByID([]);
    });
  };
  
  useEffect(() => {
    // console.log(state?.state?.reload)
    if(state?.state?.reload == true) {
      setLoading(state?.state?.reload);
      getOrdersData();
    }
  }, []);

  useEffect(() => {    
    // if(orders?.length > 0) {
    setLoading(false);      
    // }
    setRecords(orders.length);
  }, [orders?.length]);
 
  return (
    <div className='website-settings-main-container'>
      <h1 className='margin-bottom-12'><b>{t('adminDashboard.OrderRequests.sidebar_text')}</b></h1>
      <CRow className='margin-bottom-44'>
        <CToaster push={toast} placement='top-end' />
        <CCol xs={3}>
          <CFormInput
            placeholder={`${t('adminDashboard.Products.product_id_search')}`}
            value={searchText}
            onChange={(e: any) => {
              setSearchText(e.target.value);
              if(e.target.value.length >= 8) {
                filterOrders(e.target.value)
              }
              else {
                setTempOrders([])
              }
            }}
            style={{marginTop: 6, marginBottom: 16}}
          />
          {
            productErrorText?.length > 0 ?
              <CFormFeedback style={{color: 'red'}}>Error! {productErrorText}</CFormFeedback>
            : null
          }
        </CCol>
        {/* Filter Order By ID */}
        <CCol xs={3}>
          <CFormInput
            placeholder={`${t('adminDashboard.OrdersWords.order_id_search')}`}
            value={searchTextFilterByID}
            onChange={(e: any) => {
              setSearchTextFilterByID(e.target.value);
              if(e.target.value.length > 0) {
                filterByOrderId(e.target.value)
              }
              else {
                setTempOrderFilterByID([])
              }
            }}
            style={{marginTop: 6, marginBottom: 16}}
          />
          {
            orderErrorText?.length > 0 ?
              <CFormFeedback style={{color: 'red'}}>Error! {orderErrorText}</CFormFeedback>
            : null
          }
        </CCol>
      </CRow>
       
      <CSmartTable
        columns={OrdersColumns}
        columnSorter={true}
        tableFilter
        tableFilterLabel={`${t('adminDashboard.CommonWords.search')}`}
        tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
        loading={loading}
        items={
          tempOrderFilterByID?.length > 0 ? tempOrderFilterByID :
          tempOrders?.length > 0 ? tempOrders :
          orders
        }
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
        noItemsLabel={`${t('adminDashboard.CommonWords.no_items_found')}`}
        pagination={true}

        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(records / itemsPerPage) || 1,
        }}

        tableHeadProps={{
          color: 'info',
        }}

        tableProps={{
          hover: true,
          responsive: true,
          striped: true,
        }}

        tableBodyProps={{
          className: 'align-middle'
        }}

        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
        }}

        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}

        scopedColumns={{
          id: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.id}</b>
              </td>
            )
          },
          package_id: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.package_id}</b>
              </td>
            )
          },
          package_customer_company: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.package_customer_company}</b>
              </td>
            )
          },
          package_sub_total: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.package_sub_total}</b>
              </td>
            )
          },
          package_discount: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.package_discount}</b>
              </td>
            )
          },
          package_total: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.package_total}</b>
              </td>
            )
          },
          package_quantity: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.package_quantity}</b>
              </td>
            )
          },
          package_creator_first_name: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.package_creator_first_name}</b>
                <div>
                  {
                    item.created_at ? <CBadge className='margin-top-12' color={'info'}><b>{returnDateFormat(item.created_at)}</b></CBadge> : null
                  }
                </div>
              </td>
            )
          },
          truck_capacity: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.truck_capacity}</b>
              </td>
            )
          },
          items_selected: (item: any) => {
            return (
              <td className='text-center'>
                <b>{item.items_selected}</b>
              </td>
            )
          },
          
          package_status: (item: any) => {
            return (
              <td className='text-center'>
                <CBadge color={'success'}><b>{item.status == 'P' ? t('adminDashboard.CommonWords.confirm') : ORDER_STATUS[item.status]}  </b></CBadge>
                <div>
                  {
                    item.is_warehouse_package ? <CBadge className='margin-top-12' color={'dark'}><b>{t('adminDashboard.Warehouse.sidebar_text')}</b></CBadge> : null
                  }
                </div>
              </td>
            )                
          },
          sub_total: (item: any) => {
            return (
              <td className='text-center'>
                {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.sub_total)}                 
              </td>
            )
          },
          total: (item: any) => {
            return (
              <td className='text-center'>
                {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.total)}
              </td>
            )
          },
          show_details: (item: any) => {    
            return (
              <td className='text-center'>
                <CDropdown>
                  <CDropdownToggle className="elv-btn" href="#" style={{width: 110}}>{t('adminDashboard.CommonWords.action')}</CDropdownToggle>
                  <CDropdownMenu style={{position: 'absolute', right: 20}}>
                  <CDropdownItem  onClick={() =>  {downloadZipImages(item.package_products)}}>{t('adminDashboard.CommonWords.download_images')}</CDropdownItem>
                    <CDropdownItem  onClick={() =>  {downloadExcelFile(item.package, true)}}>{t('adminDashboard.CommonWords.download_excel')}</CDropdownItem>
                    <CDropdownItem  onClick={() =>  {navigation('/admin/dashboard/packages/details', {state: {packageItem: {id: item.package}}})}}>{t('adminDashboard.CommonWords.view')}</CDropdownItem>
                    <CDropdownItem onClick={() => {checkPDFStatus(item.id, 'orderPDF')}}>{t('adminDashboard.CommonWords.download_pdf')}</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </td>
            )
          },
        }}
      />
    </div>
  );
};

export default OrdersScreen;
