import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import '../../admin/AdminStyles.css';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormInput, CFormLabel, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { LoggedinUser } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Utils } from '../../admin/components/utils';
import { updateAuthUser } from '../../services/AuthService';
import { setSelectedTab } from '../../redux/slices/CustomerTotalOrdersSlice';
import { countries } from 'country-flag-icons';
import { GR, RO, RU, DE, US } from 'country-flag-icons/react/3x2'
import { setLoggedinUser } from '../../redux/slices/AuthSlice';
const CustomerWebsiteSettingsScreen = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const [language, changeLanguage] = useState<string | undefined>(authUser?.preferred_language);
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const {changePassword} = Utils();
        
    useEffect(()=>{
        i18n.changeLanguage(language);
        const formData = {
            preferred_language: language,
        }
        if(authUser?.id) {
            updateAuthUser(formData).then((response) => {
                dispatch(setLoggedinUser(response.data));
            }).catch((e) => {
            })
        }
    },[language]);
    useEffect(() => {
        dispatch(setSelectedTab(3));
    },[])
    
    return (
        <div className='website-settings-main-container'>
            <h1><b>{t('adminDashboard.WebsiteSettings.sidebar_text')}</b></h1>
            <CRow>
                <>
                    <CCol xs={12} className='margin-top-24'>
                        <CFormLabel><b>{t('adminDashboard.WebsiteSettings.select_language')}</b></CFormLabel>
                        <CDropdown className='margin-left-16'>
                            <CDropdownToggle className='elv-btn' color="info">{language}</CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem href="#" onClick={() => changeLanguage('en')}>English {countries.includes('US') == true ? <US title="US" className='flag-style'/> : ""}</CDropdownItem>
                                <CDropdownItem href="#" onClick={() => changeLanguage('de')}>Deutsch {countries.includes('DE') == true ? <DE title="Deutschland" className='flag-style'/> : ""}</CDropdownItem>
                                <CDropdownItem href="#" onClick={() => changeLanguage('ru')}>Russian {countries.includes('RU') == true ? <RU title="Russian" className='flag-style'/> : ""}</CDropdownItem>
                                <CDropdownItem href="#" onClick={() => changeLanguage('ro')}>Romanian {countries.includes('RO') == true ? <RO title="Romanian" className='flag-style'/> : ""}</CDropdownItem>
                                <CDropdownItem href="#" onClick={() => changeLanguage('gr')}>Greek {countries.includes('GR') == true ? <GR title="Greek" className='flag-style'/> : ""}</CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </CCol>
                    <CCol xs={4} className='margin-top-24'>
                        <CFormLabel><b>{t('adminDashboard.WebsiteSettings.change_password')}</b></CFormLabel>
                        <CFormInput
                            type='password'
                            placeholder={`${t('adminDashboard.WebsiteSettings.new_password')}`}
                            value={newPassword}
                            onChange={(e: any) => {
                                setNewPassword(e.target.value);
                            }}
                            
                        />
                        <CFormInput
                            type='password'
                            placeholder={`${t('adminDashboard.WebsiteSettings.confirm_new_password')}`}
                            value={confirmNewPassword}
                            onChange={(e: any) => {
                                setConfirmNewPassword(e.target.value);
                            }}
                            className='margin-top-16'
                        />
                        <CButton className='elv-btn margin-top-36' size='sm' onClick={()=> changePassword(newPassword, confirmNewPassword)}>{t('adminDashboard.CommonWords.confirm')}</CButton>
                    </CCol>
                </>
            </CRow>
        </div>
    );
};
export default CustomerWebsiteSettingsScreen;
