import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface PackageState {
  totalPending: number,
  totalActive: number,
  loading: boolean,
  error: string | null,
}

const initialState: PackageState = {
  totalPending: 0,
  totalActive: 0,
  loading: false,
  error: null,

}

const customerActivepackagesSlice = createSlice({
  name: 'activePackageSlice',
  initialState,
  reducers: {
    setPackageCountStart(state) {
      state.loading = true;
      state.error = null;
    },

    setPackageCountSuccess(state, action: PayloadAction<{ totalPending: number; totalActive: number }>) {
      state.totalPending = action.payload.totalPending;
      state.totalActive = action.payload.totalActive;
      state.loading = false;
    },

    setPackageCountFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const { setPackageCountStart, setPackageCountSuccess, setPackageCountFailure } = customerActivepackagesSlice.actions;
export default customerActivepackagesSlice.reducer;
