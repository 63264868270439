import { useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CButton, CFormInput, CForm, CFormLabel, CFormFeedback, CToaster, CSpinner, CCol, CRow} from '@coreui/react';
import { useSelector } from 'react-redux';
import { addProductManualPrices } from '../../../services/ProductService';
import { RootState } from '../../../redux/store';
import { LoggedinUser, ExcelDataObject } from '../../../types';

import * as XLSX from 'xlsx';
import { PriceDuplicateErrorToast } from '../create-package-components/ToastsComponent';
import { Utils } from '../utils';
import { cilCloudDownload } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import GenericConfirmationPopup from "../modals/DeleteItemModal";
import { useTranslation } from 'react-i18next';

interface AddManualPricesProps {
    showCreatedToast: () => void;
    handleDownload: () => void;
}
const AddManualPrices = (props: AddManualPricesProps) => {
    const {showCreatedToast, handleDownload} = props;
    const {getManualPrices} = Utils();
    const {t} = useTranslation();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [sellingPriceValid, setSellingPriceValid] = useState<boolean>(true);
    const [columnsNamesValid, setColumnsNamesValid] = useState<boolean>(true);
    const [missingColumns, setMissingColumns] = useState<string[]>([]);
    const [excelData, setExcelData] = useState<ExcelDataObject[]>([]);
    const [toast, addToast] = useState<any>(null);
    const [errorText, setErrorText] = useState<string>('');
    const [popupText, setPopupText] = useState<string[]>(['', '', '']);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);

    const isFileValid = (excelData : any):  boolean => {
        const columns = Object.keys(excelData[0] || {});
        const requiredColumns = ['Selling_Price', 'Lager_Number','Buying_Price','Buying_Online_Price'];
        const missingColumns = requiredColumns.filter(
          (column) => !columns.includes(column)
        );

        if (missingColumns.length > 0) { // if the column is missing return false and do not process further.
          setMissingColumns(missingColumns);
          setColumnsNamesValid(false);
          return false;
        } else { // if column exists then check their data types e.g. check if the selling prices are numbers only.
            setColumnsNamesValid(true);
            const invalidSellingPriceRows = excelData.filter(
                (row: any) => isNaN(row.Selling_Price)
            );
            const invalidBuyingPriceRows = excelData.filter(
                (row: any) => isNaN(row.Buying_Price)
            );
            const invalidBuyingOnlinePriceRows = excelData.filter(
                (row: any) => isNaN(row.Buying_Online_Price)
            );

            if(invalidSellingPriceRows.length > 0) {
                setSellingPriceValid(false);
                setErrorText('Error: Please check the selling prices are in 2 digits decimal and correct format.');
                return false;
            }
            else if(invalidBuyingPriceRows.length > 0) {
                setSellingPriceValid(false);
                setErrorText('Error: Please check the buying prices are in 2 digits decimal and correct format.');
                return false;
            }
            else if(invalidBuyingOnlinePriceRows.length > 0) {
                setSellingPriceValid(false);
                setErrorText('Error: Please check the buying online prices are in 2 digits decimal and correct format.');
                return false;
            }   
            else {
                // selling prices are valid.
                setSellingPriceValid(true);
                return true;
            }
        }  
    }
    const checkFileAmbigious = (excelData: any) => {
        const isBuyingPriceZero = excelData.filter((row: any) => row.Buying_Price == 0);
        const isBuyingOnlinePriceZero = excelData.filter((row: any) => row.Buying_Online_Price == 0);
        const isSellingPriceLess = excelData.filter((row: any) => row.Selling_Price < 60);

        let bodyText: string[] = ["", "", ""];

        if(isBuyingPriceZero?.length > 0) {
            bodyText[0] =`This file contains some product having buying price = 0`;
        }
        if (isSellingPriceLess?.length > 0) {
            bodyText[1] = "This file contains some product having selling price is less than 60 ";
        }
        if(isBuyingOnlinePriceZero?.length > 0) {
            bodyText[2] = "This file contains some product having buying online price = 0";
        }
        setPopupText(bodyText);
    }
    const handleFileUpload = (e: any) => {
        const file = e.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event: any) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
            const sheet = workbook.Sheets[sheetName];
            const excelData: ExcelDataObject[] = XLSX.utils.sheet_to_json(sheet);
            if(isFileValid(excelData)) {
                checkFileAmbigious(excelData);
                // process further and send data to backend.
                setExcelData(excelData);
            }
            else {
                // No process further.
                setExcelData([]);
            }
        };
        reader.readAsArrayBuffer(file);
       }
       const addProductPrices = () => {
            setShowConfirmationPopup(false);
            setLoading(true);
            const excelDataUpdated = excelData.map((item) => {
                item.created_by = authUser.id;
                return item;
            })
            addProductManualPrices(excelDataUpdated).then((response) => {
                showCreatedToast();
                getManualPrices();
                setSellingPriceValid(true);
                setErrorText('');
                setLoading(false);
                setPopupText(['','']);
            }).catch((e: any) => {
                console.log(e?.response?.data?.Selling_Price);
                if(e?.response?.data?.Selling_Price) {
                    setSellingPriceValid(false);
                    setErrorText(e?.response?.data?.Selling_Price);
                }
                if(e?.response?.data?.detail) {
                    setSellingPriceValid(false);
                    setErrorText(e?.response?.data?.detail);
                }
                addToast(PriceDuplicateErrorToast(e?.response?.data?.detail, ()=>{addToast(null)}));
                setLoading(false);
                setPopupText(['','']);
            });
       }
   
    return (
        <>
        {
            loading ?
                <CRow>
                    <CCol xs = {12} className='margin-top-16 text-center'>
                        <CSpinner />
                    </CCol>
                </CRow>
            : null
        }
         {
          showConfirmationPopup ?
            <GenericConfirmationPopup
                size='lg'
                isModalVisible={showConfirmationPopup}
                headerText={"Are you sure?"}
                bodyText={<p style={{color:'red'}}>{popupText[0]}</p>}
                bodyDetail={
                    <>
                    <p style={{color:'red'}}>
                        {popupText[1]}
                    </p>
                    <p style={{color:'red'}}>
                        {popupText[2]}
                    </p>
                    {
                        popupText[0]?.length > 0 || popupText[1]?.length > 0 || popupText[2]?.length > 0 ?
                        <>
                            <div>{t('adminDashboard.WebsiteSettings.review_price_text')}</div>
                            <ul>
                                <li>{t('adminDashboard.WebsiteSettings.review_buying_price_text')}</li>
                                <li>{t('adminDashboard.WebsiteSettings.review_selling_price_text')}</li>
                                <li>{t('adminDashboard.WebsiteSettings.review_buyingonline_price_text')}</li>
                            </ul>
                            <div>Please contact IT team if you are not sure. before continuing.</div>
                        </>
                        : null
                    }
                   
                </>
                }
                closeModal={() => setShowConfirmationPopup(false)}
                confirmButtonText={`${t('adminDashboard.CommonWords.confirm')}`}
                onClickConfirmation={addProductPrices}
            /> : null
        }
        <CForm style={{marginBottom: 16}}>
            <CToaster push={toast} placement="top-end" />
            <CFormLabel className='margin-top-24'><b>File Format: Lager_Number, Selling_Price, Buying_Price, Buying_Online_Price</b></CFormLabel>
            <CFormInput
                type={"file"}
                placeholder=' upload a xlsx file only'                                     
                onChange={handleFileUpload}                                       
                accept=".xlsx"
            />
            <CFormFeedback invalid={sellingPriceValid} style={{color: 'red', marginTop: 16}}>{errorText}</CFormFeedback>
            <CFormFeedback invalid={columnsNamesValid} style={{color: 'red', marginTop: 16}}>Error: The following required columns are missing:</CFormFeedback>
            {!columnsNamesValid ?  missingColumns.join(', ') : ""}
            <CButton className='margin-top-12' color="primary" onClick={() => setShowConfirmationPopup(true)} disabled={excelData.length === 0}>upload</CButton>
        </CForm>
        
        <CButton className='elv-btn margin-top-16' onClick={handleDownload}><CIcon icon={cilCloudDownload} size='sm'/> {t('adminDashboard.CommonWords.download_template')}</CButton>
        </>
    )
};
export default AddManualPrices;
