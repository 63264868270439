import { CForm, CRow, CCol, CFormLabel, CFormInput, CButton, CTooltip} from "@coreui/react";
import { Cart, Pallet, ProductItem } from "../../../types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
    setWarehousePackageProducts, setWarehouseSetArticles, setWarehouseSelectedSetArticles, 
    setWarehouseSelectedProducts, setWarehousePallets, setWarehouseSelectedPallets
} from '../../../redux/slices/WarehousePackageSlice';
import { scanWarehousePallet, scanWarehouseProduct } from "../../../services/ProductService";
import Select from 'react-select';
import { t } from "i18next";
import { modifyPackage } from "../../../services/PackageService";
import { Utils } from "../utils";

interface WarehousePackageFltersProps {
    setSelectedCustomer: (value: any) => void;
    selectedCustomer: any;
    selectedTab: 0 | 1 | 2;
    setLoading?: (value: boolean) => void;
    showToast?: () => void;
    showCustomerToast?: () => void;
    current_package_id?: number;
    warehousePackageStatus: string;
}
const WarehousePackageFltersComponent = (props: WarehousePackageFltersProps) => {
const {selectedTab, setSelectedCustomer, setLoading, showToast, selectedCustomer, current_package_id, showCustomerToast, warehousePackageStatus} = props;
const cart: Cart = useSelector((state: RootState) => state.warehousePackageSlice.warehouseCart);
const {getPendingPackagesData, getAllProductSupplies} = Utils();
const dispatch = useDispatch();
const customers = useSelector((state: RootState) => state.usersSlice.customers);

const products: ProductItem[] = useSelector((state: RootState) => state.warehousePackageSlice.warehouseProducts);
const pallets: Pallet[] = useSelector((state: RootState) => state.warehousePackageSlice.warehousePallets);
const setArticles: ProductItem[] = useSelector((state: RootState) => state.warehousePackageSlice.warehouseSetArticles);
const customerOptions = customers.map((customer) => ({ value: customer.id, label: customer.company }));

const selectedSetArticles: number[] = useSelector((state: RootState) => state.warehousePackageSlice.warehouseSelectedSetArticles);
const selectedProducts: number[] = useSelector((state: RootState) => state.warehousePackageSlice.warehouseSelectedProducts);
const selectedPallets: any = useSelector((state: RootState) => state.warehousePackageSlice.warehouseSelectedPallets);
// Selected variables array below.
const [searchText, setSearchText] = useState<string>('');
const [isDisabledSearchInput, setIsDisabledSearchInput] = useState<boolean>(warehousePackageStatus === 'F');

useEffect(() => {
    if(searchText.length >= 8) {
        getProducts();
    }
},[searchText]);

useEffect(() => {
    setIsDisabledSearchInput(warehousePackageStatus === 'F');
}, [warehousePackageStatus]);

// Pulling code outside to avoid redudancy to show tooltip message in case of finalized (ordered) package
const searchFormInputText = <CFormInput
    placeholder={`${t('adminDashboard.CommonWords.search')}`}
    value={searchText}
    style={{paddingLeft:  searchText ?  24 : 12, cursor: isDisabledSearchInput ? 'not-allowed' : ''}}
    onChange={(e) => {
        setSearchText(e.target.value);
    }}
    disabled={isDisabledSearchInput}
/>
const customersDropDownList = <Select
    value={{value: selectedCustomer?.id, label: selectedCustomer?.company}}
    placeholder={`${t('adminDashboard.CommonWords.select')}`}
    options={customerOptions} 
    onChange={(option: any) => {
        editPackageCustomer(option?.value)
        setSelectedCustomer({id: option?.value, company: option?.label});
        showCustomerToast && showCustomerToast();
    }}
    isDisabled={isDisabledSearchInput}
/>

const getSingleProducts = () => {
    scanWarehouseProduct(searchText)
    .then((response) => {
        if(response?.data?.success == false) {
            setLoading && setLoading(false);
            showToast && showToast();
        }
        else {
            if(response?.data?.is_setarticle) {
                dispatch(setWarehouseSelectedSetArticles(selectedSetArticles));
                let alreadySelectedSetArticles = setArticles?.filter((item: ProductItem) => {
                    // First filter out the already selected articles from the current list.
                    return (selectedSetArticles.includes(item.id));
                });
                let unselectedSetArticles = !selectedSetArticles?.includes(response.data.id) ? [{...response.data}] : [];            
                setLoading && setLoading(false);
                // Now Merge these 2 arrays. 
                // This feature is for the warehouse-package so that the selected choices are not lost for the salesmen.
                dispatch(setWarehouseSetArticles([...unselectedSetArticles, ...alreadySelectedSetArticles]));
            }
            else {
                dispatch(setWarehouseSelectedProducts([...selectedProducts]));           
                let alreadySelectedProducts = products?.filter((item: ProductItem) => {
                    // First filter out the already selected articles from the current list.
                    return (selectedProducts?.includes(item.id));
                });                
                let unselectedProducts = !selectedProducts?.includes(response?.data?.id) ?  [{...response.data}] : [];                
                setLoading && setLoading(false);
                // Now Merge these 2 arrays. 
                // This feature is for the warehouse-package so that the selected choices are not lost for the salesmen.
                dispatch(setWarehousePackageProducts([...unselectedProducts, ...alreadySelectedProducts]));
            } 
        }
       
    })
    .catch((error) => {        
        setLoading && setLoading(false);
    });
}

const getProducts = () => {
    setLoading && setLoading(true);
    // Now this condition would always be true due to the min and max grade limits.
    if(selectedTab === 0) {// fetch only products.
        getSingleProducts();
    }
    else if(selectedTab === 1){// fetch only set articles.
        getPallets();
    }
    else {
        setLoading && setLoading(false);
    }
  };

  const getPallets = () => {
    scanWarehousePallet(searchText).then((response) => {
        if(response?.data?.success == false) {
            setLoading && setLoading(false);
            showToast && showToast();
        }
        else {
            dispatch(setWarehouseSelectedPallets(selectedPallets));
            let alreadySelectedPallets = pallets.filter((item: Pallet) => {
                // First filter out the already selected articles from the current list.
                return (selectedPallets.includes(item.id));
            });
            let unselectedPallets = !selectedPallets.includes(response.data.id) ? [{...response.data}] : [];
            setLoading && setLoading(false);
            // Now Merge these 2 arrays. 
            // This feature is for the warehouse-package so that the selected choices are not lost for the salesmen.
            dispatch(setWarehousePallets([...unselectedPallets, ...alreadySelectedPallets])); 
        }
    }).catch((e) => {
        setLoading && setLoading(false);
        // console.log('error while getting pallets: ', e);
        dispatch(setWarehousePallets([]));
    })
}
const editPackageCustomer = (customer_id: number) => {
    if(current_package_id) {
        let productIds: any = [];
        productIds = [...cart.standalone, ...cart.unpaired, ...cart.pallets];
        cart?.paired.map((item)=>{
            productIds =  [...productIds, ...item.set_articles];
        });
        let formData = {
            customer: customer_id,
            products: productIds,
        }
        modifyPackage(current_package_id, formData).then((response) => {
            getPendingPackagesData();
            getAllProductSupplies();
        }).catch((e) => {
            
        })
    }
}

const warehouseOptions = [
    { value: 'AMM', label: 'AMM' }
];

return (
    <CForm style={{marginBottom: 16, marginTop: 24}}>
         <CRow>
            <CCol style={{marginBottom: 24, flexDirection: 'row', display: 'flex', alignItems: "center"}}>
              {
                isDisabledSearchInput ? (
                    <CTooltip content={t('adminDashboard.Packages.fetch_products_button_edit_package_placed_order_message')} placement='top'>
                         <div style={{ display: 'inline-block', width: '100%'}}>
                            {searchFormInputText}
                        </div>
                    </CTooltip>
                ) : (
                    searchFormInputText
                )
              }
              
              {
                searchText ? 
                <span
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        marginLeft: 7
                    }}
                    onClick={() => {setSearchText('')}}
                >
                    &#10005;
                </span>
                : null
              }
              
            </CCol>
          </CRow>

        <CRow>
            <CCol xs={12}>
                <CFormLabel><b>{t('adminDashboard.Packages.warehouse_text')}*</b></CFormLabel>
                <Select
                    placeholder={`${t('adminDashboard.CommonWords.select')}`}
                    value={warehouseOptions[0]}
                    options={warehouseOptions}
                    isDisabled={true}
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={12} className='padding-top-16'>
                <CFormLabel><b>{t('adminDashboard.Packages.customer_text')}*</b></CFormLabel>
                {
                isDisabledSearchInput ? (
                    <CTooltip content={t('adminDashboard.Warehouse.change_customer_already_ordered_message')} placement='top'>
                         <div style={{ display: 'inline-block', width: '100%'}}>
                            {customersDropDownList}
                        </div>
                    </CTooltip>
                ) : (
                    customersDropDownList
                )
              }
            </CCol>
        </CRow>
         
        {/* <CRow>
            <CCol xs={12} className='padding-top-16'>
                <CFormLabel><b>{t('adminDashboard.SupplyManagement.exclude_supplies')}</b></CFormLabel>
                <Select
                    placeholder={`${t('adminDashboard.CommonWords.select')}`}
                    isMulti
                    noOptionsMessage={({ inputValue: string }) => supplySearchText.length >= 3 ? 'Supply not found.' : '' }
                    options={supplyOptions}
                    onChange={(options: any) => {
                        setCurrentSupplyList(
                            options.map((option: any) => (option.label))
                        )
                    }} 
                    onInputChange = {(value: string)=>{
                        setSupplySearchText(value)
                    }}
                />
            </CCol>
        </CRow> */}
    </CForm>
)
};
export default WarehousePackageFltersComponent;
