import { CSmartTable, CToaster } from "@coreui/react-pro";
import { useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Supply } from "../../types";
import CIcon from "@coreui/icons-react";
import { cilViewColumn, cilCloudDownload, cilFile} from "@coreui/icons";
import { getProductsBySupplyId } from "../../services/ProductService";
import { useNavigate } from "react-router-dom";
import { downloadSupplierExcelFile } from "../components/GenerateExcelFile";
import { Utils } from "../components/utils";
import { CTooltip } from "@coreui/react";
interface SuppliesProps {   
    loading: boolean;
}

const SupplierOutlook = (props: SuppliesProps) => {
    const {loading} = props;
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const supplies = useSelector((state: RootState) => state.productSlice.productSupplies);    
   
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const { checkPDFStatus, toast, SupplierSupplyColumns, returnDateFormat } = Utils();

    
    
    return (
        <div>
            <CToaster push={toast} placement="top-end" />
            <CSmartTable
                columns={SupplierSupplyColumns}
                tableFilter
                tableFilterPlaceholder={'Search'}
                loading={loading}
                items={supplies.filter((item: Supply) => {return !(item?.id?.toLowerCase()?.includes('extra'))})}               
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                pagination={true}
                
                paginationProps={{
                    activePage: activePage,
                    pages: Math.ceil(supplies.length / itemsPerPage) || 1,
                }}
                tableHeadProps={{
                    color: 'info',
                }}
                tableProps={{
                    hover: true,
                    responsive: true,
                    style:{marginTop: 12},
                    striped: true,
                }}
                tableBodyProps={{
                    className: 'align-middle'
                }}

                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                    setActivePage(1)
                }}

                onItemsPerPageChange={(itemsPerPage) => {
                    setActivePage(1)
                    setItemsPerPage(itemsPerPage)
                }}
                
                scopedColumns={{
                    id:  (item: any) => {
                        return (
                          <td className='text-center'>
                             {item.id}
                          </td>
                        )
                    },
                    total_products:  (item: any) => {
                        return (
                          <td className='text-center'>
                             {item.total_products}
                          </td>
                        )
                    },
                    created_at:  (item: any) => {
                        return (
                          <td className='text-center'>
                             {returnDateFormat(item.created_at)}
                          </td>
                        )
                    },
                    // total_online_price: (item: any) => {
                    //     return (
                    //       <td className='text-center'>
                    //          {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.total_online_price)}                 
                    //       </td>
                    //     )
                    // },
                    // total_buying_price: (item: any) => {
                    //     return (
                    //       <td className='text-center'>
                    //          {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.total_buying_price)}                 
                    //       </td>
                    //     )
                    // },
                    show_details: (item: Supply) => {
                        return (
                            <td className="py-2" style={{ textAlign: 'center' }}>
                                <CTooltip content={`View products inside`} placement="top">
                                    <CIcon icon={cilViewColumn} size="xl" style={{color: '#23B2D9'}} onClick={() => {navigation('/admin/dashboard/products', {state: {supply_id: item.id}})}} />
                                </CTooltip>
                                <CTooltip content={`Download Excel File`} placement="top">
                                    <CIcon icon={cilCloudDownload} size="xl" style={{color: '#23B2D9', marginLeft: 24}} onClick={() => {downloadSupplierExcelFile(item.id) }} />
                                </CTooltip>
                                <CTooltip content={`Download PDF File`} placement="top">
                                    <CIcon icon={cilFile} size="xl" style={{color: '#23B2D9', marginLeft: 24}} onClick={() => {checkPDFStatus(item.id, 'supplyPDF') }} />
                                </CTooltip>
                            </td>
                        )
                    },
                }}
            />
        </div>
    )
};
export default SupplierOutlook;
