import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CButton, CForm, CHeader, CFormLabel, CFormSwitch } from '@coreui/react';
import { useSelector } from 'react-redux';
import { DropDownSwitchItem, Supply } from '../../../types';
import { RootState } from '../../../redux/store';
import Select from 'react-select';
import { Utils } from '../utils';
import { editProductSupplies } from '../../../services/ProductService';
import GenericConfirmationModal from '../modals/DeleteItemModal';
import { t } from 'i18next';

interface AddSupplyVisibilityProps {
    showEditedToast: () => void;
    showErrorToast: () => void;
}
const AddSupplyVisibility = (props: AddSupplyVisibilityProps) => {
    const {showEditedToast, showErrorToast} = props;
    const {getAllProductSupplies} = Utils();
    const authUser = useSelector((state: RootState) => state.authSlice.user);
    const productSupplies = useSelector((state: RootState) => state.productSlice.productSupplies);
    const [supplyOptions, setSupplyOptions] = useState<DropDownSwitchItem[]>(productSupplies.map((supply: Supply) => ({ value: supply?.id, label: `${supply?.id}`, checked: supply.is_allowed })));
    const [supplySearchText, setSupplySearchText] = useState<string>('');
    const [selectedSupply, setSelectedSupply] = useState<DropDownSwitchItem>({label: t('adminDashboard.CommonWords.min_3_digits'), value: "", checked: false});  
    const [confirmationModal, setConfirmationModal]= useState<boolean>(false);

    useEffect(()=>{
        setSupplyOptions(productSupplies.map((supply: Supply) => ({ value: supply.id, label: `${supply.id}`, checked: supply.is_allowed })));
    },[productSupplies])
    const toggleSupplyVisibility = () => {
        let formData = {
            is_allowed: !selectedSupply.checked,
            allowed_by: authUser.id,
        }
        editProductSupplies(selectedSupply.value, formData).then((response) => {
            getAllProductSupplies();
            showEditedToast();
            setSelectedSupply({...selectedSupply, checked: !selectedSupply.checked});
            setConfirmationModal(false);
        }).catch((e) => {
            showErrorToast();
            setSelectedSupply({label: t('adminDashboard.CommonWords.min_3_digits'), value: "", checked: false});
        })
    }

    return (
        <div>
             <GenericConfirmationModal
                size={'lg'}
                isModalVisible={confirmationModal}
                closeModal={()=> setConfirmationModal(false)}
                headerText={'Are you sure?'}
                bodyText={selectedSupply?.checked? `Are you sure you want to restrict this supply ${selectedSupply?.value} to sales.` : `Are you sure you want to allow this supply ${selectedSupply?.value} to sales.`}
                confirmButtonText={'Yes'}
                onClickConfirmation={() => {                    
                    toggleSupplyVisibility();
                }}
            />
            <CHeader><h2><b>{t('adminDashboard.AnalysisWords.change_supply_visibility')}</b></h2></CHeader>
            <CForm style={{marginBottom: 16, marginTop: 24}}>
                <CFormLabel><b>{t('adminDashboard.CommonWords.search')} {t('adminDashboard.SupplyManagement.supply')}</b></CFormLabel>
                <Select
                    value={selectedSupply}
                    noOptionsMessage={({ inputValue: string }) => supplySearchText.length >= 3 ?'Supply not found.' : t('adminDashboard.CommonWords.min_3_digits') }
                    placeholder={t('adminDashboard.CommonWords.min_3_digits')}
                    options={supplySearchText.length >= 3 ? supplyOptions : []}
                    onChange={(option: any) => {
                        setSelectedSupply(option);
                    }}
                    onInputChange = {(value: string)=>{
                        setSupplySearchText(value);
                    }}
                />
                {
                    selectedSupply?.value?.length > 0 ?
                    <>
                        <CFormSwitch 
                            label={selectedSupply.checked ? "Allowed": "Not Allowed"}
                            className='margin-top-24' 
                            checked={selectedSupply.checked}
                            onChange={(e)=>{
                                setConfirmationModal(true);
                            }}
                        />
                    </>
                    : null
                }
            </CForm>
           
        </div>
    )
};
export default AddSupplyVisibility;
