import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { CAvatar, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {setUsers} from '../../../redux/slices/UsersSlice';
import '../../AdminStyles.css';
import { cilPeople } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CBadge, CButton, CFormInput, CNavLink} from '@coreui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAllStaffUsers, deleteStaffUser } from '../../../services/StaffService';
import { ADMIN, BACK_OFFICE_GROUP_ID } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../components/utils';

const UsersScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const {getAllGroupsData} = Utils();
  const {t} = useTranslation();
  const users = useSelector((state: RootState) => state.usersSlice.users);
  const authUser = useSelector((state: RootState) => state.authSlice.user);
  const IS_ADMIN_OR_SUPERADMIN = authUser && (authUser?.is_superuser || authUser?.groups?.includes(ADMIN) || authUser?.groups?.includes(BACK_OFFICE_GROUP_ID));

  // Custom state variables.
  const [tempUsers, setTempUsers] = useState(users);
  const [searchText, setSearchText] = useState<string>('');
  
  useEffect (() => {
    refetchUsers();
    getAllGroupsData();
  },[])
  useEffect(() => {    
    setTempUsers(users);
  },[users]);
  const refetchUsers = () => {
    getAllStaffUsers().then((response) => {
      dispatch(setUsers(response.data));
    }).catch((e) => {
      console.log('get all staff users error: ', e);
      dispatch(setUsers([]));
    });
  }
  const deleteUser = (id: number) => {   
    deleteStaffUser(id).then((response) => {
      console.log('deletion successfull: ', response);
      refetchUsers();
    }).catch((e) => {
      console.log("ërror: ", e);
    });
  }
  const onchange = (value: string) => {
    if(value.length === 0) {
      setTempUsers(users);
    }

    else{
      let new_users = [];
      new_users = users.filter((user: any) => {
        if (user.user.first_name.toLowerCase().includes(value.toLowerCase()) || user.user.last_name.toLowerCase().includes(value.toLowerCase())){
          return true;
        }
        else return false;
      });
      setTempUsers(new_users);
    }
  };
  return (
  <div className='website-settings-main-container'>
    {
      IS_ADMIN_OR_SUPERADMIN ?
      <CNavLink
        to='/admin/dashboard/add/staff-user'
        component={NavLink}
      >
        <CButton className='elv-btn'>{t('adminDashboard.Users.add_user')}</CButton>
      </CNavLink>
      : null
    }
   
    <CFormInput
      placeholder={`${t('adminDashboard.OrdersWords.search_by_name')}`}
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
        onchange(e.target.value);
      }}
      style={{marginBottom: 16, marginTop: 24}}
    />
    <CTable align="middle" className="mb-0" hover responsive>
        <CTableHead color="light">
          <CTableRow>
            <CTableHeaderCell className="text-center">
              <CIcon icon={cilPeople} />
            </CTableHeaderCell>
            <CTableHeaderCell>User</CTableHeaderCell>           
            <CTableHeaderCell>Role</CTableHeaderCell>
            <CTableHeaderCell>Status</CTableHeaderCell>
            {
              IS_ADMIN_OR_SUPERADMIN ?
                <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
              : null
            }           
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {tempUsers.map((item: any, index: number) => (
          
            <CTableRow v-for="item in tableItems" key={index}>
              <CTableDataCell className="text-center">
                <CAvatar size="md" src={item.avatar.src}  />
              </CTableDataCell>
              <CTableDataCell>
                <div>{item.user.first_name} {item.user.last_name}</div>
                <div className="small text-medium-emphasis">
                  <span>{item.user.email ? item.user.email : ''}</span>
                 
                </div>
              </CTableDataCell>
              <CTableDataCell>
                <div className="clearfix">
                  {item.role.join(', ')}
                </div>
              </CTableDataCell>
              <CTableDataCell>
                <div className="clearfix">
                  <CBadge color={item.is_active ? 'success' : 'danger'}><b>{item.is_active ? "Active" : "Not Active"}</b></CBadge>
                </div>
              </CTableDataCell>
              {
                IS_ADMIN_OR_SUPERADMIN ?
                  <CTableDataCell className="text-center">
                    <CButton
                      shape="square"
                      size="sm"
                      onClick={() => {
                        navigation('/admin/dashboard/add/staff-user', {state: {userObject: item, editText: 'Edit Staff User', buttonText: 'Edit'}});
                      }}
                    >
                      edit
                    </CButton>
                    <CButton
                      color="danger"
                      shape="square"
                      size="sm"
                      disabled={IS_ADMIN_OR_SUPERADMIN ? false : true}
                      style={{color: 'white', marginLeft: 16}}
                      onClick={()=>{deleteUser(item.user.id)}}
                    >
                      delete
                    </CButton>
                  </CTableDataCell> : null
              }
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
};
export default UsersScreen;
