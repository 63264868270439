
import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {setWarehouseSetArticles, setWarehouseSelectedProducts, setWarehouseSelectedSetArticles, setWarehouseCart, setWarehousePackageProducts,
     setWarehousePackageTotalPrice, setWarehouseSelectedPallets, setWarehousePallets, setWarehousePackageDiscount } from '../../../redux/slices/WarehousePackageSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductItem, PossibleArticlePairs, Cart, PairedCartItem, CreatPackageObject, Pallet, Package } from '../../../types';
import { CCol, CRow, CToaster } from '@coreui/react';

// Import Custom Components.
import { getAutomaticPairs, getSetArticlePairs, syncCart, getPackage,  addProductsToPackage, removeProductsFromPackage, linkSetArticles } from '../../../services/PackageService';
import Modal from '../../components/modals/PairArticleModal';
import PackageHeaderComponent from '../../components/create-package-components/WarehousePackageHeaderComponent';
import { editProductDetails, getProductById } from '../../../services/ProductService';

import { alreadyPairedToast, pairedFoundToast, noPairFoundToast, AddedToCartToast, RemovedFromCartToast, GenericErrorToast, GenericSuccessToast} from '../../components/create-package-components/ToastsComponent';
import PlaceHolder from '../../components/product-components/PlaceHolder';
import WarehousePackageFltersComponent from '../../components/create-package-components/WarehousePackageFiltersComponent';
import WarehouseProductCardComponent from '../../components/product-card-component/warehouse-product-card';
import OrderSummary from '../../components/create-package-components/Summary';
import GenericConfirmationModal from '../../components/modals/DeleteItemModal';
import PalletCardComponent from '../../components/pallet-card-component/warehouse-pallet-cart';
import { editPalletDetails } from '../../../services/PalletService';
import { Utils } from '../../components/utils';
import { useTranslation } from 'react-i18next';

const CreateWarehousePackageScreen = () => {
    const dispatch = useDispatch();
   //  const navigation = useNavigate();
    const props = useLocation();
    // console.log(props);
    const {getPendingPackagesData, getAllProductSupplies} = Utils();
    const {t} = useTranslation();
    const cart: Cart = useSelector((state: RootState) => state.warehousePackageSlice.warehouseCart);
    const products: ProductItem[] = useSelector((state: RootState) => state.warehousePackageSlice.warehouseProducts);
    const pallets: Pallet[] = useSelector((state: RootState) => state.warehousePackageSlice.warehousePallets);
    const setArticles: ProductItem[] = useSelector((state: RootState) => state.warehousePackageSlice.warehouseSetArticles);
    const selectedProducts: number[] = useSelector((state: RootState) => state.warehousePackageSlice.warehouseSelectedProducts);
    const selectedPallets: any = useSelector((state: RootState) => state.warehousePackageSlice.warehouseSelectedPallets);
    const selectedSetArticles: number[] = useSelector((state: RootState) => state.warehousePackageSlice.warehouseSelectedSetArticles);
    const discount =  useSelector((state: RootState) => state.warehousePackageSlice.warehouseDiscount);

    const [packageItem, setPackageItem] =  useState<Package>();
    const [totalProducts, setTotalProducts] = useState<number>(0);
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [truckCapacity, setTruckCapacity] = useState<number>(0);
    const [selectedTab, setSelecteTab] = useState<0 | 1 | 2>(0); // 0 stands for the standalone products and set articles.
    const [automaticPairing, setAutomaticPairing] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [toast, addToast] = useState<any>(null);
    const [pairOptions, setPairOptions] = useState<PossibleArticlePairs | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [conflictingIds, setConflictingIds] = useState<string[]>([]);
    const [packageConfirmationModal, setPackageConfirmationModal] = useState<boolean>(false);
    const [priceInputId, setPriceInputId] = useState<string>('');
    const [priceInputValue, setPriceInputValue] = useState<number>(0);
    const [warehousePackageStatus, setWarehousePackageStatus] = useState<string>('');

    useEffect(()=> {
        if(conflictingIds?.length > 0) {
            setPackageConfirmationModal(true);
        }
    },[conflictingIds]);

    useEffect(() => {
        if(pairOptions && pairOptions?.availableOptions?.length > 0){
            setIsModalVisible(true);
        }
    }, [pairOptions]);

    useEffect(() => {
        if(cart && cart?.paired?.length >= 0){
           // call the synch cart API.
           calculatePackagePrice();
           syncCart(cart?.paired).then((response) => {
                console.log('cart synched: ');
           }).catch((e) => {
                console.log('syncing error: ');
           });
        }
    },[cart?.paired]);

    useEffect(() => {
        if(cart && cart?.standalone?.length >= 0){
           calculatePackagePrice();
        }
    },[cart?.standalone]);

     useEffect(() => {
        if(cart && cart?.pallets?.length >= 0){         
           calculatePackagePrice();
        }
    },[cart?.pallets]);
    
    useEffect(() => {
        if(cart && cart?.unpaired?.length >= 0){          
           calculatePackagePrice();
        }
    },[cart?.unpaired]);
    
    const getMissingProducts = (missingProducts: number[]) => {
        let missingProductsResponse: ProductItem[] = [];
        missingProducts.map(async (item: number) => {{
            await getProductById(item).then((response) => {
                missingProductsResponse.push(response.data); 
            }).catch((e) => {
                console.log('get products error: ', e);
            });
            if(missingProductsResponse?.length === missingProducts?.length) {
                dispatch(setWarehouseSetArticles([ ...missingProductsResponse, ...setArticles]));
            }
        }});
    }
   
    const resetToDefaults = () => {
        dispatch(setWarehouseSelectedSetArticles([]));
        dispatch(setWarehouseSelectedProducts([]));
        dispatch(setWarehouseSelectedPallets([]));
        dispatch(setWarehousePackageProducts([]));
        dispatch(setWarehousePallets([]));
        dispatch(setWarehouseSetArticles([]));
        dispatch(setWarehouseCart({
            ...cart,
            standalone: [],
            unpaired: [],
            paired: [],
            pallets: [],
        }));
        dispatch(setWarehousePackageDiscount(0));
        dispatch(setWarehousePackageTotalPrice(0));
    }
    const getPackageById = (id: number) => { // gets the packaged products and add them into the cart.  
        setLoading(true);
        getPackage(id).then((response) => {
            // Now we have to set the selected products, set articles and the cart respectively.
            let packageItem: Package = response.data;
            let updatedProducts: ProductItem[] = [];
            let updatedPallets: Pallet[] = [];
            let updatedSetArticles: ProductItem[] = [];
            let paired: PairedCartItem[] = [];
            let unpaired: number[] = [];
            let standalone: number[] = [];
            let pallets: number[] = [];
            let updatedSelectedProducts: number[] = [];
            let updatedSelectedPallets: number[] = [];
            let updatedSelectedSetArticles: number[] = [];
            
            // Set warehouse package current status
            setWarehousePackageStatus(packageItem.status);

            let productsAlreadyCombined: number[] = [];
            packageItem.set_articles.map((item: any) => {
                if (item?.combined_products && item?.combined_products?.length > 0) {
                    if(!productsAlreadyCombined.includes(item.id)) {
                        productsAlreadyCombined.push(item.id);
                        productsAlreadyCombined.push(item.combined_products[0]);
                        paired.push(
                            {
                                is_manual: true, // TODO: Currently just hardcoding the is_manual but need to updated it from backend.
                                set_articles: [item.id, item.combined_products[0]]
                            }
                        )
                    }
                    updatedSetArticles.push(item);
                    updatedSelectedSetArticles.push(item.id);
                }
                else if (item?.combined_products && item?.combined_products?.length === 0) { // checks if the item is unpaired set article.
                    unpaired.push(item.id);
                    updatedSetArticles.push(item);
                    updatedSelectedSetArticles.push(item.id);
                }
            });
            packageItem.standalone_products.map((item: any) => {
                standalone.push(item.id);
                updatedProducts.push(item);
                updatedSelectedProducts.push(item.id);
            });
            packageItem.pallets_products.map((item: any) => {
                pallets.push(item.id);
                updatedPallets.push(item);
                updatedSelectedPallets.push(item.id);
            });                
            dispatch(setWarehouseSetArticles(updatedSetArticles));
            dispatch(setWarehousePackageProducts(updatedProducts));
            dispatch(setWarehousePallets(updatedPallets));
            dispatch(setWarehouseCart({
                unpaired: unpaired,
                paired: paired,
                standalone: standalone,
                pallets: pallets,
            }));
            setTotalProducts(updatedSelectedSetArticles.length + updatedSelectedProducts.length + updatedSelectedPallets.length);
            dispatch(setWarehouseSelectedProducts(updatedSelectedProducts));
            dispatch(setWarehouseSelectedPallets(updatedSelectedPallets));
            dispatch(setWarehouseSelectedSetArticles(updatedSelectedSetArticles));
            setPackageItem(packageItem);
            setSelectedCustomer(packageItem.customer_details);
            setLoading(false);
        }).catch((e) => {
            console.log('getPackageById error: ', e);
            setLoading(false);
        });
    }

    const addToPackage = (id: number, type: string, callBack: () => void) => {
        let updatedSelectedPallets: any = [...selectedPallets];
        let updatedSelectedProducts: number[] = [...selectedProducts];
        let updatedSelectedSetArticles: number[] = [...selectedSetArticles];
      
        if(packageItem?.id) {
            let productIds: any =[id];
            let formData =  {
                "products": productIds,
                discount: discount / 100,
            }
            addProductsToPackage(packageItem.id, formData).then((response) => {
                // Add to front end after confirmation from backend.
                if(type == 'set-article') {
                    // add set articles to cart.
                    updatedSelectedSetArticles.push(id);
                    addSetArticleToCart(id);
                    dispatch(setWarehouseSelectedSetArticles(updatedSelectedSetArticles));
                    addToast(AddedToCartToast(()=>{addToast(null)}));  
                }
                else if(type == 'product') {
                    // add set articles to cart.
                    updatedSelectedProducts.push(id);
                    addProductToCart(id);
                    dispatch(setWarehouseSelectedProducts(updatedSelectedProducts));
                    addToast(AddedToCartToast(()=>{addToast(null)}));
                }
                else if(type == 'pallet') {
                    // Add pallet to cart front end.
                    updatedSelectedPallets.push(id);
                    addPalletsToCart(id); 
                    dispatch(setWarehouseSelectedPallets(updatedSelectedPallets));
                    addToast(AddedToCartToast(()=>{addToast(null)}));
                }
                callBack();
                getPendingPackagesData();
                getAllProductSupplies();
            }).catch((e) => {
                console.log(console.log('Error while editing package: ', e));
                if(e?.response?.data?.success == "False" && e?.response?.data?.conflicting_products[0]) {
                    addToast(GenericErrorToast(()=>{addToast(null)}, ` ${e?.response?.data?.conflicting_products[0]} ${e?.response?.data?.message}`));  
                }
                callBack();
            });
        }
    }

    const removeFromPackage = (id: number, type: string, callBack: () => void) => {
        let updatedSelectedPallets: any = [...selectedPallets];
        let updatedSelectedProducts: number[] = [...selectedProducts];
        let updatedSelectedSetArticles: number[] = [...selectedSetArticles];
        
        if(packageItem?.id) {
            let productIds: any  = [id];
            const pairedItem =  cart.paired.filter((paired_item) =>{return paired_item.set_articles.includes(id)})
            let formData =  {
                "products": productIds,
                discount: discount / 100,
                combined_product: pairedItem[0]?.set_articles[0] == id ? pairedItem[0]?.set_articles[1] :pairedItem[0]?.set_articles[1] == id ? pairedItem[0]?.set_articles[0] : null
            }
            removeProductsFromPackage(packageItem.id, formData).then((response) => {
                if(type == 'set-article') {
                    // remove set articles from cart.
                    updatedSelectedSetArticles = updatedSelectedSetArticles.filter((item) => {
                        return item !== id;
                    });
                    dispatch(setWarehouseSelectedSetArticles(updatedSelectedSetArticles));
                    if(cart.unpaired.includes(id)) {
                        removeUnpairedFromCart(id)
                    }
                    else {
                        removePairedFromCart(id);
                    }
                    addToast(RemovedFromCartToast(()=>{addToast(null)}));
                }
                else if(type == 'product') {
                    // remove products from cart.
                    updatedSelectedProducts = updatedSelectedProducts.filter((item) => {
                        return item !== id;
                    });
                    dispatch(setWarehouseSelectedProducts(updatedSelectedProducts));
                    removeStandaloneFromCart(id);
                    addToast(RemovedFromCartToast(()=>{addToast(null)}));  
                }
                else if(type == 'pallet') {
                    // Remove pallet from cart front end.
                    updatedSelectedPallets = updatedSelectedPallets?.filter((item: any) => {
                        return item !== id;
                    });
                    dispatch(setWarehouseSelectedPallets(updatedSelectedPallets));
                    removePalletsFromCart(id);
                    addToast(RemovedFromCartToast(()=>{addToast(null)}));
                }
                callBack();
                getPendingPackagesData();
                getAllProductSupplies();
            }).catch((e) => {
                callBack();
                console.log(console.log('Error while editing package: ', e));
            });
        }
    }

    const updateSelectedProducts = (product: number, callBack?: () => void) => {
        let updatedSelectedProducts: number[] = [...selectedProducts];
        if(!updatedSelectedProducts.includes(product)) {
            addToPackage(product, 'product', callBack? callBack : () =>{});
        }
        else {
            removeFromPackage(product, 'product', callBack? callBack : () =>{});
        }
    }

    const updateSelectedPallets = (pallet: any, callBack?: () => void) => {
        let updatedSelectedPallets: any = [...selectedPallets];
        if(!updatedSelectedPallets?.includes(pallet)){ // add product to cart and selected product items list.               
            addToPackage(pallet, 'pallet', callBack? callBack : () =>{});
        }
        else { // deselect / remove the product item from the cart. when click.
            removeFromPackage(pallet, 'pallet', callBack? callBack : () =>{});
        }
    }

    const updateSelectedSetArticles = (setArticle: number, callBack?: () => void) => {
        let updatedSelectedSetArticles: number[] = [...selectedSetArticles];
      
        if(!updatedSelectedSetArticles.includes(setArticle)) {
            addToPackage(setArticle, 'set-article', callBack? callBack : () =>{});
        }
        else {
            removeFromPackage(setArticle, 'set-article', callBack? callBack : () =>{});
        }
    }

    const addProductToCart = (product: number) => {
        let standalone: number[] = [...cart.standalone];
        let updatedStandalone = standalone.filter((cartItem: number) => {
            return (cartItem === product)
        });
        if(updatedStandalone?.length === 0) { // the product is not in the cart to we have to append it.
            standalone.push(product);
            dispatch(setWarehouseCart({
                ...cart,
                standalone: standalone,
            }));
        } // else do nothing.
    }
    
    const addPalletsToCart = (pallet: any) => {
        let pallets: any = [...cart.pallets];
        let updatedPallets = pallets.filter((cartItem: any) => {
            return (cartItem === pallet)
        });
        if(updatedPallets?.length === 0) { // the product is not in the cart to we have to append it.
            pallets.push(pallet);
            dispatch(setWarehouseCart({
                ...cart,
                pallets: pallets,
            }));
        } // else do nothing.
    }
    
    const addSetArticleToCart = (selectedSetArticle: number, suggestedPair?: number) => {
        let paired: PairedCartItem[] = [...cart?.paired];
        let unpaired: number[] = [...cart.unpaired];
        
        if(!suggestedPair) {
            // If there is no second argument then it means it has been called just to add an item in the cart.
            let updatedUnpaired = unpaired.filter((cartItem: number) => {
                return (cartItem === selectedSetArticle)
            });
            if(updatedUnpaired?.length === 0) { // the set article is not in the cart to we have to append it.
                unpaired.push(selectedSetArticle);
            }
            dispatch(setWarehouseCart({
                ...cart,
                unpaired: unpaired,
            }));
        }
        else {
            // If there is a second argument passed to this function it means we have to pair these 2 and, 
            // check if one of them is already paired then give error message to the user.
            let updatedPaired = paired.filter((cartItem: PairedCartItem) => {
                return (cartItem.set_articles.includes(selectedSetArticle) || cartItem.set_articles.includes(suggestedPair))
            });
            if(updatedPaired?.length === 0) { // Both of the articles are not paired and this is the simplest case.
                paired.push({
                    is_manual: true,
                    set_articles: [
                        selectedSetArticle,
                        suggestedPair,
                    ]
                });
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });
            }
            else if(updatedPaired?.length === 2) { // Both of them are in the cart but they are single, not paired.
                updatedPaired.map((item: any) => {
                    paired = paired.filter((cartItem: PairedCartItem) => {
                        return (!cartItem.set_articles.includes(item.set_articles[0]))
                    });
                })
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );

                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });
            }
            else if(updatedPaired?.length === 1 && updatedPaired[0].set_articles?.length === 2) { // Optional : we can show error to the user that which one is already paired which one is not.
                // one of them is already paired. show error toast
                addToast(alreadyPairedToast(()=>{addToast(null)}));               
            }

            else if(updatedPaired?.length === 1 && updatedPaired[0].set_articles?.length === 1 && updatedPaired[0].set_articles.includes(selectedSetArticle)) {
                paired = paired.filter((cartItem: PairedCartItem) => {
                    return (!cartItem.set_articles.includes(selectedSetArticle))
                });
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );

                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });  
            }
            else if(updatedPaired?.length === 1 &&  updatedPaired[0].set_articles?.length === 1 && updatedPaired[0].set_articles.includes(suggestedPair)) {            
                paired = paired.filter((cartItem: PairedCartItem) => {
                    return (!cartItem.set_articles.includes(suggestedPair))
                });
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });                   
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });  
            }
            dispatch(setWarehouseCart({
                ...cart,
                unpaired: unpaired,
                paired: paired,
            }));
        }       
    }

    const removePairedFromCart = (setArticle: number) => {
        // this can also be used in case of un pair the set articles
        // removing any one of them from the cart should remove both.
        let updatedSelectedSetArticles = [...selectedSetArticles];
        let paired: PairedCartItem[] = [...cart?.paired];

        let updatedPaired = paired.filter((cartItem: PairedCartItem) => {
            return (cartItem.set_articles.includes(setArticle))
        });

        updatedPaired[0].set_articles.map((cartItem: number) => { // First remove this article also from the selected set article array list.
            updatedSelectedSetArticles = updatedSelectedSetArticles.filter((item: number) => {
                return item !== cartItem;
            });          
        })

        paired = paired.filter((cartItem: PairedCartItem) => { // // then remove this article also from paired array of the cart.
            return (!cartItem.set_articles.includes(setArticle))
        });

        dispatch(setWarehouseCart({
            ...cart,
            paired: paired,
        }));

        dispatch(setWarehouseSelectedSetArticles(updatedSelectedSetArticles));
    }

    const removeUnpairedFromCart = (setArticle: number) => {        
        // This function can be done using simple splice.      
        let unpaired: number[] = [...cart.unpaired];

        unpaired = unpaired.filter((cartItem: number) => { // // then remove this article also from the cart.
            return (!(cartItem === setArticle))
        });
        dispatch(setWarehouseCart({
            ...cart,
            unpaired: unpaired,
        }));        
    }

    const removeStandaloneFromCart = (product: number) => {
        // This function can be done using simple splice.       
        let standalone: number[] = [...cart.standalone];
        standalone = standalone.filter((cartItem: number) => { // // then remove this product also from the cart.
            return (!(cartItem === product))
        });
        dispatch(setWarehouseCart({
            ...cart,
            standalone: standalone,
        }));        
    }

    const removePalletsFromCart = (pallet: any) => {
        // This function can be done using simple splice.        
        let pallets: any = [...cart.pallets];

        pallets = pallets?.filter((cartItem: any) => { // // then remove this product also from the cart.
            return (!(cartItem === pallet))
        });
        dispatch(setWarehouseCart({
            ...cart,
            pallets: pallets,
        }));        
    }

    const pairSetArticle = (selectedItem: ProductItem, option: ProductItem) => {
        // This function is only meant to be used for manual pairing
    
        let updatedSelectedSetArticles: number[] = [...selectedSetArticles];      
        const IsSetArticleSelected = updatedSelectedSetArticles.includes(option.id);
        const IsSetArticleExists = setArticles.some(article => article.id === option.id);
        
        if(packageItem?.id){
            let productIds: number[] =[selectedItem.id];
            let formData =  {
                "products": productIds,
                discount: discount / 100,
                combined_product: option.id,
            }
            addProductsToPackage(packageItem.id, formData).then((response) => {
                if(!updatedSelectedSetArticles.includes(selectedItem.id)) {
                    updatedSelectedSetArticles.push(selectedItem.id); // Currently pressed pair item will be selected.
                }

                if (option?.article && !IsSetArticleSelected && !IsSetArticleExists) {
                    // we have to pair           
                    dispatch(setWarehouseSetArticles([...setArticles, option]));
                    updatedSelectedSetArticles.push(option.id);
                }
        
                // In the below conditions, the pair is on the front end side then we push it in our selected set article array.
                else if (option?.article?.length > 0 && !IsSetArticleSelected && IsSetArticleExists) {
                    updatedSelectedSetArticles.push(option.id); // Currently pressed pair item will be selected.
                }
                else {
                    // No pair found.
                }
                addSetArticleToCart(selectedItem.id, option.id);
                dispatch(setWarehouseSelectedSetArticles(updatedSelectedSetArticles));
        
                addToast(pairedFoundToast(()=>{addToast(null)}));
                addToast(AddedToCartToast(()=>{addToast(null)}));
                getPendingPackagesData();
                getAllProductSupplies();
            }).catch((e) => {
                console.log(console.log('Error while editing package: ', e));
                if(e?.response?.data?.success == "False" && e?.response?.data?.conflicting_products[0]) {
                    addToast(GenericErrorToast(()=>{addToast(null)}, ` ${e?.response?.data?.conflicting_products[0]} ${e?.response?.data?.message}`));  
                }
            })
        }
    };
     
    const handleIndividualAutomaticPairingResponse = (response: {
        paired_sets: PairedCartItem[],
        unpaired_ids: number[],
        already_existing_temp_sets: PairedCartItem[],
    }) => {
        let paired: PairedCartItem[] = [];
        let unpaired: number[] = [];
        let missingProducts: number[] = [];
        let updatedSelectedSetArticles =  [...selectedSetArticles];
        if(response.paired_sets?.length > 0) {
            response.paired_sets.map((item: PairedCartItem) => {
                paired.push(item);
                unpaired = cart.unpaired.filter((unpairedItem: number) => {
                    return (unpairedItem !== item.set_articles[0] && unpairedItem !== item.set_articles[1]);
                })
                if(!setArticles.some(article => article.id === item.set_articles[0])){
                    // if the corresponding pair is not loaded on the front end then we have to bring it from backend.
                    updatedSelectedSetArticles.push(item.set_articles[0]);
                    missingProducts.push(item.set_articles[0]);
                }
                else if(setArticles.some(article => article.id === item.set_articles[0]) && !updatedSelectedSetArticles.includes(item.set_articles[0])) {
                    // if the corresponding pair is loaded on the front end but it is not in the selected list.
                    updatedSelectedSetArticles.push(item.set_articles[0]);
                }
                // Checks both of the paired articles coming from backend if any one of them is not loaded then we have to get it from server.
                if(!setArticles.some(article => article.id === item.set_articles[1])) {
                    // if the corresponding pair is not loaded on the front end then we have to bring it from backend.
                    updatedSelectedSetArticles.push(item.set_articles[1]);
                    missingProducts.push(item.set_articles[1]);
                }
                else if(setArticles.some(article => article.id === item.set_articles[1]) && !updatedSelectedSetArticles.includes(item.set_articles[1])) {
                    // if the corresponding pair is loaded on the front end but it is not in the selected list.
                    updatedSelectedSetArticles.push(item.set_articles[1]);
                }
            });
            dispatch(setWarehouseCart(
                {
                    ...cart,
                    paired: [...cart.paired, ...paired],
                    unpaired: [...unpaired],
                }
            ));
            addToast(pairedFoundToast(()=>{addToast(null)}));
        }
        else {
            addToast(noPairFoundToast(()=>{addToast(null)}));
        }

        if(response?.unpaired_ids?.length > 0) {          
            response.unpaired_ids.map((item: number) => {
                updatedSelectedSetArticles.push(item);
                if(!cart.unpaired.includes(item)) {
                    unpaired.push(item);
                }
            });
            dispatch(setWarehouseCart(
                {
                    ...cart,                  
                    unpaired: [...cart.unpaired, ...unpaired],
                }
            ));
          
        }
        getMissingProducts(missingProducts);
        dispatch(setWarehouseSelectedSetArticles(updatedSelectedSetArticles));
    }
    
    const handleAutomaticPairingResponse = (response: {
        paired_temp_sets: PairedCartItem[],
        unpaired_ids: number[],
        already_existing_temp_sets: PairedCartItem[],
    }, individualPairing=false) => {
        let paired: PairedCartItem[] = [];
        let unpaired: number[] = [];
        let missingProducts: number[] = [];
        let updatedSelectedSetArticles =  [...selectedSetArticles];

        response.paired_temp_sets.map((item: PairedCartItem) => {
            paired.push(item);
            if(!setArticles.some(article => article.id === item.set_articles[0])){
                // if the corresponding pair is not loaded on the front end then we have to bring it from backend.
                updatedSelectedSetArticles.push(item.set_articles[0]);
                missingProducts.push(item.set_articles[0]);
            }
            else if(setArticles.some(article => article.id === item.set_articles[0]) && !updatedSelectedSetArticles.includes(item.set_articles[0])) {
                // if the corresponding pair is loaded on the front end but it is not in the selected list.
                updatedSelectedSetArticles.push(item.set_articles[0]);
            }
            // Checks both of the paired articles coming from backend if any one of them is not loaded then we have to get it from server.
            if(!setArticles.some(article => article.id === item.set_articles[1])) {
                // if the corresponding pair is not loaded on the front end then we have to bring it from backend.
                updatedSelectedSetArticles.push(item.set_articles[1]);
                missingProducts.push(item.set_articles[1]);
            }
            else if(setArticles.some(article => article.id === item.set_articles[1]) && !updatedSelectedSetArticles.includes(item.set_articles[1])) {
                // if the corresponding pair is loaded on the front end but it is not in the selected list.
                updatedSelectedSetArticles.push(item.set_articles[1]);
            }
        });

        response.unpaired_ids.map((item: number) => {
            unpaired.push(item);
            if(individualPairing) {
                updatedSelectedSetArticles.push(item);
            }
        });

        response.already_existing_temp_sets.map((item: PairedCartItem) => {

            paired.push(item);            

        });

        getMissingProducts(missingProducts);
        dispatch(setWarehouseSelectedSetArticles(updatedSelectedSetArticles));
        if(individualPairing) {
            dispatch(setWarehouseCart(
                {
                    ...cart,
                    paired: [...cart.paired, ...paired],
                    unpaired: [...cart.unpaired, ...unpaired],
                }
            ));
            
        }
        else {
            dispatch(setWarehouseCart(
                {
                    ...cart,
                    paired: paired,
                    unpaired: unpaired,
                }
            ));
          
        }      
    }
    const getPairedSetArticlesOptions = (e: any, item?: ProductItem, callBackFunction?: () => void) => {
        e.stopPropagation() // prevents the click event of parent element.
        if(item) {
            getSetArticlePairs(item).then((response) => {
                if (response.data.length === 0) {
                    addToast(noPairFoundToast(()=>{addToast(null)}));

                    setPairOptions({
                        selectedItem: item,
                        availableOptions: [],
                    });
                }
                else {
                    setPairOptions({
                        selectedItem: item,
                        availableOptions: response.data.possible_pairs,
                    });
                }
            });
        }
        else if (automaticPairing) {
            getAutomaticPairs(selectedSetArticles).then((response) => {
                handleAutomaticPairingResponse(response.data);
                callBackFunction && callBackFunction();
            }).catch((e) => {               
                console.log('exception while getting automatic pairs: ', e);
                if(e?.response?.data) {
                    handleAutomaticPairingResponse(e.response.data);
                    callBackFunction && callBackFunction();
                }
            });
        }
    }
    const autoPairIndividually = (e: any, itemId: number, callBack?: () => void) => {
        e.stopPropagation() // prevents the click event of parent element.     
        linkSetArticles(packageItem?.id || 0, [itemId]).then((response) => {
            handleIndividualAutomaticPairingResponse(response.data);
            getPendingPackagesData();
            getAllProductSupplies();
            callBack && callBack();
        }).catch((e) => {
            getPendingPackagesData();
            getAllProductSupplies();
            callBack && callBack();
            console.log('exception while getting automatic pairs: ', e);
            if(e?.response?.data) {
                // handleIndividualAutomaticPairingResponse(e.response.data);
            }
        });
    }
    

    const setSellingPriceState = (e: any, productId: number, selling_price: number) => {
        e.stopPropagation();
        setPriceInputId(`selling-price-input-${productId}`);
        setPriceInputValue(selling_price);
    }
    const changeProductPrice = (e: any, id: any, type: string) => {
        e.stopPropagation();     
        let form_data = {
            selling_price: priceInputValue,
        }
        if (type === 'pallet') {
            editPalletDetails(id, form_data).then((response) => {
                const newPallets = pallets?.map((item: Pallet) => {
                    if(item.id === id){
                        return {
                            ...item, // Copy existing fields
                            selling_price: priceInputValue, // Update selling_price
                          };
                    }
                    else {
                        return item;
                    }
                })
                dispatch(setWarehousePallets(newPallets));
                setPriceInputId('');
                setPriceInputValue(0);
                getPendingPackagesData();
            })
            .catch((e) => {
            })
        }
        else {
            editProductDetails(id, form_data).then((response) => {
                if(type === 'set-articles') {
                    const newSetArticles = setArticles.map((item: ProductItem) => {
                        if(item.id === id){                        
                            return {
                                ...item, // Copy existing fields
                                selling_price: priceInputValue, // Update selling_price
                              };
                        }
                        else {
                            return item;
                        }                
                    })
                    dispatch(setWarehouseSetArticles(newSetArticles));           
                }
                else if(type === 'products') {
                    const newProducts = products.map((item: ProductItem) => {
                        if(item.id === id){                      
                            return {
                                ...item, // Copy existing fields
                                selling_price: priceInputValue, // Update selling_price
                              };
                        }
                        else {
                            return item;
                        }
                    })
                    dispatch(setWarehousePackageProducts(newProducts));
                }
                setPriceInputId('');
                setPriceInputValue(0);
                getPendingPackagesData();
            }).catch((e) => { 
            });
        }
    }
   
    const calculatePackagePrice = () => {
        let packagePrice = 0;
        products?.map((productItem: ProductItem) => {
            if(cart.standalone.includes(productItem.id)){
                packagePrice = packagePrice +  Number(productItem.selling_price);
            }
        });
        pallets?.map((palletItem: Pallet) => {
            if(cart.pallets.includes(palletItem.id)){
                packagePrice = packagePrice +  Number(palletItem.selling_price);
            }
        });
        setArticles?.map((productItem: ProductItem) => {
            if(cart.unpaired.includes(productItem.id)){
                packagePrice = packagePrice +  Number(productItem.selling_price);
            }
            else {
                cart.paired.map((pairedItem: PairedCartItem) => {
                    if(pairedItem.set_articles.includes(productItem.id)) {
                        packagePrice = packagePrice +  Number(productItem.selling_price);
                    }
                })
            }
        })
        dispatch(setWarehousePackageTotalPrice((packagePrice.toFixed(2))));
    }
    useEffect(()=> {
        resetToDefaults();
        console.log(props?.state?.packageItem)
        if(props?.state?.packageItem) {
            getPackageById(props?.state?.packageItem)
        }
    },[]);
    return (
        <div>           
            <CToaster push={toast} placement="top-end" />
            <Modal
                isModalVisible={isModalVisible}
                closeModal={()=>setIsModalVisible(false)}
                pairOptions={pairOptions}
                pairSetArticle={pairSetArticle}
                cart={cart}
                alreadyPairedToast={alreadyPairedToast}
                addToast={addToast}
            />

            <div className='website-settings-main-container'>
                <PackageHeaderComponent
                    selectedPallets={selectedPallets}
                    productsLength={products?.length}
                    setSelecteTab={setSelecteTab}
                    selectedTab={selectedTab}
                    setArticlesLength={setArticles?.length}
                    palletLength={pallets?.length}
                    cartLength={cart?.paired?.length + cart?.unpaired?.length + cart?.standalone?.length + cart?.pallets?.length}
                    automaticPairing={automaticPairing}
                    setAutomaticPairing={setAutomaticPairing}
                    getPairedSetArticlesOptions={getPairedSetArticlesOptions}
                    truckCapacity={truckCapacity}
                    setTruckCapacity={setTruckCapacity}
                    selectedSetArticles={selectedSetArticles}
                    selectedProducts={selectedProducts}
                    headerText={`${t('adminDashboard.Warehouse.create_warehouse_order')} ${packageItem?.id ? packageItem?.id : ''}`}
                    selectedCustomer={selectedCustomer}
                    calculatePackagePrice={calculatePackagePrice}
                />

                <CRow>
                    <CCol xs={12} md={3}>
                        <WarehousePackageFltersComponent                           
                            setSelectedCustomer ={setSelectedCustomer}
                            selectedCustomer = {selectedCustomer}
                            selectedTab={selectedTab}
                            setLoading={setLoading}
                            showCustomerToast = {() => addToast(GenericSuccessToast(()=>{addToast(null)}, 'Customer edited successfully.'))}
                            showToast={() => addToast(GenericErrorToast(()=>{addToast(null)}, "Please check if the supply is allowed from back office."))}
                            current_package_id={packageItem?.id}
                            warehousePackageStatus={warehousePackageStatus}
                        />                       
                    </CCol>
                    <CCol xs={12} md={9}>
                    {
                        loading ?
                            <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                <PlaceHolder />
                            </CCol>
                        :   null
                    }
                        <CRow>
                            {
                                selectedTab === 0 ?
                                    products?.length == 0 && setArticles.length == 0 ?
                                        <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                            <h5>{t('adminDashboard.Products.no_product_found')}</h5>
                                        </CCol>
                                    :
                                <>
                                    {
                                        products?.filter((item) => {return !selectedProducts.includes(item.id)}).map((item: ProductItem) => {
                                            // rendering only unselected products
                                            return (
                                                <CCol xs={12} md={6} lg={4} xl={4} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                                    <WarehouseProductCardComponent
                                                        selectedProducts={selectedProducts}
                                                        item={item}
                                                        changeProductPrice={changeProductPrice}
                                                        priceInputId={priceInputId}
                                                        setSellingPriceState={setSellingPriceState}
                                                        priceInputValue={priceInputValue}
                                                        setPriceInputValue={setPriceInputValue}
                                                        cardOnClick={(callBack: () => void) =>{
                                                            if(item.selling_price > 0) {
                                                                updateSelectedProducts(item.id, callBack);
                                                            }
                                                            else {
                                                                alert('Please set the selling price first.');
                                                            }
                                                        }}
                                                        selectedTab={selectedTab}
                                                        setPriceInputId={setPriceInputId}
                                                        current_package_id={packageItem?.id}
                                                    />
                                                </CCol>
                                            )
                                        })
                                    }
                                    {
                                        
                                        setArticles?.filter((item) => {return !selectedSetArticles.includes(item.id)}).map((item: ProductItem) => {
                                            // rendering only unselected set articles
                                            return (

                                                <CCol xs={12} md={6} lg={4} xl={4} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                                    <WarehouseProductCardComponent 
                                                        selectedProducts={selectedSetArticles}
                                                        item={item}
                                                        changeProductPrice={changeProductPrice}
                                                        priceInputId={priceInputId}
                                                        setSellingPriceState={setSellingPriceState}
                                                        priceInputValue={priceInputValue}
                                                        setPriceInputValue={setPriceInputValue}
                                                        cardOnClick={(callBack: () => void) => {
                                                            if (item?.article?.includes('-2') || (item?.article?.includes('-1') && item.selling_price > 0)) {
                                                                updateSelectedSetArticles(item.id, callBack);
                                                            }
                                                            else {
                                                                alert('Please set the selling price first.');
                                                            }
                                                        }}
                                                        automaticPairing={automaticPairing}
                                                        getPairedSetArticlesOptions={getPairedSetArticlesOptions}
                                                        autoPairIndividually={autoPairIndividually}
                                                        selectedTab={selectedTab}
                                                        setPriceInputId={setPriceInputId}
                                                        current_package_id={packageItem?.id}
                                                    />
                                                </CCol>
                                            )
                                        })
                                    }
                                    {
                                        products?.filter((item) => {return selectedProducts.includes(item.id)}).map((item: ProductItem) => {
                                            // rendering only selected products
                                            return (
                                                <CCol xs={12} md={6} lg={4} xl={4} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                                    <WarehouseProductCardComponent
                                                        selectedProducts={selectedProducts}
                                                        item={item}
                                                        changeProductPrice={changeProductPrice}
                                                        priceInputId={priceInputId}
                                                        setSellingPriceState={setSellingPriceState}
                                                        priceInputValue={priceInputValue}
                                                        setPriceInputValue={setPriceInputValue}
                                                        cardOnClick={(callBack: () => void) =>{
                                                            if(item.selling_price > 0) {
                                                                updateSelectedProducts(item.id, callBack);
                                                            }
                                                            else {
                                                                alert('Please set the selling price first.');
                                                            }
                                                        }}
                                                        selectedTab={selectedTab}
                                                        setPriceInputId={setPriceInputId}
                                                        current_package_id={packageItem?.id}
                                                    />
                                                </CCol>
                                            )
                                        })
                                    }
                                    
                                    {
                                        
                                        setArticles?.filter((item) => {return selectedSetArticles.includes(item.id)}).map((item: ProductItem) => {
                                            // rendering only selected set articles
                                            return (

                                                <CCol xs={12} md={4} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                                    <WarehouseProductCardComponent 
                                                        selectedProducts={selectedSetArticles}
                                                        item={item}
                                                        changeProductPrice={changeProductPrice}
                                                        priceInputId={priceInputId}
                                                        setSellingPriceState={setSellingPriceState}
                                                        priceInputValue={priceInputValue}
                                                        setPriceInputValue={setPriceInputValue}
                                                        cardOnClick={(callBack: () => void) => {
                                                            if (item?.article?.includes('-2') || (item?.article?.includes('-1') && item.selling_price > 0)) {
                                                                updateSelectedSetArticles(item.id, callBack);
                                                            }
                                                            else {
                                                                alert('Please set the selling price first.');
                                                            }
                                                        }}
                                                        automaticPairing={automaticPairing}
                                                        getPairedSetArticlesOptions={getPairedSetArticlesOptions}
                                                        autoPairIndividually={autoPairIndividually}
                                                        selectedTab={selectedTab}
                                                        setPriceInputId={setPriceInputId}
                                                        current_package_id={packageItem?.id}
                                                    />
                                                </CCol>
                                            )
                                        })
                                    }
                                </>
                                : selectedTab === 1 ?
                                    pallets?.length === 0 ?
                                        <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                            <h5>{t('adminDashboard.Products.no_pallet_found')}</h5>
                                        </CCol>
                                    :
                                    <>
                                    {
                                        pallets?.filter((item) => {return !selectedPallets.includes(item.id)}).map((item: Pallet) => {
                                            // rendering only set articles
                                            return (
                                                <CCol xs={12} md={4} style={{marginTop: 24, marginBottom: 24}}> {/* pallet cards here. */}
                                                    <PalletCardComponent 
                                                        selectedPallets={selectedPallets}
                                                        item={item}
                                                        changeProductPrice={changeProductPrice}
                                                        priceInputId={priceInputId}
                                                        setSellingPriceState={setSellingPriceState}
                                                        priceInputValue={priceInputValue}
                                                        setPriceInputValue={setPriceInputValue}
                                                        cardOnClick={(callBack: () => void) =>  updateSelectedPallets(item.id, callBack)}
                                                        current_package_id={packageItem?.id}
                                                    />
                                                </CCol>
                                            )
                                        })
                                    }
                                    {
                                        pallets?.filter((item) => {return selectedPallets.includes(item.id)}).map((item: Pallet) => {
                                            // rendering only set articles
                                            return (
                                                <CCol xs={12} md={4} style={{marginTop: 24, marginBottom: 24}}> {/* pallet cards here. */}
                                                    <PalletCardComponent 
                                                        selectedPallets={selectedPallets}
                                                        item={item}
                                                        changeProductPrice={changeProductPrice}
                                                        priceInputId={priceInputId}
                                                        setSellingPriceState={setSellingPriceState}
                                                        priceInputValue={priceInputValue}
                                                        setPriceInputValue={setPriceInputValue}
                                                        cardOnClick={(callBack: () => void) =>  updateSelectedPallets(item.id, callBack)}
                                                        current_package_id={packageItem?.id}
                                                    />
                                                </CCol>
                                            )
                                        })
                                    }
                                    </>
                                : selectedTab === 2 ?
                                    <OrderSummary
                                        is_warehouse_package={true}
                                        updateSelectedProducts={updateSelectedProducts}
                                        updateSelectedSetArticles={updateSelectedSetArticles}
                                        updateSelectedPallets={updateSelectedPallets}
                                    />
                                :
                                <div></div>
                            }
                        </CRow>
                    </CCol>
                </CRow>
            </div>
        </div>
    );
};
export default CreateWarehousePackageScreen;
