import { cilCheck, cilPen } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCard, CCardBody, CCardTitle, CCardText, CBadge } from "@coreui/react";
import { Pallet } from "../../../types";
import Divider from "../divider";

interface PalletCardComponentProps {
    edit?: boolean;
    cardOnClick: () => void;
    selectedPallets: any;
    item: Pallet;
    changeProductPrice: (e: any, id: any, type: string) => void;
    priceInputId: string;
    setSellingPriceState: (e: any, productId: any, selling_price: number) => void;
    priceInputValue: number;
    setPriceInputValue: (value: number) => void;
    setPriceInputId: (value: string) => void;
    isWarehouseModule?: boolean;
    selectedTab?: number;
}
const handleOpenNewTab = (pallet: Pallet, url: string) => {
    // Open the link in a new tab using window.open
    const newTab: any = window.open(url, '_blank');
    const serializedProduct = JSON.stringify(pallet);
    newTab.localStorage.setItem('pallet', serializedProduct);
};
const PalletCardComponent = (props: PalletCardComponentProps) => {
    const { edit, cardOnClick, selectedPallets, item, priceInputId, setSellingPriceState, setPriceInputId,
        changeProductPrice, priceInputValue, setPriceInputValue, isWarehouseModule,  selectedTab } = props;
    return (
        <CCard // Parent card component.
            className = {selectedPallets?.includes(item.id) ? "selected-card" : 'unselected-card'}
            onClick={() => (!item.is_sold || edit == true) && cardOnClick()}
        >
            <CCardBody>
                <CCardTitle>Description: {item.description}</CCardTitle>
                <h6>
                    <CCardText className='product-card-info'>
                        <div
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={(e)=>{
                                e.stopPropagation()
                                handleOpenNewTab(item, '/admin/dashboard/pallet/details')
                            }}
                        ><b>Pallet id: {item.id}</b></div>
                        <div><b>Products inside: {item?.products_inside}</b></div>
                        <div><b>Buying price: {item?.buying_price} €</b></div>
                        
                        { item?.supply ? <div><b>Supply id: {item.supply}</b></div> : null}
                        
                        <div><b>Online price: {item?.current_online_price} €</b></div>
                        <div><b>Remarks: {item?.metadata?.remarks}</b></div>
                        <Divider color={ selectedPallets?.includes(item.id) ? '#ffffff' : "#23B2D9"} thickness={1} margin={12} />
                        <h5>
                            {
                                priceInputId.includes(String(item.id)) ?
                                    <div className="input-wrapper margin-top-12">
                                        {/* <b>Selling price:</b> */}
                                        <div className="input-container">
                                            <input type={"number"} onClick={(e)=>{e.stopPropagation()}} id={`selling-price-input-${item.id}`} defaultValue={item.selling_price} value={priceInputValue} onChange={(e) => setPriceInputValue(Number(e.target.value))} />
                                            <CIcon size='xl' icon={cilCheck} onClick={(e) => {priceInputValue == 0 ? alert(" Selling price cannot be 0.") : changeProductPrice(e, item.id, 'pallet')}}/>
                                        </div>
                                    
                                    </div>
                                :
                                    <div className="margin-top-12"><b>Selling price: {  Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.selling_price) } € </b>  { (!item.is_sold || edit == true) ?<CIcon icon={cilPen} size='lg' onClick={(e) => setSellingPriceState(e, item.id, item.selling_price)}/> : null }</div>
                            }
                        </h5>
                        <div>
                            {item?.is_sold && !edit && (
                                <>
                                    <div style={{color: 'red'}}>{item?.order_id ? <b>SOLD</b> : <b>OFFERED</b> }</div>
                                    <div style={{ marginTop: '5px' }}>
                                        <CBadge color="dark">
                                        <b>{item?.customer_name}</b>
                                        </CBadge>
                                    </div>
                                    <div style={{ marginTop: '5px' }}>
                                        <CBadge color="info">
                                        <b>PackageID: {item?.package_id}</b>
                                        </CBadge>
                                    </div>
                                    {item?.order_id && (
                                        <div style={{ marginTop: '5px' }}>
                                        <CBadge color="warning">
                                            <b>OrderID: {item?.order_id}</b>
                                        </CBadge>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </CCardText>
                </h6>
            </CCardBody>
        </CCard>
    )
};

export default PalletCardComponent;