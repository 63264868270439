import { CHeader, CNav, CNavItem, CNavLink } from "@coreui/react";
import { t } from "i18next";

interface SupplyManagementHeaderProps {
    headerText?: string;
    selectedTab: 0 | 1 | 2 | 3 | 4;
    setSelecteTab: (value: 0 | 1 | 2 | 3) => void;
};

const SupplyManagementHeader = (props: SupplyManagementHeaderProps) => {
    const { headerText, setSelecteTab, selectedTab } = props;
    return (
        <>
            <CHeader className="margin-bottom-24">
                <h1><b>{headerText ? headerText : t('adminDashboard.SupplyManagement.sidebar_text')}</b></h1>
                <CNav variant="tabs" style={{alignItems: "flex-end"}}>
                    <CNavItem>
                        <CNavLink onClick={() => setSelecteTab(0)} active={selectedTab === 0 ? true : false}>
                            {t('adminDashboard.SupplyManagement.add_supply')}
                        </CNavLink>
                    </CNavItem>
                    <CNavItem className='margin-left-12'>
                        <CNavLink onClick={() => setSelecteTab(1)} active={selectedTab === 1 ? true : false}> {t('adminDashboard.SupplyManagement.all_supplies')}</CNavLink>
                    </CNavItem>
                    <CNavItem className='margin-left-12'>
                        <CNavLink onClick={() => setSelecteTab(2)} active={selectedTab === 2 ? true : false}>{t('adminDashboard.SupplyManagement.all_supply_types')}</CNavLink>
                    </CNavItem>
                    <CNavItem className='margin-left-12'>
                        <CNavLink onClick={() => setSelecteTab(3)} active={selectedTab === 3 ? true : false}>{t('adminDashboard.SupplyManagement.all_suppliers')}</CNavLink>
                    </CNavItem>
                </CNav>
            </CHeader>
        </>
    )
};

export default SupplyManagementHeader;
