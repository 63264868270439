import PropTypes from 'prop-types';

interface LegendProps {
    color?: string;
    width?: number;
    height?: number;
  
  }
const Legend = ({ color, width, height }: LegendProps) => {
  const legendStyle = { 
    backgroundColor: `${color}`,  
    width: `${width}px`,
    height: `${height}px`,
   
  };

  return <div style={legendStyle}></div>;
};

Legend.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  
};

Legend.defaultProps = {
  color: '#000000', // Default color black
  width: 12, // Default thickness 1px
  height: 12,
 
};

export default Legend;