import { Middleware } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';
import { rehydrateState } from '../slices/WarehousePackageSlice'; 
import { productRehydrateState } from '../slices/ProductSlice';
import {customerRehydrateState} from "../slices/CustomerCartSlice";

const rehydrateMiddleware: Middleware = store => next => action => {
    if (action.type === REHYDRATE) {
        // The action.payload contains the rehydrated state from storage
        // if (action.payload && action.payload.warehousePackageSlice) {
            //store.dispatch(customerRehydrateState(action?.payload?.customerCartSlice));
            store.dispatch(rehydrateState(action?.payload?.warehousePackageSlice));
            store.dispatch(productRehydrateState(action?.payload?.productSlice));
            
        // }
    }
    return next(action);
};

export default rehydrateMiddleware;

// : ['warehousePackageSlice', 'authSlice', 'adminSlice', 'catalogSlice', 'packageSlice', 'usersSlice', 
//     'orderSlice', '', '', 'activePackageSlice', 'totalOrders'],