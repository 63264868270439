import { NavLink } from 'react-router-dom';
import '../CustomerStyles.css';
import {
  CContainer,
  CHeader,
  CHeaderDivider,
  CHeaderNav,
  CNavLink,
  CNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilBell, cilSettings } from '@coreui/icons';
import  HeaderDropdown from './HeaderDropdownComponent';
import MainLogo from '../../assets/images/main-logo.png';

const AdminHeader = () => {
  return (
    <CHeader position="sticky" className="mb-4 customer-sticky-header">
      <CContainer fluid>        
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="https://www.elvinci.de/" component={NavLink}>
              <img className="sidebar-brand-full" src={MainLogo} style={{width: 150, marginLeft: 10}}/>
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>          
          <CNavItem>
            <CNavLink to='/customer/settings' component={NavLink} className='white-icon'>
              <CIcon icon={cilSettings} size="xl" />
            </CNavLink>
          </CNavItem>
          <CNavItem>            
            <CNavLink to='/' component={NavLink} className='white-icon'>
              <CIcon icon={cilBell} size="xl" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <HeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

export default AdminHeader
