import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider, useSelector } from 'react-redux'
import MainRouter from './routers';
import store from "./redux/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import './i18n';
import { PersistGate } from 'redux-persist/integration/react'
import { getRefreshToken } from './services/AuthService';
 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

getRefreshToken().then((response) => {
}).catch((e) => {
  console.log('érror: ', e);
});

root.render(
  <Provider store = {store.store}>   
      <PersistGate loading={null} persistor={store.persistor}>
        <MainRouter />
      </PersistGate>  
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
