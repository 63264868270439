import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton,
    CForm, CFormInput, CFormLabel, CFormFeedback } 
    from "@coreui/react";
import { DropDownItem, Supplier, SupplyType, SupplyTypePost } from "../../../types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { editSupplType } from "../../../services/ProductService";
import Select from 'react-select';

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    selectedSupplyTypeItem: SupplyType | undefined;
    closeModal: () => void;
    onClickConfirmation: () => void;
    confirmButtonText: string;
}
const EditSupplyTypeModal = (props: ModalProps) => {   
    const {isModalVisible, closeModal, headerText, onClickConfirmation, bodyText, confirmButtonText, selectedSupplyTypeItem} = props;
    const suppliers: Supplier[] = useSelector((state: RootState) => state.productSlice.suppliers);
    const [nameValid, setNameValid] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<boolean>(true);

    const [id, setId] = useState<number>(selectedSupplyTypeItem ? selectedSupplyTypeItem.id : 0);
    const [name, setName] = useState<string>(selectedSupplyTypeItem ? selectedSupplyTypeItem.name : '');
    const [APercentage, setAPercentage] = useState<number>(selectedSupplyTypeItem ? selectedSupplyTypeItem.grade_A_percent : 0);
    const [BPercentage, setBPercentage] = useState<number>(selectedSupplyTypeItem ? selectedSupplyTypeItem.grade_B_percent : 0);
    const [CPercentage, setCPercentage] = useState<number>(selectedSupplyTypeItem ? selectedSupplyTypeItem.grade_C_percent : 0);
    const [buyingPercentage, setBuyingPercentage] = useState<number>(selectedSupplyTypeItem ? selectedSupplyTypeItem.buying_percentage : 0);

    const supplierOptions: DropDownItem[]= (suppliers.map((supplier: Supplier) => ({ value: supplier.name, label: supplier.name })));
    const currentsupplyTypeOption: DropDownItem = supplierOptions.filter((option: DropDownItem)=> {return option.value == selectedSupplyTypeItem?.supplier})[0];
    const [selectedSupplier, setSelectedSupplier] = useState<string>(currentsupplyTypeOption?.value);

    const editSupplyType = () => {
        let formData: SupplyTypePost = {
            name: name,
            grade_A_percent: APercentage,
            grade_B_percent: BPercentage,
            grade_C_percent: CPercentage,
            buying_percentage: buyingPercentage,
            supplier: selectedSupplier,
        }
        editSupplType(id, formData).then((response) => {
            setNameValid(true);
            onClickConfirmation();
        }).catch((e) => {            
            if(e?.response?.data?.buying_percentage) {
                setNameValid(false);
                setErrorMessage(e.response.data.buying_percentage)
            }
        })
    };
    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal}>
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{bodyText}</div>
                <CForm style={{marginBottom: 16, marginTop: 24}}>
                    <CFormLabel><b>Supply type name</b></CFormLabel>
                    <CFormInput
                        type={"string"}
                        placeholder='Supply type name'
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />
                    <CFormFeedback invalid={nameValid} style={{color: 'red'}}>{errorMessage}</CFormFeedback>

                    <CFormLabel><b>Supply type A ( 0 - 1 )</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        max={1}
                        min={0}
                        placeholder='Supply type A ( 0 - 1 )'
                        value={APercentage}
                        onChange={(e) => {
                            setAPercentage(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                    />

                    <CFormLabel><b>Supply type B ( 0 - 1 )</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        max={1}
                        min={0}
                        placeholder='Supply type B ( 0 - 1 )'
                        value={BPercentage}
                        onChange={(e) => {
                            setBPercentage(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                    />

                    <CFormLabel><b>Supply type C ( 0 - 1 )</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        max={1}
                        min={0}
                        placeholder='Supply type C ( 0 - 1 )'
                        value={CPercentage}
                        onChange={(e) => {
                            setCPercentage(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                    />

                    <CFormLabel><b>Supply type Buying ( 0 - 1 )</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        max={1}
                        min={0}
                        placeholder='Supply type Buying ( 0 - 1 )'
                        value={buyingPercentage}
                        onChange={(e) => {
                            setBuyingPercentage(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                    />
                    
                    <CFormLabel><b>Supplier </b></CFormLabel>
                    <Select
                        defaultValue={currentsupplyTypeOption}
                        placeholder={'Select supplier'}
                        noOptionsMessage={({ inputValue: string }) => 'Supplier not found.' }
                        options={supplierOptions}
                        onChange={(option: any) => {
                            setSelectedSupplier(option.value)
                        }}
                    />
                </CForm>
            </CModalBody>

            <CModalFooter>
                <CButton color="primary" onClick={editSupplyType}>{confirmButtonText}</CButton>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default EditSupplyTypeModal;