import 'simplebar/dist/simplebar.min.css';
import { CCardBody } from '@coreui/react-pro';
import '../../AdminStyles.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { CCard, CCol, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilBasket, cilFactory, cilInbox, cilTruck, cilUser } from '@coreui/icons';
import PendingPackagesScreen from '../packages/PendingPackagesScreen';
import { SALES_GROUP_ID } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MainDashboardScreen = () => {
  const authUser = useSelector((state: RootState) => state.authSlice.user);
  const navigation = useNavigate();
  const {t} = useTranslation();
  const customers = useSelector((state: RootState) => state.usersSlice.customers);
  const activeProductsCount = useSelector((state: RootState) => state.productSlice.activeProductsCount);
  const activePalletsCount = useSelector((state: RootState) => state.productSlice.activePalletsCount);
  const allPackagesCount = useSelector((state: RootState) => state.packageSlice.allPackagesCount);
  const allOrdersCount = useSelector((state: RootState) => state.orderSlice.allOrdersCount);
  const suppliers = useSelector((state: RootState) => state.productSlice.suppliers);
  const supplies = useSelector((state: RootState) => state.productSlice.productSupplies);
  const supplyTypes = useSelector((state: RootState) => state.productSlice.supplyTypes);
  const flexStyle = {
    display: 'flex',
  }

  return (
    <div>
      <div className='website-settings-main-container margin-bottom-24'>
        <h1><b>{t('adminDashboard.MainDashboard.sidebar_text')}</b></h1>
        <CRow>
          <CCol>
            <CCard className='pointer' style={{marginTop: 14}} onClick={() => navigation('/admin/dashboard/customers')}>
              <CCardBody>
                <div style={flexStyle}><CIcon icon={cilUser} className='margin-right-12' size="xxl" style={{color: '#23B2D9'}} /> <h5><b>{t('adminDashboard.Customers.customers')}: </b>{customers?.length}</h5></div>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol>
            <CCard className='pointer' style={{marginTop: 14}} onClick={() => navigation('/admin/dashboard/packages')}>
              <CCardBody>
                <div style={flexStyle}><CIcon icon={cilInbox} className='margin-right-12' size="xxl" style={{color: '#23B2D9'}}/>  <h5><b>{t('adminDashboard.Packages.total_packages')}: </b>{allPackagesCount}</h5> </div>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol>
            <CCard className='pointer' style={{marginTop: 14}} onClick={() => navigation('/admin/dashboard/products')}>
              <CCardBody>
                <div style={flexStyle}><CIcon icon={cilFactory} className='margin-right-12' size="xxl" style={{color: '#23B2D9'}}/>  <h5><b>{t('adminDashboard.OrdersWords.available_products')}: </b>{activeProductsCount}</h5></div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

      {
        !authUser?.groups?.includes(SALES_GROUP_ID) ?        
          <CRow>
            <CCol>
              <CCard className='pointer' style={{marginTop: 14}} onClick={() => navigation('/admin/dashboard/supplies', {state: {tab: 3}})}>
              <CCardBody>
                  <div style={flexStyle}><CIcon icon={cilBasket} className='margin-right-12' size="xxl" style={{color: '#23B2D9'}}/>  <h5><b>{t('adminDashboard.SupplyManagement.total_suppliers')}: </b>{suppliers?.length}</h5></div>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol>
              <CCard className='pointer' style={{marginTop: 14}} onClick={() => navigation('/admin/dashboard/supplies', {state: {tab: 2}})}>
                <CCardBody>
                  <div style={flexStyle}><CIcon icon={cilBasket} className='margin-right-12' size="xxl" style={{color: '#23B2D9'}}/>  <h5><b>{t('adminDashboard.SupplyManagement.total_supply_types')}: </b>{supplyTypes?.length}</h5></div>

                </CCardBody>
              </CCard>
            </CCol>
            <CCol>
              <CCard className='pointer' style={{marginTop: 14}} onClick={() => navigation('/admin/dashboard/supplies',{state: {tab: 1}})}>
                <CCardBody>
                  <div style={flexStyle}><CIcon icon={cilBasket} className='margin-right-12' size="xxl" style={{color: '#23B2D9'}}/>  <h5><b>{t('adminDashboard.SupplyManagement.total_supplies')}: </b>{supplies?.length}</h5></div>

                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          : null
      }

        <CRow>
          <CCol xs={4}>
            <CCard className='pointer' style={{marginTop: 14}} onClick={() => navigation('/admin/dashboard/orders/requests')}>
              <CCardBody>
                <div style={flexStyle}><CIcon icon={cilTruck} className='margin-right-12' size="xxl" style={{color: '#23B2D9'}}/>  <h5><b>{t('adminDashboard.OrderRequests.total_orders')}: </b>{allOrdersCount}</h5></div>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol xs={4}>
            <CCard className='pointer' style={{marginTop: 14}} onClick={() => navigation('/admin/dashboard/products')}>
              <CCardBody>
                <div style={flexStyle}><CIcon icon={cilFactory} className='margin-right-12' size="xxl" style={{color: '#23B2D9'}}/>  <h5><b>{t('adminDashboard.OrdersWords.available_pallets')}: </b>{activePalletsCount}</h5></div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <PendingPackagesScreen />
    </div>
  );
};
export default MainDashboardScreen;
