import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CatalogItem, LoggedinUser } from '../../types';

interface CatalogSliceState {
    catalogArticles: CatalogItem[];
    inCompleteCatalog: boolean;
}

const initialState = { catalogArticles: [], inCompleteCatalog: false } as CatalogSliceState

const catalogSlice = createSlice({
  name: 'catalogSlice',
  initialState,
  reducers: {
    setCatalogArticles(state, action: PayloadAction<any>) {
      state.catalogArticles = action.payload
    },
    setInCompleteCatalog(state, action: PayloadAction<boolean>) {
      state.inCompleteCatalog = action.payload
    },
  },
})

export const { setCatalogArticles, setInCompleteCatalog } = catalogSlice.actions
export default catalogSlice.reducer