import { useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import '../AdminStyles.css';
import { useDispatch } from 'react-redux';

import SupplierOutlook from './SupplierOutlook';

const SupplierDashboardScreen = () => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <div className='website-settings-main-container'>
            <SupplierOutlook loading={loading}/>
        </div>
    )
};
export default SupplierDashboardScreen;
