import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LoggedinUser } from '../../types';

interface AuthSliceState {
    isAuthenticated: boolean;
    accessToken: string;
    user: LoggedinUser;
}

const initialState = { isAuthenticated: false, accessToken: "", user: {} } as AuthSliceState

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },
    setLoggedinUser(state, action: PayloadAction<LoggedinUser | {}>) {
      state.user = action.payload
    },
  },
})

export const { setIsAuthenticated, setAccessToken, setLoggedinUser } = authSlice.actions
export default authSlice.reducer