import { configureStore, Middleware } from '@reduxjs/toolkit'
import { salesApi } from './api/basicApi';
import { rootReducer } from './slices';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import rehydrateMiddleware from './middle-wares'; // Update with the correct path

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['warehousePackageSlice', 'authSlice', 'adminSlice', 'catalogSlice', 'packageSlice', 'usersSlice', 
    'orderSlice', 'productSlice', 'activePackageSlice', 'totalOrders'],
  blacklist: ['customerCartSlice', 'warehousePackageSlice', 'productSlice']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(rehydrateMiddleware, createStateSyncMiddleware({   
      whitelist: ['warehousePackageSlice'],
      blacklist: ['customerCartSlice', 'warehousePackageSlice','productSlice'],
    })),
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

initMessageListener(store);
let persistor = persistStore(store);
export default { store, persistor };
export type RootState = ReturnType<typeof store.getState>
// The store now has redux-thunk added and the Redux DevTools Extension is turned on