import * as XLSX from 'xlsx';
import { ExportExcelItem, Pallet, PalletProduct, ProductItem, ScanningTimeObject, SupplierExportExcelItem, SupplyInventoryExcelItem, SupplyInventoryItem, UserScanningTimeExcelItem } from '../../types';
import { getPackage } from '../../services/PackageService';
import { getProductsBySupplyId } from '../../services/ProductService';
import { getFilteredPalletProducts } from '../../services/PalletService';

export const generateExcelFile = (data: ExportExcelItem[] | SupplierExportExcelItem[] | UserScanningTimeExcelItem[] | SupplyInventoryExcelItem[], filename: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, filename + '.xlsx');
};

export const downloadExcelFile = async (pkgID: number, isAdmin: boolean) => {
  try {
    let data: ExportExcelItem[] = [];
    const response = await getPackage(pkgID); // Await the response of the package

    // Handle standalone products
    if (response.data.standalone_products) {
      response.data.standalone_products.forEach((product: any) => {
        if(isAdmin) {
          data.push({
            Brands: product.brand,
            Article_Nr: product.article,
            Supply: product.supply,
            Description: `${product.sub_category} ${product.model}`,
            Quantity: 1,
            Selling_Online_Price: Number(product.selling_online_price),
            Selling_Price: Number(product.selling_price),
            Usage: product?.usage?.name,
            Lager_Nr: product.id,
            Scale: product.final_points,
            Customer_Nr: response?.data?.customer_details?.jtl_id,
            Linked_Lager_Number: '',
          });
        }
        else {
          data.push({
            Brands: product.brand,
            Article_Nr: product.article,
            Description: `${product.sub_category} ${product.model}`,
            Quantity: 1,
            Selling_Online_Price: Number(product.selling_online_price),
            Selling_Price: Number(product.selling_price),
            Usage: product?.usage?.name,
            Lager_Nr: product.id,
            Scale: product.final_points,
            Customer_Nr: response?.data?.customer_details?.jtl_id,
            Linked_Lager_Number: '',
          });
        }
      });
    };
    // Check for set-articles if there are any
    if (response.data.set_articles) {
      response.data.set_articles.forEach((product: any) => {
        if (isAdmin) {
          data.push({
            Brands: product.brand,
            Article_Nr: product.article,
            Supply: product.supply,
            Description: `${product.sub_category} ${product.model}`,
            Quantity: 1,
            Selling_Online_Price: Number(product.selling_online_price),
            Selling_Price: Number(product.selling_price),
            Usage: product?.usage?.name,
            Lager_Nr: product.id,
            Scale: product.final_points,
            Customer_Nr: response?.data?.customer_details?.jtl_id,
            Linked_Lager_Number: product.combined_products[0],
          });
        }
        else {
          data.push({
            Brands: product.brand,
            Article_Nr: product.article,
            Description: `${product.sub_category} ${product.model}`,
            Quantity: 1,
            Selling_Online_Price: product.selling_online_price,
            Selling_Price: product.selling_price,
            Usage: product?.usage?.name,
            Lager_Nr: product.id,
            Scale: product.final_points,
            Customer_Nr: response?.data?.customer_details?.jtl_id,
            Linked_Lager_Number: product.combined_products[0],
          });
        }
      });
    };

    // Handle pallet products if any
    if (response.data.pallets_products) {
      for (const pallet of response.data.pallets_products) {
        const palletResponse = await getFilteredPalletProducts(pallet.id);
        let count = 1;
        palletResponse.data.forEach((palletProductItem: PalletProduct) => {
          if(isAdmin) {
            data.push({
              Brands: palletProductItem.brand,
              Article_Nr: palletProductItem.article,
              Supply: pallet.supply,
              Description: `${palletProductItem.sub_category} ${palletProductItem.model}`,
              Quantity: palletProductItem.quantity,
              Selling_Online_Price: palletProductItem.total_current_online_price,
              Selling_Price: palletProductItem.total_selling_price,
              Usage: pallet?.usage?.name,
              Lager_Nr: `${pallet.id}_${count}`,
              Pallet_Nr: pallet.description,
              Scale: '',
              Customer_Nr: ''
            });
            count += 1;
          }
          else {
            data.push({
              Brands: palletProductItem.brand,
              Article_Nr: palletProductItem.article,
              Description: `${palletProductItem.sub_category} ${palletProductItem.model}`,
              Quantity: palletProductItem.quantity,
              Selling_Online_Price: palletProductItem.total_current_online_price,
              Selling_Price: palletProductItem.total_selling_price,
              Usage: pallet?.usage?.name,
              Lager_Nr: `${pallet.id}_${count}`,
              Pallet_Nr: pallet.description,
              Scale: '',
              Customer_Nr: ''
            });
            count += 1;
          }
        });
      };
    };
    // Generate Excel file after all data is collected
    generateExcelFile(data, 'package_' + pkgID + '_' + response?.data?.created_at);
  } catch (e) {
    console.error('Error while handling the package data:', e);
  };
};

export const downloadSupplierExcelFile = (supply_id: string) => {
  let data: SupplierExportExcelItem[] = [];
  let date = new Date().toISOString();
  getProductsBySupplyId(supply_id).then((response) => {
    response.data && response.data.map((product: ProductItem) => {
      data.push({
        Marke: product.brand,
        'Artikel Nr.': product.article,
        Beschreibung: `${product.sub_category} ${product.model}`,
        Menge: 1,
        Zustand: product?.usage?.name,
        "Lager Nr.": product.id,
        Grade: product.supplier_grade,
      })
    })
    generateExcelFile(data, 'Liefernummer_' + supply_id + '__' + date);
  }).catch((e) => {
    console.log('error while getting products: ', e);
  })
};

export const UserScanningTimeData = (scanningTimeData: ScanningTimeObject[]) => {
  let data: UserScanningTimeExcelItem[] = [];
  let date = new Date().toISOString();
  scanningTimeData.map((scanningTimeItem: ScanningTimeObject, index: number) => {
    data.push({
      name: scanningTimeItem.name,
      month: scanningTimeItem.month,
      products_scanned: scanningTimeItem['No of Products Scanned'],
      average_scanning_time_sec: scanningTimeItem['Average Scanning Time(sec)'],
    });
  })
  generateExcelFile(data, 'Scanning-Time-' + date);
};

export const SupplyInventoryData = (Inventory: SupplyInventoryItem[]) => {
  let data: SupplyInventoryExcelItem[] = [];
  Inventory.map((inventoryItem: SupplyInventoryItem, index: number) => {
    data.push({
      Artikel: inventoryItem.article,
      Plombe: inventoryItem.supply,
      Lager: inventoryItem.product,
      Usage: inventoryItem.usage,
      Grade: inventoryItem.final_grade,
      Glassbruch: inventoryItem.broken_glass,
      Preis_Prozen: inventoryItem.price_percent,
      Skala: inventoryItem.final_point,
      VKP_pries: inventoryItem.offer_price,
      Einkauf_pries: inventoryItem.buying_price,
    });
  })
  generateExcelFile(data, `Plombe-` + data[0]?.Plombe);
};


