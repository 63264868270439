

import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import { CButton, CSmartTable } from '@coreui/react-pro';
import {getPendingCustomers, toggleCustomerStatus} from '../../../services/AuthService';
import '../../AdminStyles.css';
import { CustomerDetails } from '../../../types';
import { deleteCustomer } from '../../../services/CustomerService';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../components/utils';

const PendingCustomersScreen = () => {
  const [activePage, setActivePage] = useState(1)
  const [customersData, setCustomersData] = useState<CustomerDetails[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const {PendingCustomersColumns, returnDateFormat} = Utils();
  const {t} = useTranslation();

  const [records, setRecords] = useState<number>(0);
  
  const getPendingCustomersData = () => {
    getPendingCustomers().then((response) => {
      let updatedCustomersArray: CustomerDetails[]= [];
      for (let responseItem of response.data) {
        if(responseItem?.user?.is_active) {
          updatedCustomersArray.push({
            ...responseItem,
            email: responseItem.user.email,
            first_name: responseItem.user.first_name,
            last_name: responseItem.user.last_name,
            phone_number: responseItem.user.phone_number,
            date_joined: responseItem.user.date_joined,
          });
        }       
      }      
      setCustomersData(updatedCustomersArray);
      setRecords(updatedCustomersArray.length);
    }).catch((e) => {
        console.log('error getting pending customers: ', e);
    });
  }
  useEffect(() => {
    getPendingCustomersData();
  }, []);
  const toggleStatus = (id: number) => {
    toggleCustomerStatus(id).then((response) => {
        getPendingCustomersData();
    }).catch((e) => {
        console.log('toggleStatus error: ', e);
    });
  }
  const deleteCustomerData = (id: number) => {
    deleteCustomer(id).then((response) => {
        getPendingCustomersData();
    }).catch((e) => {
      console.log('delete customer error: ', e);
    });
  };
  return (
    <div className='website-settings-main-container'>
      <h1><b>{t('adminDashboard.PendingCustomerRequests.sidebar_text')}</b></h1>
      <CSmartTable
        columns={PendingCustomersColumns}
        items={customersData}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        pagination={true}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(records / itemsPerPage) || 1,
        }}
        tableHeadProps={{
          color: 'info',
        }}
        tableBodyProps={{
          className: 'align-middle'
        }}
        tableProps={{
          hover: true,
          striped: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        scopedColumns={{
          email: (item: CustomerDetails) => {
            return (
              <td className="text-center">
                {item.email}
              </td>
            )
          },
          first_name: (item: CustomerDetails) => {
            return (
              <td className="text-center">
                {item.first_name}
              </td>
            )
          },
          last_name: (item: CustomerDetails) => {
            return (
              <td className="text-center">
                {item.last_name}
              </td>
            )
          },
          jtl_id: (item: CustomerDetails) => {
            return (
              <td className="text-center">
                {item.jtl_id}
              </td>
            )
          },
          company: (item: CustomerDetails) => {
            return (
              <td className="text-center">
                {item.company}
              </td>
            )
          },
          phone_number: (item: CustomerDetails) => {
            return (
              <td className="text-center">
                {item.phone_number}
              </td>
            )
          },
          date_joined: (item: CustomerDetails) => {
            return (
              <td className="text-center">
                {returnDateFormat(item.date_joined)}
              </td>
            )
          },
          show_details: (item: CustomerDetails) => {
            return (
              <td className="text-center">
                <CButton
                  className='elv-btn-ghost'
                  variant="ghost"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleStatus(item.id);
                  }}
                >
                  {t('adminDashboard.CommonWords.accept')}
                </CButton>
                <CButton
                  color="danger"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    deleteCustomerData(item.id);
                  }}
                  style={{
                    marginLeft: 12,
                    color: 'white',
                  }}
                >
                   {t('adminDashboard.CommonWords.delete')}
                </CButton>
              </td>
            )
          },
        }}
      />
    </div>
  );
};
export default PendingCustomersScreen;
