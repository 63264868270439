import { CButton, CCol, CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import { t } from "i18next";
import Select, {components} from 'react-select';
import { DropDownItem } from "../../../types";
import { useState } from "react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { ELV_BLUE, FONT_15 } from "../../../constants";
import { addPalletProduct } from "../../../services/PalletService";

interface PalletProductProps {
    pallet_id: number;
    isModalVisible: boolean;
    closeModal: () => void;
    onSuccess: () => void;
    headerText?: string;
}
const AddPalletProductModal = (props: PalletProductProps) => {
    const {isModalVisible, closeModal, headerText, pallet_id, onSuccess} = props;
    const productArticles = useSelector((state: RootState) => state.productSlice.productArticles);
    const articleOptions: DropDownItem[] = productArticles.map((article: string) => ({ value: article, label: article }));
    const [selectedArticle, setSelectedArticle] = useState<string>('');
    const [buyingPrice, setBuyingPrice] = useState<number>(0);
    const [buyingOnlinePrice, setBuyingOnlinePrice] = useState<number>(0);
    const [sellingPrice, setSellingPrice] = useState<number>(0);
    const [quantity, setQuantity] = useState<number>(0);
    const [articleSearchText, setArticleSearchText] = useState<string>('');

    const uploadPalletProduct = () => {
        const formData = {
            selling_price: sellingPrice,
            quantity: quantity,
            buying_online_price: buyingOnlinePrice,
            buying_price: buyingPrice,
            pallet: pallet_id,
            article: selectedArticle
        }
        addPalletProduct(formData).then((response) => {
            onSuccess();
        }).catch((e) => {
            closeModal();
        })
    }
    const isAddButtonVisible = () : boolean => {
        return sellingPrice > 0 && buyingOnlinePrice > 0 && quantity > 0 && selectedArticle.length > 0
    }
    return (
        <div>
            <CModal size="xl" visible={isModalVisible} onClose={closeModal} style={{right: 100}}>
                <CModalHeader className="modal-header">
                    <CModalTitle>{headerText ? headerText : t('adminDashboard.Pallets.pallet_product_detail')}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <h6><b>{t('adminDashboard.Pallets.mandatory_fields_text')}</b></h6>
                    <CRow className="margin-top-24">
                        <CCol xs={12} md={6}>
                            <CFormLabel><b>Articles*</b></CFormLabel>
                            <Select
                                closeMenuOnSelect={false}
                                placeholder={t('adminDashboard.CommonWords.min_3_digits')}
                                noOptionsMessage={({ inputValue: string }) => 'Article not found.' }
                                options={ articleSearchText.length >= 3? articleOptions : [] }
                                onChange={(option: any) => {
                                    setSelectedArticle(option.value)
                                }} 
                                onInputChange = {(option: string)=>{
                                    setArticleSearchText(option)
                                }}
                                styles={{
                                    multiValueLabel: (base) => ({
                                        ...base,
                                        backgroundColor: ELV_BLUE,
                                        color: 'white',
                                        fontSize: FONT_15,
                                    }),
                                }}
                            />
                        </CCol>
                        <CCol xs={12} md={6}>
                            <CFormLabel><b>Buying price</b></CFormLabel>
                            <CFormInput
                                type={"number"}
                                max={1}
                                min={0}
                                placeholder='Enter buying price'
                                value={buyingPrice}
                                onChange={(e) => {
                                    setBuyingPrice(Number(e.target.value));
                                }}
                                style={{marginBottom: 16}}
                            />
                        </CCol>
                        <CCol xs={12} md={6}>
                            <CFormLabel><b>Buying online price*</b></CFormLabel>
                            <CFormInput
                                type={"number"}
                                max={1}
                                min={0}
                                placeholder='Enter buying online price'
                                value={buyingOnlinePrice}
                                onChange={(e) => {
                                    setBuyingOnlinePrice(Number(e.target.value));
                                }}
                                style={{marginBottom: 16}}
                            />
                        </CCol>
                        <CCol xs={12} md={6}>
                            <CFormLabel><b>Selling price*</b></CFormLabel>
                            <CFormInput
                                type={"number"}
                                max={1}
                                min={0}
                                placeholder='Enter sellihng price'
                                value={sellingPrice}
                                onChange={(e) => {
                                    setSellingPrice(Number(e.target.value));
                                }}
                                style={{marginBottom: 16}}
                            />
                        </CCol>
                        <CCol xs={12} md={6}>
                            <CFormLabel><b>Quantity*</b></CFormLabel>
                            <CFormInput
                                type={"number"}
                                max={1}
                                min={0}
                                placeholder='Enter sellihng price'
                                value={quantity}
                                onChange={(e) => {
                                    setQuantity(Number(e.target.value));
                                }}
                                style={{marginBottom: 16}}
                            />
                        </CCol>
                    </CRow>
                    <CButton
                        className='elv-btn margin-top-24'
                        size='lg'
                        onClick={()=>{
                            uploadPalletProduct();
                        }}
                        disabled={!isAddButtonVisible()}
                        
                    >Add product</CButton>
                       
                </CModalBody>
            </CModal>
        </div>
        
    )
};
export default AddPalletProductModal;