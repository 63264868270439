import { CButton, CCard, CCardBody, CCardText, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormCheck, CFormLabel, CFormSwitch, CHeader, CNav, CNavItem, CRow, CToaster } from "@coreui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DropDownItem, DropDownSwitchItem, Supplier, Supply, SupplyInventoryItem, SupplyType } from "../../../types";
import Select from 'react-select';
import { RootState } from "../../../redux/store";
import { editProductSupplies, filterSupplyInventory } from "../../../services/ProductService";
import Legend from "../legend";
import { ELV_BLUE } from "../../../constants";
import GenericConfirmationModal from "../modals/DeleteItemModal";
import { Utils } from "../utils";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import { SupplyInventoryData } from "../GenerateExcelFile";
import { GenericErrorToast, NoDataFoundToast } from "../create-package-components/ToastsComponent";

interface SupplyManagementHeaderProps {
    headerText?: string;
    filterValue: string;
    supplyInventoryData: SupplyInventoryItem[];
    setSupplyInventoryData: (inventoryData: SupplyInventoryItem[] | any) => void;
    setFilterValue:  (value: string) => void;
};

const SupplyInventoryHeader = (props: SupplyManagementHeaderProps) => {
    const {getAllProductSupplies} = Utils();
    const authUser = useSelector((state: RootState) => state.authSlice.user);
    const { headerText, filterValue, setSupplyInventoryData, setFilterValue, supplyInventoryData } = props;
    const suppliers: Supplier[] = useSelector((state: RootState) => state.productSlice.suppliers);
    const supplierOptions: DropDownItem[]= (suppliers.map((supplier: Supplier) => ({ value: supplier.name, label: supplier.name })));
    const supplyTypes: SupplyType[] = useSelector((state: RootState) => state.productSlice.supplyTypes);
    const [supplyTypeOptions, setSupplyTypeOptions] = useState<DropDownItem[]>([]);
    const [currentSupplyTypeValue, setCurrentSupplyTypeValue] = useState<DropDownItem>(supplyTypeOptions[0]);
    const supplies: Supply[] = useSelector((state: RootState) => state.productSlice.productSupplies);
    const [supplyOptions, setSupplyOptions] = useState<DropDownSwitchItem[]>([]);
    const [currentSupplyValue, setCurrentSupplyValue] = useState<DropDownSwitchItem>(supplyOptions[0]);
    const [totalBuyingPrice, setTotalBuyingPrice] = useState<number>(0);
    const [totalOfferPrice, setTotalOfferPrice] = useState<number>(0);
    const [confirmationModal, setConfirmationModal]= useState<boolean>(false);
    const [toast, addToast] = useState<any>(null);

    useEffect(()=>{
        setSupplyOptions(supplies.map((supply: Supply) => ({ value: supply.id, label: `${supply.id}`, completed: supply.is_completed })));
    },[supplies])

    const searchSupplyInventoryData = (supplies: string[]) => {
        const formData = {
            supply: supplies,
        }
        filterSupplyInventory(formData).then((response) => {
            let updatedResponse: any = [];
            let totalBuyingPrice: number = 0;
            let totalOfferPrice: number = 0;
            response.data.map((responseItem: SupplyInventoryItem) => {
                totalBuyingPrice = totalBuyingPrice + Number(responseItem.buying_price);
                totalOfferPrice = totalOfferPrice + Number(responseItem.offer_price);
                responseItem = {
                    ...responseItem,
                    _props: { color: 
                        responseItem.is_extra == false && responseItem.is_scanned == true ? 'success' 
                        : responseItem.is_extra == false && responseItem.is_scanned == false ? 'danger'
                        : 'info'
                    },
                }
                updatedResponse.push(responseItem);
            })
            setTotalOfferPrice(totalOfferPrice);
            setTotalBuyingPrice(totalBuyingPrice);
            setSupplyInventoryData([...updatedResponse]);
            if(updatedResponse.length == 0) {
                addToast(GenericErrorToast(() => {addToast(null)}, 'No Data Found.'));
            }
        }).catch((e) => {
            console.log('error while filtering supply Inventory: ', e);
            addToast(GenericErrorToast(() => {addToast(null)}, 'No Data Found.'));
        })
    }
    const calculateTotalPrices = (extraValue: boolean, scannedValue: boolean, reset?: boolean ) => {
        let totalBuyingPrice = 0;
        let totalOfferPrice = 0
        supplyInventoryData.filter((item) => {
            return (reset) || (item.is_extra == extraValue && item.is_scanned == scannedValue)
        }).map((responseItem: SupplyInventoryItem) => {
            totalBuyingPrice = totalBuyingPrice + Number(responseItem.buying_price);
            totalOfferPrice = totalOfferPrice + Number(responseItem.offer_price);
        })
        setTotalOfferPrice(totalOfferPrice);
        setTotalBuyingPrice(totalBuyingPrice);
    }
    const toggleSupplyStatus = () => {
        let formData = {
            is_completed: !currentSupplyValue.completed,
            completed_by: authUser.id,
        }
        editProductSupplies(currentSupplyValue.value, formData).then((response) => {
            setCurrentSupplyValue({...currentSupplyValue, completed: !currentSupplyValue?.completed});
            setConfirmationModal(false);
            getAllProductSupplies();
        }).catch((e) => {
            setCurrentSupplyValue({label:"", value: "", completed: false});
        });
    }
    return (
        <>
            <CToaster push={toast} placement="top-end" />
            <GenericConfirmationModal
                size={'lg'}
                isModalVisible={confirmationModal}
                closeModal={()=> setConfirmationModal(false)}
                headerText={'Are you sure?'}
                bodyText={currentSupplyValue?.completed ? `Are you sure you want to allow warehouse to scan this supply ${currentSupplyValue?.value}` : `Are you sure you want to mark ${currentSupplyValue?.value} as "scanned completely".`}
                confirmButtonText={'Yes'}
                onClickConfirmation={() => {
                    toggleSupplyStatus();
                }}
            />
            <CHeader className="margin-bottom-24">
                <h1><b>{headerText ? headerText : t('adminDashboard.SupplyInventory.sidebar_text')}</b></h1>
                <CNav style={{justifyContent: 'flex-end', alignItems:'flex-end' }}>
                    <CNavItem>
                        <Legend color={ELV_BLUE}/>
                        <CFormLabel className="margin-top-12">{t('adminDashboard.SupplyInventory.extra')}</CFormLabel>
                    </CNavItem>
                    <CNavItem className="margin-left-24">
                        <Legend color="green"/>
                        <CFormLabel className="margin-top-12">{t('adminDashboard.SupplyInventory.scanned')}</CFormLabel>
                    </CNavItem>
                    <CNavItem className="margin-left-24">
                        <Legend color="red"/>
                        <CFormLabel className="margin-top-12">{t('adminDashboard.SupplyInventory.unscanned_text')}</CFormLabel>
                    </CNavItem>
                </CNav>
                
            </CHeader>
            <CRow>
                <CCol xs={4}>
                    <CFormLabel><b>{t('adminDashboard.SupplyManagement.supplier')}</b></CFormLabel>
                    <Select
                        placeholder={`${t('adminDashboard.CommonWords.select')}`}
                        noOptionsMessage={({ inputValue: string }) => 'Supply-type not found.' }
                        options={supplierOptions}
                        onChange={(option: any) => {
                            const updatedSupplyTypes = supplyTypes.filter((type: SupplyType) => type.supplier === option.value).map((type: SupplyType) => ({ value: type.name, label: type.name }));
                            setCurrentSupplyTypeValue({value: '', label: ''});
                            setCurrentSupplyValue({value: '', label: '', completed: false});
                            setSupplyTypeOptions(updatedSupplyTypes);
                        }}
                    />
                </CCol>

                <CCol xs={4}>
                    <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply_type')}</b></CFormLabel>
                    <Select
                        value={currentSupplyTypeValue}
                        defaultValue={supplyTypeOptions ? supplyTypeOptions[0] : {value: '', label: ''}}
                        placeholder={`${t('adminDashboard.CommonWords.select')}`}
                        noOptionsMessage={({ inputValue: string }) => 'Supply-type not found.' }
                        options={supplyTypeOptions}
                        onChange={(option: any) => {
                            setCurrentSupplyTypeValue(option);
                            setCurrentSupplyValue({value: '', label: '', completed: false});
                            setSupplyOptions(supplies.filter((item: Supply) => item.type === option.value).map((supply: Supply) => ({ value: supply.id, label: supply.id, completed: supply.is_completed })));
                        }}
                    />
                </CCol>
                <CCol xs={4}>
                    <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply')}</b></CFormLabel>
                    <Select
                        value={currentSupplyValue}
                        placeholder={`${t('adminDashboard.CommonWords.select')}`}
                        noOptionsMessage={({ inputValue: string }) => 'Supply not found.' }
                        options={supplyOptions}
                        onChange={(option: any) => {
                            const supply: string = option.value;
                            searchSupplyInventoryData([supply]);
                            setCurrentSupplyValue(option);
                        }}
                    />
                </CCol>
                {
                    supplyInventoryData.length > 0 ?
                        <CCol xs={12} className="margin-top-36">
                            <CRow className='align-row-right'>
                                <CCol xs={12} className="margin-top-12">
                                    <CCard>
                                        <CCardBody>
                                            <CRow>
                                                <CCol xs={4}>
                                                    <CCardText>Scanned Items: {supplyInventoryData.filter((item: SupplyInventoryItem) => {
                                                        return item.is_extra == false && item.is_scanned == true 
                                                    }).length}</CCardText>
                                                    <CCardText>Not Scanned Items: {supplyInventoryData.filter((item: SupplyInventoryItem) => {
                                                        return item.is_extra == false && item.is_scanned == false 
                                                    }).length}</CCardText>
                                                    <CCardText>Extra Items: {supplyInventoryData.filter((item: SupplyInventoryItem) => {
                                                        return item.is_extra == true
                                                    }).length}</CCardText>
                                                </CCol>
                                                <CCol xs={4}>
                                                    <CRow>
                                                        <CCardText>{t('adminDashboard.Packages.total_items')}: {supplyInventoryData.length}</CCardText>
                                                        <CCardText>{t('adminDashboard.Packages.total_buying_price')}: {totalBuyingPrice?.toFixed(2)} €</CCardText>
                                                        <CCardText>{t('adminDashboard.Packages.total_buying_offer')}: {totalOfferPrice?.toFixed(2)} €</CCardText>
                                                    </CRow>
                                                </CCol>
                                                <CCol xs={4}>
                                                    <CCardText>Status: {currentSupplyValue.completed ? 'scanned completely' : 'scanning in progress'}
                                                        <CFormSwitch 
                                                            label={currentSupplyValue.completed ? "scanned completely" : "scanning in progress" }
                                                            className='margin-top-6' 
                                                            checked={currentSupplyValue.completed}
                                                            onChange={(e)=>{
                                                                setConfirmationModal(true);
                                                            }}
                                                        />
                                                    </CCardText>
                                                    <CCardText>                                                       
                                                        <CButton className="margin-top-12" size="lg" color="light" onClick={() => {SupplyInventoryData(supplyInventoryData)}}>
                                                            Download Excel <CIcon icon={cilCloudDownload} size="lg"/>
                                                        </CButton>
                                                    </CCardText>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol xs={12} className="margin-top-12 align-col-right">
                                    <CDropdown className='margin-left-16'>
                                        <CDropdownToggle href="#" color="light">{filterValue?.length > 0 ? filterValue : 'Select'}</CDropdownToggle>
                                        <CDropdownMenu>
                                            <CDropdownItem onClick=
                                                {(e: any) => {
                                                    calculateTotalPrices(false, true);
                                                    setFilterValue('scanned')
                                                }}>
                                                Scanned
                                            </CDropdownItem>
                                            <CDropdownItem onClick=
                                                {(e: any) => {
                                                    calculateTotalPrices(false, false);
                                                    setFilterValue("not_scanned")
                                                }}>
                                                Not Scanned
                                            </CDropdownItem>
                                            <CDropdownItem onClick=
                                                {(e: any) => {
                                                    calculateTotalPrices(true, true);
                                                    setFilterValue("extra")
                                                }}>
                                                Extra
                                            </CDropdownItem>
                                            <CDropdownItem onClick=
                                                {(e: any) => {
                                                    calculateTotalPrices(true, true, true);
                                                    setFilterValue("")
                                                }}>
                                                All
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </CCol>
                            </CRow>
                        </CCol>
                    : null
                }
                
            </CRow>
        </>
    )
};

export default SupplyInventoryHeader;
