import React, { useEffect } from 'react'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavLink,
} from '@coreui/react'
import {
  cilBell,
  cilEnvelopeLetter,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { logoutUser } from '../../services/AuthService'
import avatar8 from '../../assets/images/8.jpg'
import { NavLink, useNavigate } from 'react-router-dom'
import { setIsAuthenticated, setLoggedinUser } from '../../redux/slices/AuthSlice';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { setSelectedSetArticles, setSelectedProducts, setPackageProducts, setSetArticles, setCart, setPackages } from '../../redux/slices/PackageSlice'
import { LoggedinUser } from '../../types'
import { Utils } from '../../admin/components/utils'
import { useTranslation } from 'react-i18next'
import { GR, RO, RU, DE, US } from 'country-flag-icons/react/3x2';

const CustomerHeaderDropdown = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const isAuthenticated = useSelector((state: RootState) => state.authSlice.isAuthenticated);
  const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
  const {logout} = Utils();

  useEffect(()=> {
    if(isAuthenticated === false) {
      // navigation('/logout');
    }
  },[isAuthenticated])
  
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0" caret={false}>
        <CNavLink className='white-icon'>
          <CIcon icon={cilUser} size="lg" />
        </CNavLink>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">{t('adminDashboard.CommonWords.account')}</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          {authUser.first_name} {authUser.last_name} 
          {
          authUser.preferred_language == 'en' ?   <US title="US" className='flag-style'/> 
          : authUser.preferred_language == 'de' ? <DE title="DE" className='flag-style'/>
          : authUser.preferred_language == 'ro' ? <RO title="RO" className='flag-style'/>
          : authUser.preferred_language == 'ru' ? <RU title="RU" className='flag-style'/>
          : authUser.preferred_language == 'gr' ? <GR title="GR" className='flag-style'/>
          : null }
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeLetter} className="me-2" />
          {authUser.email}
        </CDropdownItem>
        <CDropdownHeader className="bg-light fw-semibold py-2">{t('adminDashboard.CommonWords.more')}</CDropdownHeader>
        <CDropdownItem onClick={() => navigation('/customer/settings')}>
          <CIcon icon={cilSettings} className="me-2" />
          {t('adminDashboard.WebsiteSettings.settings')}
        </CDropdownItem>
        <CDropdownItem onClick={logout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          {t('adminDashboard.CommonWords.logout')}
        </CDropdownItem>
      
      </CDropdownMenu>
    </CDropdown>
  )
}

export default CustomerHeaderDropdown;
