import { combineReducers } from 'redux';
import authSlice from './AuthSlice';
import catalogSlice from './CatalogSlice';
import adminSlice from './AdminSlice';
import usersSlice from './UsersSlice';
import packageSlice from './PackageSlice';
import warehousePackageSlice from './WarehousePackageSlice';
import productSlice from './ProductSlice';
import orderSlice from './OrderSlice';
import { salesApi } from '../api/basicApi';
import CustomerActivePackagesSlice from './CustomerActivePackagesSlice';
import CustomerTotalOrdersSlice from './CustomerTotalOrdersSlice';
import CustomerCartSlice from './CustomerCartSlice';

export const rootReducer = combineReducers({
    authSlice,
    adminSlice,
    usersSlice,
    [salesApi.reducerPath]: salesApi.reducer,
    packageSlice,
    productSlice,
    orderSlice,
    CustomerActivePackagesSlice,
    CustomerTotalOrdersSlice,
    CustomerCartSlice,
    catalogSlice,
    warehousePackageSlice,
})