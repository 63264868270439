import { useEffect } from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilBell,
  cilLockLocked,
  cilSettings,
  cilUser,
  cilEnvelopeLetter,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../../redux/store'

import { Utils } from '../utils'
import { LoggedinUser } from '../../../types'
import { useTranslation } from 'react-i18next'

const AdminHeaderDropdown = () => {
  const navigation = useNavigate();
  const {t} = useTranslation();
  const {logout} = Utils();
  const isAuthenticated = useSelector((state: RootState) => state.authSlice.isAuthenticated);  
  const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);

  useEffect(()=> {
    if(isAuthenticated === false) {
      navigation('/logout');
    }
  },[isAuthenticated]);

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0" caret={false}>
        <CIcon icon={cilUser} size="lg" className='white'/>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">{t('adminDashboard.CommonWords.account')}</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          {authUser.first_name} {authUser.last_name}
        </CDropdownItem>

        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeLetter} className="me-2" />
          {authUser.email}
        </CDropdownItem>
        <CDropdownHeader className="bg-light fw-semibold py-2">{t('adminDashboard.CommonWords.role')}</CDropdownHeader>
        <CDropdownItem href="#">
          {authUser.groups}
        </CDropdownItem>

        <CDropdownHeader className="bg-light fw-semibold py-2">{t('adminDashboard.CommonWords.more')}</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          {t('adminDashboard.WebsiteSettings.settings')}
        </CDropdownItem>
        <CDropdownItem onClick={logout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          {t('adminDashboard.CommonWords.logout')}
        </CDropdownItem>
      
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AdminHeaderDropdown
