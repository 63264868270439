import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react";
import { useTranslation } from "react-i18next";

interface ConfirmPackageModalProps {
    note: string;
    showModal: boolean;
    productCart: number[];
    handleCloseModal: () => void;
    handleChangeInfo: (value: any) => void;
    handleConfirmPackage: () => void;
}
const ConfirmPackageModal = (props: ConfirmPackageModalProps) => {
    const {note, showModal, productCart, handleCloseModal, handleChangeInfo, handleConfirmPackage} = props;
    const {t} = useTranslation();
    return (
        <CModal visible={showModal} onClose={handleCloseModal}>
            <CModalHeader closeButton>
                <div>
                    <b>{t('adminDashboard.Packages.confirm_package')}</b>
                </div>
                {/* <CIcon icon={cilWarning} className="text-warning mr-2" /> */}
            </CModalHeader>
            <CModalBody>
                <form>
                    <div className="mb-3">
                        <div>
                            <div>{t('adminDashboard.Packages.you_have_selected')} <b>{productCart.length}</b> {t('adminDashboard.Packages.items')}</div>
                        </div>
                        <label htmlFor="infoTextArea" className="form-label">{t('adminDashboard.Packages.message')}:</label>
                        <textarea
                            className="form-control"
                            id="infoTextArea"
                            placeholder={`${t('adminDashboard.Packages.type_your_messsage_here')}`}
                            rows={3}
                            value={note}
                            onChange={handleChangeInfo}
                        />
                    </div>
                </form>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={handleCloseModal}>
                {t('adminDashboard.CommonWords.close')}
                </CButton>
                <CButton color="primary" onClick={handleConfirmPackage}>
                {t('adminDashboard.CommonWords.confirm')}
                </CButton>
            </CModalFooter>
        </CModal>
    )
};
export default ConfirmPackageModal;