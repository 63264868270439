import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton,
    CForm, CFormInput, CFormLabel, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormFeedback } 
    from "@coreui/react";
import { Supplier } from "../../../types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { editSuppliers } from "../../../services/ProductService";
import { setProductSupplies } from "../../../redux/slices/ProductSlice";

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    selectedSupplierItem: Supplier | undefined;
    closeModal: () => void;
    onClickConfirmation: () => void;
    confirmButtonText: string;
}
const EditSupplierModal = (props: ModalProps) => {
    const [nameValid, setNameValid] = useState<boolean>(true);
    const {isModalVisible, closeModal, headerText, onClickConfirmation, bodyText, confirmButtonText, selectedSupplierItem} = props; 
    const supplierId = selectedSupplierItem ? selectedSupplierItem.id : 0; 
    const [supplierName, setSupplierName] = useState<string>(selectedSupplierItem ? selectedSupplierItem.name : '');

    const editSupplier = () => {
        let formData = {           
            name: supplierName,
        }
        editSuppliers(supplierId, formData).then((response) => {
            setNameValid(true);
            onClickConfirmation();
        }).catch((e) => {
            if (e.response.request.status === 400) {
                setNameValid(false);
            }
        })
    };
    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal}>
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{bodyText}</div>
                <CForm style={{marginBottom: 16, marginTop: 24}}>
                    <CFormLabel><b>Supplier Name</b></CFormLabel>
                    <CFormInput
                        type={"string"}
                        placeholder='Supplier name'
                        value={supplierName}
                        onChange={(e) => {
                            setSupplierName(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />
                    <CFormFeedback invalid={nameValid} style={{color: 'red'}}>Supplier with this name already exists.</CFormFeedback>
                </CForm>
            </CModalBody>
            
            <CModalFooter>
                <CButton color="primary" onClick={editSupplier}>{confirmButtonText}</CButton>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default EditSupplierModal;