import { useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CSmartTable } from '@coreui/react-pro';
import SupplyInventoryHeader from '../../components/supply-inventory';

import { SupplyInventoryItem } from '../../../types';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../components/utils';
const SupplyInventoryScreen = () => {
  const [activePageScanned, setActivePageScanned] = useState(1);
  const [supplyInventoryData, setSupplyInventoryData] = useState<
    SupplyInventoryItem[]
  >([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const { t } = useTranslation();
  const { SupplyInventoryColumns } = Utils();

  return (
    <div className="website-settings-main-container">
      <SupplyInventoryHeader
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        supplyInventoryData={supplyInventoryData}
        setSupplyInventoryData={setSupplyInventoryData}
      />
      <div className="margin-top-44">
        {supplyInventoryData.length > 0 ? (
          <CSmartTable
            columns={SupplyInventoryColumns}
            tableFilter
            tableFilterLabel={`${t("adminDashboard.CommonWords.search")}`}
            tableFilterPlaceholder={`${t("adminDashboard.CommonWords.search")}`}
            items={supplyInventoryData.filter((item: SupplyInventoryItem) => {
              if (filterValue == "scanned") {
                return item.is_extra == false && item.is_scanned == true;
              } else if (filterValue == "not_scanned") {
                return item.is_extra == false && item.is_scanned == false;
              } else if (filterValue == "extra") {
                return item.is_extra == true;
              } else {
                return true;
              }
            })}
            itemsPerPage={supplyInventoryData.length}
            tableHeadProps={{
              color: "light",
            }}
            tableProps={{
              hover: true,
              responsive: true,
              style: { marginBottom: 24 },
              striped: true,
            }}
            onActivePageChange={(activePage) =>
              setActivePageScanned(activePage)
            }
            onColumnFilterChange={(filter) => {
              setActivePageScanned(1);
            }}
            tableBodyProps={{
              className: "align-middle",
            }}
            onItemsPerPageChange={(itemsPerPage) => {
              setActivePageScanned(1);
            }}
            scopedColumns={{
              article: (item: any) => {
                return <td className="text-center">{item.article}</td>;
              },
              supply: (item: any) => {
                return <td className="text-center">{item.supply}</td>;
              },
              product: (item: any) => {
                return <td className="text-center">{item.product}</td>;
              },
              usage: (item: any) => {
                return <td className="text-center">{item.usage}</td>;
              },
              final_grade: (item: any) => {
                return <td className="text-center">{item.final_grade}</td>;
              },
              broken_glass: (item: any) => {
                return <td className="text-center">{item.broken_glass}</td>;
              },
              price_percent: (item: any) => {
                return <td className="text-center">{item.price_percent}</td>;
              },
              scale: (item: any) => {
                return <td className="text-center">{item.final_point}</td>;
              },
              offer_price: (item: any) => {
                return <td className="text-center">{item.offer_price}</td>;
              },
              buying_price: (item: any) => {
                return <td className="text-center">{item.buying_price}</td>;
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SupplyInventoryScreen;
