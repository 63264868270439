import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../admin/AdminStyles.css';
import { Package, ProductDetailedSetArticle, ProductItem } from '../../types';
import ProductDetailsCardComponent from '../components/ProductDetailsCardComponent';
import { getProductById } from '../../services/ProductService';
import { CSpinner, CButton } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { formattedPrice } from '../../constants';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../admin/components/utils';

const ProductDetailScreen = () => {
  const { t } = useTranslation();
  const {getAllProductCategoryQuestions} = Utils();
  const productDataString = localStorage.getItem('product');
  const productObject: ProductDetailedSetArticle = productDataString ? JSON.parse(productDataString) : null;
  const [combinedProduct, setCombinedProduct] = useState<ProductDetailedSetArticle>();
  const [product, setProduct] = useState<ProductDetailedSetArticle>();
  const [loading, setLoading] = useState<boolean>(true);
  
  const packageItemString = localStorage.getItem('packageItem');
  const packageItem: Package = packageItemString ? JSON.parse(packageItemString) : null;
  const productCartSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.productCart;
  const totalPriceSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.totalPrice;
  const productCart = useSelector(productCartSelector(packageItem?.id)) || [];
  const totalPrice = useSelector(totalPriceSelector(packageItem?.id)) || 0;
  const [combineProductId, setCombinedProductId] = useState<number[]>();

  useEffect(() => {
    packageItem?.set_articles.map((item: ProductDetailedSetArticle) => {
      if (productObject?.combined_products?.length > 0 && item.id === productObject?.combined_products[0] && item.id !== productObject.id) {
        getProductById(item.id).then((response) => {
          setCombinedProduct(response.data);
          setCombinedProductId([item.id]);
          setLoading(false);
        }).catch((e) => {
          console.log('get products error: ', e);
          setLoading(false);
        });
      }
    });
    getAllProductCategoryQuestions();
    getProductById(productObject.id).then((response) => {
      setProduct(response.data);
      setLoading(false);
    }).catch((e) => {
      console.log('get products error: ', e);
      setLoading(false);
    });
    // packageItem?.id((item: ProductDetailedSetArticle | ProductItem ) => { 

    // });
  }, []);

  const images = product?.image_list?.map((item: any) => {
    return {
      question_number: item,
      url: `https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${product.id}&filename=${item}.jpg`
    }
  })

  const combinedProductImages = combinedProduct && combinedProduct.image_list?.map((item: any) => {
    return {
      question_number: item,
      url: `https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${combinedProduct.id}&filename=${item}.jpg`
    }
  });

  return (
    <div>
      <div className='website-settings-main-container'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <h3><b>{t('adminDashboard.Products.product_details')}</b></h3>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <h6><b>{t('adminDashboard.Packages.total_items')}: {productCart?.length}</b></h6>
            <h6><b>{t('adminDashboard.Packages.grand_total_euro')} {formattedPrice(totalPrice)}</b></h6>
          </div>
        </div>
        {
          loading ?
            <CSpinner />
            :
            <>
            {
              product &&
                <div>
                  <ProductDetailsCardComponent 
                    images={images} 
                    product={product} 
                    combinedProductId={combineProductId} 
                    showButton={true}
                    productEditable={false}
                  />
                </div>
            }
              {
                combinedProduct &&
                  <div className='margin-top-24'>
                    <ProductDetailsCardComponent 
                      images={combinedProductImages}
                      product={combinedProduct}
                      combinedProductId={combineProductId}
                      showButton={false}
                      productEditable={false}
                    />
                  </div>
              }
            </>
        }
      </div>
    </div>
  );
};
export default ProductDetailScreen;

