import axios from "axios";
import { BASE_URL } from "../constants";


export const getUserScanningTime = async() => {
    return await axios.get(
        BASE_URL + 'User_scanning_time',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getMonthlyProfitData = async(year: string) => {
    return await axios.post(
        BASE_URL + 'monthly_profit', 
        {
            'year': year
        }
        )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getMonthlyTurnoverData = async(year: string) => {
    return await axios.post(
        BASE_URL + 'monthly_turnover',
        {
            'year': year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getYearlyProfitData = async() => {
    return await axios.get(
        BASE_URL + 'yearly_profit',
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getYearlyTurnoverData = async() => {
    return await axios.get(
        BASE_URL + 'yearly_turnover',
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getSalesPersonYearlyData = async(salesPersonArray: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'Salesperson_yearly_sales',
        {
            "SalesPerson": salesPersonArray,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getSalesPersonMonthlyData = async(salesPersonArray: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'Salesperson_monthly_sales',
        {
            "SalesPerson": salesPersonArray,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getSalesPersonCategorySales = async(salesPersonArray: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'Salesperson_category_sales',
        {
            "SalesPerson": salesPersonArray,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getSalesPersonBrandSales = async(salesPersonArray: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'Salesperson_brand_sales',
        {
            "SalesPerson": salesPersonArray,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getSalesPersonGradeSales = async(salesPersonArray: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'Salesperson_grade_sales',
        {
            "SalesPerson": salesPersonArray,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getYearlyCompaniesData = async(companies: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'company_yearly_order',
        {
            "company": companies,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getMonthlyCompaniesData = async(companies: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'company_monthly_order',
        {
            "company": companies,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getCompaniesCategoriesData = async(companies: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'company_category_sales',
        {
            "company": companies,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getCompaniesBrandsData = async(companies: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'company_brand_sales',
        {
            "company": companies,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getCompaniesGradesData = async(companies: string[], year: string) => {
    return await axios.post(
        BASE_URL + 'company_grade_sales',
        {
            "company": companies,
            "year":year,
        }
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getSupplyAnalysisData = async(supply_id = "9651726") => {
    return await axios.post(
        BASE_URL + 'Supply_Analysis',
        {"Supply_id" :supply_id}
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getStockAnalysisData = async(lagerList: string[]) => {
    return await axios.post(
        BASE_URL + 'Stock_Analysis',
        {"lager" : lagerList}
    )
    .then((response) => { 
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getAllSalesPerson = async() => {
    return await axios.get(
        BASE_URL + 'get_all_Salesperson',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getAllCompanies = async() => {
    return await axios.get(
        BASE_URL + 'get_all_Companies',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
export const getAllSupplies = async() => {
    return await axios.get(
        BASE_URL + 'Get_all_supplies',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};
