import { CSmartTable } from "@coreui/react-pro";
import { useState } from "react";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Supply } from "../../../types";
import CIcon from "@coreui/icons-react";
import { cilPen } from "@coreui/icons";
import EditSupplyModal from "../modals/EditSupplyModal";
import DeleteItemModal from "../modals/DeleteItemModal";
import { deleteProductSupply } from "../../../services/ProductService";
import SupplyInfoModal from "../modals/SupplyInfoModal";
import { Utils } from "../utils";
import { useTranslation } from "react-i18next";

interface SuppliesProps {
    showEditedToast: () => void;
    showDeletedToast: () => void;
}

const AllSupplies = (props: SuppliesProps) => {
    const {showDeletedToast, showEditedToast} = props;
    const {t} = useTranslation();
    const {getAllProductSupplies, AllSuppliesColumns} = Utils();   
    const supplies = useSelector((state: RootState) => state.productSlice.productSupplies);
    const [showInfoSupplyModal, setShowInfoSupplyModal] = useState<boolean>(false);
    const [showEditSupplyModal, setShowEditSupplyModal] = useState<boolean>(false);
    const [showDeleteSupplyModal, setShowDeleteSupplyModal] = useState<boolean>(false);
    const [selectedSupplyItem, setSelectedSupplyItem] = useState<Supply>();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);

    const getAllSupplies = () => {
        getAllProductSupplies();
        setShowEditSupplyModal(false);
    }
    const deleteSupplyItem = () => {
        deleteProductSupply(selectedSupplyItem ? selectedSupplyItem.id : '').then((response) => {
            getAllSupplies();
            setShowDeleteSupplyModal(false);
            showDeletedToast();
        }).catch((e) => {
        })
    }
    return (
        <div>
            {
                showInfoSupplyModal ?
                    <SupplyInfoModal 
                        isModalVisible= {showInfoSupplyModal}
                        headerText={"Supply information"}
                        bodyText={""}
                        selectedSupplyItem = {selectedSupplyItem}
                        closeModal = {() => setShowInfoSupplyModal(false)}
                    /> :  null
            }
            {
                showDeleteSupplyModal ?
                    <DeleteItemModal 
                        isModalVisible={showDeleteSupplyModal}
                        headerText={"Are you sure?"}
                        bodyText={"This will delete the item permanently."}
                        closeModal={() => setShowDeleteSupplyModal(false)}
                        confirmButtonText={"Delete"}
                        onClickConfirmation={deleteSupplyItem}
                    /> : null
            }
            {
                showEditSupplyModal ?
                    <EditSupplyModal 
                        isModalVisible={showEditSupplyModal}
                        headerText={"Edit supply"}
                        bodyText={""}
                        selectedSupplyItem={selectedSupplyItem}
                        closeModal={() => setShowEditSupplyModal(false)}
                        confirmButtonText={"Edit"}
                        onClickConfirmation={() => {getAllSupplies();  showEditedToast();}}
                    /> : null
            }        
            <CSmartTable
                columns={AllSuppliesColumns}
                tableFilter
                tableFilterLabel={`${t('adminDashboard.CommonWords.search')}`}
                tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
                items={supplies}               
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                pagination={true}
                paginationProps={{
                    activePage: activePage,
                    pages: Math.ceil(supplies.length / itemsPerPage) || 1,
                }}
                tableHeadProps={{
                    color: 'info',
                }}
                tableProps={{
                    hover: true,
                    responsive: true,
                    style:{marginTop: 12},
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                    setActivePage(1)
                }}

                tableBodyProps={{
                    className: 'align-middle'
                }}

                onItemsPerPageChange={(itemsPerPage) => {
                    setActivePage(1)
                    setItemsPerPage(itemsPerPage)
                }}
                itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
                scopedColumns={{
                    id: (item: any) => {
                        return (
                          <td className='text-center'>
                            {item.id}
                          </td>
                        )
                    },
                    is_fresh: (item: Supply) => {
                        return (
                            <td className="text-center">
                                {
                                    item.is_fresh ? `New supply (${item.is_allowed ? 'Allowed to Sales' : 'Not allowed to Sales'})` : `Old supply (${item.is_allowed ? 'Allowed to Sales' : 'Not allowed to Sales'})`
                                }
                            </td>
                        )
                    },
                    type: (item: any) => {
                        return (
                          <td className='text-center'>
                            {item.type}
                          </td>
                        )
                    },
                    available_products_count: (item: any) => {
                        return (
                          <td className='text-center'>
                            {item.available_products_count}
                          </td>
                        )
                    },
                    total_products: (item: any) => {
                        return (
                          <td className='text-center'>
                            {item.total_products}
                          </td>
                        )
                    },
                    show_details: (item: Supply) => {
                        return (
                            <td className="text-center">
                                {/* <CIcon icon={cilInfo} size="xl" style={{color: '#23B2D9'}} onClick={() => { setSelectedSupplyItem(item); setShowInfoSupplyModal(true); }} /> */}
                                <CIcon icon={cilPen} size="xl" style={{color: '#23B2D9', marginLeft: 24}} onClick={() => {setSelectedSupplyItem(item); setShowEditSupplyModal(true); }}/>                               
                            </td>
                        )
                    },
                }}
            />
        </div>
    )
};
export default AllSupplies;
