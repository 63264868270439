import { CCard, CCardImage, CCardBody, CPlaceholder, CCardTitle, CCardText, CButton, CCol, CRow } from "@coreui/react";

const PlaceHolderItem = () => {
    return (
        <CCard style={{ width: '18rem' }}>
            <CCardImage component="svg" orientation="top" width="100%" height="162" role="img" aria-label="Placeholder">
            <title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect>
            </CCardImage>
            <CCardBody>
            <CPlaceholder component={CCardTitle} animation="glow" xs={7}>
                <CPlaceholder xs={6} />
            </CPlaceholder>
            <CPlaceholder component={CCardText} animation="glow">
                <CPlaceholder xs={7} />
                <CPlaceholder xs={4} />
                <CPlaceholder xs={4} />
                <CPlaceholder xs={6} />
                <CPlaceholder xs={8} />
            </CPlaceholder>
            <CPlaceholder component={CButton} tabIndex={-1} xs={6}></CPlaceholder>
            </CCardBody>
        </CCard>
    )
};

const PlaceHolder = () => {
    return (
        <CRow>
            <CCol xs={12} md={4} style={{marginTop: 24 ,marginBottom: 24, }}>
                <PlaceHolderItem />                  
            </CCol>
            <CCol xs={12} md={4} style={{marginTop: 24,  marginBottom: 24,}}>
                <PlaceHolderItem />                    
            </CCol>
            <CCol xs={12} md={4} style={{marginTop: 24,  marginBottom: 24, }}>
                <PlaceHolderItem />                   
            </CCol>

            <CCol xs={12} md={4} style={{marginTop: 24 ,marginBottom: 24, }}>
                <PlaceHolderItem />                  
            </CCol>
            <CCol xs={12} md={4} style={{marginTop: 24,  marginBottom: 24,}}>
                <PlaceHolderItem />                    
            </CCol>
            <CCol xs={12} md={4} style={{marginTop: 24,  marginBottom: 24, }}>
                <PlaceHolderItem />                   
            </CCol>

            <CCol xs={12} md={4} style={{marginTop: 24 ,marginBottom: 24, }}>
                <PlaceHolderItem />                  
            </CCol>
            <CCol xs={12} md={4} style={{marginTop: 24,  marginBottom: 24,}}>
                <PlaceHolderItem />                    
            </CCol>
            <CCol xs={12} md={4} style={{marginTop: 24,  marginBottom: 24, }}>
                <PlaceHolderItem />                   
            </CCol>
        </CRow>
    )
}
export default PlaceHolder;
