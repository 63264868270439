import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react';
import MainLogo from '../../../assets/images/main-logo.png';
import SmallLogo from '../../../assets/images/small-logo.png';
import { AppSidebarNav } from './SideBarNavigation';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

// sidebar nav config
import items from './SideBarNavItems';
import { RootState } from '../../../redux/store';
import { setSidebarShow } from '../../../redux/slices/AdminSlice';

const AdminSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state: RootState) => state.adminSlice.sidebarUnfoldable)
  const sidebarShow = useSelector((state: RootState) => state.adminSlice.sidebarShow)

  return (
    <CSidebar
      position='fixed'
      unfoldable={unfoldable}
      visible={true}
      onVisibleChange={(visible) => {
        dispatch(setSidebarShow(!sidebarShow))
      }}
      className='side-bar'
      narrow={!sidebarShow}
    >
      <CSidebarBrand className="d-none d-md-flex" style={{backgroundColor: 'transparent'}}>
        <img className="sidebar-brand-full" src={MainLogo} style={{width: 150}} />
        <img className="sidebar-brand-narrow" src={SmallLogo} style={{width: 60}} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav
            // salesAnalyticsDashboardItems={items.salesAnalyticsDashboardItems}
            backOfficeDashboardItems={items.backOfficeDashboardItems}
            salesPersonDashboardItems={items.salesPersonDashboardItems}
            salesAssistantDashboardItems={items.salesAssistantDashboardItems}
            adminDashboardItems={items.adminDashboardItems}
            supplierDashboardItems={items.supplierDashboardItems}
            warehouseManagerDashboardItems={items.warehouseManagerDashboardItems}
          />
        </SimpleBar>
      </CSidebarNav>     
    </CSidebar>
  )
}

export default React.memo(AdminSidebar);
